import axios from "axios";

import {DOCUMENTS, LOADING} from "../actionTypes";
import {adminToken} from "../../helper";

const {GET, RESET, TOGGLE} = DOCUMENTS;

const {SET_TRUE, SET_FALSE} = LOADING;

export const get = (categoryID, cityID) => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .get(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/org-management/documents/category/${categoryID}/city/${cityID}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(res => {
                console.log(res.data)
                dispatch({type: GET, payload: res.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const add = data => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();

        axios
            .post(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/org-management/documents`,
                data,
                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            )
            .then(res => {
                dispatch({type: GET, payload: res.data.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const toggle = (id, type) => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();

        axios
            .patch(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/org-management/documents/${id}`,
                {},
                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            )
            .then(res => {
                console.log(res.data);
                dispatch({type: TOGGLE, payload: {data: res.data.data, type}});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const remove = id => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();

        axios
            .delete(
                `https://cgapp.clykk.com/sp/clykk/ext/sp/v1/org-management/documents/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(res => console.log(res.data))
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const reset = () => dispatch => dispatch({type: RESET});
