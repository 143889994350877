import React, {useEffect, useState} from "react";
import DashboardServiceProviderOverview from "../../components/Dashboard/DashboardServiceProviderOverview";
import DashboardCustomerOverview from "../../components/Dashboard/DashboardCustomerOverview";
import {adminToken} from "../../helper";
import axios from "axios";
import clsx from "clsx";
import DashboardCommissions from "../../components/Dashboard/DashboardCommissions";
import DashboardOrders from "../../components/Dashboard/DashboardOrders";

const Dashboard = () => {

    const [state, setState] = useState(1)

    const [serviceProviderSum, setServiceProviderSum] = useState(0)

    const [customerSum, setCustomerSum] = useState(0)

    useEffect(() => {
        adminToken()
            .then(token =>
                axios.get(
                    `https://cgapp.clykk.com/um/clykk/int/um/v1/get-user-list?pageNumber=1&pageSize=1`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                ).then(response => {
                    setCustomerSum(response.data.data.count)
                }))

        adminToken()
            .then(token =>
                axios.get(
                    `https://cgapp.clykk.com/sp/clykk/int/sp/v1/get-user-list?pageNumber=1&pageSize=1`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                ).then(response => {
                    setServiceProviderSum(response.data.count)
                }))
    }, [])

    // customers
    const [customerDatesFilter, setCustomerDatesFilter] = useState({
        start: new Date().toISOString(),
        end: new Date(new Date() - 1000 * 60 * 60 * 24 * 14).toISOString()
    })
    const onCustomerDateChange = (e) => {
        setCustomerDatesFilter(
            {...customerDatesFilter, [e.target.name]: e.target.value}
        )
    }


    // SP
    const [SPDatesFilter, setSPDatesFilter] = useState({
        start: new Date().toISOString(),
        end: new Date(new Date() - 1000 * 60 * 60 * 24 * 14).toISOString()
    })
    const onSPDateChange = (e) => {
        setSPDatesFilter(
            {...SPDatesFilter, [e.target.name]: e.target.value}
        )
    }


    // commissions

    const [datesFilter, setDatesFilter] = useState({
        start: new Date().toISOString(),
        end: new Date(new Date() - 1000 * 60 * 60 * 24 * 14).toISOString()
    })

    const onDateChange = (e) => {
        setDatesFilter(
            {...datesFilter, [e.target.name]: e.target.value}
        )
    }

    const [data, setData] = useState([])
    const [response, setResponse] = useState([])

    const [filter, setFilter] = useState("")

    const onChange = (e) => {
        setFilter(e.target.value)
    }

    const [commissionsMeta, setCommissionsMeta] = useState({
        grossRevenue: 0,
        grossPreTaxRevenue: 0,
        spCommissions: 0,
        clykkFee: 0,
    })

    useEffect(() => {
        adminToken()
            .then(token =>
                axios.get(
                    `https://clapp.clykk.com/sp_payments/public/index.php/api/getsppaymentswithdetails`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                ).then(res => {
                    setResponse(res.data.data)
                    setFilter("")
                }))
    }, [])

    const [commissionsStatus, setCommissionsStatus] = useState('done')

    useEffect(() => {
        if (response.length > 0) {
            if (filter === '') {
                let data = response.filter((payment) => {
                    return payment.get_subscription_details[0].service_date >= datesFilter.end && payment.get_subscription_details[0].service_date <= datesFilter.start && payment.payment_status === commissionsStatus
                })
                setData(data.sort((a, b) => new Date(b.get_subscription_details[0].service_date) - new Date(a.get_subscription_details[0].service_date)))
            } else {
                let data = response.filter((payment) => {
                    return payment.get_subscription_details[0].service_date >= datesFilter.end && payment.get_subscription_details[0].service_date <= datesFilter.start && payment.payment_status === commissionsStatus && payment.sp_id.toString() === filter
                })
                setData(data.sort((a, b) => new Date(b.get_subscription_details[0].service_date) - new Date(a.get_subscription_details[0].service_date)))
            }
        }
    }, [response, datesFilter, commissionsStatus])

    const calculate_fees = (item, commissions) => {
        item.get_payment_transaction.map((_item) => {
            let subscription = item.get_subscription_transaction.filter(item => item.id == _item.transaction_id)
            if (subscription.length !== 0) {
                subscription = subscription[0]
            } else {
                subscription = {
                    payable_amount: 0,
                }
            }

            if (parseFloat(_item.amount_before_tax)) {
                commissions.grossPreTaxRevenue += parseFloat(_item.amount_before_tax)
            }
            if (parseFloat(subscription.payable_amount)) {
                commissions.grossRevenue += parseFloat(subscription.payable_amount)
            }

            if (_item.amount_before_tax && _item.commission_mount) {
                commissions.clykkFee += parseFloat(_item.amount_before_tax) - parseFloat(_item.commission_mount)
            }
        })
    }

    useEffect(() => {
        let commissions = {
            grossRevenue: 0,
            grossPreTaxRevenue: 0,
            spCommissions: 0,
            clykkFee: 0,
        }

        data.map((payment) => {
            commissions.spCommissions += parseFloat(payment.amount)
            calculate_fees(payment, commissions)
        })

        setCommissionsMeta(commissions)

    }, [data])


    // orders


    const [orderDatesFilter, setOrderDatesFilter] = useState({
        start: new Date().toISOString(),
        end: new Date(new Date() - 1000 * 60 * 60 * 24 * 60).toISOString()
    })

    const onOrderDateChange = (e) => {
        setOrderDatesFilter(
            {...orderDatesFilter, [e.target.name]: e.target.value}
        )
    }

    const [orderData, setOrderData] = useState([])
    const [orderResponse, setOrderResponse] = useState([])

    const [orderFilter, setOrderFilter] = useState("")

    const onOrderChange = (e) => {
        setOrderFilter(e.target.value)
    }

    const [orderMeta, setOrderMeta] = useState({
        grossRevenue: 0,
        grossPreTaxRevenue: 0,
        spCommissions: 0,
        clykkFee: 0
    })

    useEffect(() => {
        console.log("orderDatesFilter =>", orderDatesFilter)
        adminToken()
            .then((token) =>
                axios.get(
                    `https://clapp.clykk.com/routing_management/public/index.php/api/getallorderswdetails?from_date=${orderDatesFilter.end.slice(0,10)}&to_date=${orderDatesFilter.start.slice(0,10)}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                ).then(res => {
                    setOrderResponse(res.data.data)
                    setOrderFilter("")
                }))
    }, [orderDatesFilter])

    const [orderStatus, setOrderStatus] = useState('Completed')

    useEffect(() => {
        if (orderResponse.length > 0) {
            // if (orderFilter === '') {

            let data = orderResponse.filter((payment) => {
                return payment.service_date >= orderDatesFilter.end && payment.service_date <= orderDatesFilter.start && payment.status === orderStatus
            })
            console.log("data =>", data.sort((a, b) => new Date(b.service_date) - new Date(a.service_date)))

            setOrderData(data.sort((a, b) => new Date(b.service_date) - new Date(a.service_date)))

            // }
            // else {
            //     let data = orderResponse.filter((payment) => {
            //         return payment.service_date >= orderDatesFilter.end && payment.service_date <= orderDatesFilter.start && payment.status === orderStatus
            // && payment.sp_id.toString() === orderFilter
            //     })
            //     setOrderData(data.sort((a, b) => new Date(b.service_date) - new Date(a.service_date)))
            // }
        }
    }, [orderResponse, orderDatesFilter, orderStatus])

    const orders_calculate_fees = (item, commissions) => {
        if(item.get_s_p_payments.length > 0) {
            item.get_s_p_payments[0].get_payment_transaction.map((_item) => {
                let subscription = item.get_sub_transactions.filter(item => item.id == _item.transaction_id)
                if (subscription.length !== 0) {
                    subscription = subscription[0]
                } else {
                    subscription = {
                        payable_amount: 0,
                    }
                }

                if (parseFloat(_item.commission_mount)) {
                    commissions.spCommissions += parseFloat(_item.commission_mount)
                }

                if (parseFloat(_item.amount_before_tax)) {
                    commissions.grossPreTaxRevenue += parseFloat(_item.amount_before_tax)
                }
                if (parseFloat(subscription.payable_amount)) {
                    commissions.grossRevenue += parseFloat(subscription.payable_amount)
                }

                if (_item.amount_before_tax && _item.commission_mount) {
                    commissions.clykkFee += parseFloat(_item.amount_before_tax) - parseFloat(_item.commission_mount)
                }
            })
        }
    }

    useEffect(() => {
        let orders = {
            grossRevenue: 0,
            grossPreTaxRevenue: 0,
            spCommissions: 0,
            clykkFee: 0,
        }

        orderData.map((payment) => {
            orders_calculate_fees(payment, orders)
        })

        setOrderMeta(orders)

    }, [orderData])

    return (
        <div style={{padding: 20}}>
            <div className={'d-flex mb-4'}>

                <div className={clsx('dashboard-overview-panel', state === 1 && 'shadow')} onClick={() => {
                    setState(1)
                }}>
                    <p style={{fontSize: 17, textAlign: 'center'}}>Customers</p>
                    <div style={{display: "flex", marginBottom: 10}}>
                        <input style={{width: 200}} type={'date'} value={customerDatesFilter.end.slice(0, 10)}
                               onChange={onCustomerDateChange}
                               name={'end'}
                        />
                        <p style={{margin: "auto 10px"}}>to</p>
                        <input style={{margin: "auto 0", width: 200}} className="form-control" type={'date'}
                               value={customerDatesFilter.start.slice(0, 10)}
                               onChange={onCustomerDateChange}
                               name={'start'}
                        />
                    </div>
                    <p style={{fontSize: 13}}>Total Customers {customerSum}</p>
                </div>

                <div className={clsx('dashboard-overview-panel', state === 2 && 'shadow')} onClick={() => {
                    setState(2)
                }}>
                    <p style={{fontSize: 17, textAlign: 'center'}}>Service Provider</p>
                    <div style={{display: "flex", marginBottom: 10}}>
                        <input style={{width: 200}} type={'date'} value={SPDatesFilter.end.slice(0, 10)} onChange={onSPDateChange}
                               name={'end'}
                        />
                        <p style={{margin: "auto 10px"}}>to</p>
                        <input style={{margin: "auto 0", width: 200}} className="form-control" type={'date'}
                               value={SPDatesFilter.start.slice(0, 10)}
                               onChange={onSPDateChange}
                               name={'start'}
                        />
                    </div>
                    <p style={{fontSize: 13}}>Total Service Providers {serviceProviderSum}</p>
                </div>


                <div
                    className={clsx('dashboard-overview-panel', state === 3 && 'shadow')} onClick={() => {
                    setState(3)
                }}
                >
                    <p style={{fontSize: 17, textAlign: 'center'}}>
                        Commissions
                    </p>
                    <div>
                        <div style={{display: "flex", marginBottom: 10}}>
                            <input style={{width: 200}} type={'date'} value={datesFilter.end.slice(0, 10)} onChange={onDateChange}
                                   name={'end'}
                            />
                            <p style={{margin: "auto 10px"}}>to</p>
                            <input style={{margin: "auto 0", width: 200}} className="form-control" type={'date'}
                                   value={datesFilter.start.slice(0, 10)}
                                   onChange={onDateChange}
                                   name={'start'}
                            />
                        </div>
                    </div>
                    <p style={{fontSize: 13, padding: 0, margin: 0}}>
                        Gross Revenue: {commissionsMeta.grossRevenue.toFixed(2)}
                    </p>
                    <p style={{fontSize: 13, padding: 0, margin: 0}}>
                        Gross Pre-Tax Revenue: {commissionsMeta.grossPreTaxRevenue.toFixed(2)}
                    </p>
                    <p style={{fontSize: 13, padding: 0, margin: 0}}>
                        SP Commissions: {commissionsMeta.spCommissions.toFixed(2)}
                    </p>
                    <p style={{fontSize: 13, padding: 0, margin: 0}}>
                        CLYKK Fee: {commissionsMeta.clykkFee.toFixed(2)}
                    </p>
                </div>

                <div
                    className={clsx('dashboard-overview-panel', state === 4 && 'shadow')} onClick={() => {
                    setState(4)
                }}
                >
                    <p style={{fontSize: 17, textAlign: 'center'}}>
                        Orders
                    </p>
                    <div>
                        <div style={{display: "flex", marginBottom: 10}}>
                            <input style={{width: 200}} type={'date'} value={orderDatesFilter.end.slice(0, 10)}
                                   onChange={onOrderDateChange}
                                   name={'end'}
                            />
                            <p style={{margin: "auto 10px"}}>to</p>
                            <input style={{margin: "auto 0", width: 200}} className="form-control" type={'date'}
                                   value={orderDatesFilter.start.slice(0, 10)}
                                   onChange={onOrderDateChange}
                                   name={'start'}
                            />
                        </div>
                    </div>
                    <p style={{fontSize: 13, padding: 0, margin: 0}}>
                        Gross Revenue: {orderMeta.grossRevenue.toFixed(2)}
                    </p>
                    <p style={{fontSize: 13, padding: 0, margin: 0}}>
                        Gross Pre-Tax Revenue: {orderMeta.grossPreTaxRevenue.toFixed(2)}
                    </p>
                    <p style={{fontSize: 13, padding: 0, margin: 0}}>
                        SP Commissions: {orderMeta.spCommissions.toFixed(2)}
                    </p>
                    <p style={{fontSize: 13, padding: 0, margin: 0}}>
                        CLYKK Fee: {orderMeta.clykkFee.toFixed(2)}
                    </p>
                </div>

            </div>
            <div style={{backgroundColor: "white", padding: 20, borderRadius: 10}}>
                {state === 1 &&
                    <DashboardCustomerOverview datesFilter={customerDatesFilter} setDatesFilter={setCustomerDatesFilter}/>
                }

                {state === 2 &&
                    <DashboardServiceProviderOverview datesFilter={SPDatesFilter} setDatesFilter={setSPDatesFilter}/>
                }

                {state === 3 &&
                    <DashboardCommissions
                        response={response}
                        data={data}
                        setData={setData}
                        filter={filter}
                        datesFilter={datesFilter}
                        onDateChange={onDateChange}
                        onChange={onChange}
                        setCommissionsMeta={setCommissionsMeta}
                        setCommissionsStatus={setCommissionsStatus}
                    />
                }

                {state === 4 &&
                    <DashboardOrders
                        response={orderResponse}
                        data={orderData}
                        setData={setOrderData}
                        filter={orderFilter}
                        datesFilter={orderDatesFilter}
                        onDateChange={onOrderDateChange}
                        onChange={onOrderChange}
                        setOrdersMeta={setOrderMeta}
                        setOrdersStatus={setOrderStatus}
                    />
                }
            </div>
        </div>
    )
}
export default Dashboard;
