import React, {useEffect, useState, useMemo} from "react";
import {MdEdit} from "react-icons/md";
import styles from "./styles.module.scss";
import {
    MultiTable,
    SearchButton,
    LightTextButton,
    FilterDropdown,
    SwitchButton,
    Icon
} from "components";
import UpdateAnswer from "../popups/SPAppPreference/UpdateAnswer";
import UpdateQuestion from "../popups/SPAppPreference/UpdateQuestion";
import {spAppPreferencesAction} from "redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {CgAddR} from "react-icons/cg";

const AppPreference = () => {
    // Redux
    const dispatch = useDispatch();
    const {preferences, userRole} = useSelector(state => ({
        preferences: state.spAppPreferences,
        userRole: state.userRole?.filter(role => role.module === "App Preferences")
    }));
    // States
    const [tableRows, setTableRows] = useState();
    const [editableDetail, setEditableDetails] = useState();
    const [isQuestionModal, setQuestionModal] = useState();
    const [isAnswerModal, setAnswerModal] = useState();

    // Filter States
    const [status, setStatus] = useState();

    // Fetch Package Add On
    useEffect(() => {
        if (userRole?.find(item => item.privilege === "Read"))
            dispatch(spAppPreferencesAction.get());

        return () => dispatch(spAppPreferencesAction.reset());
    }, [dispatch]);

    useEffect(() => {
        if (!preferences?.length) return;

        let filtered = preferences;

        // filter based on Status
        filtered = filtered?.filter(item =>
            status === "Enabled"
                ? item.enabled
                : status === "Disabled"
                    ? !item.enabled
                    : item
        );

        const data = filtered.map((qna, index) => {
            let modifiedQA = {
                ...qna,
                edit: (
                    <MdEdit
                        size="20"
                        color="#528ff0"
                        onClick={
                            userRole?.find(item => item.privilege === "Update")
                                ? () => {
                                    setEditableDetails(qna);
                                    setQuestionModal(true);
                                }
                                : null
                        }
                        className="clickable"
                    />
                )
            };

            modifiedQA.options = qna.options.map(ans => ({
                id: ans.id,
                display_name: ans.optionName,
                display_text: ans.optionText,
                enabled: (
                    <SwitchButton
                        enabled={ans.enabled}
                        onClick={
                            userRole?.find(item => item.privilege === "Enable")
                                ? () =>
                                    dispatch(spAppPreferencesAction.toggleAns(qna.id, ans.id))
                                : null
                        }
                    />
                ),
                edit: (
                    <MdEdit
                        size="20"
                        color="#528ff0"
                        onClick={
                            userRole?.find(item => item.privilege === "Update")
                                ? () => {
                                    setEditableDetails({...ans, pid: qna.id});
                                    setAnswerModal(true);
                                }
                                : null
                        }
                        className="clickable"
                    />
                ),
                addAns: " "
            }));

            return modifiedQA;
        });
        setTableRows(data);
    }, [preferences, status, dispatch]);

    // Search
    const onSearch = () => {
    };

    // MultiTable Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "Question Name"
            },
            {
                title: "Question Help Text"
            },
            {
                title: "Enabled"
            },
            {
                title: "Edit"
            }
        ],
        []
    );

    const secTableHeaders = useMemo(
        () => [
            {
                title: "ID"
            },
            {
                title: "Option Name"
            },
            {
                title: "Option Text"
            },
            {
                title: "Enabled"
            },
            {
                title: "Edit"
            }
        ],
        []
    );

    return (
        <div>
            <div className={styles.filter}>
                <FilterDropdown
                    onChange={e => setStatus(e.target.value)}
                    value={status}
                    label="Status"
                    options={["All", "Enabled", "Disabled"]}
                />
                <SearchButton onClick={onSearch}/>
                <LightTextButton/>
            </div>

            {tableRows?.length
                ? tableRows.map((qna, index) => (
                    <MultiTable
                        key={index}
                        headers={tableHeaders}
                        data={[
                            {
                                categoryName: qna.categoryName,
                                categoryHelpText: qna.categoryHelpText,
                                enabled: (
                                    <SwitchButton
                                        enabled={qna.enabled}
                                        onClick={
                                            userRole?.find(item => item.privilege === "Enable")
                                                ? () =>
                                                    dispatch(spAppPreferencesAction.toggle(qna.id))
                                                : null
                                        }
                                    />
                                ),
                                edit: qna.edit
                            }
                        ]}
                        secHeaders={[
                            ...secTableHeaders,
                            {
                                title: (
                                    <Icon
                                        ReactIcon={CgAddR}
                                        name="CgAddR"
                                        color="primary"
                                        onClick={
                                            userRole?.find(item => item.privilege === "Create")
                                                ? () => {
                                                    setEditableDetails({pid: qna.id});
                                                    setAnswerModal(true);
                                                }
                                                : null
                                        }
                                    />
                                )
                            }
                        ]}
                        secData={qna.options}
                    />
                ))
                : null}

            {isQuestionModal && (
                <UpdateQuestion
                    data={editableDetail}
                    closeModal={() => setQuestionModal(false)}
                />
            )}

            {isAnswerModal && (
                <UpdateAnswer
                    data={editableDetail}
                    closeModal={() => setAnswerModal(false)}
                />
            )}
        </div>
    );
};

export default AppPreference;
