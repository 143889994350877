import {GET_USER_SHARES, RESET_USER_SHARES} from "../actionTypes";

const userShares = (state = [], action) => {
    switch (action.type) {
        case GET_USER_SHARES:
            return action.payload;

        case RESET_USER_SHARES:
            return [];

        default:
            return state;
    }
};

export default userShares;
