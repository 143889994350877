import { SUBSCRIPTION_ROUTING } from "../actionTypes";
const { GET, ADD, UPDATE, TOGGLE, RESET } = SUBSCRIPTION_ROUTING;

export default function subscriptionRoutingReducer(state = null, action) {
  switch (action.type) {
    case GET:
      return action.payload;

    case ADD:
      return action.payload;

    case UPDATE:
      return state.map(item =>
        item.id === action.payload.id ? action.payload : item
      );

    case TOGGLE:
      return {
        ...state,
        org: state.org.map(item =>
          item.ID === action.payload.ID ? action.payload : item
        )
      };

    case RESET:
      return null;

    default:
      return state;
  }
}
