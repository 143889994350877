import React, { useState, useEffect } from "react";
import { SideModal, Tabs } from "components";
import { modalTabRoutes } from "../tab-routes";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const FAQTopic = () => {
  // Routers
  const { id } = useParams();

  const faqInfo = useSelector(state => state.faqInfo);
  const [titles, setTitles] = useState();

  useEffect(() => {
    const faq = faqInfo;

    const titleArr = [{ key: "FAQ Name", value: faq?.faqName }];
    setTitles(titleArr);
  }, [id]);

  return (
    <SideModal title="FAQ Info ID" titles={titles}>
      <Tabs routes={modalTabRoutes} />
    </SideModal>
  );
};

export default FAQTopic;
