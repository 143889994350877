import {
  GET_SP_ORDERS,
  ADD_SP_ORDERS,
  TOGGLE_SP_ORDERS,
  UPDATE_SP_ORDERS,
  RESET_SP_ORDERS
} from "../actionTypes";

export default function spOrdersReducer(state = null, action) {
  switch (action.type) {
    case GET_SP_ORDERS:
      return action.payload;

    case ADD_SP_ORDERS:
      return action.payload;

    case UPDATE_SP_ORDERS:
      return state.map(item =>
        item.id === action.payload.id ? action.payload : item
      );

    case TOGGLE_SP_ORDERS:
      return state.map(item =>
        item.id !== action.payload.id
          ? item
          : { ...item, enabled: !item.enabled }
      );

    case RESET_SP_ORDERS:
      return null;

    default:
      return state;
  }
}
