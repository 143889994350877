import React, { useState, useEffect } from "react";
import { Modal, FilterDropdown } from "components";
import { useDispatch, useSelector } from "react-redux";
import { adminRolesAction } from "redux/actions";

export default function AdminRoles({ closeModal, data }) {
  const dispatch = useDispatch();
  const { roles, users, privileges } = useSelector(state => state);

  const [details, setDetails] = useState({
    role_id: "",
    admin_id: "",
    privilege_id: ""
  });

  useEffect(() => {
    setDetails(data);
  }, [data]);

  const onSave = () => {
    if (data) {
      dispatch(adminRolesAction.update(details));
    } else {
      dispatch(adminRolesAction.add(details));
    }
    closeModal();
  };

  const handleChange = setter => event =>
    setter(prev => ({
      ...prev,
      [event.target.name]: event.target.value
    }));

  return (
    <Modal
      title={data ? "Edit Dashboard Privilege" : "Create Dashboard Privilege"}
      closeModal={closeModal}
      onSave={onSave}>
      {data ? (
        <FilterDropdown
          label="Select Privilege"
          name="privilege_id"
          options={[
            "Select Privilege",
            ...(privileges?.map(privilege => privilege?.name) || [])
          ]}
          values={[
            0,
            ...(privileges?.map(privilege => privilege?.privilege_id) || [])
          ]}
          onChange={handleChange(setDetails)}
          value={details?.privilege_id}
        />
      ) : (
        <>
          <FilterDropdown
            label="Select Role"
            name="role_id"
            options={[
              "Select Role",
              ...(roles?.map(role => role?.role_name) || [])
            ]}
            values={[0, ...(roles?.map(role => role?.role_id) || [])]}
            onChange={handleChange(setDetails)}
            value={details?.role_id}
          />

          <FilterDropdown
            label="Select User"
            name="admin_id"
            options={[
              "Select User",
              ...(users?.map(
                user => `${user?.first_name} ${user?.last_name}`
              ) || [])
            ]}
            values={[0, ...(users?.map(user => user?.admin_id) || [])]}
            onChange={handleChange(setDetails)}
            value={details?.admin_id}
          />
        </>
      )}
    </Modal>
  );
}
