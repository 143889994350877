import {GET_USER, RESET_USER, GET_USER_ROLES} from "../actionTypes";
import axios from "axios";
import {AWS_URL_UM_8089, BASE_URL_NEW} from "configurations";
import Cookies from "js-cookie";

export const login = mobile => {
    return dispatch => {
        axios
            .post("http://15.207.247.51:8089/clykk/ext/um/v1/sign-up", {
                mobile,
                countryCode: "92"
            })
            .then(res => {
                // Cookies.set("user", {
                //     firstName: res.data?.firstName,
                //     mobile: res.data?.mobile
                // });
                localStorage.setItem("user", {
                    firstName: res.data?.firstName,
                    mobile: res.data?.mobile
                });
                dispatch({type: GET_USER, payload: res.data});
            })
            .catch(err => {
                dispatch({type: RESET_USER, payload: undefined});
                console.log(err);
            });
    };
};

export const getProfile =
    ({mobile, password, countryCode, rawPhone}) =>
        async dispatch => {
            try {
                // fetch token
                let token = await axios.post(`${BASE_URL_NEW}/otpverify`, {
                    mobile,
                    password
                });

                token = token.data?.token;

                if (!token) throw new Error("Unauthorized");

                // fetch profile data
                let profile = await axios.get(`${BASE_URL_NEW}/profile`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                });

                profile = profile.data?.user;

                console.log(
                    JSON.stringify({
                        user_id: profile?.admin_id,
                        token
                    })
                );

                // fetch all permissions
                let userPermissions = await axios.post(
                    `${BASE_URL_NEW}/getusermodulespermission`,
                    {user_id: profile?.admin_id},
                    {
                        headers: {
                            Authorization: `bearer ${token}`
                        }
                    }
                );

                userPermissions = userPermissions.data?.data?.modules_privileges;

                const user = {
                    ...profile,
                    token
                };
                // Cookies.set("user_roles", JSON.stringify(userPermissions));
                localStorage.setItem("user_roles", JSON.stringify(userPermissions))

                // Cookies.set("user", JSON.stringify(user));
                localStorage.setItem("user", JSON.stringify(user));

                dispatch({type: GET_USER, payload: user});
                dispatch({type: GET_USER_ROLES, payload: userPermissions});
            } catch (err) {
                throw new Error(err.message);
            }
        };

// export const logout = () => dispatch => dispatch({type: "USER_LOGOUT"});

export const logout = (history) => {
    localStorage.clear();
    history.push('/login')
}
