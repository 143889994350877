import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdEdit } from "react-icons/md";
import styles from "./styles.module.scss";
import {
  Table,
  TextInput,
  SearchButton,
  LightTextButton,
  FilterDropdown,
  SwitchButton,
  ImageStamp
} from "components";
import { subCategoriesAction } from "redux/actions";
import SubCategoryPopup from "../popups/SubCategory/SubCategoryPopup";
import { internalSearch } from "helper";

const initialState = {
  name: "",
  enabled: ""
};

const SubCategory = () => {
  // States
  const [selectedCategory, setSelectedCategory] = useState();
  const [tableRows, setTableRows] = useState([]);
  const [editableDetail, setEditableDetails] = useState();
  const [isPopupVisible, setPopupVisible] = useState();

  // Filter States
  const [filterState, setFilterState] = useState(initialState);

  // Redux actions
  const dispatch = useDispatch();

  const { categories, subCategories, userRole } = useSelector(state => ({
    categories: state.categories,
    subCategories: state.subCategories,
    userRole: state.userRole?.filter(role => role.module === "Category")
  }));

  useEffect(() => {
    let filtered = subCategories;

    if (!filtered?.length) {
      setTableRows();
      return;
    }

    filtered = internalSearch(filterState, "name", filtered);
    filtered = internalSearch(filterState, "enabled", filtered);

    // get category name by category Id
    const categoryName =
      filtered?.length &&
      categories?.find(category => category.id === filtered[0]?.categoryID);

    // Map table rows
    const data = filtered?.map(subCategory => ({
      categoryName: `${categoryName.name}`,
      name: `${subCategory.name}`,
      description: `${subCategory.description}`,
      image: <ImageStamp src={subCategory.image} />,
      displayName: `${subCategory.displayName}`,
      displayOrder: `${subCategory.displayOrder}`,
      serviceRules: subCategory?.serviceRule?.map(service => (
        <p>{service.type}</p>
      )),
      createdAt: `${new Date(subCategory.createdAt).toLocaleString()}`,
      createdBy: subCategory.createdBy,
      updatedAt: `${new Date(subCategory.updatedAt).toLocaleString()}`,
      updatedBy: subCategory.updatedBy,
      enabled: (
        <SwitchButton
          enabled={subCategory.enabled}
          onClick={
            userRole?.find(item => item.privilege === "Enable")
              ? () =>
                  dispatch(
                    subCategoriesAction.toggleSubCategory(
                      subCategory.categoryID,
                      subCategory.id
                    )
                  )
              : null
          }
        />
      ),
      edit: (
        <MdEdit
          size="20"
          color="#528ff0"
          onClick={
            userRole?.find(item => item.privilege === "Update")
              ? () => {
                  setEditableDetails(subCategory);
                  setPopupVisible(true);
                }
              : null
          }
          className="clickable"
        />
      )
    }));

    setTableRows(data);
  }, [subCategories, filterState, dispatch, categories]);

  // Search based on category name
  const onSearch = () => {
    if (selectedCategory === "Pick a category" || !selectedCategory) return;

    const categoryId = categories.find(
      category => selectedCategory === category.name && category
    );
    dispatch(subCategoriesAction.get(categoryId.id));
  };

  // Table Header
  const tableHeaders = useMemo(
    () => [
      {
        title: "Category"
      },
      {
        title: "Sub Category",
        sortBy: "name"
      },
      {
        title: "Description"
      },
      {
        title: "Image"
      },
      {
        title: "Display Name"
      },
      {
        title: "Display Order",
        sortBy: "displayOrder"
      },
      {
        title: "Service Rules"
      },
      {
        title: "Created At",
        sortBy: "createdAt"
      },
      {
        title: "Created By"
      },
      {
        title: "Updated At",
        sortBy: "updatedAt"
      },
      {
        title: "Updated By"
      },
      {
        title: "Enabled"
      },
      {
        title: "Edit"
      }
    ],
    [tableRows]
  );

  return (
    <div>
      <div className={styles.filter}>
        <FilterDropdown
          onChange={e => setSelectedCategory(e.target.value)}
          value={selectedCategory}
          label="Category Name"
          options={
            categories?.length
              ? [
                  "Pick a category",
                  ...categories?.map(category => category.name)
                ]
              : ["Pick a category"]
          }
        />
        <TextInput
          search
          label="Sub Category Name"
          name="name"
          value={filterState?.name}
          onChange={e =>
            setFilterState({ ...filterState, [e.target.name]: e.target.value })
          }
        />
        <FilterDropdown
          label="Status"
          name="enabled"
          value={filterState?.enabled}
          options={["All", "Enabled", "Disabled"]}
          values={["", true, false]}
          onChange={e =>
            setFilterState({ ...filterState, [e.target.name]: e.target.value })
          }
        />
        <SearchButton
          onClick={
            userRole?.find(item => item.privilege === "Read") ? onSearch : null
          }
        />
        <LightTextButton
          onClick={() => {
            setFilterState(initialState);
            setSelectedCategory("Pick a category");
          }}
        />
      </div>
      <Table headers={tableHeaders} data={tableRows} />
      {isPopupVisible && (
        <SubCategoryPopup
          data={editableDetail}
          closeModal={() => setPopupVisible(false)}
        />
      )}
    </div>
  );
};

export default SubCategory;
