import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {Table} from "../../../components";

function SubscriptionTab(props) {
    // Redux
    const subscription = useSelector(state => state.subsDetail);

    const [tableRows, setTableRows] = useState();

    useEffect(() => {
        let filtered = subscription;

        if (!filtered?.cartID) {
            setTableRows();
            return;
        }

        // Map table rows
        filtered = {
            cartID: filtered?.cartID,
            id: filtered?.id,
            status: filtered?.status,
            serviceDate: filtered?.serviceDate,
            serviceTime: filtered?.serviceTime,
            updatedAt: `${new Date(filtered?.updatedAt).toLocaleString()}`,
            reschedCount: filtered?.reschedCount
        };

        setTableRows([filtered]);
    }, []);

    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "Order ID"
            },
            {
                title: "Subscription ID",
                sortBy: "id"
            },
            {
                title: "Status"
            },
            {
                title: "Service Date",
                sortBy: "serviceDate"
            },
            {
                title: "Service Time"
            },
            {
                title: "Updated",
                sortBy: "updatedAt"
            },
            {
                title: "ReScheduled Count"
            }
        ],
        [tableRows]
    );
    return <Table headers={tableHeaders} data={tableRows}/>;
}

export default SubscriptionTab;