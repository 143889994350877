import React, { useEffect, useState, useMemo } from "react";
import { Table } from "components";
import PackageAddOnPopup from "../popups/PackageAddOn/PackageAddOnPopup";
import { useHistory } from "react-router-dom";
import { packageLikeAction } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";

const PackageLike = () => {
  // Routers
  const pid = useHistory().location.pathname.split("/")[3];

  // Redux
  const dispatch = useDispatch();

  const { packageLike, userRole } = useSelector(state => ({
    packageLike: state.packageLike,
    userRole: state.userRole?.filter(role => role.module === "Users")
  }));

  // States
  const [tableRows, setTableRows] = useState();
  const [editableDetail, setEditableDetails] = useState();
  const [isPopupVisible, setPopupVisible] = useState();

  // Filter States
  const [status, setStatus] = useState();

  // Fetch Package Add On
  useEffect(() => {
    if (userRole?.find(item => item.privilege === "Read"))
      dispatch(packageLikeAction.get(pid));

    return () => dispatch(packageLikeAction.reset());
  }, [dispatch]);

  useEffect(() => {
    let filtered = packageLike?.[0]?.users;

    if (!filtered?.length) return;

    // filter based on Status
    filtered = filtered?.filter(item =>
      status === "Enabled"
        ? item.enabled
        : status === "Disabled"
        ? !item.enabled
        : item
    );

    // Map table rows
    const data = filtered?.map(obj => ({
      id: obj?.id,
      firstName: obj?.firstName,
      lastName: obj?.lastName,
      likedOn: new Date(obj?.likedOn).toLocaleString()
    }));

    setTableRows(data);
  }, [packageLike, status]);

  // Search based on category & sub category name
  const onSearch = () => {};

  // Table Header
  const tableHeaders = useMemo(
    () => [
      {
        title: "ID"
      },
      {
        title: "First Name"
      },
      {
        title: "Last Name"
      },
      {
        title: "Created At",
        sortBy: "likedOn"
      }
    ],
    [tableRows]
  );

  return (
    <div>
      <Table headers={tableHeaders} data={tableRows} />
      {isPopupVisible && (
        <PackageAddOnPopup
          data={editableDetail}
          closeModal={() => setPopupVisible(false)}
        />
      )}
    </div>
  );
};

export default PackageLike;
