import { SET_ERROR } from '../actionTypes';

export default function errorReducer( state='', action ){
    
    switch(action.type){
        
        case SET_ERROR:
            return action.payload;
    
        default:
            return state;
    }

}