import { GET_FAQ_TOPIC, ADD_FAQ_TOPIC, TOGGLE_FAQ_TOPIC, UPDATE_FAQ_TOPIC, RESET_FAQ_TOPIC } from '../actionTypes'


const packageQnAReducer = ( state = [], action ) => {
    
    switch(action.type){
        
        case GET_FAQ_TOPIC:
            return action.payload

        case ADD_FAQ_TOPIC:
            return action.payload;

        case UPDATE_FAQ_TOPIC:
            return action.payload;
        
        case TOGGLE_FAQ_TOPIC:
            return action.payload;
        
        case RESET_FAQ_TOPIC:
            return [];
      
        default:
            return state;
    }

}

export default packageQnAReducer;