import React, {useEffect} from "react";
import {TextInput, FilterDropdown, Alert, Button} from "components";
import {useDispatch} from "react-redux";
import {subOrgAction} from "redux/actions";
import styles from "../styles.module.scss";

export default function CategoryDetails({onClick, details, setDetails}) {
    const dispatch = useDispatch();

    // Get sub-organizations base on organziation
    useEffect(() => {
        if (details?.orgID) dispatch(subOrgAction.get());
        else {
            dispatch(subOrgAction.reset());
        }
    }, [details?.orgID]);

    return (
        <div className={styles.main__container}>
            <Alert/>
            <FilterDropdown
                onChange={setDetails}
                value={details?.discount}
                label="Discount"
                name="discount"
                options={[
                    "Pick a discount",
                    "Deal",
                    "Offer",
                    "Coupon",
                    "Earned Credit"
                ]}
                values={[0, "deal", "offer", "coupon", "earnedCredit"]}
            />
            <FilterDropdown
                onChange={setDetails}
                value={details?.discountType}
                label="Discount Type"
                name="discountType"
                options={["Pick a discount type", "Percent", "Value"]}
                values={[0, "percent", "value"]}
            />
            <TextInput
                label="Min Discount"
                name="minDiscount"
                onChange={setDetails}
                value={details?.minDiscount}
                type="number"
            />
            <TextInput
                label="Max Discount"
                placeholder="Max Discount"
                name="maxDiscount"
                onChange={setDetails}
                value={details?.maxDiscount}
                type="number"
            />
            <div className={styles.btn__container}>
                <Button title="Next" onClick={onClick}/>
            </div>
        </div>
    );
}
