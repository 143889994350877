import axios from "axios";

import {
  GET_PACKAGES,
  TOGGLE_PACKAGE,
  UPDATE_PACKAGE,
  ADD_PACKAGE,
  RESET_PACKAGE,
  LOADING
} from "../actionTypes";
import { AWS_URL_CM_8090 } from "configurations";
import { adminToken } from "helper";
import uploadImage from "helper/uploadImage";

const { SET_TRUE, SET_FALSE } = LOADING;

export const get = categoryId => {
  return async dispatch => {
    dispatch({ type: SET_TRUE });

    try {
      const token = await adminToken();

      const res = await axios.get(
        `${AWS_URL_CM_8090}/sub-categories/${categoryId}/package`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      dispatch({ type: GET_PACKAGES, payload: res.data });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({ type: SET_FALSE });
    }
  };
};

export const addPackage = (details = {}) => {
  const {
    packageName,
    shortDescription,
    basePrice,
    sellingPrice,
    subCategoryServiceRuleID,
    subcategoryID,
    packageImage,
    displayOrder,
    offer,
    offerURL,
    tags,
    cityID,
    cityName
  } = details;

  return async dispatch => {
    dispatch({ type: SET_TRUE });

    try {
      // Request to Upload Image
      const imgUrl = await uploadImage(packageImage, "catalogue-management");

      const token = await adminToken();

      // Request to Create Pacakge
      const { data } = await axios.post(
        `${AWS_URL_CM_8090}/sub-categories/${subcategoryID}/package`,
        {
          packageName,
          shortDescription,
          basePrice: parseFloat(basePrice),
          sellingPrice: parseFloat(sellingPrice),
          subCategoryServiceRuleID: parseInt(subCategoryServiceRuleID),
          displayOrder: parseInt(displayOrder),
          packageImage: imgUrl,
          enabled: true,
          offer,
          offerURL,
          tags,
          cityID: parseInt(cityID),
          cityName
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      dispatch({ type: ADD_PACKAGE, payload: data });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({ type: SET_FALSE });
    }
  };
};

// Update Package
export const updatePackage = (details = {}) => {
  const {
    id,
    packageName,
    shortDescription,
    basePrice,
    sellingPrice,
    subCategoryServiceRuleID,
    subcategoryID,
    packageImage,
    displayOrder,
    offer,
    offerURL,
    tags,
    cityID,
    cityName
  } = details;

  console.log(details);

  return async dispatch => {
    dispatch({ type: SET_TRUE });

    try {
      let imgUrl;

      // Request to Upload Image
      if (typeof packageImage === "object")
        imgUrl = await uploadImage(packageImage, "catalogue-management");

      const token = await adminToken();

      // Request to Create Category
      const finalRes = await axios.put(
        `${AWS_URL_CM_8090}/sub-categories/${subcategoryID}/package/${id}`,
        {
          packageName,
          shortDescription,
          basePrice: parseFloat(basePrice),
          sellingPrice: parseFloat(sellingPrice),
          subCategoryServiceRuleID: parseInt(subCategoryServiceRuleID),
          displayOrder: parseInt(displayOrder),
          packageImage: imgUrl || packageImage,
          offer,
          offerURL,
          tags,
          cityID: parseInt(cityID),
          cityName
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      dispatch({ type: UPDATE_PACKAGE, payload: finalRes.data });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({ type: SET_FALSE });
    }
  };
};

export const toggle = (details = {}) => {
  const { id, subcategoryID, enabled } = details;

  console.log(details);

  return async dispatch => {
    dispatch({ type: SET_TRUE });

    try {
      const token = await adminToken();

      // Request to Create Category
      const finalRes = await axios.put(
        `${AWS_URL_CM_8090}/sub-categories/${subcategoryID}/package/${id}`,
        {
          ...details,
          enabled: !enabled
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      dispatch({ type: UPDATE_PACKAGE, payload: finalRes.data });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({ type: SET_FALSE });
    }
  };
};

export const reset = () => ({ type: RESET_PACKAGE });
