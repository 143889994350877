import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import styles from "./../styles.module.scss";
import {TextInput, Button, FilterDropdown} from "components";

export default function Details({onClick, details, setDetails, edit}) {
    const faqScreens = useSelector(state => state.faqScreens);
    const names = faqScreens?.map(item => item.name); //make an arry of names
    const IDs = faqScreens?.map(item => item.id);

    return (
        <div className={styles.main__container}>
            <FilterDropdown
                onChange={setDetails}
                name="faqScreenID"
                label="FAQ Screen"
                options={["Pick a faq screen", ...names]}
                value={details?.faqScreenID}
                values={[0, ...IDs]}
                disabled={edit}
            />
            <TextInput
                label="Name"
                placeholder="Name"
                name="faqName"
                onChange={setDetails}
                value={details?.faqName}
                textarea={true}
            />
            <TextInput
                label="Description"
                placeholder="Description"
                name="shortDescription"
                onChange={setDetails}
                value={details?.shortDescription}
                textarea={true}
            />
            <TextInput
                label="Display Order"
                placeholder="Display Order"
                name="displayOrder"
                onChange={setDetails}
                value={details?.displayOrder}
            />
            <div className={styles.btn__container}>
                <Button title="Next" onClick={onClick}/>
            </div>
        </div>
    );
}
