import React from "react";
import styles from "./styles.module.scss";
import {BiSearchAlt} from "react-icons/bi";
import {SearchButton} from "components";

export default function Search({onClick, clear, label, ...props}) {
    return (
        <>
            <div className={styles.container} style={{justifyContent: "start"}}>
                <p>{label}</p>
                <div className={styles.input__container}>
                    <input className={styles.input} placeholder="Search" {...props} />
                    <BiSearchAlt size="15" color="#000" className={styles.search__icon}/>
                </div>

                <SearchButton onClick={onClick} title="Search"/>

                <p onClick={clear} style={{margin: "auto 10px", color: "#528ff0", cursor: "pointer"}}>Clear</p>
            </div>
        </>
    );
}
