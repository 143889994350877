import React from 'react';
import styles from './styles.module.scss';
import {TransparentBG, Button} from "../index";
import {IoIosClose} from "react-icons/io";

export default function Modal({title, children, closeModal, onSave}) {
    return (
        <TransparentBG>
            <div className={styles.content__container}>
                <h5>{title}</h5>
                <IoIosClose
                    size={20}
                    className={styles.icon__close}
                    onClick={closeModal}
                />
                <div className={styles.main__container}>
                    {children}
                </div>

                <div className={styles.btn__container}>
                    <Button
                        title="Save"
                        onClick={onSave}
                    />
                </div>
            </div>
        </TransparentBG>
    )
}
