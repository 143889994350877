import React, { useEffect, useState, useMemo } from "react";
import { MdEdit } from "react-icons/md";
import styles from "./styles.module.scss";
import {
  Table,
  SearchButton,
  LightTextButton,
  FilterDropdown,
  SwitchButton,
  ImageStamp,
  Button
} from "components";
import PackageAddOnPopup from "../popups/PackageAddOn/PackageAddOnPopup";
import { useHistory } from "react-router-dom";
import { packageAddonAction } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";

const PackageAddOn = () => {
  // Routers
  const pid = useHistory().location.pathname.split("/")[3];

  // Redux
  const dispatch = useDispatch();

  const { packageAddon, userRole } = useSelector(state => ({
    packageAddon: state.packageAddon,
    userRole: state.userRole?.filter(role => role.module === "Users")
  }));

  // States
  const [tableRows, setTableRows] = useState();
  const [editableDetail, setEditableDetails] = useState();
  const [isPopupVisible, setPopupVisible] = useState();

  // Filter States
  const [status, setStatus] = useState();

  // Fetch Package Add On
  useEffect(() => {
    if (userRole?.find(item => item.privilege === "Read"))
      dispatch(packageAddonAction.get(pid));

    return () => dispatch(packageAddonAction.reset());
  }, [dispatch]);

  useEffect(() => {
    if (!packageAddon?.length) return;

    let filtered = packageAddon;

    // filter based on Status
    filtered = filtered?.filter(item =>
      status === "Enabled"
        ? item.enabled
        : status === "Disabled"
        ? !item.enabled
        : item
    );

    // Map table rows
    const data = filtered?.map(obj => ({
      addonName: obj.addonName,
      addonImage: <ImageStamp src={obj.addonImage} />,
      basePrice: obj.basePrice,
      sellingPrice: obj.sellingPrice,
      displayOrder: obj.displayOrder,
      createdAt: `${new Date(obj.createdAt).toLocaleString()}`,
      updatedAt: `${new Date(obj.updatedAt).toLocaleString()}`,
      enabled: (
        <SwitchButton
          enabled={obj.enabled}
          onClick={
            userRole?.find(item => item.privilege === "Enable")
              ? () => dispatch(packageAddonAction.toggle(pid, obj.id))
              : null
          }
        />
      ),
      edit: (
        <MdEdit
          size="20"
          color="#528ff0"
          onClick={
            userRole?.find(item => item.privilege === "Update")
              ? () => {
                  setEditableDetails(obj);
                  setPopupVisible(true);
                }
              : null
          }
          className="clickable"
        />
      )
    }));

    setTableRows(data);
  }, [packageAddon, status]);

  // Search based on category & sub category name
  const onSearch = () => {};

  // Table Header
  const tableHeaders = useMemo(
    () => [
      {
        title: "Add On Name",
        sortBy: "packageName"
      },
      {
        title: "Image"
      },
      {
        title: "Base Price"
      },
      {
        title: "Selling Price"
      },
      {
        title: "Display Order",
        sortBy: "displayOrder"
      },
      {
        title: "Created At",
        sortBy: "createdAt"
      },
      {
        title: "Updated At",
        sortBy: "updatedAt"
      },
      {
        title: "Enabled"
      },
      {
        title: "Edit"
      }
    ],
    [tableRows]
  );

  return (
    <div>
      <div className={styles.filter}>
        <FilterDropdown
          onChange={e => setStatus(e.target.value)}
          label="Status"
          options={["All", "Enabled", "Disabled"]}
        />
        <SearchButton onClick={onSearch} />
        <LightTextButton />

        <div className={styles.float__end}>
          <Button
            title="+ New Package Addon"
            onClick={() => setPopupVisible(true)}
          />
        </div>
      </div>
      <Table headers={tableHeaders} data={tableRows} />
      {isPopupVisible && (
        <PackageAddOnPopup
          data={editableDetail}
          closeModal={() => setPopupVisible(false)}
        />
      )}
    </div>
  );
};

export default PackageAddOn;
