import React, {useEffect, useState, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    Table,
    TextLink,
    TextInput,
    SearchButton,
    LightTextButton,
    FilterDropdown,
    Search
} from "components";
import {customersAction, subscriptionAction} from "redux/actions";
import {useHistory} from "react-router-dom";
import {internalSearch} from "helper";
import styles from "./styles.module.scss";
import SubscriptionModal from "../../Customers/modals/SubscriptionModal";
import {Modal} from "@material-ui/core";
import SendInvoice from "./SendInvoice";

const initialState = {
    cartID: "",
    id: "",
    status: "",
    search: ""
};

export default function Orders() {
    // Routers
    const {push} = useHistory();

    // States
    const [tableRows, setTableRows] = useState();

    // Filter States
    const [filterState, setfilterState] = useState(initialState);

    // Redux actions
    const dispatch = useDispatch();
    const {subscription, userRole} = useSelector(state => ({
        subscription: state.subscription,
        userRole: state.userRole?.filter(role => role.module === "Orders")
    }));

    useEffect(() => {
        if (userRole?.find(item => item.privilege === "Read"))
            dispatch(subscriptionAction.get(1));
        // return () => dispatch(subscriptionAction.reset());
    }, []);

    useEffect(() => {
        let filtered = subscription?.subscriptions;
        if (!filtered?.length) {
            setTableRows();
            return;
        }

        filtered = internalSearch(filterState, "cartID", filtered);
        filtered = internalSearch(filterState, "id", filtered);
        filtered = internalSearch(filterState, "status", filtered);

        
        // Map table rows
        filtered = filtered?.map(obj => ({
            cartID: (
                <TextLink
                    text={obj.cartID}
                    onClick={() => {
                        localStorage.setItem("spID", obj.id)
                        push(`/orders/orders?orderID=${obj.cartID}&spID=${obj.id}`)
                    }}
                />
            ),
            id: (
                <TextLink
                    text={obj.id}
                    onClick={() => {
                        setClickedSubscription({id: obj?.id, userId: null})
                        handleOpenSubscriptionModal()
                        // push(`/subscription/orders/${obj.id}`)
                    }}
                />
            ),
            status: obj.status,
            serviceDate: obj.serviceDate,
            serviceTime: obj.serviceTime,
            createdAt: `${new Date(obj.createdAt).toLocaleString()}`,
            updatedAt: `${new Date(obj.updatedAt).toLocaleString()}`,
            reschedCount: obj.reschedCount,
            invoiceBtn: <SendInvoice orderId={obj.id} status={obj.status}/>
        }));

        setTableRows(filtered);
    }, [subscription, filterState]);

    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "Order ID",
                sortBy: "cartID"
            },
            {
                title: "Subscription ID",
                sortBy: "id"
            },
            {
                title: "Status"
            },
            {
                title: "Service Date",
                sortBy: "serviceDate"
            },
            {
                title: "Service Time"
            },
            {
                title: "Created",
                sortBy: "createdAt"
            },
            {
                title: "Updated",
                sortBy: "updatedAt"
            },
            {
                title: "ReScheduled Count"
            },
            {
                title: "Invoice"
            }
        ],
        [tableRows]
    );

    const onChange = e =>
        setfilterState({...filterState, [e.target.name]: e.target.value});

    const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false)
    const [clickedSubscription, setClickedSubscription] = useState(null)

    const handleOpenSubscriptionModal = () => {
        setOpenSubscriptionModal(true)
    }

    const handleCloseSubscriptionModal = () => {
        setOpenSubscriptionModal(false)
    }

    return (
        <div>
            <div className={styles.filter}>
                <TextInput
                    search
                    label="Order ID"
                    name="cartID"
                    onChange={onChange}
                    value={filterState?.cartID}
                />
                <TextInput
                    search
                    label="Subscription ID"
                    name="id"
                    value={filterState?.id}
                    onChange={onChange}
                />
                <FilterDropdown
                    onChange={onChange}
                    label="Status"
                    name="status"
                    value={filterState?.status}
                    options={[
                        "All",
                        "Scheduled",
                        "Routed",
                        "Assigned",
                        "Accepted",
                        "On the way",
                        "Arrived",
                        "In progress",
                        "Completed"
                    ]}
                    values={[
                        "",
                        "scheduled",
                        "routed",
                        "assigned",
                        "accepted",
                        "ontheway",
                        "arrived",
                        "inprogress",
                        "completed"
                    ]}
                />
                <SearchButton/>
                <LightTextButton onClick={() => setfilterState(initialState)}/>
                <Search
                    name="search"
                    value={filterState?.search}
                    onChange={e =>
                        setfilterState({...filterState, [e.target.name]: e.target.value})
                    }
                    onClick={() =>
                        dispatch(
                            subscriptionAction.search({
                                pageNumber: 1,
                                query: filterState?.search
                            })
                        )
                    }
                    clear={() => {
                        setfilterState({...filterState, 'search': ""})
                        dispatch(
                            customersAction.search({
                                pageNumber: 1,
                                query: ""
                            })
                        )
                    }}
                />
            </div>
            <Table
                headers={tableHeaders}
                data={tableRows}
                current={subscription?.currentPage}
                totalPage={subscription?.maxPages}
                onPageChange={pageNumber =>
                    dispatch(subscriptionAction.get(pageNumber))
                }
                state={filterState !== initialState}
                length={tableRows?.length}
                r_length={subscription?.subscriptions?.length}
                t_length={subscription?.maxPages}

            />
            <Modal
                open={openSubscriptionModal}
                onClose={handleCloseSubscriptionModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <SubscriptionModal clickedSubscription={clickedSubscription} handleCloseModal={handleCloseSubscriptionModal}/>
            </Modal>
        </div>
    );
}
