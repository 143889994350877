import React, { useState, useEffect } from "react";
import { TextInput, Modal } from "components";
import { useDispatch } from "react-redux";
import { privilegesAction } from "redux/actions";

export default function PrivilegePopup({ closeModal, data }) {
  const dispatch = useDispatch();

  const [details, setDetails] = useState({
    name: ""
  });

  useEffect(() => {
    setDetails(data);
  }, [data]);

  const onSave = () => {
    if (data) {
      dispatch(privilegesAction.update(details));
    } else {
      dispatch(privilegesAction.add(details));
    }
    closeModal();
  };

  const handleChange = setter => event =>
    setter(prev => ({ ...prev, [event.target.name]: event.target.value }));

  return (
    <Modal
      title={data ? "Edit Privilege" : "Create New Privilege"}
      closeModal={closeModal}
      onSave={onSave}>
      <TextInput
        label="Name"
        placeholder="Name"
        name="name"
        onChange={handleChange(setDetails)}
        value={details?.name}
      />
    </Modal>
  );
}
