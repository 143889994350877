import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { FixedMultiTable, ImageStamp } from "../index";
import { getDay } from "../../helper";

export default function Invoice({
  customer,
  serviceAddress,
  deliveryAddress,
  items,
  schedule,
}) {
  const [addOns, setAddOns] = useState();

  useEffect(() => {
    const data = items?.addOns?.map((item) => ({
      packageImage: <ImageStamp src={item.packageImage} />,
      packageName: item?.packageName,
      qty: item?.itemCount,
      basePrice: (
        <p style={{ textDecorationLine: "line-through", color: "red" }}>
          &#8377; {item?.basePrice}
        </p>
      ),
      sellingPrice: <p>&#8377;{item?.sellingPrice}</p>,
    }));

    setAddOns(data);
  }, [items]);

  return (
    <div className={styles.container}>
      <p>Hello {customer}</p>

      <h4>
        Thank you for your Service Booking with CLYKK Service India Private
        Limited
      </h4>
      <p>(customer.support@clykk.com)</p>

      <p>
        It may take a few moments for this transaction to appear in your
        account.
      </p>

      <h4>Marketplace</h4>
      <p>CLYKK Service India Private Limited</p>
      <p>customer.support@clykk.com</p>

      <div className={styles.row}>
        {deliveryAddress ? (
          <>
            <h4>Pickup Address: </h4>
            <p>{serviceAddress}</p>
            <h4>Delivery Address: </h4>
            <p>{deliveryAddress}</p>
          </>
        ) : (
          <>
            <h4>Location of Service: </h4>
            <p>{serviceAddress}</p>
          </>
        )}
      </div>

      <div className={styles.row}>
        <h4>Service Date: </h4>
        <p>{schedule?.startDate}</p>
        <h4>Service Time: </h4>
        <p>{schedule?.serviceTime}</p>
      </div>

      <div className={styles.row}>
        <h4>Subscription Frequency: </h4>
        <p>{schedule?.subscriptionFrequency}</p>
        {schedule?.weekdays?.length ? (
          <>
            <h4>Weekdays: </h4>
            <p>
              {schedule?.weekdays.map(
                (day, index) =>
                  `${getDay(day)}${
                    index !== schedule?.weekdays?.length - 1 ? ", " : ""
                  }`
              )}
            </p>
            <h4>Service End Date: </h4>
            <p>{schedule?.endDate}</p>
          </>
        ) : null}
      </div>

      <FixedMultiTable
        headers={["Package", " ", "Qty", "Base Price", "Selling Price"]}
        data={[
          {
            packageImage: <ImageStamp src={items?.package.packageImage} />,
            packageName: items?.package.packageName,
            qty: items?.package?.itemCount,
            basePrice: (
              <span
                style={{ textDecorationLine: "line-through", color: "red" }}
              >
                &#8377; {items?.package?.basePrice?.toFixed(2)}
              </span>
            ),
            sellingPrice: (
              <span>&#8377;{items?.package?.sellingPrice?.toFixed(2)}</span>
            ),
          },
        ]}
        secHeaders={["AddOns", " ", "Qty", "Base Price", "Selling Price"]}
        secData={addOns}
      />

      <div className={styles.amounts}>
        <div className={styles.row}>
          <h4>Sub Total:</h4>
          <p>
            &#8377;
            {`${(
              items?.package?.sellingPrice +
              items?.addOns?.reduce(
                (acc, current) => acc + current?.sellingPrice,
                0
              )
            )?.toFixed(2)}`}
          </p>
        </div>
        <div className={styles.row}>
          <h4>Coupon:</h4>
          <p>25%</p>
        </div>
        <div className={styles.row}>
          <h4>Coupon Value:</h4>
          <p>&#8377; 29.00</p>
        </div>
        <div className={styles.row}>
          <h4>Paid Amount:</h4>
          <p>&#8377; 29.00</p>
        </div>
        <div className={styles.row}>
          <h4>Payment Method:</h4>
          <p>Razorpay</p>
        </div>
        <div className={styles.row}>
          <h4>Total:</h4>
          <p>
            &#8377;
            {`${(
              items?.package?.sellingPrice +
              items?.addOns?.reduce(
                (acc, current) => acc + current?.sellingPrice,
                0
              )
            )?.toFixed(2)}`}
          </p>
        </div>
      </div>
    </div>
  );
}
