import {
  ADD_FAQ_INFO,
  GET_FAQ_INFO,
  RESET_FAQ_INFO,
  TOGGLE_FAQ_INFO,
  UPDATE_FAQ_INFO
} from "../actionTypes";

const faqScreensReducer = (state = null, action) => {
  switch (action.type) {
    case GET_FAQ_INFO:
      return action.payload;

    case ADD_FAQ_INFO:
      return [...state, action.payload];

    case UPDATE_FAQ_INFO:
      return state.map(item =>
        item.id === action.payload.id ? action.payload : item
      );

    case TOGGLE_FAQ_INFO:
      return state.map(item =>
        item.id !== action.payload.id
          ? item
          : { ...item, enabled: !item.enabled }
      );

    case RESET_FAQ_INFO:
      return [];

    default:
      return state;
  }
};

export default faqScreensReducer;
