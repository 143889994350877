import { ORG } from "../actionTypes";

export default function orgReducer(state = null, action) {
  const {
    GET,
    ADD,
    TOGGLE,
    UPDATE,
    RESET,
    REJECT_DOC,
    CHANGE_DOC_STATUS,
  } = ORG;
  switch (action.type) {
    case GET:
      return action.payload;

    case ADD:
      return action.payload;

    case UPDATE:
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );

    case TOGGLE:
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );

    case REJECT_DOC:
      return state.map((item) =>
        item.id == action.payload.oid
          ? { ...item, documents: action.payload.documents }
          : item
      );

    case CHANGE_DOC_STATUS:
      return state.map((item) =>
        item.id == action.payload.orgID
          ? { ...item, documents: action.payload.documents }
          : item
      );

    case RESET:
      return null;

    default:
      return state;
  }
}
