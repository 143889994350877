import React from "react";
import styles from "./styles.module.scss";
import {MdClose} from "react-icons/md";
import {useParams, useHistory} from "react-router-dom";

const SideModal = ({children, title, titles, onClose}) => {
    const {id} = useParams();
    const {
        push,
        location: {pathname}
    } = useHistory();

    return (
        <div className={`${styles.modal} ${styles.show}`}>
            <header style={styles.header}>
                <h1>
                    {title ? title + ":" : ""} {id}
                </h1>

                <div className={styles.details}>
                    {titles?.length
                        ? titles.map((item, index) => (
                            <p key={index}>
                                <b>{item?.key ? item?.key + ": " : ""} &nbsp; </b>{" "}
                                {item?.value}
                            </p>
                        ))
                        : null}
                </div>

                <div
                    onClick={() =>
                        typeof onClose === "function"
                            ? onClose()
                            : push("/" + pathname.split("/")[1])
                    }
                    className={styles.close}>
                    <MdClose size="24"/>
                </div>
            </header>

            {children}
        </div>
    );
};

export default SideModal;
