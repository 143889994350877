import React from "react";
import styles from "./styles.module.scss";

const SwitchButton = ({ enabled, onClick }) => (
  <div className={styles.wrapper}>
    <div
      className={`${styles.button}${enabled ? " " + styles.enabled : ""}`}
      style={{ cursor: typeof onClick === "function" ? "pointer" : "auto" }}
      onClick={onClick}>
      <div className={styles.switch}>
        <div className={styles.line} />
        <div className={styles.line} />
      </div>
    </div>
    <span>{enabled ? "Enabled" : "Disabled"}</span>
  </div>
);

export default SwitchButton;
