import React, {useEffect, useState, useMemo} from "react";
import {MdEdit} from "react-icons/md";
import styles from "./styles.module.scss";
import {
    MultiTable,
    SearchButton,
    LightTextButton,
    FilterDropdown,
    SwitchButton,
    Button,
    Icon
} from "components";
import PackageQnAPopup from "../popups/FAQTopics/FAQTopics";
import {useHistory} from "react-router-dom";
import {faqTopicAction} from "redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {CgAddR} from "react-icons/cg";

const FAQTopics = () => {
    // Routers
    const pid = useHistory().location.pathname.split("/")[3];

    // Redux
    const dispatch = useDispatch();
    const {faqTopic, userRole} = useSelector(state => ({
        faqTopic: state.faqTopic,
        userRole: state.userRole?.filter(role => role.module === "FAQ")
    }));

    // States
    const [tableRows, setTableRows] = useState();
    const [editableDetail, setEditableDetails] = useState();
    const [isPopupVisible, setPopupVisible] = useState();
    const [sorting, setSorting] = useState({
        sortBy: "",
        sortIn: ""
    });

    // Filter States
    const [status, setStatus] = useState();

    // Fetch Package Add On
    useEffect(() => {
        if (userRole?.find(item => item.privilege === "Read"))
            dispatch(faqTopicAction.get(pid));

        return () => dispatch(faqTopicAction.reset());
    }, []);

    useEffect(() => {
        if (!faqTopic.qna?.length) return;
        const data = faqTopic.qna.map((qna, index) => {
            let modifiedQA = {
                ...qna,
                edit: (
                    <Icon
                        ReactIcon={MdEdit}
                        name="MdEdit"
                        color="primary"
                        onClick={
                            userRole?.find(item => item.privilege === "Update")
                                ? () => {
                                    setEditableDetails(qna);
                                    setPopupVisible(true);
                                }
                                : null
                        }
                        className="clickable"
                    />
                )
            };

            modifiedQA.answers = qna.answers.map(ans => ({
                answerType: ans.answerType,
                answer: ans.answer,
                sortOrder: ans.sortOrder,
                createdAt: `${new Date(ans.createdAt).toLocaleString()}`,
                updatedAt: `${new Date(ans.updatedAt).toLocaleString()}`,
                enabled: (
                    <SwitchButton
                        enabled={ans.enabled}
                        onClick={
                            userRole?.find(item => item.privilege === "Enable")
                                ? () =>
                                    dispatch(faqTopicAction.toggle(faqTopic.faqInfoID, ans.id))
                                : null
                        }
                    />
                ),
                addAns: " "
            }));

            return modifiedQA;
        });
        setTableRows(data);
    }, [faqTopic, status]);

    // Search
    const onSearch = () => {
    };

    // Sort rows by given heading
    const sortRows = sortBy => {
        if (!tableRows?.length) return;

        const rows = [...tableRows];

        if (sorting.sortBy !== sortBy || sorting.sortIn !== "ASC") {
            rows?.sort((a, b) => {
                if (a[sortBy] < b[sortBy]) return -1;
                if (a[sortBy] > b[sortBy]) return 1;
                return 0;
            });
            setSorting({sortIn: "ASC", sortBy});
        } else {
            rows?.sort((a, b) => {
                if (a[sortBy] < b[sortBy]) return 1;
                if (a[sortBy] > b[sortBy]) return -1;
                return 0;
            });
            setSorting({sortIn: "DSC", sortBy});
        }
        setTableRows(rows);
    };

    // MultiTable Header
    const tableHeaders = [
        {
            title: "Question Type"
        },
        {
            title: "Question Name"
        },
        {
            title: "Sort Order",
            onClick: () => sortRows("questionSortOrder"),
            icon: true,
            sortIn: sorting?.sortIn
        },
        {
            title: "Edit"
        }
    ];

    const secTableHeaders = useMemo(
        () => [
            {
                title: "Answer Type"
            },
            {
                title: "Answer Name"
            },
            {
                title: "Sort Order"
            },
            {
                title: "Created At"
            },
            {
                title: "Updated At"
            },
            {
                title: "Enabled"
            }
        ],
        []
    );

    return (
        <div>
            <div className={styles.filter}>
                <FilterDropdown
                    onChange={e => setStatus(e.target.value)}
                    label="Status"
                    options={["All", "Enabled", "Disabled"]}
                />
                <SearchButton
                    onClick={
                        userRole?.find(item => item.privilege === "Read") ? onSearch : null
                    }
                />
                <LightTextButton/>

                <div className={styles.float__end}>
                    <Button
                        title="+ New FAQ Topic"
                        onClick={
                            userRole?.find(item => item.privilege === "Create")
                                ? () => {
                                    setEditableDetails();
                                    setPopupVisible(true);
                                }
                                : null
                        }
                    />
                </div>
            </div>

            {tableRows?.length
                ? tableRows.map((qna, index) => (
                    <MultiTable
                        key={index}
                        headers={tableHeaders}
                        data={[
                            {
                                questionType: qna.questionType,
                                questionName: qna.questionName,
                                questionSortOrder: qna.questionSortOrder,
                                edit: qna.edit
                            }
                        ]}
                        secHeaders={[
                            ...secTableHeaders,
                            {
                                title: (
                                    <Icon
                                        ReactIcon={CgAddR}
                                        name="CgAddR"
                                        color="primary"
                                        onClick={
                                            userRole?.find(item => item.privilege === "Create")
                                                ? () => {
                                                    setEditableDetails({...qna, answers: []});
                                                    setPopupVisible(true);
                                                }
                                                : null
                                        }
                                    />
                                )
                            }
                        ]}
                        secData={qna.answers}
                    />
                ))
                : null}

            {isPopupVisible && (
                <PackageQnAPopup
                    data={editableDetail}
                    closeModal={() => setPopupVisible(false)}
                />
            )}
        </div>
    );
};

export default FAQTopics;
