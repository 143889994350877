import CouponsPanel from "./panels/CouponsPanel";
import DealsPanel from "./panels/DealsPanel";
import OffersPanel from "./panels/OffersPanel";
import DocumentsPanel from "./panels/CouponsPanel";

const tabRoutes = [
  {
    name: "Coupons",
    path: "/coupons",
    component: <CouponsPanel />,
  },
  {
    name: "Deals",
    path: "/deals",
    component: <DealsPanel />,
  },
  {
    name: "Offers",
    path: "/offers",
    component: <OffersPanel />,
  },
];

export default tabRoutes;

export const modalTabRoutes = [
  {
    name: "Package Add On",
    path: "/package-add-on",
    component: <DocumentsPanel />,
  },
  {
    name: "Package QA",
    path: "/package-qa",
    component: <DocumentsPanel />,
  },
  {
    name: "Package Like",
    path: "/package-like",
    component: <DocumentsPanel />,
  },
  {
    name: "Package Rating",
    path: "/package-rating",
    component: <DocumentsPanel />,
  },
  {
    name: "Package Sub Org Map",
    path: "/package-sub-org-map",
    component: <DocumentsPanel />,
  },
  {
    name: "Package City Map",
    path: "/package-city-map",
    component: <DocumentsPanel />,
  },
];
