import React, { useState } from "react";
import styles from "./../styles.module.scss";
import { TextInput, Button, FilterDropdown } from "components";
import { IoIosClose } from "react-icons/io";

export default function Details({
  onClick,
  details,
  setDetails,
  addAnswer,
  removeAnswer
}) {
  return (
    <div className={styles.main__container}>
      {details?.answers?.length
        ? details?.answers.map((ans, index) => (
            <div className={styles.ans__container} key={index}>
              <IoIosClose
                size={20}
                className={styles.icon__close}
                onClick={() => removeAnswer(index)}
              />
              <FilterDropdown
                onChange={e => setDetails(e, index)}
                name="answerType"
                label="Answer Type"
                options={["Pick a type", "Text", "Upload"]}
                value={ans.answerType}
                values={[0, "text", "upload"]}
              />
              <TextInput
                label="Answer"
                placeholder="Answer"
                name="answer"
                onChange={e => setDetails(e, index)}
                value={ans.answer}
                textarea={true}
              />
              <TextInput
                label="Display Order"
                placeholder="Display Order"
                name="sortOrder"
                onChange={e => setDetails(e, index)}
                value={ans.sortOrder}
              />
            </div>
          ))
        : null}

      <div className={styles.add__btn__container}>
        <Button title="Add Answer" onClick={addAnswer} />
      </div>
      <div className={styles.add__btn__container}>
        <Button title="Save" onClick={onClick} />
      </div>
    </div>
  );
}
