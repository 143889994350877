import axios from "axios";
import {
  GET_FAQ_SCREENS,
  TOGGLE_FAQ_SCREENS,
  UPDATE_FAQ_SCREENS,
  ADD_FAQ_SCREENS,
  RESET_FAQ_SCREENS,
  LOADING
} from "../actionTypes";
import { AWS_FAQ_7070 } from "configurations";
import { adminToken } from "helper";
import uploadImage from "helper/uploadImage";

const { SET_TRUE, SET_FALSE } = LOADING;
export const get = id => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.get(`${AWS_FAQ_7070}/faq-areas/${id}/faq-screens`, {
          headers: { Authorization: `Bearer ${token}` }
        })
      )
      .then(res => {
        dispatch({ type: GET_FAQ_SCREENS, payload: res.data });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const add = (details = {}) => {
  const { name, displayName, description, displayOrder, faqAreaID, image } =
    details;

  return async dispatch => {
    try {
      const imgUrl = await uploadImage(image, "faq-management");

      const token = await adminToken();

      const res = await axios.post(
        `${AWS_FAQ_7070}/faq-areas/${faqAreaID}/faq-screens`,
        {
          description,
          displayName,
          name,
          faqAreaID,
          displayOrder: parseInt(displayOrder),
          enabled: true,
          image: imgUrl
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      dispatch({ type: ADD_FAQ_SCREENS, payload: res.data });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({ type: SET_FALSE });
    }
  };
};

// Update
export const update = (details = {}) => {
  const { name, displayName, description, displayOrder, faqAreaID, image, id } =
    details;

  return async dispatch => {
    try {
      dispatch({ type: SET_TRUE });
      let imgUrl;

      // Request to Upload Image
      if (typeof packageImage === "object")
        imgUrl = await uploadImage(image, "faq-management");

      const token = await adminToken();

      const finalRes = await axios.put(
        `${AWS_FAQ_7070}/faq-areas/${faqAreaID}/faq-screens/${id}`,
        {
          description,
          displayName,
          name,
          displayOrder: parseInt(displayOrder),
          image: imgUrl || image
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      dispatch({ type: UPDATE_FAQ_SCREENS, payload: finalRes.data });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({ type: SET_FALSE });
    }
  };
};

export const toggle = (aid, id) => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.patch(
          `${AWS_FAQ_7070}/faq-areas/${aid}/faq-screens/${id}`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
      )

      .then(res => dispatch({ type: TOGGLE_FAQ_SCREENS, payload: res.data }))
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const reset = () => dispatch => dispatch({ type: RESET_FAQ_SCREENS });
