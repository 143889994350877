import React, { useState, useEffect } from "react";
import { TextInput, Modal, FilterDropdown, Alert } from "components";
import { useDispatch, useSelector } from "react-redux";
import {
  stateAction,
  cityAction,
  locationAction,
  errorAction
} from "redux/actions";

export default function ({ closeModal }) {
  const [name, setName] = useState();
  const [pinCode, setPinCode] = useState();

  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedCity, setSelectedCity] = useState();

  const dispatch = useDispatch();
  const country = useSelector(state => state.country);
  const state = useSelector(state => state.state);
  const city = useSelector(state => state.city);

  // Get states based on country selection
  useEffect(() => {
    setSelectedState(0);
    if (selectedCountry === "Pick a country" || !selectedCountry) return;
    dispatch(stateAction.get(selectedCountry));

    return () => dispatch(errorAction());
  }, [selectedCountry]);

  useEffect(() => {
    setSelectedCity(0);
    if (selectedState === "Pick a state" || !selectedState) return;
    dispatch(cityAction.get(selectedCountry, selectedState));
  }, [selectedState]);

  const onSave = () => {
    if (
      !name ||
      !pinCode ||
      !selectedCountry ||
      !selectedState ||
      !selectedCity
    )
      return;
    dispatch(
      locationAction.add(
        selectedCountry,
        selectedState,
        selectedCity,
        name,
        pinCode
      )
    );
    closeModal();
  };

  return (
    <Modal title="Create New State" closeModal={closeModal} onSave={onSave}>
      <Alert />
      <FilterDropdown
        onChange={e => setSelectedCountry(e.target.value)}
        value={selectedCountry}
        label="Country"
        options={
          country?.length
            ? ["Pick a country", ...country?.map(item => item.name)]
            : ["Pick a country"]
        }
        values={country?.length ? [0, ...country?.map(item => item.id)] : [0]}
      />
      <FilterDropdown
        onChange={e => setSelectedState(e.target.value)}
        value={selectedState}
        label="State"
        options={
          state?.length
            ? ["Pick a state", ...state?.map(item => item.name)]
            : ["Pick a state"]
        }
        values={state?.length ? [0, ...state?.map(item => item.id)] : [0]}
      />
      <FilterDropdown
        onChange={e => setSelectedCity(e.target.value)}
        value={selectedCity}
        label="City"
        options={
          city?.length
            ? ["Pick a city", ...city?.map(item => item.name)]
            : ["Pick a city"]
        }
        values={city?.length ? [0, ...city?.map(item => item.id)] : [0]}
      />
      <TextInput
        label="Location"
        placeholder="Location"
        name="name"
        onChange={e => setName(e.target.value)}
        value={name}
      />
      <TextInput
        label="Pin Code"
        placeholder="Pin Code"
        name="pinCode"
        onChange={e => setPinCode(e.target.value)}
        value={pinCode}
      />
    </Modal>
  );
}
