import React from "react";
import styles from "./styles.module.scss";

const SearchButton = ({ onClick, title, ...props }) => (
  <button
    className={styles.button}
    style={{ cursor: typeof onClick === "function" ? "pointer" : "auto" }}
    onClick={onClick}
    {...props}>
    {title || "Filter"}
  </button>
);

export default SearchButton;
