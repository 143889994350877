import React, {useEffect, useState, useMemo} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Table} from "components";
import {useHistory} from "react-router-dom";
import {subscriptionAction} from "redux/actions";

export default function Subscription() {
    // Routers
    const id = useHistory().location.pathname.split("/")[3];

    // Redux
    const subscriptions = useSelector(state =>
        state.subscription?.subscriptions?.filter(item => item.cartID == id)
    );

    const [tableRows, setTableRows] = useState();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(subscriptionAction.get(1));
    }, [dispatch]);

    useEffect(() => {
        let filtered = subscriptions;
        if (!filtered?.length) return;

        // Map table rows
        filtered = filtered?.map(obj => ({
            cartID: obj?.cartID,
            id: obj?.id,
            status: obj?.status,
            serviceDate: obj?.serviceDate,
            serviceTime: obj?.serviceTime,
            updatedAt: `${new Date(obj?.updatedAt).toLocaleString()}`,
            reschedCount: obj?.reschedCount
        }));

        setTableRows(filtered);
    }, []);

    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "Order ID"
            },
            {
                title: "Subscription ID",
                sortBy: "id"
            },
            {
                title: "Status"
            },
            {
                title: "Service Date",
                sortBy: "serviceDate"
            },
            {
                title: "Service Time"
            },
            {
                title: "Updated",
                sortBy: "updatedAt"
            },
            {
                title: "ReScheduled Count"
            }
        ],
        [tableRows]
    );

    return <Table headers={tableHeaders} data={tableRows}/>;
}
