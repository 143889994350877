import React from "react";
import styles from "./styles.module.scss";

export default function ImageStamp({src, onClick}) {
    return (
        <div className={styles.image__container} onClick={onClick}>
            {src && (
                <>
                    <img src={src} alt="thumbnail" className={styles.image}/>
                    {typeof onClick !== "function" && (
                        <div className={styles.image__view}>
                            <img src={src} alt="thumbnail" className={styles.max__image}/>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
