import axios from "axios";

import {SP_SERVICE_SETTINGS, LOADING} from "../actionTypes";
import {IMAGE_URL, Who_Obj} from "configurations";
import {adminToken} from "../../helper";

const {GET, UPDATE, TOGGLE, RESET} = SP_SERVICE_SETTINGS;

const {SET_TRUE, SET_FALSE} = LOADING;

export const get = id => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .get(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/servicerules/user/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(res => {
                console.log(res.data);
                dispatch({type: GET, payload: res.data.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const add = data => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();

        axios
            .post(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/org-management/documents`,
                {
                    ...data
                },
                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            )
            .then(res => {
                dispatch({type: GET, payload: res.data.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

// Update
export const update = (details = {}) => {
    const {name, displayName, description, displayOrder, id, image} = details;

    return async dispatch => {
        dispatch({type: SET_TRUE});
        let imageRes;

        // Request to Upload Image
        if (typeof image === "object") {
            const formData = new FormData();
            formData.append("imageFile", image);
            imageRes = await axios.post(`${IMAGE_URL}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
        }

        const finalRes = await axios.put(
            `http://cgapp.clykk.com/location/country/${id}`,
            {
                description,
                displayName,
                name,
                displayOrder: parseInt(displayOrder),
                image: imageRes?.data.imgUrl || image
            },
            {
                headers: {who: JSON.stringify(Who_Obj)}
            }
        );

        dispatch({type: UPDATE, payload: finalRes.data});
    };
};

export const toggle = (sID, rID) => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .patch(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/servicerules/user/${sID}/rule/${rID}`,
                {},
                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            )
            .then(res => {
                dispatch({type: TOGGLE, payload: res.data.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const reset = () => dispatch => dispatch({type: RESET});
