import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import {
  Table,
  SearchButton,
  LightTextButton,
  FilterDropdown,
  SwitchButton,
  TextLink
} from "components";
import { categoryAction, cityAction, documentsAction } from "redux/actions";
import { useHistory } from "react-router-dom";
import DocumentsPopup from "../popups/Documents/DocumentsPopup";

export default function ServiceProvicer() {
  // Routers
  const history = useHistory();
  const currentLocation = history.location.pathname;

  // States
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [tableRows, setTableRows] = useState();
  const [editableDetail, setEditableDetails] = useState();
  const [isPopupVisible, setPopupVisible] = useState();

  // Filter States
  const [status, setStatus] = useState();

  // Redux actions
  const dispatch = useDispatch();

  const { documents, categories, city, userRole } = useSelector(state => ({
    documents: state.documents?.sp,
    categories: state.categories,
    city: state.city,
    userRole: state.userRole?.filter(role => role.module === "Documents")
  }));

  const filteredCategories = categories?.map(category => category.name); // make an arry of category names

  const filteredCity = city?.map(item => item.name) || [];

  // Search based on category & sub category name
  const onSearch = () => {
    if (!selectedCity || !selectedCategory) return;
    dispatch(documentsAction.get(selectedCategory, selectedCity));
  };

  useEffect(() => {
    if (!categories?.length) dispatch(categoryAction.get());

    dispatch(cityAction.getAll());
    return () => {
      dispatch(documentsAction.reset());
      dispatch(cityAction.reset());
    };
  }, []);

  useEffect(() => {
    if (!documents?.length) {
      setTableRows();
      return;
    }

    let filtered = documents;

    // filter based on Status
    filtered = filtered?.filter(item =>
      status === "Enabled"
        ? item.Enabled
        : status === "Disabled"
        ? !item.Enabled
        : item
    );

    // Map table rows
    const data = filtered?.map(obj => ({
      city: city.find(item => item.id == obj.Cityid)?.name || "",
      category: categories.find(item => item.id == obj.CategoryID)?.name || "",
      documentName: (
        <TextLink
          text={obj.Documentname}
          onClick={() => history.push(`${currentLocation}/${obj.ID}`)}
        />
      ),

      createdAt: `${new Date(obj.CreatedAt).toLocaleString()}`,
      updatedAt: `${new Date(obj.UpdatedAt).toLocaleString()}`,
      enabled: (
        <SwitchButton
          enabled={obj.Enabled}
          onClick={
            userRole?.find(item => item.privilege === "Enable")
              ? () => dispatch(documentsAction.toggle(obj.ID, "sp"))
              : null
          }
        />
      )
    }));

    setTableRows(data);
  }, [documents, status]);

  // Table Header
  const tableHeaders = [
    {
      title: "City"
    },
    {
      title: "Category"
    },
    {
      title: "Document Name"
    },
    {
      title: "Created",
      sortBy: "createdAt"
    },
    {
      title: "Updated",
      sortBy: "updatedAt"
    },
    {
      title: "Enabled"
    }
  ];

  return (
    <div>
      <div className={styles.filter}>
        <FilterDropdown
          onChange={e => setSelectedCategory(e.target.value)}
          value={selectedCategory}
          label="Category Name"
          options={["Pick a category", ...(filteredCategories || [])]}
          values={
            categories?.length && [0, ...categories?.map(item => item.id)]
          }
        />
        <FilterDropdown
          onChange={e => setSelectedCity(e.target.value)}
          value={selectedCity}
          label="City"
          options={["Pick a city", ...filteredCity]}
          values={city?.length && [0, ...city?.map(item => item.id)]}
        />
        <FilterDropdown
          onChange={e => setStatus(e.target.value)}
          label="Status"
          options={["All", "Enabled", "Disabled"]}
        />
        <SearchButton
          onClick={
            userRole?.find(item => item.privilege === "Read") ? onSearch : null
          }
        />
        <LightTextButton />
      </div>
      <Table headers={tableHeaders} data={tableRows} />
      {isPopupVisible && (
        <DocumentsPopup
          data={editableDetail}
          closeModal={() => setPopupVisible(false)}
        />
      )}
    </div>
  );
}
