import {DOCUMENTS} from "../actionTypes";

const {GET, ADD, UPDATE, TOGGLE, RESET} = DOCUMENTS;

export default function orgDocReducer(state = null, action) {
    switch (action.type) {
        case GET:
            return action.payload;

        case ADD:
            return action.payload;

        case UPDATE:
            return state.map((item) =>
                item.id === action.payload.id ? action.payload : item
            );

        case TOGGLE:
            return {
                ...state,
                [action.payload.type]: state?.[action.payload.type]?.map((item) =>
                    item.ID === action.payload.data.ID ? action.payload.data : item
                ),
            };

        case RESET:
            return null;

        default:
            return state;
    }
}
