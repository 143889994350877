import React, { useEffect } from "react";
import styles from "./../styles.module.scss";
import { Button, FilterDropdown } from "components";
import { useDispatch, useSelector } from "react-redux";
import { cityAction } from "redux/actions";

export default function LocationDetails({ onClick, details, setDetails }) {
  const dispatch = useDispatch();
  const city = useSelector(state => state.city);

  useEffect(() => {
    dispatch(cityAction.getAll());
    return () => dispatch(cityAction.reset());
  }, []);

  return (
    <div className={styles.main__container}>
      <FilterDropdown
        label="City"
        name="cityID"
        onChange={setDetails}
        value={details?.cityID}
        options={
          city?.length
            ? ["Pick a city", ...city?.map(item => item.name)]
            : ["Pick a city"]
        }
        values={city?.length ? [0, ...city?.map(item => item.id)] : [0]}
      />

      <div className={styles.btn__container}>
        <Button title="Next" onClick={onClick} />
      </div>
    </div>
  );
}
