import axios from "axios";
import {GET_USER_SHARES, RESET_USER_SHARES, LOADING} from "../actionTypes";
import {customerToken} from "helper";
import {adminToken} from "../../helper";

const {SET_TRUE, SET_FALSE} = LOADING;

export const get = ({countryCode, mobile}) => {
    return async dispatch => {
        if (!countryCode || !mobile) return;
        dispatch({type: SET_TRUE});

        try {
            // const token = await adminToken();

            const token = await customerToken({mobile, countryCode});

            const {data} = await axios.get(`https://cgapp.clykk.com/like/api/v2/user/packageShare`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            dispatch({type: GET_USER_SHARES, payload: data?.rs});
        } catch (err) {
            console.log(err);
        } finally {
            dispatch({type: SET_FALSE});
        }
    };
};

export const reset = () => dispatch => dispatch({type: RESET_USER_SHARES});
