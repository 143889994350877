import axios from "axios";
import { GET_SP_ORDERS, RESET_SP_ORDERS, LOADING } from "../actionTypes";
import { AWS_ROUTING } from "configurations";
import { adminToken } from "helper";

const { SET_TRUE, SET_FALSE } = LOADING;
export const get = id => {
  return async dispatch => {
    dispatch({ type: SET_TRUE });

    console.log("SP ORDERS");

    adminToken()
      .then(token =>
        axios.get(`${AWS_ROUTING}/getupcomingorders?sp_id=${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
      )
      .then(res => {
        dispatch({ type: GET_SP_ORDERS, payload: res.data.data });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const reset = () => dispatch => dispatch({ type: RESET_SP_ORDERS });
