import React, {useEffect, useState} from 'react';
import {Box, makeStyles, Tab, Tabs, useTheme} from "@material-ui/core";
import PropTypes from "prop-types";
import BookingDetailsTab from "./BookingDetailsTab";
import {IoIosClose} from "react-icons/io";
import {useSelector} from "react-redux";
import {TextLink} from "../../../components";
import SubscriptionTab from "./SubscriptionTab";
import DetailsTab from "./DetailsTab";
import RequirementsTab from "./RequirementsTab";
import ChecklistTab from "./ChecklistTab";
import AdditionalBillsTab from "./AdditionalBillsTab";
import TransactionsTab from "./TransactionsTab";
import RatingTab from "./RatingTab";
import RoutingTab from "./RoutingTab";
import ManualRouting from "./ManualRouting";
import axios from "axios";
import {adminToken} from "../../../helper";

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


const useStyles = makeStyles((theme) => ({
    indicator: {
        backgroundColor: "#6CD7BB"
    }
}))

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            style={{backgroundColor: "white"}}
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <p>{children}</p>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function SubscriptionModal({clickedSubscription, handleCloseModal}) {
    console.log("clickedSubscription =>", clickedSubscription)
    const cSubscription = useSelector(state => state.cSubscription);


    const [subscription, setSubscription] = useState(null)
    useEffect(() => {
        if (clickedSubscription?.userId) {
            adminToken().then(token => axios.get(`https://cgapp.clykk.com/cart/api/v2/int/cart-management/cart/subscriptions?uid=${clickedSubscription?.userId}&pagenumber=1&pagesize=99999999999999`, {
                headers: {Authorization: `Bearer ${token}`}
            }).then(response => {
                console.log(clickedSubscription?.id)
                console.log('=>', response.data.subscriptions.filter(item => item.id === clickedSubscription?.id)[0])

                setSubscription(response.data.subscriptions.filter(item => item.id === clickedSubscription?.id)[0])

            }).catch(e => console.log(e.message)))
        } else {
            adminToken().then(token => axios.get(`https://cgapp.clykk.com/cart/api/v2/int/cart-management/cart/subscriptions?pagenumber=1&pagesize=99999999999999`, {
                headers: {Authorization: `Bearer ${token}`}
            }).then(response => {
                console.log(response.data)
                console.log(clickedSubscription?.id)
                console.log('=>', response.data.subscriptions.filter(item => item.id == clickedSubscription?.id)[0])

                setSubscription(response.data.subscriptions.filter(item => item.id === clickedSubscription?.id)[0])

            }).catch(e => console.log(e.message)))
        }
    }, [])

    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [modalStyle] = useState(getModalStyle);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const classes = useStyles()

    const {transaction} =
        useSelector(state => ({
            transaction: state.subsDetail?.transaction?.[0]
        }))

    return (
        subscription !== null &&
        <div style={modalStyle} className={'modal-paper'}>
            <div className={'modal-top-bar'}>
                <IoIosClose
                    size={20}
                    className={'modal-close'}
                    onClick={handleCloseModal}
                />
            </div>
            <div style={{margin: "0 0 10px 0"}}>
                <h5>Subscription ID:  {clickedSubscription?.id}</h5>
                <div style={{display: 'flex', marginTop: 10}}>
                    <p style={{marginRight: 20}}>Order ID:
                        {subscription.cartID}
                    </p>
                    <p>Transaction ID
                        <TextLink
                            text={transaction?.transactionID}
                            onClick={() =>
                                window.open(
                                    `https://dashboard.razorpay.com/app/payments/${transaction?.transactionID}`,
                                    "_blank"
                                )
                            }
                        />
                    </p>
                    &nbsp;&nbsp;&nbsp;<p>Status: {subscription.status}</p>
                    
                </div>
            </div>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                classes={{
                    indicator: classes.indicator
                }}>
                <Tab label="Booking Details" {...a11yProps(0)} />
                <Tab label="Subscription"
                     style={{}} {...a11yProps(1)} />
                <Tab label="Package Details"
                     {...a11yProps(2)} />
                <Tab label="Customer Requirements"
                     {...a11yProps(3)} />
                <Tab label="SP Checklist"
                     {...a11yProps(4)} />
                {/*<Tab label="Orders"*/}
                {/*     {...a11yProps(5)} />*/}
                <Tab label="Additional Bills"
                     {...a11yProps(5)} />
                <Tab label="Transactions"
                     {...a11yProps(6)} />
                <Tab label="Rating"
                     {...a11yProps(7)} />
                <Tab label="Routing"
                     {...a11yProps(8)} />
                <Tab label="Manual Routing"
                     {...a11yProps(9)} />
            </Tabs>
            <div className={'modal-body'}>
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <BookingDetailsTab clickedSubscription={clickedSubscription}/>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <SubscriptionTab/>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <DetailsTab/>
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                    <RequirementsTab/>
                </TabPanel>
                <TabPanel value={value} index={4} dir={theme.direction}>
                    <ChecklistTab clickedSubscription={clickedSubscription}/>
                </TabPanel>
                <TabPanel value={value} index={5} dir={theme.direction}>
                    <AdditionalBillsTab/>
                </TabPanel>
                <TabPanel value={value} index={6} dir={theme.direction}>
                    <TransactionsTab/>
                </TabPanel>
                <TabPanel value={value} index={7} dir={theme.direction}>
                    <RatingTab/>
                </TabPanel>
                <TabPanel value={value} index={8} dir={theme.direction}>
                    <RoutingTab clickedSubscription={clickedSubscription}/>
                </TabPanel>
                <TabPanel value={value} index={9} dir={theme.direction}>
                    <ManualRouting clickedSubscription={clickedSubscription}/>
                </TabPanel>
            </div>
        </div>
    );
}

export default SubscriptionModal;
