import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

export function handleAuth(dispatch) {
    try {
        // let user = Cookies.get("user");
        // let userRole = Cookies.get("user_roles");

        let user = localStorage.getItem("user");
        let userRole = localStorage.getItem("user_roles");

        user = JSON.parse(user);
        userRole = JSON.parse(userRole);

        const decodedToken = jwtDecode(user?.token);

        if (decodedToken?.exp * 1000 > Date.now()) {
            dispatch({type: "GET_USER", payload: user});
            dispatch({type: "GET_USER_ROLES", payload: userRole || []});
        } else {
            console.log("I ran bro")
            dispatch({type: "USER_LOGOUT"});
        }
    } catch (err) {
        console.log(err);
        dispatch({type: "USER_LOGOUT"});
    }
}

export function validateAuth(dispatch) {
    try {
        // let user = Cookies.get("user");

        let user = localStorage.getItem("user");
        user = JSON.parse(user);

        const decodedToken = jwtDecode(user?.token);

        if (decodedToken?.exp * 1000 < Date.now())
            dispatch({type: "USER_LOGOUT"});
    } catch (err) {
        console.log(err);
        dispatch({type: "USER_LOGOUT"});
    }
}
