import axios from "axios";
import {OWNER, LOADING} from "../actionTypes";
import {adminToken} from "../../helper";

const {GET} = OWNER;

const {SET_TRUE, SET_FALSE} = LOADING;
export const get = id => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .get(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/get-user-profile?userId=${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(res => {
                dispatch({type: GET, payload: res.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};
