import React, {useEffect, useState, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    Table,
    TextLink,
    TextInput,
    FilterDropdown,
    SearchButton,
    LightTextButton
} from "components";
import {customersAction, ordersAction} from "redux/actions";
import {useHistory, useLocation} from "react-router-dom";
import {adminToken, getDay, internalSearch} from "helper";
import styles from "./styles.module.scss";
import {Modal} from "@material-ui/core";
import ReportModal from "../../Report/ReportModal";
import axios from "axios";
import {AWS_URL_UM_INT_8089} from "../../../configurations";
import OrderModal from "../modals/OrderModal";

export default function Orders() {
    const [openModal, setOpenModal] = useState(false)

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const [openOrderModal, setOpenOrderModal] = useState(false)

    const handleOpenOrderModal = () => {
        setOpenOrderModal(true)
    }

    const handleCloseOrderModal = () => {
        setOpenOrderModal(false)
    }

    const [clickedCustomer, setClickedCustomer] = useState(null);

    const [clickedOrder, setClickedOrder] = useState(null);

    // Routers
    const {
        push,
        location: {pathname}
    } = useHistory();

    const locationSearch = useLocation().search;
    const query = new URLSearchParams(locationSearch);
    const orderID = query.get("orderID");

    const spID = localStorage.getItem("spID")

    // States
    const [tableRows, setTableRows] = useState();

    // Filter States
    const [status, setStatus] = useState();
    const [filterState, setfilterState] = useState({status: "booked"});

    // Redux actions
    const dispatch = useDispatch();
    const {customers, orders, userRole} = useSelector(state => ({
        orders: state.orders,
        customers: state.customers,
        userRole: state.userRole?.filter(role => role.module === "Orders")
    }));

    useEffect(() => {
        if (userRole?.find(item => item.privilege === "Read"))
            dispatch(ordersAction.get(orderID));
    }, []);

    useEffect(() => {
        if (userRole?.find(item => item.privilege === "Read"))
            dispatch(customersAction.get(1));
        return () => dispatch(customersAction.reset());
    }, []);


    useEffect(() => {
        if (!orders?.length) {
            setTableRows();
            return;
        }

        let filtered = orders;

        // filter based on Status
        filtered = filtered?.filter(item =>
            status === "Enabled"
                ? item.enabled
                : status === "Disabled"
                    ? !item.enabled
                    : item
        );

        filtered = internalSearch(filterState, "cartID", filtered);
        console.log("filtered =>", filtered)
        // Map table rows
        filtered = filtered?.map(obj => ({
            cartID: (
                <TextLink
                    text={obj.cartID}
                    onClick={() => {
                        setClickedOrder(obj)
                        handleOpenOrderModal()
                        // push(`${pathname}/${obj.cartID}`)
                    }}
                />
            ),
            userID: (
                // <TextLink
                //     text={obj.userID}
                //     onClick={() => push(`/customers/customers?userID=${obj.userID}`)}
                // />
                <TextLink
                    text={obj?.userID}
                    onClick={() => {
                        // push(`${location.pathname}/${obj?.userId}`)
                        adminToken()
                            .then(token => axios.get(
                                    `${AWS_URL_UM_INT_8089}/get-user-list?pageNumber=1&pageSize=${customers?.count}`,
                                    {
                                        headers: {
                                            Authorization: `Bearer ${token}`
                                        }
                                    }
                                ).then((response) => {
                                        const users = response.data.data.users;
                                        const user = users?.filter(user => user.userId == obj?.userID);
                                        if (user.length > 0) {
                                            setClickedCustomer({
                                                id: user[0].userId,
                                                mobile: user[0].mobile,
                                                name: user[0].firstName + " " + user[0].lastName
                                            })
                                        }
                                        handleOpenModal()
                                    }
                                )
                            )
                    }}
                />
            ),
            spID: spID,
            packageName: obj.items.package.packageName,
            address: (
                <TextLink
                    text={obj.serviceAddress.line2}
                    onClick={() =>
                        window.open(
                            `https://www.google.com/maps/search/?api=1&query=${obj.serviceAddress.latitude},${obj.serviceAddress.longitude}`,
                            "_blank"
                        )
                    }
                />
            ),
            city: obj.serviceAddress.city,
            startDate: obj.schedule.startDate,
            serviceTime: obj.schedule.serviceTime,
            endDate: obj.schedule.endDate,
            subscriptionFrequency: obj.schedule.subscriptionFrequency,
            weekdays: obj.schedule.weekdays?.map(
                (day, index) =>
                    `${getDay(day)}${
                        index !== obj.schedule.weekdays.length - 1 ? ", " : ""
                    }`
            ),
            total: obj.total?.toFixed(2),
            transactionID: (
                <TextLink
                    text={obj.transactionID}
                    onClick={() =>
                        window.open(
                            `https://dashboard.razorpay.com/app/payments/${obj.transactionID}`,
                            "_blank"
                        )
                    }
                />
            ),
            createdAt: `${new Date(obj.createdAt).toLocaleString()}`
        }));

        filtered = internalSearch(filterState, "city", filtered);

        setTableRows(filtered);
    }, [orders, status, filterState]);

    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "Order ID",
                sortBy: "cartID"
            },
            {
                title: "User ID"
            },
            {
                title: "SP ID"
            },
            {
                title: "Package"
            },
            {
                title: "Address",
                sortBy: "address"
            },
            {
                title: "City",
                sortBy: "city"
            },
            {
                title: "Service Date",
                sortBy: "startDate"
            },
            {
                title: "Service Time"
            },
            {
                title: "End Date"
            },
            {
                title: "Subscription"
            },
            {
                title: "Weekdays"
            },
            {
                title: "Total Amount"
            },
            {
                title: "Transaction ID"
            },
            {
                title: "Created",
                sortBy: "createdAt"
            }
        ],
        [tableRows]
    );

    return (
        <div>
            <div className={styles.filter}>
                <TextInput
                    search
                    label="Order ID"
                    name="cartID"
                    onChange={e =>
                        setfilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
                <TextInput
                    search
                    label="City"
                    name="city"
                    onChange={e =>
                        setfilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
                <TextInput
                    search
                    label="Customer"
                    name="customerID"
                    onChange={e =>
                        setfilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
                <FilterDropdown
                    onChange={e => setStatus(e.target.value)}
                    label="Status"
                    options={["All", "Enabled", "Disabled"]}
                />
                <SearchButton/>
                <LightTextButton/>
            </div>
            <Table headers={tableHeaders} data={tableRows}/>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <ReportModal clickedCustomer={clickedCustomer} handleCloseModal={handleCloseModal}/>
            </Modal>

            <Modal
                open={openOrderModal}
                onClose={handleOpenOrderModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <OrderModal clickedOrder={clickedOrder} handleCloseModal={handleCloseOrderModal}/>
            </Modal>

            {/*openOrderModal*/}
        </div>
    );
}
