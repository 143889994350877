import React, { useState, useEffect } from "react";
import { TextInput, Modal } from "components";
import { useDispatch } from "react-redux";
import { modulesAction } from "redux/actions";

export default function ModulesPopup({ closeModal, data }) {
  const [details, setDetails] = useState({
    module_name: "",
    description: ""
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setDetails(data);
  }, [data]);

  const onSave = () => {
    if (data) {
      dispatch(modulesAction.update(details));
    } else {
      dispatch(modulesAction.add(details));
    }
    closeModal();
  };

  const handleChange = setter => event =>
    setter(prev => ({ ...prev, [event.target.name]: event.target.value }));

  return (
    <Modal
      title={data ? "Edit Module" : "Create New Module"}
      closeModal={closeModal}
      onSave={onSave}>
      <TextInput
        label="Name"
        placeholder="Name"
        name="module_name"
        onChange={handleChange(setDetails)}
        value={details?.module_name}
        // disabled={data ? true : false}
      />
      <TextInput
        label="Description"
        placeholder="Description"
        name="description"
        onChange={handleChange(setDetails)}
        value={details?.description}
      />
    </Modal>
  );
}
