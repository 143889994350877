import React from "react";
import styles from "./styles.module.scss";

const AuthInput = props => (
  <div className={styles.code__container} style={props?.styles}>
    <input {...props} />
  </div>
);

export default AuthInput;
