import React from "react";
import styles from "./styles.module.scss";
import {BiSearchAlt} from "react-icons/bi";

export default function SearchTextInput({
                                            label,
                                            placeholder,
                                            search,
                                            disabled,
                                            options,
                                            ...props
                                        }) {
    return (
        <div className={styles.text__input} style={{width: 250}}>
            <p>{label}</p>
            <input
                style={{width: 250}}
                list="searchables"
                className={styles.input}
                placeholder={placeholder || label}
                disabled={disabled}
                {...props}
            />

            <datalist id="searchables">
                {options?.map(user => (
                    <option value={user}/>
                ))}
            </datalist>

            {search && (
                <BiSearchAlt size="15" color="#000" className={styles.search__icon}/>
            )}

            {/* <div className={styles.dropdown}></div> */}
        </div>
    );
}
