import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {Table} from "../../../components";

function AdditionalBillsTab(props) {
    // Redux

    const additionalMaterials = useSelector(
        state => state.subsDetail?.additionalMaterials
    );

    const [tableRows, setTableRows] = useState();

    useEffect(() => {
        let filtered = [additionalMaterials];
        if (!filtered?.length) {
            setTableRows();
            return;
        }

        console.log(filtered);
        // Map table rows
        filtered = filtered?.map(obj => ({
            id: obj?.id,
            image: obj?.image,
            description: obj?.description,
            amount: obj?.amount,
            updatedAt: `${new Date(obj?.updatedAt).toLocaleString()}`
        }));

        setTableRows(filtered);
    }, []);

    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "ID",
                sortBy: "id"
            },
            {
                title: "Image"
            },
            {
                title: "Description"
            },
            {
                title: "Amount"
            },
            {
                title: "Updated",
                sortBy: "updatedAt"
            }
        ],
        [tableRows]
    );

    return <Table headers={tableHeaders} data={tableRows}/>;
}

export default AdditionalBillsTab;
