import React, { useState } from "react";
import { useLocation, Switch, Route } from "react-router-dom";
import tabRoutes from "./tab-routes";
import { Tabs } from "components";
import RoutingPopup from "./popups/Routing/RoutingPopup";

const popups = [
  {
    title: "+ New Route",
    path: "settings",
    component: closeModal => <RoutingPopup closeModal={closeModal} key={1} />
  }
];

const Settings = () => {
  const location = useLocation();
  const currentLocation = location.pathname.split("/", 3)[1];

  // States
  const [modal, setModal] = useState(); // Popup open or close state

  return (
    <>
      {/* Panels */}
      <Tabs
        routes={tabRoutes}
        title={popups.find(popup => currentLocation === popup.path)?.title}
        onClick={() => setModal(currentLocation)}
      />

      {
        // Popups
        modal &&
          popups?.length &&
          popups.map(
            popup =>
              currentLocation === popup.path &&
              popup.component(() => setModal(false))
          )
      }

      {/* Modals */}
      <Switch>
        <Route path={`${tabRoutes[0]?.modalRoutes[0]?.path}`}>
          {tabRoutes[0]?.modalRoutes[0]?.component}
        </Route>
      </Switch>
    </>
  );
};

export default Settings;
