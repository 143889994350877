import React from "react";
import styles from "./styles.module.scss";
import logo from "../../assets/img/logo.png";
import MenuItem from "../MenuItem/MenuItem";
import { withRouter } from "react-router-dom";

import routes from "../../routes";

const Sidebar = ({ location, history }) => {
  return (
    <div className={styles.sidebar}>
      <img
        src={logo}
        alt="CLYKK"
        className={styles.logo}
        onClick={() => history.push(process.env.PUBLIC_URL)}
        style={{ cursor: "pointer" }}
      />
      <div className={styles.line} />

      {routes.map((item, index) => (
        <MenuItem
          key={`menu-${index}`}
          name={item.name}
          active={
            location.pathname.split("/", 2)[1] === item.path.split("/", 2)[1]
              ? true
              : false
          }
          path={item.path}
          menuBreak={item.break}>
          {item.Icon}
        </MenuItem>
      ))}
    </div>
  );
};

export default withRouter(Sidebar);
