import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {SideModal, Tabs} from "../../../components";
import {modalSpCommisionRoutes, modalTabRoutes} from "../tab-routes";

function SpCommisionModal({}) {
    // Routers
    const {id} = useParams();

    const serviceProvider = useSelector(state => state.serviceProvider?.users);
    const [titles, setTitles] = useState();

    useEffect(() => {
        const user = serviceProvider?.find(item => item?.userId == id);

        const titleArr = [
            {key: "Name", value: user?.firstName + " " + user?.lastName},
            {key: "Mobile", value: user?.mobile}
        ];
        setTitles(titleArr);
    }, [id]);
    return (
        <SideModal
            title="Service Provider ID"
            titles={titles?.length ? [...titles] : []}>
            <Tabs routes={modalTabRoutes}/>
        </SideModal>
    );
}

export default SpCommisionModal;
