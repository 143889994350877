import {
  ADD_CATEGORIES,
  GET_CATEGORIES,
  TOGGLE_CATEGORY,
  UPDATE_CATEGORY,
  RESET_CATEGORY,
} from "../actionTypes";

const categoryReducer = (state = null, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return action.payload;

    case ADD_CATEGORIES:
      return [...state, action.payload];

    case UPDATE_CATEGORY:
      return state.map((category) =>
        category.id === action.payload.id ? action.payload : category
      );

    case TOGGLE_CATEGORY:
      return state.map((item) =>
        item.id !== action.payload ? item : { ...item, enabled: !item.enabled }
      );

    case RESET_CATEGORY:
      return null;

    default:
      return state;
  }
};

export default categoryReducer;
