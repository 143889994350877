import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./../styles.module.scss";
import { TextInput, Button, FilterDropdown, CheckBox } from "components";
import { serviceRulesAction } from "redux/actions";

export default function Details({ onClick, details, setDetails }) {
  const dispatch = useDispatch();
  const categories = useSelector(state => state.categories);
  const filteredCategories = categories?.map(category => category.name); //make an arry of category names
  const categoryIDs = categories?.map(category => category.id);

  // Service Rules to map checkboxes
  const serviceRules = useSelector(state => state.serviceRules);

  // Fetch service rules if not available in global state
  useEffect(() => {
    if (serviceRules?.length) return;
    dispatch(serviceRulesAction.get());
  }, [dispatch]);

  return (
    <div className={styles.main__container}>
      <FilterDropdown
        onChange={setDetails}
        name="category"
        label="Category"
        options={["Pick a category", ...filteredCategories]}
        value={details?.categoryID}
        values={[0, ...categoryIDs]}
      />
      <TextInput
        label="Name"
        placeholder="Name"
        name="name"
        onChange={setDetails}
        value={details?.name}
        textarea={true}
      />
      <TextInput
        label="Description"
        placeholder="Description"
        name="description"
        onChange={setDetails}
        value={details?.description}
        textarea={true}
      />
      <TextInput
        label="Display Name"
        placeholder="Display Name"
        name="displayName"
        onChange={setDetails}
        value={details?.displayName}
        textarea={true}
      />
      <TextInput
        label="Display Order"
        placeholder="Display Order"
        name="displayOrder"
        onChange={setDetails}
        value={details?.displayOrder}
      />
      <div className={styles.service__rules}>
        <p>Delivery Type</p>
      </div>
      {serviceRules?.length &&
        serviceRules.map((serviceRule, index) => {
          const check = details?.serviceRule?.find(
            obj => obj.id == serviceRule?.id
          );
          return (
            <CheckBox
              key={index}
              title={serviceRule.type}
              name="serviceRule"
              value={serviceRule.id}
              onChange={setDetails}
              checked={check?.id}
            />
          );
        })}
      <div className={styles.btn__container}>
        <Button title="Next" onClick={onClick} />
      </div>
    </div>
  );
}
