import React, {useState, useMemo} from "react";
import {
    SwitchButton,
    TableVertical,
    DocumentButton,
    StatusButton,
    Status,
    ImageStamp
} from "components";
import PackageAddOnPopup from "../popups/PackageAddOn/PackageAddOnPopup";
import {useHistory} from "react-router-dom";
import {serviceProviderAction} from "redux/actions";
import {useDispatch, useSelector} from "react-redux";

export default function Profile() {
    // Routers
    const history = useHistory();
    const path = history.location.pathname;
    const id = path.split("/")[3];

    // Redux
    const dispatch = useDispatch();
    const {serviceProvider, userRole} = useSelector(state => ({
        serviceProvider: state?.serviceProvider?.users?.find(
            item => item.userId == id
        ),
        userRole: state.userRole?.filter(role => role.module === "Service Provider")
    }));

    // States
    const [tableRows, setTableRows] = useState();
    const [editableDetail, setEditableDetails] = useState();
    const [isPopupVisible, setPopupVisible] = useState();

    // Custom Functions
    const data = {
        firstName: serviceProvider?.firstName,
        lastName: `${serviceProvider?.lastName}`,
        email: `${serviceProvider?.email}`,
        emailVerified: `${
            serviceProvider?.emailVerified ? "Verified" : "Not Verified"
        }`,
        countryCode: `${serviceProvider?.countryCode}`,
        mobile: `${serviceProvider?.mobile}`,
        mobileVerified: `${
            serviceProvider?.mobileVerified ? "Verified" : "Not Verified"
        }`,
        notificationEnabled: (
            <SwitchButton enabled={serviceProvider?.notificationEnabled}/>
        ),
        dob: `${new Date(serviceProvider?.dob).toLocaleString()}`,
        anniversary: `${new Date(serviceProvider?.anniversary).toLocaleString()}`,
        lastLoginAt: `${new Date(serviceProvider?.lastLoginAt).toLocaleString()}`,
        deviceId: serviceProvider?.deviceId,
        deviceType: serviceProvider?.deviceType,
        referralCode: serviceProvider?.referralCode,
        picture: <ImageStamp src={serviceProvider?.picture}/>,
        orgID: serviceProvider?.orgID,
        subOrgID: serviceProvider?.subOrgID,
        location: serviceProvider?.location,
        rating: serviceProvider?.rating,
        active: <Status active={serviceProvider?.active}/>,
        status: (
            <StatusButton
                title={serviceProvider?.status}
                options={["onboarding", "approved", "rejected"]}
                onChange={status =>
                    dispatch(
                        serviceProviderAction.changeStatus(serviceProvider.userId, status)
                    )
                }
            />
        ),
        documents: (
            <DocumentButton
                approved={
                    serviceProvider?.documents?.filter(doc => doc.status === "approved")
                        .length
                }
                uploaded={
                    serviceProvider?.documents?.filter(doc => doc.documentURL).length
                }
                total={serviceProvider?.documents?.length}
                onClick={() =>
                    history.push(
                        `/${path.split("/", 2)[1]}/service-providers/${
                            serviceProvider?.userId
                        }/documents`
                    )
                }
            />
        ),
        enabled: (
            <SwitchButton
                enabled={serviceProvider?.enabled}
                onClick={() =>
                    userRole?.find(item => item.privilege === "Enable")
                        ? dispatch(serviceProviderAction.toggle(serviceProvider.userId))
                        : null
                }
            />
        )
    };

    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "First Name"
            },
            {
                title: "Last Name"
            },
            {
                title: "Email"
            },
            {
                title: "Email Verified"
            },
            {
                title: "Country Code"
            },
            {
                title: "Mobile"
            },
            {
                title: "Mobile Verified"
            },
            {
                title: "Notification Enabled"
            },
            {
                title: "DOB"
            },
            {
                title: "Anniversary"
            },
            {
                title: "Last Login At"
            },
            {
                title: "Device ID"
            },
            {
                title: "Device Type"
            },
            {
                title: "Referral Code"
            },
            {
                title: "Picture"
            },
            {
                title: "Organization"
            },
            {
                title: "Sub Organziation"
            },
            {
                title: "Location"
            },
            {
                title: "Rating"
            },
            {
                title: "Online"
            },
            {
                title: "Status"
            },
            {
                title: "Documents"
            },
            {
                title: "Enabled"
            }
        ],
        [tableRows]
    );

    return (
        <div>
            <TableVertical
                heading="Service Provider Details"
                headers={tableHeaders}
                data={data}
            />
            {isPopupVisible && (
                <PackageAddOnPopup
                    data={editableDetail}
                    closeModal={() => setPopupVisible(false)}
                />
            )}
        </div>
    );
}
