import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {MdEdit} from "react-icons/md";
import styles from "./styles.module.scss";
import {
    Table,
    SearchButton,
    LightTextButton,
    FilterDropdown,
    ImageStamp
} from "components";
import {cityAction, discountsAction, orgAction} from "redux/actions";
import Popup from "../popups/Discounts/Popup";
import {internalSearch} from "helper";

const initialState = {
    city: "",
    discount: "coupon",
    status: ""
};

export default function () {
    // States
    const [tableRows, setTableRows] = useState();
    const [editableDetail, setEditableDetails] = useState();
    const [isPopupVisible, setPopupVisible] = useState();

    // Filter States
    const [filterState, setFilterState] = useState(initialState);

    // Redux actions
    const dispatch = useDispatch();

    const {discounts, city, userRole} = useSelector(state => ({
        discounts: state.discounts,
        city: state.city,
        userRole: state.userRole?.filter(role => role.module === "Users")
    }));

    const onSearch = () => {
        if (filterState?.city && userRole?.find(item => item.privilege === "Read"))
            dispatch(discountsAction.get(filterState?.city));
    };

    // One-time Effects
    useEffect(() => {
        if (userRole?.find(item => item.privilege === "Read")) {
            dispatch(cityAction.getAll());
            dispatch(orgAction.get());
        }

        return () => {
            dispatch(orgAction.reset());
            if (city?.length) dispatch(cityAction.reset());
        };
    }, []);

    useEffect(() => {
        let filtered = discounts;
        console.log("discounts", discounts)
        if (!filtered?.length) {
            setTableRows();
            return;
        }

        filtered = internalSearch(filterState, "discount", filtered);
        filtered = internalSearch(filterState, "status", filtered);

        // Map table rows
        const data = filtered?.map(obj => ({
            cityName: obj.cityName,
            discount: obj.discount,
            discountType: obj.discountType,
            image: <ImageStamp src={obj.image}/>,
            minDiscount: obj.minDiscount,
            maxDiscount: obj.maxDiscount,
            orgID: obj.orgID,
            suborgID: obj.suborgID,
            categoryID: obj.categoryID,
            userID: obj.userID,
            launchAt: obj.launchAt,
            expireAt: obj.expireAt,
            status: obj.status,
            edit: (
                <MdEdit
                    size="20"
                    color="#528ff0"
                    onClick={
                        userRole?.find(item => item.privilege === "Update")
                            ? () => {
                                setEditableDetails(obj);
                                setPopupVisible(true);
                            }
                            : null
                    }
                    className="clickable"
                />
            )
        }));

        setTableRows(data);
    }, [discounts, filterState]);

    // Table Header
    const tableHeaders = [
        {
            title: "City",
            sortBy: "cityName"
        },
        {
            title: "Discount",
            sortBy: "discount"
        },
        {
            title: "Discount Type",
            sortBy: "discountType"
        },
        {
            title: "Image"
        },
        {
            title: "Min Discount"
        },
        {
            title: "Max Discount"
        },
        {
            title: "Organization",
            sortBy: "orgID"
        },
        {
            title: "Sub Organization",
            sortBy: "suborgID"
        },
        {
            title: "Category",
            sortBy: "categoryID"
        },
        {
            title: "User",
            sortBy: "userID"
        },
        {
            title: "Launch Date",
            sortBy: "launchAt"
        },
        {
            title: "Expiry Date",
            sortBy: "expireAt"
        },
        {
            title: "Status",
            sortBy: "status"
        },
        {
            title: "Edit"
        }
    ];

    const handleChange = e => {
        setFilterState({
            ...filterState,
            [e.target.name]: parseInt(e.target.value)
        });
    };

    return (
        <div>
            <div className={styles.filter}>
                <FilterDropdown
                    onChange={e => handleChange(e)}
                    value={filterState?.city}
                    label="City"
                    name="city"
                    options={
                        city?.length
                            ? ["Pick a city", ...city?.map(item => item.name)]
                            : ["Pick a city"]
                    }
                    values={city?.length ? [0, ...city?.map(item => item.id)] : [0]}
                />
                <FilterDropdown
                    label="Status"
                    onChange={e =>
                        setFilterState({...filterState, status: e.target.value})
                    }
                    value={filterState?.status}
                    options={["All", "Approved", "Pending", "Expired", "Cancelled"]}
                    values={["", "approved", "pending", "expired", "cancelled"]}
                />
                <SearchButton onClick={onSearch}/>
                <LightTextButton/>
            </div>
            <Table headers={tableHeaders} data={tableRows}/>
            {isPopupVisible && (
                <Popup
                    data={editableDetail}
                    closeModal={() => setPopupVisible(false)}
                />
            )}
        </div>
    );
}
