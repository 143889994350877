import { SP_SUB_CATEGORIES } from "../actionTypes";

export default function serviceProviderReducer(state = null, action) {
  const { GET, RESET } = SP_SUB_CATEGORIES;

  switch (action.type) {
    case GET:
      return action.payload;

    case RESET:
      return null;

    default:
      return state;
  }
}
