import React, {useEffect, useMemo, useState} from 'react';
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    FilterDropdown,
    ImageStamp,
    LightTextButton, Search,
    SearchButton,
    Status,
    SwitchButton, Table, Tabs,
    TextInput,
    TextLink
} from "../../components";
import * as XLSX from "xlsx";
import {customersAction} from "redux/actions";
import styles from "../Customers/panels/styles.module.scss";
import PackagesPopup from "../Customers/popups/Packages/PackagesPopup";
import tabRoutes, {tabReportRoutes} from "../Customers/tab-routes";

const Reports = () => {
    const {pathname} = useLocation();
    const currentLocation = pathname.split("/", 3)[1];

    // States
    const [modal, setModal] = useState(); // Popup open or close state

    const popups = [
        {
            title: "+ New Package",
            path: "packages",
            component: closeModal => <PackagesPopup closeModal={closeModal}/>
        }
    ];

    return (
        <>
            {/* Panels */}
            <Tabs
                routes={tabReportRoutes}
                title={popups.find(popup => currentLocation === popup.path)?.title}
                onClick={() => setModal(currentLocation)}
            />

            {/* Modals */}

            {/*<Switch>*/}
            {/*    {tabReportRoutes?.map(tabRoute =>*/}
            {/*        tabRoute?.modalRoutes?.map(({path, component}) => {*/}
            {/*            return <Route path={path} children={component}/>;*/}
            {/*        })*/}
            {/*    )}*/}
            {/*</Switch>*/}
        </>
    );
}
export default Reports;