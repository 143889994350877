import { GET_USER_LIKES, RESET_USER_LIKES } from "../actionTypes";

const userLikes = (state = [], action) => {
  switch (action.type) {
    case GET_USER_LIKES:
      return action.payload;

    case RESET_USER_LIKES:
      return [];

    default:
      return state;
  }
};

export default userLikes;
