import React, {useEffect, useState} from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {BiSend} from "react-icons/bi";
import {Box, Tab, Tabs, useTheme} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import PendingTab from "../../components/Dashboard/PendingTab";
import PropTypes from "prop-types";
import ServiceProviderChat from "./ServiceProviderChat";
import CustomerChat from "./CustomerChat";
import CartCustomerChat from "./CartCustomerChat";
import SubscriptionChat from "./SubscriptionChat";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            style={{backgroundColor: "white"}}
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <p>{children}</p>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


function Chat() {
    const theme = useTheme();

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <div className={'generic-tabs-container container'}>
            <Box display="flex" justifyContent="left" width="100%">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    indicatorColor={'primary'}
                    scrollButtons="auto"
                >
                    <Tab label="Service Provider Support" {...a11yProps(0)} />
                    <Tab label="Customer Support"
                         style={{}} {...a11yProps(1)} />
                    <Tab label="Cart Customer Support"
                         style={{}} {...a11yProps(2)} />
                    <Tab label="Subscription Support"
                         style={{}} {...a11yProps(2)} />
                </Tabs>
            </Box>

            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                disabled={true}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel className={'generic-tab-panel'} value={value} index={0}
                          dir={theme.direction}>
                    <ServiceProviderChat parentDocName={'generic_sp_support'} conversationsName={'gen_conversations'} sp_id={true} message_type={'Service Provider'}/>
                </TabPanel>

                <TabPanel className={'generic-tab-panel'} value={value} index={1} dir={theme.direction}>
                    <ServiceProviderChat parentDocName={'generic_customer_support'} conversationsName={'gen_conversations'} sp_id={false} message_type={'Customer'}/>
                </TabPanel>

                <TabPanel className={'generic-tab-panel'} value={value} index={2} dir={theme.direction}>
                    <ServiceProviderChat parentDocName={'cart_customer_support'} conversationsName={'cart_conversations'} sp_id={false} message_type={'Customer'}/>
                </TabPanel>

                <TabPanel className={'generic-tab-panel'} value={value} index={3} dir={theme.direction}>
                    <SubscriptionChat/>
                </TabPanel>
            </SwipeableViews>
        </div>
    );
}

export default Chat;