import React, { useState } from "react";
import { TextInput, Modal } from "components";
import { useDispatch } from "react-redux";
import { countryAction } from "redux/actions";

export default function CountryPopup({ closeModal }) {
  const [country, setCountry] = useState();

  const dispatch = useDispatch();

  const onSave = () => {
    if (!country) return;
    dispatch(countryAction.add(country));
    closeModal();
  };

  return (
    <Modal title="Create New Country" closeModal={closeModal} onSave={onSave}>
      <TextInput
        label="Country"
        placeholder="Country"
        name="country"
        onChange={e => setCountry(e.target.value)}
        value={country}
      />
    </Modal>
  );
}
