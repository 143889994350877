import React from "react";
import styles from "./../styles.module.scss";
import { TextInput, Button } from "components";

export default function Details({ onClick, details, setDetails, edit }) {
  return (
    <div className={styles.main__container}>
      <TextInput
        label="Category Name"
        placeholder="Category Name"
        name="categoryName"
        onChange={setDetails}
        value={details?.categoryName}
      />
      <TextInput
        label="Category Help Text"
        placeholder="Category Help Text"
        name="categoryHelpText"
        onChange={setDetails}
        value={details?.categoryHelpText}
      />
      <TextInput
        label="Category Help Message"
        placeholder="Category Help Message"
        name="categoryHelpMessage"
        onChange={setDetails}
        value={details?.categoryHelpMessage}
      />
      <TextInput
        label="Display Order"
        placeholder="Display Order"
        name="displayOrder"
        onChange={setDetails}
        value={details?.displayOrder}
      />
      <div className={styles.btn__container}>
        <Button title="Next" onClick={onClick} />
      </div>
    </div>
  );
}
