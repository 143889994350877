import { OWNER } from "../actionTypes";

export default function ownerReducer(state = null, action) {
  const { GET } = OWNER;

  switch (action.type) {
    case GET:
      return action.payload;

    default:
      return state;
  }
}
