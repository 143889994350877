import React from 'react';

function OrdersTab({
                       status,
                       response,
                       data,
                       setData,
                       filter,
                       datesFilter,
                       onDateChange,
                       onChange,
                       setOrdersMeta
                   }) {

    const filterSP = () => {
        if (filter === '') {
            let data = response.filter((payment) => {
                return payment.service_date > datesFilter.end && payment.service_date < datesFilter.start && payment.payment_status === status
            })
            setData(data.sort((a, b) => new Date(b.service_date) - new Date(a.service_date)))
        } else {
            let data = response.filter((payment) => {
                return payment.service_date > datesFilter.end && payment.service_date < datesFilter.start && payment.payment_status === status
                // && payment.sp_id.toString() === filter
            })
            setData(data.sort((a, b) => new Date(b.service_date) - new Date(a.service_date)))
        }
    }


    const calculate_clykk_fee = (item) => {
        let sum = 0;
        item.get_sub_transactions.map((_item) => {
            if (_item.amount_before_tax && _item.commission_mount) {
                sum += _item.amount_before_tax - _item.commission_mount
            }
        })
        return sum.toFixed(2);
    }

    return (
        <div>
            <div style={{display: 'flex'}}>
                {/*<div style={{marginBottom: 10}}>*/}
                {/*    <p style={{margin: "auto 0 5px 0", fontSize: 16, fontWeight: 600}}>Search Service Provider Id</p>*/}
                {/*    <div style={{display: 'flex'}}>*/}
                {/*        <input style={{margin: "auto 0", width: 200}} className="form-control" type={'number'}*/}
                {/*               value={filter}*/}
                {/*               placeholder={"Example: 45"}*/}
                {/*               onChange={onChange}*/}
                {/*        />*/}
                {/*        <button onClick={filterSP} className={'btn btn-primary mx-2'}>Submit</button>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            <div className="accordion" id="accordionExample">
                {data.map((item, index) => {
                    return (
                        <div className="accordion-item mb-4">
                            <h2 className="accordion-header" id={`heading${index}`}>
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target={`#collapse${index}`} aria-expanded="false"
                                        aria-controls={`collapse${index}`}>
                                    <div>
                                        <div style={{display: 'flex'}}>
                                            <p style={{fontSize: 16}}>Subscription Id: <span
                                                style={{fontWeight: 600}}>{item.subscription_id}</span></p>
                                            <p style={{fontSize: 16, marginLeft: 10}}>Service Provider Id: <span
                                                style={{fontWeight: 600}}>{item.sp_id}</span></p>
                                            <p style={{fontSize: 16, marginLeft: 10}}>
                                                Order Status: {item.status === "Completed" ?
                                                <span style={{
                                                    padding: "2px 5px",
                                                    borderRadius: 5,
                                                    backgroundColor: "#27c24c",
                                                    color: "white"
                                                }}>
                                                Completed
                                            </span>
                                                :
                                                <span style={{
                                                    padding: "2px 5px",
                                                    borderRadius: 5,
                                                    backgroundColor: "#f05050",
                                                    color: "white"
                                                }}>
                                                {item.status}
                                            </span>
                                            }</p>

                                            {item.payment_status === "done" &&
                                                <p style={{fontSize: 16, marginLeft: 10}}>Payment Date:
                                                    <span style={{
                                                        fontSize: 14,
                                                        marginLeft: 5
                                                    }}>{item.updated_at.slice(0, 10)}</span>
                                                </p>
                                            }
                                            <p style={{fontSize: 16, marginLeft: 10}}>Total: <span style={{
                                                padding: "2px 5px",
                                                borderRadius: 5,
                                                backgroundColor: "#27c24c",
                                                color: "white"
                                            }}>{item.total}</span></p>
                                            <p style={{fontSize: 16, marginLeft: 10}}>CLYKK Fee: <span style={{
                                                padding: "2px 5px",
                                                borderRadius: 5,
                                                backgroundColor: "#27c24c",
                                                color: "white"
                                            }}>{calculate_clykk_fee(item)}</span></p>
                                        </div>

                                        <div>
                                            <div style={{display: 'flex'}}>
                                                <p>
                                                    Order Id: {item.get_sub_transactions[0].order_id}
                                                </p>
                                                <p style={{marginLeft: 10}}>{item.created_at.slice(0, 10)}
                                                </p>
                                                <p style={{marginLeft: 10}}>Service Date: <span
                                                    style={{fontWeight: 600}}>{item.service_date}</span>
                                                </p>
                                                <p style={{marginLeft: 10}}>Service Time: <span
                                                    style={{fontWeight: 600}}>{item.service_time}</span>
                                                </p>
                                            </div>
                                            <div style={{display: 'flex'}}>
                                                <p style={{
                                                    fontSize: 14
                                                }}>{item.get_cart_package_details.package_name}</p>
                                                <p style={{marginLeft: 10}}>Cart
                                                    Id: {item.get_cart_package_details.cartID}</p>
                                                <p style={{marginLeft: 10}}>Sub Category
                                                    Id: {item.get_cart_package_details.sub_category_id}</p>
                                                <p style={{marginLeft: 10}}>Base
                                                    Price: {item.get_cart_package_details.base_price}</p>
                                                <p style={{marginLeft: 10}}>Selling
                                                    Price: {item.get_cart_package_details.selling_price}</p>
                                            </div>
                                        </div>

                                        <div>
                                            <div style={{display: 'flex'}}>
                                                <p>{item.get_address_details.line2}</p>
                                            </div>
                                        </div>

                                        <div>
                                            <div style={{display: 'flex'}}>
                                                <p><b>Routing:</b> {item.get_routing_details.status}</p>
                                                {item.get_routing_details.get_s_p_details &&
                                                    <>
                                                        <p style={{marginLeft: 5}}><b>SP Details:</b></p>
                                                        <p style={{marginLeft: 5}}>{item.get_routing_details.get_s_p_details.id}</p>
                                                        <p style={{marginLeft: 5}}>{item.get_routing_details.get_s_p_details.first_name}</p>
                                                        <p style={{marginLeft: 5}}>{item.get_routing_details.get_s_p_details.last_name}</p>
                                                        <p style={{marginLeft: 5}}>{item.get_routing_details.get_s_p_details.email}</p>
                                                        <p style={{marginLeft: 5}}>{item.get_routing_details.get_s_p_details.mobile}</p>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id={`collapse${index}`} className="accordion-collapse collapse"
                                 aria-labelledby={`heading${index}`}
                                 data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    {item.get_sub_transactions.map((_item) => {
                                        let subscription = []
                                        if(item.get_s_p_payments.length > 0) {
                                            subscription = item.get_s_p_payments[0].get_payment_transaction.filter(item => item.transaction_id == _item.id)
                                        }
                                        if (subscription.length !== 0) {
                                            subscription = subscription[0]
                                        } else {
                                            subscription = {
                                                commission: 0,
                                            }
                                        }

                                        return (
                                            <div className={'ml-4 mb-2'}>
                                                <div style={{display: "flex"}}>
                                                    <p>Description: {_item.description}</p>
                                                    <p style={{marginLeft: 10}}>Created
                                                        At: {item.created_at.slice(0, 10)}</p>
                                                </div>
                                                <div style={{display: "flex"}}>
                                                    <p>Total Amount: <span style={{fontWeight: 600, color: "#f05050"}}>
                                                    {_item.amount}</span></p>

                                                    <p style={{marginLeft: 10}}>Amount Paid: <span
                                                        style={{fontWeight: 600, color: "#f05050"}}>
                                                    {_item.payable_amount}</span></p>

                                                    <p style={{marginLeft: 10}}>Amount Before Tax: <span
                                                        style={{fontWeight: 600, color: "#f05050"}}>
                                                    {_item.amount_before_tax}</span></p>

                                                    <p style={{marginLeft: 10}}>Tax Amount: <span
                                                        style={{fontWeight: 600, color: "#f05050"}}>
                                                    {_item.tax_amount}</span></p>

                                                    <p style={{marginLeft: 10}}>Commission Percentage: <span
                                                        style={{fontWeight: 600, color: "#27c24c"}}>
                                                    {subscription.commission}
                                                </span></p>
                                                    {/*        <p style={{marginLeft: 10}}>Commission to be paid: <span*/}
                                                    {/*            style={{fontWeight: 600, color: "#27c24c"}}>*/}
                                                    {/*    {_item.commission === 100 ? subscription.payable_amount : _item.commission_mount}*/}
                                                    {/*</span>*/}
                                                    {/*        </p>*/}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

        </div>
    );
}

export default OrdersTab;