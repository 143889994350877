import React from "react";
import {useParams} from "react-router-dom";
import {TableVertical, SideModal} from "components";
import {useDispatch, useSelector} from "react-redux";
import {manageUserAction} from "redux/actions";
import {SwitchButton} from "components";

const userDetails = [
    "ID",
    "Picture",
    "First Name",
    "Last Name",
    "Email",
    "Email Verified",
    "Mobile",
    "Mobile Verified",
    "Created At",
    "Created By",
    "Updated At",
    "Updated By",
    "Enabled"
];

const ManageUsers = () => {
    const dispatch = useDispatch();

    // params
    const {id} = useParams();

    // redux
    const user = useSelector(state =>
        state.users.find(user => user.admin_id == id)
    );

    // Custom Functions
    const data = {
        admin_id: user.admin_id,
        picture: `${user.picture}`,
        first_name: `${user.first_name}`,
        last_name: `${user.last_name}`,
        email: `${user.email}`,
        email_verified: `${user.email_verified ? "Verified" : "Not Verified"}`,
        mobile: `${user.mobile}`,
        mobile_verified: `${user.mobile_verified ? "Verified" : "Not Verified"}`,
        created_at: `${new Date(user.created_at).toLocaleString()}`,
        created_by: user.created_by,
        updated_at: `${new Date(user.updated_at).toLocaleString()}`,
        updated_by: user.updated_by,
        enabled: (
            <SwitchButton
                enabled={user.enabled}
                onClick={() => dispatch(manageUserAction.toggle(user.admin_id))}
            />
        )
    };

    const role = user.role;

    return (
        <SideModal title="User ID">
            <div style={{paddingRight: "20px", paddingLeft: "20px"}}>
                <TableVertical
                    heading="User Details"
                    headers={userDetails}
                    data={data}
                />
                <TableVertical heading="Roles" headers={role} data={role}/>
            </div>
        </SideModal>
    );
};

export default ManageUsers;
