import axios from "axios";

import {SP_ADDRESS, LOADING} from "../actionTypes";
import {IMAGE_URL, Who_Obj} from "configurations";
import {adminToken} from "../../helper";

const {GET, UPDATE, RESET, TOGGLE} = SP_ADDRESS;

const {SET_TRUE, SET_FALSE} = LOADING;
export const get = id => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();

        axios
            .get(`https://cgapp.clykk.com/sp/clykk/int/sp/v1/get-address/${id}`, {
                headers: {Authorization: `Bearer ${token}`}
            })
            .then(res => {
                dispatch({type: GET, payload: res.data.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const add = (cid, sid, name) => {
    return dispatch => {
        dispatch({type: SET_TRUE});
        axios
            .post(
                `http://cgapp.clykk.com/location/country/${cid}/state/${sid}/city`,
                {
                    name,
                    enabled: true
                },
                {
                    headers: {who: Who_Obj}
                }
            )
            .then(res => {
                dispatch(get(cid, sid));
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

// Update
export const update = (details = {}) => {
    const {name, displayName, description, displayOrder, id, image} = details;

    return async dispatch => {
        dispatch({type: SET_TRUE});
        let imageRes;

        // Request to Upload Image
        if (typeof image === "object") {
            const formData = new FormData();
            formData.append("imageFile", image);
            imageRes = await axios.post(`${IMAGE_URL}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
        }

        const finalRes = await axios.put(
            `http://cgapp.clykk.com/location/country/${id}`,
            {
                description,
                displayName,
                name,
                displayOrder: parseInt(displayOrder),
                image: imageRes?.data.imgUrl || image
            },
            {
                headers: {who: JSON.stringify(Who_Obj)}
            }
        );

        dispatch({type: UPDATE, payload: finalRes.data});
    };
};

export const toggle = id => {
    return dispatch => {
        dispatch({type: SET_TRUE});
        axios
            .patch(
                `http://cgapp.clykk.com/location/country/${id}`,
                {},
                {headers: {who: JSON.stringify(Who_Obj)}}
            )
            .then(res => dispatch({type: TOGGLE, payload: res.data}))
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const remove = (cid, sid, id) => {
    return dispatch => {
        dispatch({type: SET_TRUE});
        axios
            .delete(
                `http://cgapp.clykk.com/location/country/${cid}/state/${sid}/city/${id}`,
                {
                    headers: {who: Who_Obj}
                }
            )
            .then(res => dispatch(get(cid, sid)))
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const reset = () => dispatch => dispatch({type: RESET});
