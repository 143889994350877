import React, { useRef, useState, useEffect } from "react";
import styles from "./styles.module.scss";

export default function ImageUpload({ onImageSelect, image }) {
  const hiddenFileInput = useRef(null);
  const [imageSrc, setImageSrc] = useState();

  useEffect(() => {
    if (!image) return;
    setImageSrc(image);
  }, [image]);

  const handleClick = event => {
    hiddenFileInput.current?.click();
  };

  // Handle Image Select
  const handleImageSelect = e => {
    e.preventDefault();

    const files = e.target.files || e.dataTransfer.files;

    const reader = new FileReader();
    reader.onload = e => setImageSrc(e.target.result);
    reader.readAsDataURL(files[0]);

    onImageSelect(files[0]);
  };

  return (
    <div
      onClick={handleClick}
      className={styles.image__upload}
      onDrop={handleImageSelect}
      onDragOver={e => {
        e.preventDefault();
      }}
      onDragEnter={e => {
        e.preventDefault();
      }}
      onDragLeave={e => {
        e.preventDefault();
      }}>
      {!imageSrc ? (
        <p>
          Drag and drop image here
          <br />
          Or
          <br />
          Click to choose image
        </p>
      ) : (
        <img src={imageSrc} alt="Dropped-Img" className={styles.image} />
      )}

      {/* Hidden File Input */}
      <input
        type="file"
        className={styles.image__input}
        onChange={handleImageSelect}
        accept="image/*"
        ref={hiddenFileInput}
      />
    </div>
  );
}
