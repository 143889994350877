import React, {useState, useEffect} from "react";
import styles from "./styles.module.scss";
import {
    Table,
    TextInput,
    SearchButton,
    LightTextButton,
    Icon,
    SwitchButton
} from "components";
import {useDispatch, useSelector} from "react-redux";
import {modulesAction} from "redux/actions";
import ModulesPopup from "../popups/Modules/ModulesPopup";
import {MdEdit} from "react-icons/md";

const tableHeaders = [
    "ID",
    {title: "Module Name", sortBy: "moduleName"},
    "Description",
    "Created At",
    "Created By",
    "Updated At",
    "Updated By",
    "Enabled",
    "Edit"
    // "Delete"
];

const ModulesPanel = () => {
    const [editableDetail, setEditableDetails] = useState();
    const [name, setName] = useState("");
    const [tableRows, setTableRows] = useState();

    // Disptach redux action to get modules
    const dispatch = useDispatch();
    const {modules, userRole} = useSelector(state => ({
        modules: state.modules,
        userRole: state.userRole?.filter(role => role.module === "Users")
    }));

    // Effects
    useEffect(() => {
        if (userRole?.find(item => item.privilege === "Read"))
            dispatch(modulesAction.get());
    }, [dispatch]);

    // Custom Functions
    useEffect(() => {
        let filtered = modules;
        if (!filtered?.length) {
            setTableRows();
            return;
        }

        filtered = filtered.filter(
            item =>
                item?.module_name?.toLowerCase().indexOf(name?.toLowerCase()) !== -1
        );

        const data = filtered?.map(obj => ({
            moduleID: `${obj.module_id}`,
            moduleName: `${obj.module_name}`,
            description: `${obj.description}`,
            createdAt: `${new Date(obj.created_at).toLocaleString()}`,
            createdBy: obj.created_by,
            updatedAt: `${new Date(obj.updated_at).toLocaleString()}`,
            updatedBy: obj.updated_by,
            enabled: (
                <SwitchButton
                    enabled={obj.enabled}
                    onClick={
                        userRole?.find(item => item.privilege === "Enable")
                            ? () =>
                                dispatch(
                                    obj.enabled
                                        ? modulesAction.inactive(obj)
                                        : modulesAction.active(obj)
                                )
                            : null
                    }
                />
            ),
            edit: (
                <Icon
                    ReactIcon={MdEdit}
                    name="MdEdit"
                    color="primary"
                    onClick={
                        userRole?.find(item => item.privilege === "Update")
                            ? () => setEditableDetails(obj)
                            : null
                    }
                />
            )
            // delete: (
            //   <Icon
            //     color="danger"
            //     onClick={() => dispatch(modulesAction.remove(obj))}
            //   />
            // )
        }));

        setTableRows(data);
    }, [modules, name, dispatch]);

    return (
        <div>
            <div className={styles.filter}>
                <TextInput
                    label="Module Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
                <SearchButton/>
                <LightTextButton onClick={() => setName("")}/>
            </div>
            <Table headers={tableHeaders} data={tableRows}/>

            {editableDetail && (
                <ModulesPopup
                    data={editableDetail}
                    closeModal={() => setEditableDetails()}
                />
            )}
        </div>
    );
};

export default ModulesPanel;
