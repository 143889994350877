import React from "react";
import styles from "./GreenButtonSm.module.scss";

const GreenButtonSm = ({ title, ...props }) => (
  <button className={styles.green__btn} {...props}>
    {title}
  </button>
);

export default GreenButtonSm;
