import {
  GET_HOME_GREETING,
  ADD_HOME_GREETING,
  TOGGLE_HOME_GREETING,
  UPDATE_HOME_GREETING,
  RESET_HOME_GREETING,
  LOADING
} from "../actionTypes";
import axios from "axios";
import { adminToken } from "helper";

import { AWS_HOMEPAGE_8088 } from "configurations";
import uploadImage from "helper/uploadImage";

const { SET_TRUE, SET_FALSE } = LOADING;

export const get = () => {
  return dispatch => {
    dispatch({ type: SET_TRUE });

    adminToken()
      .then(token =>
        axios.get(`${AWS_HOMEPAGE_8088}/homepageimage`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
      )
      .then(res => {
        dispatch({
          type: GET_HOME_GREETING,
          payload: [...res.data["customer"], ...res.data["service-provider"]]
        });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

// Add Category
export const add = (details = {}) => {
  const {
    imageUrl,
    client,
    imageText,
    imageHelp,
    availableFrom,
    availableTo,
    cityLocation
  } = details;

  if (
    !imageUrl ||
    !client ||
    !imageText ||
    !imageHelp ||
    !availableFrom ||
    !availableTo
  )
    return;

  return dispatch => {
    dispatch({ type: SET_TRUE });

    uploadImage(imageUrl, "homepage-management")
      .then(async imgUrl => {
        const token = await adminToken();
        // Request to Create Category
        axios.post(
          `${AWS_HOMEPAGE_8088}/${client}/homepageimage`,
          {
            imageText,
            imageHelp,
            cityLocation,
            client,
            availableFrom: new Date(availableFrom),
            availableTo: new Date(availableTo),
            imageUrl: imgUrl
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      })
      .then(res => {
        dispatch({
          type: ADD_HOME_GREETING,
          payload: { ...res?.data, client }
        });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

// Update Sub Category
export const update = (details = {}) => {
  const {
    id,
    imageUrl,
    client,
    imageText,
    imageHelp,
    availableFrom,
    availableTo,
    cityLocation
  } = details;

  if (
    !id ||
    !imageUrl ||
    !client ||
    !imageText ||
    !imageHelp ||
    !availableFrom ||
    !availableTo
  )
    return;

  return async dispatch => {
    dispatch({ type: SET_TRUE });
    let imgUrl;

    // Request to Upload Image
    if (typeof imageUrl === "object")
      imgUrl = await uploadImage(imageUrl, "homepage-management");

    const token = await adminToken();

    // Request to Create Category
    const finalRes = await axios.put(
      `${AWS_HOMEPAGE_8088}/${client}/homepageimage`,
      {
        id,
        imageText,
        imageHelp,
        cityLocation,
        client,
        availableFrom: new Date(availableFrom),
        availableTo: new Date(availableTo),
        imageUrl: imgUrl || imageUrl
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    dispatch({
      type: UPDATE_HOME_GREETING,
      payload: { ...finalRes.data, client }
    });

    dispatch({ type: SET_FALSE });
  };
};

// Enable or disable category
export const toggle = (client, id) => {
  return async dispatch => {
    dispatch({ type: SET_TRUE });

    const token = await adminToken();

    axios
      .patch(
        `${AWS_HOMEPAGE_8088}/${client}/homepageimage/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(res => dispatch({ type: TOGGLE_HOME_GREETING, payload: id }))
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const reset = () => ({ type: RESET_HOME_GREETING });
