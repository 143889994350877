import {useState} from "react";
import {useDispatch} from "react-redux";
import styles from "./styles.module.scss";
import {
    GrayCard,
    PhoneInput,
    GreenButton,
    GreenButtonSm,
    CountDown,
    AuthInput,
    LoadingSpinner
} from "components";
import logo from "assets/img/logo.png";
import {useHistory} from "react-router-dom";
import {confirmCode, sendCode} from "../../components/Firebase/firebase";


const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    // States
    const [phoneCard, setPhoneCard] = useState(true);
    const [timer, setTimer] = useState("02:59");
    const [percent, setPercent] = useState();
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [isAgree, setIsAgree] = useState(false);
    const [phone, setPhone] = useState("");
    const [countryCode, setCountryCode] = useState();
    const [rawPhone, setRawPhone] = useState();

    const [confirmation, setConfirmation] = useState(null);
    const [intervals, setIntervals] = useState(null)

    return (
        <div className={styles.container}>
            <div className={`${styles.sub__container} ${styles.green}`}></div>
            <div className={styles.sub__container}>
                <div className={styles.top__bar}>
                    <img src={logo} className={styles.logo} alt="CLYKK"/>

                    <div className={styles.signup__container}>
                        <span>New to CLYKK?</span>
                        <GreenButtonSm
                            title="Sign Up"
                            onClick={() => history.push("/sign-up")}
                        />
                    </div>
                </div>

                {phoneCard ? (
                    <GrayCard>
                        {loading ? (
                            <LoadingSpinner/>
                        ) : (
                            <>
                                <p className={styles.heading}>Sign In</p>

                                <PhoneInput
                                    onChange={(value, countryData) => {
                                        setPhone(value);
                                        setCountryCode(countryData?.dialCode);
                                        setRawPhone(value?.slice(countryData?.dialCode?.length));
                                    }}
                                    value={phone}
                                />

                                <div className={styles.text__container}>
                                    <p>
                                        We will send you a One Time Password (OTP) SMS. Standard
                                        carrier rates may apply.
                                    </p>
                                </div>

                                <GreenButton title="Send Code" onClick={() => sendCode(phone, isAgree, setLoading, confirmation, setConfirmation, setRawPhone, setPhoneCard, intervals, setIntervals, setTimer, setPercent)}/>

                                <div className={styles.text__container}>
                                    <input
                                        type="checkbox"
                                        style={styles.checkbox}
                                        checked={isAgree}
                                        onChange={() => setIsAgree(prev => !prev)}
                                    />
                                    <p>
                                        By clicking, I accept{" "}
                                        <a href="http://www.clykk.com/terms" target="_blank">
                                            terms of use
                                        </a>{" "}
                                        and{" "}
                                        <a
                                            href="http://www.clykk.com/privacy-policy/"
                                            target="_blank">
                                            privacy policy.
                                        </a>
                                    </p>
                                </div>
                            </>
                        )}

                        <div id="recaptcha__container"></div>
                    </GrayCard>
                ) : (
                    <GrayCard>
                        {loading ? (
                            <LoadingSpinner/>
                        ) : (
                            <>
                                <p className={styles.heading}>Sign In</p>
                                <div className={styles.text__container}>
                                    <p>
                                        We have sent an OTP to your contact number, kindly enter the
                                        SMS code.
                                    </p>
                                </div>
                                <AuthInput
                                    styles={{marginTop: 20}}
                                    name="code"
                                    placeholder="Enter your code"
                                    value={code}
                                    onChange={e => setCode(e.target.value)}
                                />
                                <CountDown
                                    percentage={100 - Math.floor((percent / 180) * 100)}
                                    value={timer}
                                />

                                <GreenButton title="Login"
                                             onClick={() => confirmCode(setLoading, code, dispatch, confirmation, phone, countryCode, rawPhone, intervals)}/>

                                <div className={styles.text__container}>
                                    {timer === "00:00" && (
                                        <p>
                                            Didn't receive a code?{" "}
                                            <span
                                                onClick={() => sendCode(phone, isAgree, setLoading, confirmation, setConfirmation, setRawPhone, setPhoneCard, intervals, setIntervals, setTimer, setPercent)}>Resend Code</span>
                                        </p>
                                    )}
                                </div>
                            </>
                        )}
                    </GrayCard>
                )}
            </div>
        </div>
    );
};

export default Login;
