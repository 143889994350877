import React, { useEffect } from "react";
import { TextInput, FilterDropdown, Alert, Button } from "components";
import { useDispatch } from "react-redux";
import { subOrgAction } from "redux/actions";
import styles from "../styles.module.scss";

function DiscountDiscription({onClick, details, setDetails}) {
    const dispatch = useDispatch();
    // Get sub-organizations base on organziation
    useEffect(() => {
        if (details?.orgID) dispatch(subOrgAction.get());
        else {
            dispatch(subOrgAction.reset());
        }
    }, [details?.orgID]);
    return (
        <div className={styles.main__container}>
            <Alert/>
            <TextInput
                label="Title"
                name="title"
                onChange={setDetails}
                value={details?.title}
                textarea={true}
            />
            <TextInput
                label="Description"
                name="description"
                onChange={setDetails}
                value={details?.description}
                textarea={true}
            />
            <FilterDropdown
                onChange={setDetails}
                value={details?.status}
                label="Status"
                name="status"
                options={["Pending", "Approved", "Expired", "Canceled"]}
                values={["pending", "approved", "expired", "canceled"]}
            />
            <div className={styles.btn__container}>
                <Button title="Next" onClick={onClick}/>
            </div>
        </div>
    );
}

export default DiscountDiscription;