import React, {useEffect, useState, useMemo} from "react";
import styles from "./styles.module.scss";
import {
    Table,
    TextInput,
    SearchButton,
    LightTextButton,
    Icon,
    SwitchButton
} from "components";
import {useDispatch, useSelector} from "react-redux";
import {
    rolesAction,
    modulesAction,
    privilegesAction,
    adminRolesAction,
    manageUserAction
} from "redux/actions";
import {internalSearch} from "helper";
import AdminRolePopup from "../popups/AdminRoles";
import {MdEdit} from "react-icons/md";

const tableHeaders = [
    "ID",
    {title: "Admin ID", sortBy: "admin"},
    "Role",
    "Module",
    "Privilege",
    "Created At",
    "Created By",
    "Updated At",
    "Updated By",
    "Status",
    "Edit"
    // "Delete"
];

const initialState = {
    role: "",
    module: "",
    privilege: "",
    admin: ""
};

const AdminRolesPanel = () => {
    // Disptach redux action to get default privileges
    const dispatch = useDispatch();
    const {adminRoles, roles, modules, privileges, users, userRole} =
        useSelector(state => ({
            ...state,
            userRole: state.userRole?.filter(role => role.module === "Users")
        }));

    const [search, setSearch] = useState(initialState);
    const [editableDetails, setEditableDetails] = useState();

    // Effects
    useEffect(() => {
        if (userRole?.find(item => item.privilege === "Read")) {
            dispatch(adminRolesAction.get());
            dispatch(rolesAction.get());
            dispatch(modulesAction.get());
            dispatch(privilegesAction.get());
            dispatch(manageUserAction.get());
        }
    }, [dispatch]);

    const rows = useMemo(() => {
        let filtered = adminRoles;

        if (!filtered?.length) return [];

        const data = filtered;

        filtered = filtered?.map(obj => ({
            ...obj,
            admin:
                users?.find(item => item?.admin_id == obj?.admin_id)?.first_name ||
                obj?.admin_id,
            role: roles?.find(item => item?.role_id == obj?.role_id)?.role_name,
            module: modules?.find(item => item?.module_id == obj?.module_id)
                ?.module_name,
            privilege: privileges?.find(
                item => item?.privilege_id == obj?.privilege_id
            )?.name
        }));

        filtered = internalSearch(search, "role", filtered);
        filtered = internalSearch(search, "module", filtered);
        filtered = internalSearch(search, "privilege", filtered);
        filtered = internalSearch(search, "admin", filtered);

        filtered = filtered?.map(obj => ({
            role_map_id: obj?.role_map_id,
            admin: obj?.admin,
            role: obj?.role,
            module: obj?.module,
            privilege: obj?.privilege,
            created_at: `${new Date(obj?.created_at).toLocaleString()}`,
            created_by: obj?.created_by,
            updated_at: `${new Date(obj?.updated_at).toLocaleString()}`,
            updated_by: obj?.updated_by,
            status: (
                <SwitchButton
                    enabled={obj?.status === "1" ? true : false}
                    onClick={
                        userRole?.find(item => item.privilege === "Enable")
                            ? () =>
                                obj?.status === "1"
                                    ? dispatch(adminRolesAction.remove(obj?.role_map_id))
                                    : dispatch(adminRolesAction.active(obj.role_map_id))
                            : null
                    }
                />
            ),
            edit: (
                <Icon
                    ReactIcon={MdEdit}
                    name="MdEdit"
                    color="primary"
                    onClick={
                        userRole?.find(item => item.privilege === "Update")
                            ? () =>
                                setEditableDetails(
                                    data?.find(item => item?.role_map_id === obj?.role_map_id)
                                )
                            : null
                    }
                />
            )
            // delete: (
            //   <Icon
            //     color="danger"
            //     onClick={() => dispatch(adminRolesAction.remove(obj?.role_map_id))}
            //   />
            // )
        }));

        return filtered;
    }, [adminRoles, roles, modules, privileges, users, search]);

    const handleChange = setter => event =>
        setter(prev => ({...prev, [event.target.name]: event.target.value}));

    return (
        <div>
            <div className={styles.filter}>
                <TextInput
                    label="Admin Name"
                    name="admin"
                    value={search?.admin}
                    onChange={handleChange(setSearch)}
                />

                <TextInput
                    label="Role Name"
                    name="role"
                    value={search?.role}
                    onChange={handleChange(setSearch)}
                />

                <TextInput
                    label="Module Name"
                    name="module"
                    value={search?.module}
                    onChange={handleChange(setSearch)}
                />

                <TextInput
                    label="Privilege Name"
                    name="privilege"
                    value={search?.privilege}
                    onChange={handleChange(setSearch)}
                />
                <SearchButton/>
                <LightTextButton onClick={() => setSearch(initialState)}/>
            </div>
            <Table headers={tableHeaders} data={rows}/>

            {editableDetails && (
                <AdminRolePopup
                    data={editableDetails}
                    closeModal={() => setEditableDetails()}
                />
            )}
        </div>
    );
};

export default AdminRolesPanel;
