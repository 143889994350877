import React, { useState, useEffect } from "react";
import { SideModal, Tabs, TextLink } from "components";
import { modalTabRoutes } from "../tab-routes";
import { useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

const SubscriptionSideModal = () => {
  // Routers
  const { id } = useParams();
  const { pathname } = useLocation();

  // Redux

  const { transaction, subscription, manualRouting, subscriptionRouting } =
    useSelector(state => ({
      transaction: state.subsDetail?.transaction?.[0],
      subscription: state.subscription?.subscriptions?.find(
        item => item.id == id
      ),
      manualRouting: state.manualRouting,
      subscriptionRouting: state.subscriptionRouting
    }));

  const [titles, setTitles] = useState();

  useEffect(() => {
    const cart = subscription;
    const titleArr = [
      { key: "Order ID", value: cart?.cartID },
      {
        key: "Transaction ID",
        value: (
          <TextLink
            text={transaction?.transactionID}
            onClick={() =>
              window.open(
                `https://dashboard.razorpay.com/app/payments/${transaction?.transactionID}`,
                "_blank"
              )
            }
          />
        )
      },
      pathname?.split("/")?.at(-1) === "manual-routing" &&
        manualRouting && {
          key: "Category",
          value: manualRouting?.[0]?.category_id
        },
      pathname?.split("/")?.at(-1) === "manual-routing" &&
        manualRouting && {
          key: "Sub Category",
          value: manualRouting?.[0]?.subcategory_id
        },
      pathname?.split("/")?.at(-1) === "routing" &&
        subscriptionRouting && {
          key: "User",
          value: subscriptionRouting?.[0]?.user_id
        }
    ];
    setTitles(titleArr);
  }, [transaction, manualRouting, pathname, subscriptionRouting]);

  return (
    <SideModal
      title="Subscription ID"
      titles={titles?.length ? [...titles] : []}>
      <Tabs routes={modalTabRoutes} />
    </SideModal>
  );
};

export default SubscriptionSideModal;
