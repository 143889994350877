import CustomersPanel from "./panels/CustomersPanel";
import Profile from "./panels/Profile";
import CustomerSideModal from "./modals/CustomerSideModal";
import SubscriptionSideModal from "./modals/SubscriptionSideModal";
import Dummy from "./panels/Dummy";
import Address from "./panels/Address";
import Cart from "./panels/Cart";
import Coupons from "./panels/Coupons";
import Todo from "./panels/Todo";
import Order from "./panels/Order";
import Preferences from "./panels/Preferences";
import Subscriptions from "./panels/Subscriptions";
import Likes from "./panels/Likes";
import Shares from "./panels/Shares";
import ReferAndEarn from "./panels/ReferAndEarn";

import Requirements from "./subscription-panels/Requirements";
import Checklist from "./subscription-panels/Checklist";
import Details from "./subscription-panels/Details";
import Subscription from "./subscription-panels/Subscription";
import BookingDetails from "./subscription-panels/BookingDetails";
import AdditionalBills from "./subscription-panels/AdditionalBills";
import Transactions from "./subscription-panels/Transactions";
import Routing from "./subscription-panels/Routing";
import ManualRouting from "./subscription-panels/ManualRouting";
import ReportPanel from "../Report/ReportPanel";

const tabRoutes = [
    {
        name: "Customers",
        path: "/customers",
        component: <CustomersPanel/>,
        modalRoutes: [
            {
                name: "",
                path: "/customers/customers/:id/customer-modal",
                component: <CustomerSideModal/>
            },
            {
                name: "",
                path: "/customers/customers/:sid/:id/subscription-modal",
                component: <SubscriptionSideModal/>
            }
        ]
    }
];

export default tabRoutes;

export const tabReportRoutes = [
    {
        name: "Customers",
        path: "/customers",
        component: <ReportPanel/>,
    }
];

export const modalTabRoutes = [
    {
        name: "Profile",
        path: "/profile",
        component: <Profile/>
    },
    {
        name: "Address",
        path: "/address",
        component: <Address/>
    },
    {
        name: "Cart",
        path: "/cart",
        component: <Cart/>
    },
    {
        name: "To-Do",
        path: "/to-do",
        component: <Todo/>
    },
    {
        name: "Subscriptions",
        path: "/subscriptions",
        component: <Subscriptions/>
    },
    {
        name: "Orders",
        path: "/orders",
        component: <Order/>
    },
    {
        name: "Preferences",
        path: "/preferences",
        component: <Preferences/>
    },
    {
        name: "Likes",
        path: "/likes",
        component: <Likes/>
    },
    {
        name: "Shares",
        path: "/shares",
        component: <Shares/>
    },
    {
        name: "Refer & Earn",
        path: "/refer-earn",
        component: <ReferAndEarn/>
    },
    {
        name: "Coupons",
        path: "/coupons",
        component: <Coupons/>
    }
];

export const subscriptionModalTabRoutes = [
    {
        name: "Booking Details",
        path: "/booking-details",
        component: <BookingDetails/>
    },
    {
        name: "Subscription",
        path: "/subscription",
        component: <Subscription/>
    },
    {
        name: "Package Details",
        path: "/package-details",
        component: <Details/>
    },
    {
        name: "Customer Requirements",
        path: "/customer-requirements",
        component: <Requirements/>
    },
    {
        name: "SP Checklist",
        path: "/sp-checklist",
        component: <Checklist/>
    },
    {
        name: "Additional Bills",
        path: "/additional-bills",
        component: <AdditionalBills/>
    },
    {
        name: "Transactions",
        path: "/transactions",
        component: <Transactions/>
    },
    {
        name: "Rating",
        path: "/rating",
        component: <Dummy/>
    },
    {
        name: "Routing",
        path: "/routing",
        component: <Routing/>
    },
    {
        name: "Manual Routing",
        path: "/manual-routing",
        component: <ManualRouting/>
    }
];
