import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, CheckBox, Button } from "components";
import { useHistory } from "react-router-dom";
import { manualRoutingAction, subscriptionRoutingAction } from "redux/actions";

export default function Subscription() {
  // Routers
  const id = useHistory().location.pathname.split("/")[3];

  // Redux
  const dispatch = useDispatch();
  const { manualRouting } = useSelector(state => state);

  const [tableRows, setTableRows] = useState();
  const [spIDs, setSpIDs] = useState([]);

  useEffect(() => {
    dispatch(manualRoutingAction.get(id));
  }, [dispatch]);

  useEffect(() => {
    let filtered = manualRouting;
    if (!filtered?.length) {
      setTableRows();
      return;
    }

    // Map table rows
    filtered = filtered?.map(obj => {
      const {
        first_name,
        last_name,
        mobile,
        country_code,
        role,
        org_id,
        sub_org_id,
        location,
        city_id
      } = obj || {};

      return {
        select: (
          <CheckBox
            marginRight={0}
            marginTop={0}
            checked={spIDs.find(id => id == obj?.sp_id) ? true : false}
            onChange={e =>
              setSpIDs(prev => {
                const arr = prev;
                if (e.target.checked) arr.push(obj?.sp_id);
                else arr.splice(arr.indexOf(obj?.sp_id), 1);

                console.log({
                  checked: e.target.checked,
                  arr,
                  prev
                });
                return [...arr];
              })
            }
          />
        ),
        sp_id: obj?.sp_id,
        sp_name: first_name + " " + last_name,
        mobile: country_code + mobile,
        role,
        org_id,
        sub_org_id,
        location,
        city_id,
        s2_c: obj?.s2_c,
        c2_s: obj?.c2_s,
        s2_vc: obj?.s2_vc,
        s2_c2_c: obj?.s2_c2_c,
        enabled: obj?.enabled,
        updated_at: `${new Date(obj?.updated_at).toLocaleString()}`
      };
    });

    setTableRows(filtered);
  }, [spIDs, manualRouting]);

  // Table Header
  const tableHeaders = useMemo(
    () => [
      {
        title: (
          <CheckBox
            marginRight={0}
            marginTop={0}
            checked={
              manualRouting?.length && manualRouting?.length === spIDs?.length
            }
            onChange={e => {
              if (e.target.checked)
                setSpIDs(manualRouting?.map(obj => obj?.sp_id));
              else setSpIDs([]);
            }}
          />
        )
      },
      { title: "SP ID" },
      { title: "SP Name" },
      { title: "Mobile" },
      { title: "Role" },
      { title: "Org" },
      { title: "Sub Org" },
      { title: "Location" },
      { title: "City" },
      { title: "s2_c" },
      { title: "c2_s" },
      { title: "s2_vc" },
      { title: "s2_c2_c" },
      { title: "Enabled" },
      {
        title: "Updated",
        sortBy: "updatedAt"
      }
    ],
    [tableRows]
  );

  return (
    <>
      <Button
        title="Send Routes"
        style={{ marginTop: 25 }}
        onClick={
          spIDs?.length
            ? () =>
                dispatch(
                  subscriptionRoutingAction.post({
                    subscription_id: id,
                    sp_ids: spIDs
                  })
                )
            : null
        }
      />
      <Table headers={tableHeaders} data={tableRows} />
    </>
  );
}
