import React, {useState, useMemo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {faqInfoAction} from "redux/actions";
import {TabModal} from "components";
import Details from "./Details";
import ImageFileUpload from "./ImageFileUpload";

export default function SubCategoryPopup({closeModal, data}) {
    // redux
    const dispatch = useDispatch();

    // states
    const [tabIndex, setTabIndex] = useState(0); //Popup tab active or inactive state
    const [details, setDetails] = useState();

    // useEffects
    useEffect(() => {
        if (!data) return;
        setDetails(data);
    }, [data]);

    // Handle change in Details Form
    const handleChange = e => {
        console.log(isNaN(e.target.value));

        setDetails({
            ...details,
            [e.target.name]:
                !e.target.value || isNaN(e.target.value)
                    ? e.target.value
                    : parseInt(e.target.value)
        });
    };

    // Handle Image Select
    const handleImageSelect = e => setDetails({...details, faqImage: e});

    // On Submit Data to Server
    const submit = () => {
        setTabIndex(0);
        closeModal();
        if (data) return dispatch(faqInfoAction.update(details));

        dispatch(faqInfoAction.add(details));
    };

    // Popup tabs routes
    const modalTabs = useMemo(
        () => [
            {
                name: "FAQ Screens Details",
                component: (
                    <Details
                        key="details"
                        onClick={e => setTabIndex(1)}
                        details={details}
                        setDetails={handleChange}
                        edit={data ? true : false}
                    />
                )
            },
            {
                name: "FAQ Screens Image",
                component: (
                    <ImageFileUpload
                        key="image"
                        onClick={submit}
                        onImageSelect={handleImageSelect}
                        imageSrc={details?.faqImage}
                    />
                )
            }
        ],
        [details]
    );

    return (
        <TabModal
            tabTitle={data ? "Edit FAQ Screens" : "Create New FAQ Screens"}
            tabs={modalTabs}
            tabIndex={tabIndex}
            setTabIndex={index => setTabIndex(index)}
            closeModal={() => {
                setTabIndex(0);
                closeModal();
            }}
        />
    );
}
