import {
    GET_ADMIN_ROLES,
    ADD_ADMIN_ROLE,
    UPDATE_ADMIN_ROLE,
    DELETE_ADMIN_ROLE,
    ADD_SNACK,
    LOADING,
    GET_USER_ROLES
} from "../actionTypes";
import axios from "axios";
import Cookies from "js-cookie";

import {BASE_URL_NEW} from "configurations";

const {SET_TRUE, SET_FALSE} = LOADING;

export const get = () => {
    return dispatch => {
        dispatch({type: SET_TRUE});

        // const {token} = JSON.parse(Cookies.get("user"));
const {token} = JSON.parse(localStorage.getItem("user"));

        axios
            .get(`${BASE_URL_NEW}/getadminroles`, {
                headers: {
                    Authorization: `bearer ${token}`
                }
            })
            .then(res => dispatch({type: GET_ADMIN_ROLES, payload: res.data?.data}))
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const getUserRole = id => {
    return dispatch => {
        dispatch({type: SET_TRUE});

        // const {token} = JSON.parse(Cookies.get("user"));
const {token} = JSON.parse(localStorage.getItem("user"));

        axios
            .get(`${BASE_URL_NEW}/getadminroles`, {
                headers: {
                    Authorization: `bearer ${token}`
                }
            })
            .then(res => dispatch({type: GET_USER_ROLES, payload: res.data?.data}))
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const active = id => {
    return dispatch => {
        dispatch({type: SET_TRUE});

        // const {token} = JSON.parse(Cookies.get("user"));
const {token} = JSON.parse(localStorage.getItem("user"));

        axios
            .get(`${BASE_URL_NEW}/activeadminrole/${id}`, {
                headers: {
                    Authorization: `bearer ${token}`
                }
            })
            .then(res => dispatch({type: GET_ADMIN_ROLES, payload: res.data?.data}))
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

// add a new privilege
export const add = details => {
    return dispatch => {
        dispatch({type: SET_TRUE});

        // const {token} = JSON.parse(Cookies.get("user"));
const {token} = JSON.parse(localStorage.getItem("user"));

        console.log({details});

        axios
            .post(`${BASE_URL_NEW}/addadminrole`, details, {
                headers: {
                    Authorization: `bearer ${token}`
                }
            })
            .then(res => {
                if (res.data?.type === "error")
                    throw new Error(JSON.stringify(res.data?.message));

                const adminRole = res.data?.data?.find(
                    item =>
                        item.role_id === details.role_id &&
                        item.admin_id === details.admin_id
                );

                dispatch({type: ADD_ADMIN_ROLE, payload: adminRole});
                dispatch({
                    type: ADD_SNACK,
                    payload: `New admin role has been created`
                });
            })
            .catch(err => {
                dispatch({
                    type: ADD_SNACK,
                    payload: err.message
                });
                console.log(err);
            })
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

// update a specific privileges
export const update = details => {
    return dispatch => {
        dispatch({type: SET_TRUE});

        // const {token} = JSON.parse(Cookies.get("user"));
const {token} = JSON.parse(localStorage.getItem("user"));

        axios
            .post(
                `${BASE_URL_NEW}/updateadminrole/${details?.role_map_id}`,
                details,
                {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                }
            )
            .then(res => {
                if (res.data?.type === "error")
                    throw new Error(JSON.stringify(res.data?.message));
                dispatch({type: UPDATE_ADMIN_ROLE, payload: res.data?.data});
                dispatch({
                    type: ADD_SNACK,
                    payload: `${details?.role_map_id} has been updated`
                });
            })
            .catch(err => {
                dispatch({
                    type: ADD_SNACK,
                    payload: err.message
                });
                console.log(err);
            })
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const remove = id => {
    return dispatch => {
        dispatch({type: SET_TRUE});

        // const {token} = JSON.parse(Cookies.get("user"));
const {token} = JSON.parse(localStorage.getItem("user"));

        axios
            .get(`${BASE_URL_NEW}/deleteadminrole/${id}`, {
                headers: {
                    Authorization: `bearer ${token}`
                }
            })
            .then(res => {
                dispatch({type: DELETE_ADMIN_ROLE, payload: res.data?.data});

                dispatch({
                    type: ADD_SNACK,
                    payload: `${id} has been deactivated`
                });
            })
            .catch(err => {
                dispatch({
                    type: ADD_SNACK,
                    payload: err.message
                });
                console.log(err);
            })
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};
