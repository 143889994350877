import React, {useEffect, useState, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Table, SwitchButton, Status} from "components";
import {spServiceSettingsAction} from "redux/actions";
import {useLocation} from "react-router-dom";

const Address = () => {
    // Routers
    const location = useLocation();
    const id = location.pathname.split("/", 4)[3];

    // States
    const [tableRows, setTableRows] = useState();

    // Filter States
    const [status, setStatus] = useState();
    const [filterState, setfilterState] = useState();

    // Redux actions
    const dispatch = useDispatch();
    const spServiceSettings = useSelector(state => state.spServiceSettings);

    useEffect(() => {
        dispatch(spServiceSettingsAction.get(id));
        return () => dispatch(spServiceSettingsAction.reset());
    }, []);

    useEffect(() => {
        if (!spServiceSettings?.length) {
            setTableRows();
            return;
        }

        let filtered = spServiceSettings;

        // filter based on Status
        filtered = filtered?.filter(item =>
            status === "Enabled"
                ? item.enabled
                : status === "Disabled"
                    ? !item.enabled
                    : item
        );

        // Map table rows
        const data = filtered?.map(obj => ({
            ID: obj.ID,
            SpID: obj.SpID,
            CategoryID: obj.CategoryID,
            SubcategoryID: obj.SubcategoryID,

            S2C: <Status active={obj.S2C}/>,
            C2S: <Status active={obj.C2S}/>,
            S2VC: <Status active={obj.S2VC}/>,
            S2C2C: <Status active={obj.S2C2C}/>,
            Enabled: (
                <SwitchButton
                    enabled={obj.Enabled}
                    onClick={() => dispatch(spServiceSettingsAction.toggle(id, obj.ID))}
                />
            ),

            CreatedAt: `${new Date(obj.CreatedAt).toLocaleString()}`,
            UpdatedAt: `${new Date(obj.UpdatedAt).toLocaleString()}`
        }));

        setTableRows(data);
    }, [spServiceSettings, status, filterState]);

    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "ID"
            },
            {
                title: "SP ID"
            },
            {
                title: "Category ID"
            },
            {
                title: "Sub Category ID"
            },
            {
                title: "S2C"
            },
            {
                title: "C2S"
            },
            {
                title: "S2VC"
            },
            {
                title: "S2C2C"
            },
            {
                title: "Enabled"
            },
            {
                title: "Created"
            },
            {
                title: "Updated"
            }
        ],
        [tableRows]
    );

    return <Table headers={tableHeaders} data={tableRows}/>;
};

export default Address;
