import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { subCategoriesAction } from "redux/actions";
import { TabModal } from "components";
import Details from "./Details";
import ImageFileUpload from "./ImageFileUpload";

export default function SubCategoryPopup({ closeModal, data }) {
  // redux
  const dispatch = useDispatch();
  const categories = useSelector(state => state.categories);

  // states
  const [tabIndex, setTabIndex] = useState(0); //Popup tab active or inactive state
  const [details, setDetails] = useState(); // Sub Category Details

  // useEffects
  useEffect(() => {
    if (!data) return;
    setDetails(data);
  }, [data]);

  // Handle change in Details Form
  const handleChange = e => {
    if (e.target.name === "serviceRule") {
      let serviceRule = details?.serviceRule || [];

      // add service rule id
      if (e.target.checked) serviceRule.push({ id: parseInt(e.target.value) });
      // remove service rule id
      else serviceRule = serviceRule?.filter(obj => obj.id != e.target.value);

      setDetails({
        ...details,
        [e.target.name]: serviceRule
      });

      return;
    }

    if (e.target.name === "category") {
      const category = categories.find(
        category => category.id == e.target.value
      );

      setDetails({
        ...details,
        categoryID: parseInt(e.target.value),
        [e.target.name]: category?.name
      });

      return;
    }

    setDetails({
      ...details,
      [e.target.name]: e.target.value
    });
  };

  // Handle Image Select
  const handleImageSelect = e => setDetails({ ...details, image: e });

  // On Submit Data to Server
  const submit = () => {
    setTabIndex(0);
    closeModal();
    if (data) return dispatch(subCategoriesAction.updateSubCategory(details));

    dispatch(subCategoriesAction.addSubCategory(details));
  };

  // Popup tabs routes
  const modalTabs = useMemo(
    () => [
      {
        name: "Sub Category Details",
        component: (
          <Details
            key="details"
            onClick={e => setTabIndex(1)}
            details={details}
            setDetails={handleChange}
          />
        )
      },
      {
        name: "Sub Category Image",
        component: (
          <ImageFileUpload
            key="image"
            onClick={submit}
            onImageSelect={handleImageSelect}
            imageSrc={details?.image}
          />
        )
      }
    ],
    [details]
  );

  return (
    <TabModal
      tabTitle={data ? "Edit Sub Category" : "Create New Sub Category"}
      tabs={modalTabs}
      tabIndex={tabIndex}
      setTabIndex={index => setTabIndex(index)}
      closeModal={() => {
        setTabIndex(0);
        closeModal();
      }}
    />
  );
}
