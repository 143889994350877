import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "../styles.module.scss";
import { TextInput, Button, FilterDropdown } from "components";
import { serviceRulesAction, subCategoriesAction } from "redux/actions";

export default function Details({ onClick, details, setDetails, edit }) {
  const dispatch = useDispatch();

  // Local state
  const [sRule, setSRule] = useState();
  const [sRuleID, setSRuleID] = useState();

  const categories = useSelector(state => state.categories);
  const filteredCategories = categories?.map(category => category.name); //make an arry of category names
  const categoryIDs = categories?.map(category => category.id);

  // Service Rules to map checkboxes
  const serviceRules = useSelector(state => state.serviceRules);

  // Fetch service rules if not available in global state
  useEffect(() => {
    if (serviceRules?.length) return;
    dispatch(serviceRulesAction.get());
  }, [dispatch]);

  const subCategories = useSelector(state => state.subCategories);
  const filteredSubCategories =
    subCategories?.map(subCategory => subCategory.name) || []; //make an arry of sub category names
  const subCategoryIDs = subCategories?.map(subCategory => subCategory.id); //make an arry of sub category id

  // Get sub categories based on category selection
  useEffect(() => {
    if (details?.categoryID === "Pick a category" || !details?.categoryID)
      return;
    dispatch(subCategoriesAction.get(details?.categoryID));
  }, [details?.categoryID, dispatch]);

  // Get service rules of selected sub category
  useEffect(() => {
    if (!details?.subcategoryID) {
      setSRule();
      setSRuleID();
      return;
    }
    const subCategory = subCategories?.find(
      subCategory => subCategory?.id == details?.subcategoryID
    );
    const serviceRuleType = subCategory?.serviceRule?.map(sr => sr.type);
    const serviceRuleID = subCategory?.serviceRule?.map(sr => sr.id);
    setSRule(serviceRuleType);
    setSRuleID(serviceRuleID);
  }, [details?.subcategoryID]);

  return (
    <div className={styles.main__container}>
      {!edit && (
        <FilterDropdown
          onChange={setDetails}
          name="category"
          label="Category"
          options={["Pick a category", ...filteredCategories]}
          value={details?.categoryID}
          values={[0, ...categoryIDs]}
        />
      )}
      <FilterDropdown
        onChange={setDetails}
        name="subcategoryID"
        label="Sub Category"
        options={["Pick a sub category", ...filteredSubCategories]}
        value={details?.subcategoryID}
        values={subCategoryIDs?.length ? [0, ...subCategoryIDs] : []}
        disabled={edit}
      />
      <TextInput
        label="Package Name"
        placeholder="Package Name"
        name="packageName"
        onChange={setDetails}
        value={details?.packageName}
      />
      <TextInput
        label="Short Description"
        placeholder="Short Description"
        name="shortDescription"
        onChange={setDetails}
        value={details?.shortDescription}
      />
      <TextInput
        label="Base Price"
        placeholder="Base Price"
        name="basePrice"
        onChange={setDetails}
        value={details?.basePrice}
      />
      <TextInput
        label="Selling Price"
        placeholder="Selling Price"
        name="sellingPrice"
        onChange={setDetails}
        value={details?.sellingPrice}
      />
      <TextInput
        label="Display Order"
        placeholder="Display Order"
        name="displayOrder"
        onChange={setDetails}
        value={details?.displayOrder}
      />
      <FilterDropdown
        onChange={setDetails}
        name="subCategoryServiceRuleID"
        label="Delivery Type"
        options={
          sRule?.length
            ? ["Pick a delivery type", ...sRule]
            : ["Pick a delivery type"]
        }
        value={details?.subCategoryServiceRuleID}
        values={sRuleID?.length ? [0, ...sRuleID] : [0]}
        disabled={edit}
      />
      <div className={styles.btn__container}>
        <Button title="Next" onClick={onClick} />
      </div>
    </div>
  );
}
