import CategoryPanel from './panels/CategoryPanel';
import SubCategory from './panels/SubCategory';
import ServiceRules from './panels/ServiceRules';
import SubCategoryLocation from './panels/SubCategoryLocation';
import ManageUsers from './modals/ManageUsers';

const UsersRoutes = [
    {
        name: 'Category',
        path: '/category',
        component: <CategoryPanel/>,
        subRotes: [
            {
                name: 'Manage Users',
                path: '/category/:id',
                component: <ManageUsers/>
            }
        ]
    },
    {
        name: 'Sub Category',
        path: '/sub-category',
        component: <SubCategory/>
    },
    {
        name: 'Service Rules',
        path: '/service-rule',
        component: <ServiceRules/>
    },
    {
        name: 'Sub Category Location',
        path: '/sub-category-location',
        component: <SubCategoryLocation/>
    },
]

export default UsersRoutes;