import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { packageAddonAction } from "redux/actions";
import { TabModal } from "components";
import Details from "./Details";
import ImageFileUpload from "./ImageFileUpload";

export default function PackagesPopup({ closeModal, data }) {
  // Routers
  const pid = useHistory().location.pathname.split("/")[3];

  // redux
  const dispatch = useDispatch();

  // states
  const [tabIndex, setTabIndex] = useState(0); //Popup tab active or inactive state
  const [details, setDetails] = useState();

  // useEffects
  useEffect(() => {
    if (!data) return;
    setDetails(data);
  }, [data]);

  // Handle change in Details Form
  const handleChange = e => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value
    });
  };

  // Handle Image Select
  const handleImageSelect = e => setDetails({ ...details, addonImage: e });

  // On Submit Data to Server
  const submit = () => {
    setTabIndex(0);
    closeModal();

    if (data) return dispatch(packageAddonAction.update(details));
    dispatch(packageAddonAction.add(pid, details));
  };

  // Popup tabs routes
  const modalTabs = useMemo(
    () => [
      {
        name: "Add On Details",
        component: (
          <Details
            key="details"
            onClick={e => setTabIndex(1)}
            details={details}
            setDetails={handleChange}
            edit={data ? true : false}
          />
        )
      },
      {
        name: "Add On Image",
        component: (
          <ImageFileUpload
            key="image"
            onClick={submit}
            onImageSelect={handleImageSelect}
            imageSrc={details?.addonImage}
          />
        )
      }
    ],
    [details]
  );

  return (
    <TabModal
      tabTitle={data ? "Edit Add On" : "Create New Add On"}
      tabs={modalTabs}
      tabIndex={tabIndex}
      setTabIndex={index => setTabIndex(index)}
      closeModal={() => {
        setTabIndex(0);
        closeModal();
      }}
    />
  );
}
