import {
  ADD_HOME_GREETING,
  GET_HOME_GREETING,
  TOGGLE_HOME_GREETING,
  UPDATE_HOME_GREETING,
  RESET_HOME_GREETING,
} from "../actionTypes";

export default function homeGreetingReducer(state = [], action) {
  switch (action.type) {
    case GET_HOME_GREETING:
      return action.payload;

    case ADD_HOME_GREETING:
      return [...state, action.payload];

    case UPDATE_HOME_GREETING:
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );

    case TOGGLE_HOME_GREETING:
      return state.map((item) =>
        item.id !== action.payload ? item : { ...item, enabled: !item.enabled }
      );

    case RESET_HOME_GREETING:
      return [];

    default:
      return state;
  }
}
