import firebase from 'firebase';
import 'firebase/auth';


firebase.initializeApp({
    apiKey: "AIzaSyCqpV2l27R9vc2iEesiuWd0TKwCdHTa-ZQ",
    authDomain: "clykk-356df.firebaseapp.com",
    databaseURL: "https://clykk-356df.firebaseio.com",
    projectId: "clykk-356df",
    storageBucket: "clykk-356df.appspot.com",
    messagingSenderId: "11379513871",
    appId: "1:11379513871:web:edcf59904041cc7bde617a",
    measurementId: "G-DKPRY72V4G"
});

// firebase.initializeApp({
//     apiKey: "AIzaSyAbNmGm75G0vYnvO8wMMNIeJKkhY-zvqSY",
//     authDomain: "clykk-admin.firebaseapp.com",
//     databaseURL: "https://clykk-admin.firebaseio.com",
//     projectId: "clykk-admin",
//     storageBucket: "clykk-admin.appspot.com",
//     messagingSenderId: "175993573285",
//     appId: "1:175993573285:web:eae69bbc76df6dcc51e24c",
//     measurementId: "G-8SXMDEXTRJ"
// });

// firebase.initializeApp({
//     apiKey: "AIzaSyCq7z36nZYY2m1E4qPf6AzaP_XgI-A5C_8",
//     authDomain: "clykk-356df.firebaseapp.com",
//     databaseURL: "https://clykk-356df.firebaseio.com",
//     projectId: "clykk-356df",
//     storageBucket: "clykk-356df.appspot.com",
//     messagingSenderId: "11379513871",
//     appId: "1:11379513871:web:e6d15083b499d10ade617a",
//     measurementId: "G-9SVF06531T"
// });

export const firebaseApp = firebase;
export const auth = firebaseApp.auth();
