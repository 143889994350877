import {useState} from 'react';
import {Box, Tab, Tabs, useTheme} from "@material-ui/core";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import PendingTab from "./PendingTab";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            style={{backgroundColor: "white"}}
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <p>{children}</p>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function DashboardCommissions({
                                  datesFilter,
                                  onDateChange,
                                  data,
                                  setData,
                                  response,
                                  filter,
                                  onChange,
                                  setCommissionsMeta,
                                  setCommissionsStatus
                              }) {
    const theme = useTheme();

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setCommissionsStatus(newValue === 0 ? "done" : "pending")
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setCommissionsStatus(index === 0 ? "done" : "pending")
        setValue(index);
    };

    return (
        <div className={'generic-tabs-container'}>
            <Box display="flex" justifyContent="left" width="100%">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    indicatorColor={'primary'}
                    scrollButtons="auto"
                >
                    <Tab label="Completed" {...a11yProps(0)} />
                    <Tab label="Pending"
                         style={{}} {...a11yProps(1)} />
                </Tabs>
            </Box>

            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                disabled={true}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel className={'generic-tab-panel'} value={value} index={0}
                          dir={theme.direction}>
                    <PendingTab
                        status={'done'}
                        response={response}
                        data={data}
                        setData={setData}
                        filter={filter}
                        datesFilter={datesFilter}
                        onDateChange={onDateChange}
                        onChange={onChange}
                        setCommissionsMeta={setCommissionsMeta}
                    />
                </TabPanel>

                <TabPanel className={'generic-tab-panel'} value={value} index={1} dir={theme.direction}>
                    <PendingTab
                        status={'pending'}
                        response={response}
                        data={data}
                        setData={setData}
                        filter={filter}
                        datesFilter={datesFilter}
                        onDateChange={onDateChange}
                        onChange={onChange}
                        setCommissionsMeta={setCommissionsMeta}
                    />
                </TabPanel>
            </SwipeableViews>

        </div>
    );
}

export default DashboardCommissions;