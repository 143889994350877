import Login from "views/Login";
import SignUp from "views/SignUp";

export default [
    {
        name: "Login",
        path: "/login",
        component: <Login/>
    },
    {
        name: "Sign Up",
        path: "/sign-up",
        component: <SignUp/>
    }
];
