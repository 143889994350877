import axios from "axios";
import {ROUTING, LOADING} from "../actionTypes";
import {AWS_ROUTING_SETTING} from "configurations";
import {adminToken} from "helper";
import {snackAction} from "redux/actions";

const {GET, ADD, UPDATE, RESET} = ROUTING;
const {SET_TRUE, SET_FALSE} = LOADING;

export const get = (subCategory, serviceRule) => {
    return dispatch => {
        dispatch({type: SET_TRUE});
        adminToken()
            .then(token =>
                axios.get(
                    `${AWS_ROUTING_SETTING}/routes?subcategoryID=${subCategory}&ruleID=${serviceRule}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                )
            )
            .then(res => {
                dispatch({type: GET, payload: res.data});
            })
            .catch(err => {
                dispatch(snackAction.add(err.message));
                console.log(err);
            })
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const add = data => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        adminToken()
            .then(token =>
                axios.post(`${AWS_ROUTING_SETTING}/routes`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            )
            .then(res => {
                dispatch({type: ADD, payload: res.data});
            })
            .catch(err => {
                dispatch(snackAction.add(err.message));
                console.log(err);
            })
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const update = data => {
    return async dispatch => {
        try {
            dispatch({type: SET_TRUE});
            const token = await adminToken();
            const res = await axios.put(
                `${AWS_ROUTING_SETTING}/routes/${data.id}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            dispatch({type: UPDATE, payload: res.data});
        } catch (err) {
            dispatch(snackAction.add(err.message));
            console.log(err);
        } finally {
            dispatch({type: SET_FALSE});
        }
    };
};

export const reset = () => dispatch => dispatch({type: RESET});
