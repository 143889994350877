import axios from "axios";
import {
  GET_STATE,
  TOGGLE_STATE,
  ADD_STATE,
  RESET_STATE,
  LOADING
} from "../actionTypes";
import { AWS_LOCATION_8888 } from "configurations";
import { adminToken } from "helper";

const { SET_TRUE, SET_FALSE } = LOADING;

export const get = id => {
  return dispatch => {
    dispatch({ type: SET_TRUE });

    adminToken()
      .then(token =>
        axios.get(`${AWS_LOCATION_8888}/country/${id}/state`, {
          headers: { Authorization: `Bearer ${token}` }
        })
      )

      .then(res => {
        dispatch({ type: GET_STATE, payload: res.data });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const add = (cid, name) => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.post(
          `${AWS_LOCATION_8888}/country/${cid}/state`,
          {
            name,
            enabled: true
          },
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        )
      )
      .then(res => {
        dispatch({ type: ADD_STATE, payload: res.data });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const toggle = id => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.patch(
          `${AWS_LOCATION_8888}/country/${id}`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
      )
      .then(res => dispatch({ type: TOGGLE_STATE, payload: res.data }))
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const remove = (cid, id) => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.delete(`${AWS_LOCATION_8888}/country/${cid}/state/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
      )
      .then(res => dispatch(get(cid)))
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const reset = () => dispatch => dispatch({ type: RESET_STATE });
