import {
  ADD_LOCATION,
  GET_LOCATION,
  TOGGLE_LOCATION,
  UPDATE_LOCATION,
  RESET_LOCATION,
} from "../actionTypes";

const locationReducer = (state = [], action) => {
  switch (action.type) {
    case GET_LOCATION:
      return action.payload;

    case ADD_LOCATION:
      return action.payload;

    case UPDATE_LOCATION:
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );

    case TOGGLE_LOCATION:
      return state.map((item) =>
        item.id !== action.payload.id
          ? item
          : { ...item, enabled: !item.enabled }
      );

    case RESET_LOCATION:
      return [];

    default:
      return state;
  }
};

export default locationReducer;
