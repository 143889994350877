import React, {useMemo} from 'react';
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {Status, SwitchButton, TableVertical} from "../../../components";

function ProfileTab({clickedCustomer}) {
    // Redux
    const customer = useSelector(state =>
        state?.customers?.users?.find(item => item.userId == clickedCustomer.id)
    );

    // Custom Functions
    const data = {
        firstName: customer?.firstName,
        lastName: `${customer?.lastName}`,
        email: `${customer?.email}`,
        emailVerified: `${customer?.emailVerified ? "Verified" : "Not Verified"}`,
        mobile: `${customer?.mobile}`,
        mobileVerified: `${customer?.mobileVerified ? "Verified" : "Not Verified"}`,
        notificationEnabled: (
            <SwitchButton enabled={customer?.notificationEnabled}/>
        ),
        dob: `${new Date(customer?.dob).toLocaleString()}`,
        anniversary: `${new Date(customer?.anniversary).toLocaleString()}`,
        lastLoginAt: `${new Date(customer?.lastLoginAt).toLocaleString()}`,
        createdAt: `${new Date(customer?.createdAt).toLocaleString()}`,
        updatedAt: `${new Date(customer?.updatedAt).toLocaleString()}`,
        active: <Status active={customer?.active}/>,
        enabled: <SwitchButton enabled={customer?.enabled}/>
    };

    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "First Name"
            },
            {
                title: "Last Name"
            },
            {
                title: "Email"
            },
            {
                title: "Email Verified"
            },
            {
                title: "Mobile"
            },
            {
                title: "Mobile Verified"
            },
            {
                title: "Notification Enabled"
            },
            {
                title: "DOB"
            },
            {
                title: "Anniversary"
            },
            {
                title: "Last Login At"
            },
            {
                title: "Created At"
            },
            {
                title: "Updated At"
            },
            {
                title: "Active"
            },
            {
                title: "Enabled"
            }
        ],
        []
    );

    return (
        <>
            <TableVertical
                heading="Customer Details"
                headers={tableHeaders}
                data={data}
            />
        </>
    );
}

export default ProfileTab;