import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { MdArrowDropDown } from "react-icons/md";
import ProfileCard from "./ProfileCard";
import { useSelector } from "react-redux";
import axios from "axios";
import Newsubmsg from "./Newsubmsg";
import NotAcceptRouteMsg from "./NotAcceptRouteMsg";



const Navbar = () => {
  const [drop, setDrop] = useState(false);
  const [newsubmsg, setNewsubmsg] = useState('');
  const [newsubcount, setNewsubcount] = useState(0);
  const [duration, setDuration] = useState(0);
  const user = useSelector(state => state.user);
  const [notaccerou, setNotaccerou] = useState('');
  const [notarcount, setNotarcount] = useState(0);

  const toggle = () => setDrop(!drop);

  useEffect(() => {
    setInterval(newsubcheck, 30000);
    newsubcheck();
    notacceptroutes();
  }, []);

  const newsubcheck = () => {
    axios
      .get(
        `http://cgapp-dev.clykk.com/mail_manager/public/index.php/api/getneworders`,)
      .then(res => {
        console.log('-----New----->>>>'+res);
        setNewsubmsg(res.data.message);
        setNewsubcount(res.data.count);
        setDuration(res.data.db_sec);
      })
      .catch(err => console.log(err))
  }

  const notacceptroutes = () => {
    axios
      .get(
        `http://cgapp-dev.clykk.com/mail_manager/public/index.php/api/getnotacceptroutes`,)
      .then(res => {
        setNotaccerou(res.data.message);
        setNotarcount(res.data.count);
      })
      .catch(err => console.log(err))
  }

  return (
    <div className={styles.navbar}>
      <div className={styles.newsubmain}>
        <Newsubmsg newsubmsg={newsubmsg} newsubcount={newsubcount} duration={duration}/>
      </div>
      <div className={styles.notacceptroute}>
        <NotAcceptRouteMsg notaccerou={notaccerou} notarcount={notarcount}/>
      </div>
      <div className={styles.dropdown} onClick={toggle}>
       <p>{`${user?.first_name || "John"} ${user?.last_name || ""}`}</p>
        <MdArrowDropDown size="20" />
      </div>

      {drop && <ProfileCard />}
    </div>
  );
};

export default Navbar;
