import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {adminToken} from "../../helper";
import axios from "axios";
import ReactApexChart from "react-apexcharts";

function DashboardCustomerOverview({datesFilter}) {

    const [data, setData] = useState([])
    const [response, setResponse] = useState([])

    const [labels, setLabels] = useState([])

    useEffect(() => {
        adminToken()
            .then(token =>
                axios.get(
                    `https://cgapp.clykk.com/um/clykk/int/um/v1/get-user-list?pageNumber=1&pageSize=1000000000`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                ).then(res => {
                    setResponse(res.data.data.users)
                }))
    }, [])

    useEffect(() => {
        if (response.length > 0) {
            let data = response

            data = data.filter((user) => {
                return user.createdAt > datesFilter.end && user.createdAt < datesFilter.start
            })
            let _dates = []
            let _data = []
            data.map(user => {
                if (!_dates.includes(user.createdAt.split('T')[0])) {
                    _dates.push(user.createdAt.split('T')[0])
                }
            })

            _dates.reverse().map((date, index) => {
                const users = data.filter(item => item.createdAt.split('T')[0] === date)
                _data.push(users.length)
            })

            setData(_data)
            setLabels(_dates)
        }
    }, [response, datesFilter])

    const return_sum = (index) => {
        let sum = 0
        data.map(item => sum = sum + item.data[index])
        return sum
    }

    const return_col_sums = (data) => {
        let sum = 0
        data.map(item => sum += item)
        return sum
    }

    const return_growth = (index) => {
        if (labels.length - 1 > index + 1) {
            let val1 = data[index]
            let val2 = data[index + 1]

            return (((val1 - val2) / val2) * 100).toFixed(2)
        } else {
            return null
        }
    }

    let state_line = {
        series: [{
            name: "sum of SP",
            data: data,
            color: "#03f3a0"
        }],
        options: {
            grid: {
                show: false
            },
            chart: {
                width: '100%',
                type: 'area',
            },
            dataLabels: {
                enabled: false
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        total: {
                            enabled: true,
                            offsetX: 0,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900
                            }
                        }
                    }
                },
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                categories: labels,
            },
            yaxis: {
                labels: {
                    formatter: function (val) {
                        return val
                    }
                }
            },
            tooltip: {
                style: {
                    color: 'red'
                },
                x: {
                    format: 'dd/MM/yy'
                },
            }
        },
    }

    return (
        !data ?
            <div style={{width: '100%', height: '100%', display: ' flex'}}>
                <div className="spinner-grow text-warning" role="status"
                     style={{margin: "auto", width: "5rem", height: "5rem"}}>
                </div>
            </div>
            :
            <>
                <div style={{maxHeight: 390, overflowY: "auto"}}>
                    <table className="table" style={{border: '1px solid #e2e2e2'}}>
                        <thead style={{position: "sticky", top: 0}}>
                        <tr style={{backgroundColor: '#eaeff0'}}>
                            <th scope="col" style={{
                                width: 150,
                                textAlign: "left",
                                fontSize: 13,
                                padding: 5,
                                position: "sticky",
                                top: "0",
                            }}>Date
                            </th>
                            <th scope="col" style={{
                                width: 150,
                                textAlign: "left",
                                fontSize: 13,
                                padding: 5,
                                position: "sticky",
                                top: "0"
                            }}>Sum
                            </th>
                            <th scope="col" style={{
                                width: 150,
                                textAlign: "left",
                                fontSize: 13,
                                padding: 5,
                                position: "sticky",
                                top: "0"
                            }}>Growth
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{padding: 5, border: "none"}}>Sum</td>
                            <td style={{padding: 5, border: "none"}}>{return_col_sums(data)}</td>
                        </tr>
                        {labels.map((item, index) => {
                            return (
                                <>
                                    <tr>
                                        <td style={{fontWeight: 600, padding: 5, border: "none"}}>
                                            {labels[index]}
                                        </td>
                                        <td style={{padding: 5, border: "none"}}>
                                            {data[index]}
                                        </td>

                                        <td style={{padding: 5, border: "none"}}>
                                            {return_growth(index)} %
                                        </td>
                                    </tr>
                                </>
                            )
                        })}
                        </tbody>
                    </table>
                </div>

                <div className="row mt-4">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                        <ReactApexChart options={state_line.options} series={state_line.series} type="area" height={300}/>
                    </div>
                </div>
            </>
    );
}

export default DashboardCustomerOverview;