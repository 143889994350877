import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import {
  Table,
  TextInput,
  SearchButton,
  LightTextButton,
  FilterDropdown,
  SwitchButton
} from "components";
import { countryAction, stateAction } from "redux/actions";

const SubCategory = () => {
  // States
  const [selectedCountry, setSelectedCountry] = useState();
  const [tableRows, setTableRows] = useState([]);

  // Filter States
  const [status, setStatus] = useState();
  const [searchText, setSearchText] = useState();

  // Redux actions
  const dispatch = useDispatch();
  const { country, state, userRole } = useSelector(state => ({
    country: state.country,
    state: state.state,
    userRole: state.userRole?.filter(role => role.module === "Location")
  }));
  const countryName = country?.map(item => item?.name); //make an arry of faq areas names

  useEffect(() => {
    if (userRole?.find(item => item.privilege === "Read"))
      dispatch(countryAction.get());
    return () => {
      dispatch(countryAction.reset());
      dispatch(stateAction.reset());
    };
  }, [dispatch]);

  useEffect(() => {
    let filtered = state;

    // filter based on Status
    filtered = filtered?.filter(item =>
      status === "Enabled"
        ? item.enabled
        : status === "Disabled"
        ? !item.enabled
        : item
    );

    // filter based on faq areas name
    if (searchText) {
      filtered = filtered.filter(
        subCategory =>
          subCategory.name.toLowerCase().indexOf(searchText?.toLowerCase()) !==
          -1
      );
    }

    // get country name
    const countryName = country?.find(item => item.id == selectedCountry);

    // Map table rows
    const data = filtered?.map(item => ({
      id: `${item.id}`,
      name: `${item.name}`,
      countryName: `${countryName?.name}`,
      deletedAt: `${
        item?.deletedAt && new Date(item.deletedAt).toLocaleString()
      }`,
      enabled: <SwitchButton enabled={item.enabled} />
    }));

    setTableRows(data);
  }, [state, status, searchText]);

  // Search based on category name
  const onSearch = () => {
    if (selectedCountry === "Pick a category" || !selectedCountry)
      return dispatch(stateAction.reset());
    dispatch(stateAction.get(selectedCountry));
  };

  // Table Header
  const tableHeaders = useMemo(
    () => [
      {
        title: "ID"
      },
      {
        title: "State",
        sortBy: "name"
      },
      {
        title: "Country"
      },
      {
        title: "Deleted At"
      },
      {
        title: "Enabled"
      }
    ],
    [tableRows]
  );

  return (
    <div>
      <div className={styles.filter}>
        <FilterDropdown
          onChange={e => setSelectedCountry(e.target.value)}
          value={selectedCountry}
          label="Country"
          options={["Pick a country", ...countryName]}
          values={[0, ...country?.map(item => item.id)]}
        />
        <TextInput
          search
          label="State"
          name="State"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
        <FilterDropdown
          onChange={e => setStatus(e.target.value)}
          label="Status"
          value={status}
          options={["All", "Enabled", "Disabled"]}
        />
        <SearchButton
          onClick={
            userRole?.find(item => item.privilege === "Read") ? onSearch : null
          }
        />
        <LightTextButton
          onClick={() => {
            setSearchText("");
            setStatus("");
          }}
        />
      </div>
      <Table headers={tableHeaders} data={tableRows} />
    </div>
  );
};

export default SubCategory;
