import axios from "axios";
import {
  GET_PACKAGE_ADDON,
  TOGGLE_PACKAGE_ADDON,
  UPDATE_PACKAGE_ADDON,
  ADD_PACKAGE_ADDON,
  RESET_PACKAGE_ADDON,
  LOADING
} from "../actionTypes";
import { AWS_URL_CM_8090 } from "configurations";
import { adminToken } from "helper";
import uploadImage from "helper/uploadImage";

const { SET_TRUE, SET_FALSE } = LOADING;
export const get = id => {
  return async dispatch => {
    dispatch({ type: SET_TRUE });

    try {
      const token = await adminToken();

      const { data } = await axios.get(
        `${AWS_URL_CM_8090}/package/${id}/addOn`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      dispatch({ type: GET_PACKAGE_ADDON, payload: data });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({ type: SET_FALSE });
    }
  };
};

export const add = (pid, details = {}) => {
  const {
    addonName,
    addonDescription,
    basePrice,
    sellingPrice,
    addonImage,
    displayOrder
  } = details;

  return async dispatch => {
    try {
      dispatch({ type: SET_TRUE });
      // Request to Upload Image
      const token = await adminToken();

      const imgUrl = await uploadImage(addonImage, "catalogue-management");

      const { data } = await axios.post(
        `${AWS_URL_CM_8090}/package/${pid}/addOn`,
        {
          addonName,
          addonDescription,
          basePrice: parseFloat(basePrice),
          sellingPrice: parseFloat(sellingPrice),
          displayOrder: parseInt(displayOrder),
          addonImage: imgUrl,
          enabled: true
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      dispatch({ type: ADD_PACKAGE_ADDON, payload: data });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({ type: SET_FALSE });
    }
  };
};

// Update Package
export const update = (details = {}) => {
  const {
    id,
    packageID,
    addonName,
    addonDescription,
    basePrice,
    sellingPrice,
    addonImage,
    displayOrder
  } = details;

  return async dispatch => {
    try {
      dispatch({ type: SET_TRUE });

      const token = await adminToken();

      let imgUrl;

      // Request to Upload Image
      if (typeof addonImage === "object")
        imgUrl = await uploadImage(addonImage, "catalogue-management");

      // Request to Create Category
      const { data } = await axios.put(
        `${AWS_URL_CM_8090}/package/${packageID}/addOn/${id}`,
        {
          addonName,
          addonDescription,
          basePrice: parseFloat(basePrice),
          sellingPrice: parseFloat(sellingPrice),
          displayOrder: parseInt(displayOrder),
          addonImage: imgUrl || addonImage
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      dispatch({ type: UPDATE_PACKAGE_ADDON, payload: data });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({ type: SET_FALSE });
    }
  };
};

export const toggle = (pid, id) => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.patch(
          `${AWS_URL_CM_8090}/package/${pid}/addOn/${id}`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
      )
      .then(({ data }) =>
        dispatch({ type: TOGGLE_PACKAGE_ADDON, payload: data.id })
      )
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const reset = () => dispatch => dispatch({ type: RESET_PACKAGE_ADDON });
