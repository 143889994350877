import React, { useEffect } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { useSelector, useDispatch } from "react-redux";
import { snackAction } from "redux/actions";

export default function SnackBar() {
  const dispatch = useDispatch();
  const snacks = useSelector(state => state.snacks);
  const [openSnackbar] = useSnackbar();

  useEffect(() => {
    if (snacks) openSnackbar(snacks);

    return () => dispatch(snackAction.add(""));
  }, [snacks]);

  return <></>;
}
