import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FilterDropdown, LightTextButton, MultiTable, SearchButton, Status} from "../../../components";
import styles from "../../Customers/panels/styles.module.scss";
import {preferencesAction} from "redux/actions";

function PreferencesTab({clickedCustomer}) {
    const id = clickedCustomer.id

    // Redux
    const dispatch = useDispatch();
    const preferences = useSelector(state => state.preferences);

    // States
    const [tableRows, setTableRows] = useState();

    // Filter States
    const [status, setStatus] = useState();

    // Fetch Package Add On
    useEffect(() => {
        dispatch(preferencesAction.get(id));

        return () => dispatch(preferencesAction.reset());
    }, [dispatch]);

    useEffect(() => {
        if (!preferences.length) return;
        const data = preferences.map((qna, index) => {
            let modifiedQA = {...qna};
            modifiedQA.options = qna.options.map(ans => ({
                id: ans.id,
                display_name: ans.display_name,
                enabled: <Status active={ans.enabled}/>,
                selected: <Status active={ans.selected}/>
            }));

            return modifiedQA;
        });
        setTableRows(data);
    }, [preferences, status]);

    // Search
    const onSearch = () => {
    };

    // MultiTable Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "Question Name"
            },
            {
                title: "Question Help Text"
            }
        ],
        []
    );

    const secTableHeaders = useMemo(
        () => [
            {
                title: "ID"
            },
            {
                title: "Option Name"
            },
            {
                title: "Enabled"
            },
            {
                title: "Selected"
            }
        ],
        []
    );

    return (
        <div>
            <div className={styles.filter}>
                <FilterDropdown
                    onChange={e => setStatus(e.target.value)}
                    label="Status"
                    options={["All", "Enabled", "Disabled"]}
                />
                <SearchButton onClick={onSearch}/>
                <LightTextButton/>
            </div>

            {tableRows?.length
                ? tableRows.map((qna, index) => (
                    <MultiTable
                        key={index}
                        headers={tableHeaders}
                        data={[
                            {
                                display_name: qna.display_name,
                                display_text: qna.display_text
                                // edit: qna.edit,
                            }
                        ]}
                        secHeaders={secTableHeaders}
                        secData={qna.options}
                    />
                ))
                : null}
        </div>
    );
}

export default PreferencesTab;