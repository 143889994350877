import {
  ADD_STATE,
  GET_STATE,
  TOGGLE_STATE,
  UPDATE_STATE,
  RESET_STATE,
} from "../actionTypes";

const stateReducer = (state = [], action) => {
  switch (action.type) {
    case GET_STATE:
      return action.payload;

    case ADD_STATE:
      return action.payload;

    case UPDATE_STATE:
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );

    case TOGGLE_STATE:
      return state.map((item) =>
        item.id !== action.payload.id
          ? item
          : { ...item, enabled: !item.enabled }
      );

    case RESET_STATE:
      return [];

    default:
      return state;
  }
};

export default stateReducer;
