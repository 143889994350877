import React from 'react';
import {useSelector} from "react-redux";
import {NoData, UnlimitedTable} from "../../../components";

function DetailsTab(props) {
    // Redux
    const order = useSelector(state =>
        state.subsDetail?.qnA.filter(item => item.questionType === "detail")
    );

    return (
        <div>
            {order?.length ? (
                order.map((qna, index) => (
                    <UnlimitedTable
                        key={index}
                        question={qna.questionName}
                        answers={qna.answers}
                    />
                ))
            ) : (
                <NoData/>
            )}
        </div>
    );
}

export default DetailsTab;