import axios from "axios";

import { SUBS_DETAIL, LOADING } from "../actionTypes";
import { AWS_CART_8000 } from "configurations";
import { adminToken } from "helper";

const { GET, RESET } = SUBS_DETAIL;
const { SET_TRUE, SET_FALSE } = LOADING;

export const get = id => {
  return async dispatch => {
    dispatch({ type: SET_TRUE });

    const token = await adminToken();

    axios
      .get(`${AWS_CART_8000}/subscriptions/${id}/detail`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        dispatch({ type: GET, payload: res.data });
      })
      .catch(err => {
        dispatch({ type: RESET });
        console.log(err);
      })
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};
