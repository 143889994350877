import React, {useEffect, useState, useMemo} from "react";
import styles from "./styles.module.scss";
import {
    Table,
    TextInput,
    SearchButton,
    LightTextButton,
    Icon
} from "components";
import {useDispatch, useSelector} from "react-redux";
import {privilegesAction} from "redux/actions";
import {internalSearch} from "helper";
import PrivilegePopup from "../popups/Privileges";
import {MdEdit} from "react-icons/md";

const tableHeaders = [
    "ID",
    "Privilege Name",
    "Created At",
    "Created By",
    "Updated At",
    "Updated By",
    "Edit"
];

const initialState = {
    name: ""
};

const PrivilegesPanel = () => {
    // Disptach redux action to get privileges
    const dispatch = useDispatch();
    const {privileges, userRole} = useSelector(state => ({
        privileges: state.privileges,
        userRole: state.userRole?.filter(role => role.module === "Users")
    }));

    const [search, setSearch] = useState(initialState);
    const [editableDetails, setEditableDetails] = useState();

    // Effects
    useEffect(() => {
        if (userRole?.find(item => item.privilege === "Read"))
            dispatch(privilegesAction.get());
    }, [dispatch]);

    const rows = useMemo(() => {
        let filtered = privileges;

        if (!filtered?.length) return [];

        filtered = internalSearch(search, "name", filtered);

        filtered = filtered?.map(privilege => ({
            privilege_id: `${privilege.privilege_id}`,
            name: `${privilege.name}`,
            created_at: `${new Date(privilege.created_at).toLocaleString()}`,
            created_by: privilege.created_by,
            updated_at: `${new Date(privilege.updated_at).toLocaleString()}`,
            updated_by: privilege.updated_by,
            edit: (
                <Icon
                    ReactIcon={MdEdit}
                    name="MdEdit"
                    color="primary"
                    onClick={
                        userRole?.find(item => item.privilege === "Update")
                            ? () => setEditableDetails(privilege)
                            : null
                    }
                />
            )
        }));

        return filtered;
    }, [privileges, search]);

    const handleChange = setter => event =>
        setter(prev => ({...prev, [event.target.name]: event.target.value}));

    return (
        <div>
            <div className={styles.filter}>
                <TextInput
                    label="Privilege Name"
                    name="name"
                    value={search?.name}
                    onChange={handleChange(setSearch)}
                />
                <SearchButton/>
                <LightTextButton onClick={() => setSearch(initialState)}/>
            </div>
            <Table headers={tableHeaders} data={rows}/>

            {editableDetails && (
                <PrivilegePopup
                    data={editableDetails}
                    closeModal={() => setEditableDetails()}
                />
            )}
        </div>
    );
};

export default PrivilegesPanel;
