import React, {useState, useMemo, useEffect} from "react";
import {useDispatch} from "react-redux";
import {categoryAction} from "redux/actions";
import {TabModal} from "components";
import Details from "./Details";
import ImageFileUpload from "./ImageFileUpload";

export default function CategoryPopup({closeModal, data}) {
    // redux
    const dispatch = useDispatch();

    // states
    const [tabIndex, setTabIndex] = useState(0); //Popup tab active or inactive state
    const [details, setDetails] = useState(); // Sub Category Details

    // useEffects
    useEffect(() => {
        if (!data) return;
        setDetails(data);
    }, [data]);

    // Handle change in Details Form
    const handleChange = e => {
        setDetails({
            ...details,
            [e.target.name]: e.target.value
        });
    };

    // Handle Image Select
    const handleImageSelect = e => setDetails({...details, image: e});

    // On Submit Data to Server
    const submit = () => {
        setTabIndex(0);
        closeModal();
        if (data) return dispatch(categoryAction.updateCategory(details));

        dispatch(categoryAction.addCategory(details));
    };

    // Popup tabs routes
    const modalTabs = useMemo(
        () => [
            {
                name: "Category Details",
                component: (
                    <Details
                        onClick={e => setTabIndex(1)}
                        details={details}
                        setDetails={handleChange}
                    />
                )
            },
            {
                name: "Category Image",
                component: (
                    <ImageFileUpload
                        onClick={submit}
                        onImageSelect={handleImageSelect}
                        imageSrc={details?.image}
                    />
                )
            }
        ],
        [details]
    );

    return (
        <TabModal
            tabTitle={data ? "Edit Category" : "Create New Category"}
            tabs={modalTabs}
            tabIndex={tabIndex}
            setTabIndex={index => setTabIndex(index)}
            closeModal={() => {
                setTabIndex(0);
                closeModal();
            }}
        />
    );
}
