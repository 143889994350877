import React, {useEffect, useMemo, useState} from 'react';
import {SwitchButton, Table, TextLink} from "../../../components";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addressAction} from "redux/actions";

function AddressTab({clickedCustomer}) {
    // Routers
    const location = useLocation();
    const currentLocation = clickedCustomer.id;

    // States
    const [tableRows, setTableRows] = useState();

    // Redux actions
    const dispatch = useDispatch();
    const address = useSelector(state => state.address);

    useEffect(() => {
        dispatch(addressAction.get(currentLocation));
        return () => dispatch(addressAction.reset());
    }, []);

    useEffect(() => {
        if (!address?.length) {
            setTableRows();
            return;
        }

        let filtered = address;

        // Map table rows
        const data = filtered?.map(obj => ({
            addressType: obj.locationText,
            line1: obj.line1,
            line2: (
                <TextLink
                    text={obj.line2}
                    onClick={() =>
                        window.open(
                            `https://www.google.com/maps/search/?api=1&query=${obj.latitude},${obj.longitude}`,
                            "_blank"
                        )
                    }
                />
            ),
            city: obj.city,
            state: obj.state,
            country: obj.country,
            defaultServiceAddress: (
                <SwitchButton enabled={obj.defaultServiceAddress} onClick={() => {
                }}/>
            ),
            defaultBillingAddress: (
                <SwitchButton enabled={obj.defaultBillingAddress} onClick={() => {
                }}/>
            )
        }));

        setTableRows(data);
    }, [address]);

    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "Address Type"
            },
            {
                title: "Line 1"
            },
            {
                title: "Line 2"
            },
            {
                title: "City"
            },
            {
                title: "State"
            },
            {
                title: "Country"
            },
            {
                title: "Default Service"
            },
            {
                title: "Default Billing"
            }
        ],
        [tableRows]
    );

    return <Table headers={tableHeaders} data={tableRows}/>;
}

export default AddressTab;