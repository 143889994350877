import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Table, TextLink} from "../../../components";
import {cSubscriptionAction} from "redux/actions";
import {useHistory} from "react-router-dom";

function SubscriptionsTab({clickedCustomer, handleCloseModal, setClickedSubscription, handleOpenSubscriptionModal}) {
    const {
        push
    } = useHistory();
    const currentLocation = clickedCustomer.id

    const [tableRows, setTableRows] = useState();

    // Redux actions
    const dispatch = useDispatch();
    const cSubscription = useSelector(state => state.cSubscription);

    useEffect(() => {
        dispatch(cSubscriptionAction.get(currentLocation, 1));
        return () => dispatch(cSubscriptionAction.reset());
    }, []);

    useEffect(() => {
        let filtered = cSubscription?.subscriptions;

        if (!filtered?.length) {
            setTableRows();
            return;
        }

        // Map table rows
        const data = filtered?.map(obj => ({
            id: (
                <TextLink
                    text={obj?.id}
                    onClick={() => {
                        // handleCloseModal()
                        setClickedSubscription({id: obj?.id, userId: clickedCustomer?.id})
                        handleOpenSubscriptionModal()
                        // push(
                        //     `/customers/customers/${obj?.id}/${currentLocation}/subscription-modal`
                        // );
                    }}
                />
            ),
            cartID: (
                <TextLink
                    text={obj?.cartID}
                    onClick={() => push(`/orders/orders?orderID=${obj?.cartID}`)}
                />
            ),
            status: obj?.status,
            dateTime: `${obj?.serviceDate}, ${obj?.serviceTime}`,
            reschedCount: obj?.reschedCount,
            createdAt: `${new Date(obj?.createdAt).toLocaleString()}`,
            updatedAt: `${new Date(obj?.updatedAt).toLocaleString()}`
        }));

        setTableRows(data);
    }, [cSubscription]);

    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "Subscription ID"
            },
            {
                title: "Order ID"
            },
            {
                title: "Status"
            },
            {
                title: "Date/Time"
            },
            {
                title: "ReScheduled Count"
            },
            {
                title: "Created"
            },
            {
                title: "Updated"
            }
        ],
        [tableRows]
    );

    return (
        <>
            <Table
                headers={tableHeaders}
                data={tableRows}
                current={cSubscription?.currentPage}
                totalPage={cSubscription?.maxPages}
                onPageChange={pageNumber => {
                    dispatch(cSubscriptionAction.get(currentLocation, pageNumber));
                }}
            />
        </>
    );
}

export default SubscriptionsTab;