import React, { useState, useEffect } from "react";
import { TextInput, Modal } from "components";
import { useDispatch } from "react-redux";
import { spAppPreferencesAction } from "redux/actions";

export default function UpdateQuestion({ closeModal, data }) {
  const [details, setDetails] = useState({
    id: null,
    pid: null,
    optionName: "",
    optionText: ""
  });

  const [isAddAnswer, setAddAnswer] = useState();

  const dispatch = useDispatch();

  // If data exist (to edit)
  useEffect(() => {
    if (!data?.optionName && !data?.optionText) {
      setDetails({ pid: data.pid });
      setAddAnswer(true);
      return;
    }

    if (data)
      setDetails({
        id: data.id,
        pid: data.pid,
        optionName: data.optionName,
        optionText: data.optionText
      });
  }, [data]);

  const handleChange = e => {
    setDetails({
      ...details,
      [e.target.name]:
        isNaN(e.target.value) || !e.target.value
          ? e.target.value
          : parseInt(e.target.value)
    });
  };

  const onSave = () => {
    closeModal();
    if (isAddAnswer) return dispatch(spAppPreferencesAction.addAnswer(details));
    dispatch(spAppPreferencesAction.updateAnswer(details));
  };

  return (
    <Modal
      title={isAddAnswer ? "Add Answer" : "Edit Answer"}
      closeModal={closeModal}
      onSave={onSave}>
      <TextInput
        label="Option Name"
        placeholder="Option Name"
        name="optionName"
        onChange={handleChange}
        value={details?.optionName}
      />
      <TextInput
        label="Option Help Text"
        placeholder="Option Help Text"
        name="optionText"
        onChange={handleChange}
        value={details?.optionText}
      />
    </Modal>
  );
}
