import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from 'redux-thunk';
import Cookies from "js-cookie";
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import * as reducers from "./redux/reducers";
import {combineReducers} from "redux";

const persistConfig = {
    key: 'root',
    storage,
}

const appReducer = combineReducers(reducers);

const persistedReducer = persistReducer(persistConfig, appReducer)

// const rootReducer = (state, action) => {
//     if (action.type === "USER_LOGOUT") {
//         state = undefined;
//         document.cookie?.split(";")?.forEach(cookie => {
//             const name = cookie.split("=")?.[0]?.trim();
//             Cookies.remove(name);
//         });
//     }
//
//     return persistedReducer(state, action);
// };

const middlewear = [thunk]

const configureStore = () => {
    if (process?.env?.NODE_ENV === "development") {
        return createStore(
            persistedReducer,
            composeWithDevTools(
                applyMiddleware(...middlewear)
            )
        );
    } else {
        return createStore(
            persistedReducer,
            {},
            composeWithDevTools(applyMiddleware(...middlewear))
        )
    }
};
export const store = configureStore();
export const persistor = persistStore(store);

