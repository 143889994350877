import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function SendInvoice(props) {

  if(props.status == 'Completed'){
  return (
    <> 
    <div>
      <butto class="styles_button__1T2PZ" style={{cursor: 'pointer',padding:'1px 6px',float: 'left', backgroundColor: '#528ff0', color: '#fff', borderRadius: '3px', fontSize: '11px', marginBottom: '2px'}} onClick={() =>
              window.open(
                `http://cgapp-dev.clykk.com/mail_manager/public/index.php/api/sendinvoicetocustomer?sub_id=${props.orderId}`,
                "_blank"
              )
            }>Send Invoice</butto>
    </div>
    <div>
      <butto class="styles_button__1T2PZ" style={{cursor: 'pointer',padding:'1px 6px',float: 'left', backgroundColor: '#528ff0', color: '#fff', borderRadius: '3px', fontSize: '11px', marginBottom: '2px'}} onClick={() =>
              window.open(
                `http://cgapp-dev.clykk.com/mail_manager/public/index.php/api/viewinvoice?sub_id=${props.orderId}`,
                "_blank"
              )
            }>View Invoice</butto>
    </div>
    </>
  );
  }else{
    return (
      <></>
    )
  }
}
