import axios from "axios";
import { SUBSCRIPTION, LOADING } from "../actionTypes";
import { AWS_CART_8000 } from "configurations";
import { adminToken } from "helper";

const { SET_TRUE, SET_FALSE } = LOADING;

const { GET } = SUBSCRIPTION;

export const get = pageNumber => {
  return async dispatch => {
    dispatch({ type: SET_TRUE });

    const token = await adminToken();

    axios
      .get(
        `${AWS_CART_8000}/subscriptions?pagenumber=${pageNumber}&pagesize=10`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(({ data }) => {
        dispatch({ type: GET, payload: data });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const search = ({ pageNumber, query }) => {
  return async dispatch => {
    dispatch({ type: SET_TRUE });

    const token = await adminToken();

    axios
      .get(
        `${AWS_CART_8000}/subscriptions?pagenumber=${pageNumber}&pagesize=20&q=${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(({ data }) => {
        dispatch({ type: GET, payload: data });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};
