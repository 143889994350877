import React, {useEffect, useMemo, useState} from 'react';
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {SwitchButton, Table, TextLink} from "../../components";
import PackagesPopup from "../ServiceProviders/popups/Packages/PackagesPopup";
import { spAddressAction } from "redux/actions";

function AddressTab({clickedSP}) {
    // Routers
    const location = useLocation();
    const currentLocation = clickedSP.id

    // States
    const [tableRows, setTableRows] = useState();
    const [editableDetail, setEditableDetails] = useState();
    const [isPopupVisible, setPopupVisible] = useState();

    // Filter States
    const [status, setStatus] = useState();
    const [filterState, setfilterState] = useState();

    // Redux actions
    const dispatch = useDispatch();
    const {spAddress, userRole} = useSelector(state => ({
        spAddress: state.spAddress,
        userRole: state.userRole?.filter(role => role.module === "Service Provider")
    }));

    useEffect(() => {
        if (userRole?.find(item => item.privilege === "Read"))
            dispatch(spAddressAction.get(currentLocation));
        return () => dispatch(spAddressAction.reset());
    }, []);

    useEffect(() => {
        if (!spAddress?.length) {
            setTableRows();
            return;
        }

        let filtered = spAddress;

        // filter based on Status
        filtered = filtered?.filter(item =>
            status === "Enabled"
                ? item.enabled
                : status === "Disabled"
                    ? !item.enabled
                    : item
        );

        filtered = internalSearch("userId", filtered);
        filtered = internalSearch("firstName", filtered);
        filtered = internalSearch("lastName", filtered);
        filtered = internalSearch("mobile", filtered);

        // Map table rows
        const data = filtered?.map(obj => ({
            addressType: obj.addressType,
            line1: obj.line1,
            line2: (
                <TextLink
                    text={obj.line2}
                    onClick={() =>
                        window.open(
                            `https://www.google.com/maps/search/?api=1&query=${obj.latitude},${obj.longitude}`,
                            "_blank"
                        )
                    }
                />
            ),
            city: obj.city,
            state: obj.state,
            country: obj.country,
            defaultServiceAddress: (
                <SwitchButton enabled={obj.defaultServiceAddress}/>
            ),
            defaultBillingAddress: (
                <SwitchButton enabled={obj.defaultBillingAddress}/>
            )
        }));

        setTableRows(data);
    }, [spAddress, status, filterState]);

    // internal search
    const internalSearch = (searchBy, rows) => {
        if (!filterState?.[searchBy]) return rows;

        const filteredRows =
            rows?.length &&
            rows.filter(
                item =>
                    item[searchBy]
                        ?.toString()
                        .toLowerCase()
                        .indexOf(filterState?.[searchBy]?.toLowerCase()) !== -1
            );

        return filteredRows;
    };

    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "Address Type"
            },
            {
                title: "Line 1"
            },
            {
                title: "Line 2"
            },
            {
                title: "City"
            },
            {
                title: "State"
            },
            {
                title: "Country"
            },
            {
                title: "Default Service"
            },
            {
                title: "Default Billing"
            }
        ],
        [tableRows]
    );

    return (
        <div>
            <Table headers={tableHeaders} data={tableRows}/>

            {isPopupVisible && (
                <PackagesPopup
                    data={editableDetail}
                    closeModal={() => setPopupVisible(false)}
                />
            )}
        </div>
    );
};

export default AddressTab;