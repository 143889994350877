import React, {useEffect} from "react";
import {TextInput, FilterDropdown, Alert, Button} from "components";
import {useDispatch} from "react-redux";
import {subOrgAction} from "redux/actions";
import styles from "../styles.module.scss";

function DiscoutDate({onClick, details, setDetails}) {
    const dispatch = useDispatch();
    // Get sub-organizations base on organziation
    useEffect(() => {
        if (details?.orgID) dispatch(subOrgAction.get());
        else {
            dispatch(subOrgAction.reset());
        }
    }, [details?.orgID]);
    return (
        <div className={styles.main__container}>
            <Alert/>
            <TextInput
                label="Launch Date"
                placeholder="Launch Date"
                name="launchAt"
                type="date"
                onChange={setDetails}
                value={details?.launchAt?.split("T")[0]}
            />
            <TextInput
                label="Expire Date"
                placeholder="Expire Date"
                name="expireAt"
                type="date"
                onChange={setDetails}
                value={details?.expireAt?.split("T")[0]}
            />
            <div className={styles.btn__container}>
                <Button title="Next" onClick={onClick}/>
            </div>
        </div>
    );
}

export default DiscoutDate;