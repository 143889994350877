import React, {useState, useMemo} from "react";
import {useLocation, Switch, Route} from "react-router-dom";
import tabRoutes from "./tab-routes";
import {Tabs} from "components";
import PackagesPopup from "./popups/Packages/PackagesPopup";

const popups = [
    {
        title: "+ New Package",
        path: "packages",
        component: closeModal => <PackagesPopup closeModal={closeModal}/>
    }
];

export default function Customers() {
    const {pathname} = useLocation();
    const currentLocation = pathname.split("/", 3)[1];

    // States
    const [modal, setModal] = useState(); // Popup open or close state

    return (
        <>
            {/* Panels */}
            <Tabs
                routes={tabRoutes}
                title={popups.find(popup => currentLocation === popup.path)?.title}
                onClick={() => setModal(currentLocation)}
            />

            {
                // Popups
                modal &&
                popups?.length &&
                popups.map(
                    popup =>
                        currentLocation === popup.path &&
                        popup.component(() => setModal(false))
                )
            }

            {/* Modals */}
            <Switch>
                {tabRoutes?.map(tabRoute =>
                    tabRoute?.modalRoutes?.map(({path, component}) => {
                        return <Route path={path} children={component}/>;
                    })
                )}
            </Switch>
        </>
    );
}
