import axios from "axios";
import {
    GET_CATEGORIES,
    ADD_CATEGORIES,
    TOGGLE_CATEGORY,
    UPDATE_CATEGORY,
    RESET_CATEGORY,
    LOADING
} from "../actionTypes";
import {AWS_URL_CM_8090} from "configurations";
import {adminToken} from "helper";
import uploadImage from "helper/uploadImage";

const {SET_TRUE, SET_FALSE} = LOADING;
export const get = () => {
    return async dispatch => {
        try {
            dispatch({type: SET_TRUE});

            const token = await adminToken();

            const res = await axios.get(`${AWS_URL_CM_8090}/category`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            dispatch({type: GET_CATEGORIES, payload: res.data});
        } catch (err) {
            console.log(err);
        } finally {
            dispatch({type: SET_FALSE});
        }
    };
};

// Add Category
export const addCategory = (details = {}) => {
    const {name, displayName, description, displayOrder, image} = details;

    return async dispatch => {
        try {
            const token = await adminToken();
            const imgUrl = await uploadImage(image, "catalogue-management");
            const res = await axios.post(
                `${AWS_URL_CM_8090}/category`,
                {
                    description,
                    displayName,
                    name,
                    displayOrder: parseInt(displayOrder),
                    image: imgUrl
                },
                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            );

            dispatch({type: ADD_CATEGORIES, payload: res.data});
        } catch (err) {
            console.log(err);
        } finally {
            dispatch({type: SET_FALSE});
        }
    };
};

// Update Sub Category
export const updateCategory = (details = {}) => {
    const {name, displayName, description, displayOrder, id, image} = details;

    return async dispatch => {
        try {
            dispatch({type: SET_TRUE});

            const token = await adminToken();

            let imgUrl;
            if (typeof image === "object")
                imgUrl = await uploadImage(image, "catalogue-management");

            // Request to Create Category
            const finalRes = await axios.put(
                `${AWS_URL_CM_8090}/category/${id}`,
                {
                    description,
                    displayName,
                    name,
                    displayOrder: parseInt(displayOrder),
                    image: imgUrl || image
                },
                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            );

            dispatch({type: UPDATE_CATEGORY, payload: finalRes.data});
        } catch (err) {
            console.log(err);
        } finally {
            dispatch({type: SET_FALSE});
        }
    };
};

// Enable or disable category
export const toggleCategory = categoryId => {
    return async dispatch => {
        try {
            dispatch({type: SET_TRUE});

            const token = await adminToken();
            const res = await axios.patch(
                `${AWS_URL_CM_8090}/category/${categoryId}`,
                {},
                {headers: {Authorization: `Bearer ${token}`}}
            );
            dispatch({type: TOGGLE_CATEGORY, payload: res.data.id});
        } catch (err) {
            console.log(err);
        } finally {
            dispatch({type: SET_FALSE});
        }
    };
};

export const reset = () => {
    return {type: RESET_CATEGORY};
};
