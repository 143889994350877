import {CUSTOMER_APP_PREFERENCES} from "../actionTypes";

export default function spAppPreferencesReducer(state = null, action) {
    const {
        ADD,
        GET,
        TOGGLE,
        UPDATE,
        RESET,
        TOGGLE_ANS,
    } = CUSTOMER_APP_PREFERENCES;

    switch (action.type) {
        case GET:
            return action.payload;

        case ADD:
            console.log(action.payload);
            const newState = state?.length
                ? [...state, action.payload]
                : [action.payload];
            return newState;

        case UPDATE:
            return state.map((item) =>
                item.id === action.payload.id ? action.payload : item
            );

        case TOGGLE:
            return state.map((item) =>
                item.id === action.payload.id ? action.payload : item
            );

        case TOGGLE_ANS:
            return state.map((item) =>
                item.id === action.payload.id ? action.payload : item
            );

        case RESET:
            return null;

        default:
            return state;
    }
}
