import React, {useEffect, useState, useMemo} from "react";
import styles from "./styles.module.scss";
import {
    Table,
    TextInput,
    SearchButton,
    LightTextButton,
    FilterDropdown,
    SwitchButton,
    ImageStamp
} from "components";
import {useDispatch, useSelector} from "react-redux";
import {categoryAction} from "redux/actions";
import {MdEdit} from "react-icons/md";
import CategoryPopup from "../popups/Category/CategoryPopup";
import {internalSearch} from "helper";

const initialState = {
    name: "",
    enabled: ""
};

const CategoryPanel = () => {
    // Disptach redux action to get category
    const dispatch = useDispatch();
    const {categories, userRole} = useSelector(state => ({
        categories: state.categories,
        userRole: state.userRole?.filter(role => role.module === "Category")
    }));

    // Component States
    const [tableRows, setTableRows] = useState();
    const [editableDetail, setEditableDetails] = useState();
    const [isPopupVisible, setPopupVisible] = useState();

    // Filter States
    const [filterState, setFilterState] = useState(initialState);

    // Initially get the categories
    useEffect(() => {
        if (userRole?.find(item => item.privilege === "Read"))
            dispatch(categoryAction.get());

        return dispatch(categoryAction.reset());
    }, [dispatch]);

    useEffect(() => {
        let filtered = categories;

        if (!filtered?.length) return;

        filtered = internalSearch(filterState, "name", filtered);
        filtered = internalSearch(filterState, "enabled", filtered);

        // Custom Functions
        const data = filtered?.map(category => ({
            id: `${category.id}`,
            name: `${category.name}`,
            description: `${category.description}`,
            image: <ImageStamp src={category.image}/>,
            displayName: `${category.displayName}`,
            displayOrder: `${category.displayOrder}`,
            createdAt: `${new Date(category.createdAt).toLocaleString()}`,
            createdBy: category.createdBy,
            updatedAt: `${new Date(category.updatedAt).toLocaleString()}`,
            updatedBy: category.updatedBy,
            enabled: (
                <SwitchButton
                    enabled={category.enabled}
                    onClick={
                        userRole?.find(item => item.privilege === "Enable")
                            ? () => dispatch(categoryAction.toggleCategory(category.id))
                            : null
                    }
                />
            ),
            edit: (
                <MdEdit
                    size="20"
                    color="#528ff0"
                    onClick={
                        userRole?.find(item => item.privilege === "Update")
                            ? () => {
                                setEditableDetails(category);
                                setPopupVisible(true);
                            }
                            : null
                    }
                    className="clickable"
                />
            )
        }));

        setTableRows(data);
    }, [categories, filterState, dispatch]);

    // Table header
    const tableHeaders = useMemo(() => [
        {
            title: "Category ID"
        },
        {
            title: "Category",
            sortBy: "name"
        },
        {
            title: "Description"
        },
        {
            title: "Image"
        },
        {
            title: "Display Name"
        },
        {
            title: "Display Order",
            sortBy: "displayOrder"
        },
        {
            title: "Created At",
            sortBy: "createdAt"
        },
        {
            title: "Created By"
        },
        {
            title: "Updated At",
            sortBy: "updatedAt"
        },
        {
            title: "Updated By"
        },
        {
            title: "Enabled"
        },
        {
            title: "Edit"
        }
    ]);

    return (
        <div>
            <div className={styles.filter}>
                <TextInput
                    search
                    label="Category Name"
                    name="name"
                    value={filterState?.name}
                    onChange={e =>
                        setFilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
                <FilterDropdown
                    label="Status"
                    name="enabled"
                    value={filterState?.enabled}
                    options={["All", "Enabled", "Disabled"]}
                    values={["", true, false]}
                    onChange={e =>
                        setFilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
                <SearchButton/>
                <LightTextButton onClick={() => setFilterState(initialState)}/>
            </div>

            <Table headers={tableHeaders} data={tableRows}/>

            {isPopupVisible && (
                <CategoryPopup
                    data={editableDetail}
                    closeModal={() => setPopupVisible(false)}
                />
            )}
        </div>
    );
};

export default CategoryPanel;
