import axios from "axios";
import {
  GET_SUB_CATEGORIES,
  ADD_SUB_CATEGORIES,
  TOGGLE_SUB_CATEGORY,
  UPDATE_SUB_CATEGORY,
  RESET_SUB_CATEGORY,
  LOADING
} from "../actionTypes";
import { AWS_URL_CM_8090 } from "configurations";
import { adminToken } from "helper";
import uploadImage from "helper/uploadImage";

const { SET_TRUE, SET_FALSE } = LOADING;

export const get = categoryId => {
  return async dispatch => {
    dispatch({ type: SET_TRUE });

    const token = await adminToken();
    axios
      .get(`${AWS_URL_CM_8090}/category/${categoryId}/sub-categories`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        dispatch({ type: GET_SUB_CATEGORIES, payload: res.data });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const addSubCategory = (details = {}) => {
  const {
    name,
    displayName,
    description,
    displayOrder,
    categoryID,
    image,
    serviceRule
  } = details;

  return async dispatch => {
    try {
      const imgUrl = await uploadImage(image, "catalogue-management");
      const token = await adminToken();
      const res = await axios.post(
        `${AWS_URL_CM_8090}/category/${categoryID}/sub-categories`,
        {
          description,
          displayName,
          name,
          displayOrder: parseInt(displayOrder),
          enabled: true,
          image: imgUrl,
          serviceRule
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      dispatch({ type: ADD_SUB_CATEGORIES, payload: res.data });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({ type: SET_FALSE });
    }
  };
};

// Update Sub Category
export const updateSubCategory = (details = {}) => {
  const {
    name,
    displayName,
    description,
    displayOrder,
    categoryID,
    image,
    id,
    serviceRule
  } = details;

  return async dispatch => {
    dispatch({ type: SET_TRUE });

    try {
      let imgUrl;

      // Request to Upload Image
      if (typeof image === "object")
        imgUrl = await uploadImage(image, "catalogue-management");

      const token = await adminToken();

      // Request to Create Category
      const finalRes = await axios.put(
        `${AWS_URL_CM_8090}/category/${categoryID}/sub-categories/${id}`,
        {
          description,
          displayName,
          name,
          displayOrder: parseInt(displayOrder),
          image: imgUrl || image,
          serviceRule: serviceRule?.map(service => ({ id: service?.id }))
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      dispatch({ type: UPDATE_SUB_CATEGORY, payload: finalRes.data });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({ type: SET_FALSE });
    }
  };
};

export const toggleSubCategory = (categoryId, subCategoryId) => {
  return async dispatch => {
    dispatch({ type: SET_TRUE });

    try {
      const token = await adminToken();

      const res = await axios.patch(
        `${AWS_URL_CM_8090}/category/${categoryId}/sub-categories/${subCategoryId}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );

      dispatch({ type: TOGGLE_SUB_CATEGORY, payload: res.data.id });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({ type: SET_FALSE });
    }
  };
};

export const reset = () => ({ type: RESET_SUB_CATEGORY });
