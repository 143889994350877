import OrganizationPanel from "./panels/OrganizationPanel";
import SubOrganizationPanel from "./panels/SubOrganizationPanel";
import ServiceProviderPanel from "./panels/ServiceProviderPanel";
import DocumentsPanel from "./panels/OrganizationPanel";

const tabRoutes = [
  {
    name: "Organization",
    path: "/organization",
    component: <OrganizationPanel />,
  },
  {
    name: "Sub Organization",
    path: "/sub-organization",
    component: <SubOrganizationPanel />,
  },
  {
    name: "Service Provider",
    path: "/service-provider",
    component: <ServiceProviderPanel />,
  },
];

export default tabRoutes;

export const modalTabRoutes = [
  {
    name: "Package Add On",
    path: "/package-add-on",
    component: <DocumentsPanel />,
  },
  {
    name: "Package QA",
    path: "/package-qa",
    component: <DocumentsPanel />,
  },
  {
    name: "Package Like",
    path: "/package-like",
    component: <DocumentsPanel />,
  },
  {
    name: "Package Rating",
    path: "/package-rating",
    component: <DocumentsPanel />,
  },
  {
    name: "Package Sub Org Map",
    path: "/package-sub-org-map",
    component: <DocumentsPanel />,
  },
  {
    name: "Package City Map",
    path: "/package-city-map",
    component: <DocumentsPanel />,
  },
];
