import {useEffect, useState} from "react";
import styles from "./styles.module.scss";
import {
    Table,
    FilterDropdown,
    TextInput,
    SearchButton,
    LightTextButton,
    Status
} from "components";
import {useDispatch, useSelector} from "react-redux";
import {manageUserAction} from "redux/actions";
import {SwitchButton, TextLink} from "components";
import {useHistory} from "react-router-dom";
import {internalSearch} from "helper";

const initialState = {
    first_name: "",
    mobile: "",
    enabled: "",
    role: ""
};

const UsersPanel = () => {
    // Routers
    const history = useHistory();
    const currentLocation = history.location.pathname;

    // Disptach redux action to get users
    const dispatch = useDispatch();
    const {users, userRole} = useSelector(state => ({
        users: state.users,
        userRole: state.userRole?.filter(role => role.module === "Users")
    }));

    // Filter State
    const [filterState, setFilterState] = useState(initialState);
    const [tableRows, setTableRows] = useState();

    // Effects
    useEffect(() => {
        if (userRole?.find(item => item.privilege === "Read"))
            dispatch(manageUserAction.get());
    }, []);

    useEffect(() => {
        let filtered = users;

        if (!filtered?.length) {
            setTableRows();
            return;
        }

        filtered = internalSearch(filterState, "first_name", filtered);
        filtered = internalSearch(filterState, "mobile", filtered);
        filtered = internalSearch(filterState, "enabled", filtered);

        filtered = filtered?.map(user => ({
            admin_id: (
                <TextLink
                    text={user.admin_id}
                    onClick={() => history.push(`${currentLocation}/${user.admin_id}`)}
                />
            ),
            picture: `${user.picture}`,
            first_name: `${user.first_name}`,
            last_name: `${user.last_name}`,
            role: user?.get_user_role?.get_role_details?.role_name,
            email: `${user.email}`,
            email_verified: `${user.email_verified ? "Verified" : "Not Verified"}`,
            mobile: `${user.mobile}`,
            mobile_verified: `${user.mobile_verified ? "Verified" : "Not Verified"}`,
            created_at: `${new Date(user.created_at).toLocaleString()}`,
            updated_at: `${new Date(user.updated_at).toLocaleString()}`,
            enabled: (
                <SwitchButton
                    enabled={user.enabled}
                    onClick={
                        userRole?.find(item => item.privilege === "Enable")
                            ? () => dispatch(manageUserAction.toggle(user))
                            : null
                    }
                />
            ),
            status: <Status active={user.status === "active" ? true : false}/>
        }));

        filtered = internalSearch(filterState, "role", filtered);

        setTableRows(filtered);
    }, [users, filterState, dispatch, history]);

    // Custom Functions

    const tableHeaders = [
        {
            title: "ID",
            sortBy: "admin_id"
        },
        "Picture",
        {
            title: "First Name",
            sortBy: "first_name"
        },
        {
            title: "Last Name",
            sortBy: "last_name"
        },
        {
            title: "Role",
            sortBy: "role"
        },
        {
            title: "Email",
            sortBy: "email"
        },
        "Email Verified",
        "Mobile",
        "Mobile Verified",
        {
            title: "Created",
            sortBy: "created_at"
        },
        {
            title: "Updated",
            sortBy: "updated_at"
        },
        "Enabled",
        "Status"
    ];

    return (
        <div>
            <div className={styles.filter}>
                <TextInput
                    label="First Name"
                    value={filterState?.first_name}
                    name="first_name"
                    onChange={e =>
                        setFilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
                <TextInput
                    label="Role"
                    value={filterState?.role}
                    name="role"
                    onChange={e =>
                        setFilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
                <TextInput
                    label="Mobile"
                    value={filterState?.mobile}
                    name="mobile"
                    onChange={e =>
                        setFilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
                <FilterDropdown
                    label="Status"
                    value={filterState?.enabled}
                    options={["All", "Enabled", "Disabled"]}
                    values={["", true, false]}
                    name="enabled"
                    onChange={e =>
                        setFilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
                <SearchButton/>
                <LightTextButton onClick={() => setFilterState(initialState)}/>
            </div>
            <Table headers={tableHeaders} data={tableRows}/>
        </div>
    );
};

export default UsersPanel;
