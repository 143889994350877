import {
  GET_ROLES,
  ADD_ROLE,
  DELETE_ROLE,
  UPDATE_ROLE,
  ACTIVE_ROLE,
  INACTIVE_ROLE
} from "../actionTypes";

const rolesReducer = (state = [], action) => {
  switch (action.type) {
    case GET_ROLES:
      return action.payload;

    case ADD_ROLE:
      return [action.payload, ...state];

    case UPDATE_ROLE:
      return state.map(item =>
        item?.role_id === action.payload?.role_id ? action.payload : item
      );

    case DELETE_ROLE:
      return action.payload;

    case ACTIVE_ROLE:
      return action.payload;

    case INACTIVE_ROLE:
      return action.payload;

    default:
      return state;
  }
};

export default rolesReducer;
