import React, { useState } from "react";
import { useLocation, Switch, Route } from "react-router-dom";
import tabRoutes from "./tab-routes";
import { Tabs } from "components";
import PackagesPopup from "./popups/Packages/PackagesPopup";
import { useSelector } from "react-redux";

const popups = [
  {
    title: "+ New Package",
    path: "packages",
    component: closeModal => <PackagesPopup closeModal={closeModal} />
  }
];

const Packages = () => {
  const location = useLocation();
  const currentLocation = location.pathname.split("/", 3)[1];

  const userRole = useSelector(state =>
    state.userRole?.filter(role => role.module === "Package")
  );

  // States
  const [modal, setModal] = useState(); // Popup open or close state

  return (
    <>
      {/* Panels */}
      <Tabs
        routes={tabRoutes}
        title={popups.find(popup => currentLocation === popup.path)?.title}
        onClick={
          userRole?.find(item => item.privilege === "Create")
            ? () => setModal(currentLocation)
            : null
        }
      />

      {
        // Popups
        modal &&
          popups?.length &&
          popups.map(
            popup =>
              currentLocation === popup.path &&
              popup.component(() => setModal(false))
          )
      }

      {/* Modals */}
      <Switch>
        <Route path={`${tabRoutes[0]?.modalRoutes[0]?.path}`}>
          {tabRoutes[0]?.modalRoutes[0]?.component}
        </Route>
      </Switch>
    </>
  );
};

export default Packages;
