import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";

const Newsubmsg = (props) => {
    if(props.newsubcount == 0){
        return '';//<p className={styles.newsubnotfound}>{props.newsubmsg}</p>;
    }else{
        //return <p className={styles.newsubfound}>{props.newsubmsg}</p>;
        return (
            <>
                <b data-bs-toggle="collapse" data-bs-target="#demo" style={{cursor:'pointer'}}><b style={{color:'green'}}>{props.newsubcount}</b> New orders received in ( last <b style={{color:'green'}}> {Math.floor(props.duration / 3600).toString().padStart(2,'0')} H {Math.floor(props.duration % 3600 / 60).toString().padStart(2,'0')} M {Math.floor(props.duration % 60).toString().padStart(2,'0')} S</b>)</b>
                <div id="demo" className={'collapse'}>
                    <a href="/subscription/orders"><div className={styles.newsubfound} dangerouslySetInnerHTML={{ __html: props.newsubmsg}} /></a>
                </div>
            </>
        )
    }
};

export default Newsubmsg;
