import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { subCategoriesAction, packagesAction } from "redux/actions";
import { TabModal } from "components";
import Details from "./Details";
import ImageFileUpload from "./ImageFileUpload";

export default function PackagesPopup({ closeModal, data }) {
  // redux
  const dispatch = useDispatch();
  const categories = useSelector(state => state.categories);
  const subCategories = useSelector(state => state.subCategories);

  // states
  const [tabIndex, setTabIndex] = useState(0); //Popup tab active or inactive state
  const [details, setDetails] = useState(); // Sub Category Details

  // useEffects
  useEffect(() => {
    if (!data) return;
    setDetails(data);
  }, [data]);

  // Handle change in Details Form
  const handleChange = e => {
    // Handle Category
    if (e.target.name === "category") {
      const category = categories.find(
        category => category.id == e.target.value
      );

      setDetails({
        ...details,
        categoryID: e.target.value,
        [e.target.name]: category?.name,
        subCategoryID: 0
      });

      return;
    }

    setDetails({
      ...details,
      [e.target.name]: e.target.value
    });
  };

  // Handle Image Select
  const handleImageSelect = e => setDetails({ ...details, packageImage: e });

  // On Submit Data to Server
  const submit = () => {
    setTabIndex(0);
    closeModal();
    if (data) return dispatch(packagesAction.updatePackage(details));

    dispatch(packagesAction.addPackage(details));
  };

  // Popup tabs routes
  const modalTabs = useMemo(
    () => [
      {
        name: "Package Details",
        component: (
          <Details
            key="details"
            onClick={e => setTabIndex(1)}
            details={details}
            setDetails={handleChange}
            edit={data ? true : false}
          />
        )
      },
      {
        name: "Package Image",
        component: (
          <ImageFileUpload
            key="image"
            onClick={submit}
            onImageSelect={handleImageSelect}
            imageSrc={details?.packageImage}
          />
        )
      }
    ],
    [details]
  );

  return (
    <TabModal
      tabTitle={data ? "Edit Package" : "Create New Package"}
      tabs={modalTabs}
      tabIndex={tabIndex}
      setTabIndex={index => setTabIndex(index)}
      closeModal={() => {
        setTabIndex(0);
        closeModal();
      }}
    />
  );
}
