import React from "react";
import styles from "./styles.module.scss";
import { TransparentBG } from "../index";
import { IoIosClose } from "react-icons/io";

export default function DocumentModal({ title, documentURL, closeModal }) {
  return (
    <TransparentBG>
      <div className={styles.content__container}>
        <div className={styles.header}>
          <h5>{title || "Document View"}</h5>
          <IoIosClose
            size={20}
            className={styles.icon__close}
            onClick={closeModal}
          />
        </div>
        <div className={styles.main__container}>
          <div className={styles.img__container}>
            <img src={documentURL} alt="Document View" />
          </div>
        </div>
      </div>
    </TransparentBG>
  );
}
