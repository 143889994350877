import React from "react";
import styles from "./styles.module.scss";
import {BiSearchAlt} from "react-icons/bi";

export default function TextInput({
                                      label,
                                      placeholder,
                                      search,
                                      disabled,
                                      textarea = false,
                                      ...props
                                  }) {
    return (
        <div className={styles.text__input}>
            <p>{label}</p>
            {textarea ?
                <textarea style={{width: 250}}
                    className={styles.input}
                    placeholder={placeholder || label}
                    disabled={disabled}
                    {...props}
                />
                :
                <input style={{width: 250}}
                    className={styles.input}
                    placeholder={placeholder || label}
                    disabled={disabled}
                    {...props}
                />
            }
            {search && (
                <BiSearchAlt size="15" color="#000" className={styles.search__icon}/>
            )}
        </div>
    );
}
