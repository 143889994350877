import {SERVICE_PROVIDER, SP_Commision} from "../actionTypes";

export default function spCommisionReducer(state = null, action) {
    const {
        GET,
    } = SP_Commision;

    switch (action.type) {
        case GET:
            return action.payload;

        default:
            return state;
    }
}
