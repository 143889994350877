import axios from "axios";

import {DISCOUNTS, LOADING} from "../actionTypes";
import {AWS_URL_DM_8095} from "configurations";
import {adminToken} from "helper";
import uploadImage from "helper/uploadImage";

const {GET, UPDATE, RESET, ADD} = DISCOUNTS;
const {SET_TRUE, SET_FALSE} = LOADING;

export const get = id => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        try {
            const token = await adminToken();
            const {data} = await axios.get(
                `${AWS_URL_DM_8095}/city/${id}?default=true`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            dispatch({type: GET, payload: data});
        } catch (err) {
            console.log(err);
        } finally {
            dispatch({type: SET_FALSE});
        }
    };
};

// Add
export const add = (details = {}) => {
    const {image, minDiscount, maxDiscount, cityID} = details;

    return async dispatch => {
        try {
            const token = await adminToken();

            const imgUrl = await uploadImage(image, "coupon-management");

            const res = await axios.post(
                `${AWS_URL_DM_8095}/city/${cityID}`,
                {
                    ...details,
                    minDiscount: parseFloat(parseFloat(minDiscount).toFixed(2)),
                    maxDiscount: parseFloat(parseFloat(maxDiscount).toFixed(2)),
                    image: imgUrl
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            dispatch({type: ADD, payload: res.data});
        } catch (err) {
            console.log(err);
        } finally {
            dispatch({type: SET_FALSE});
        }
    };
};

// Update
export const update = (details = {}) => {
    const {image, minDiscount, maxDiscount, id} = details;

    return async dispatch => {
        dispatch({type: SET_TRUE});

        try {
            let imgUrl;

            const token = await adminToken();

            // Request to Upload Image
            if (typeof image === "object")
                imgUrl = await uploadImage(image, "coupon-management");

            const finalRes = await axios.put(
                `${AWS_URL_DM_8095}/discount/${id}`,
                {
                    ...details,
                    minDiscount: parseFloat(parseFloat(minDiscount).toFixed(2)),
                    maxDiscount: parseFloat(parseFloat(maxDiscount).toFixed(2)),
                    image: imgUrl || image
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            dispatch({type: UPDATE, payload: finalRes.data});
        } catch (err) {
            console.log(err);
        } finally {
            dispatch({type: SET_FALSE});
        }
    };
};

// Duplicate
export const duplicate = (details = {}) => {
    const {image, minDiscount, maxDiscount, cityID} = details;
    delete details["id"];
    return async dispatch => {
        dispatch({type: SET_TRUE});

        try {
            const token = await adminToken();

            let imgUrl;

            // Request to Upload Image
            if (typeof image === "object")
                imgUrl = await uploadImage(image, "coupon-management");

            const finalRes = await axios.post(
                `${AWS_URL_DM_8095}/city/${cityID}`,
                {
                    ...details,
                    minDiscount: parseFloat(parseFloat(minDiscount).toFixed(2)),
                    maxDiscount: parseFloat(parseFloat(maxDiscount).toFixed(2)),
                    image: imgUrl || image
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            dispatch({type: ADD, payload: finalRes.data});
        } catch (err) {
            console.log(err);
        } finally {
            dispatch({type: SET_FALSE});
        }
    };
};

export const reset = () => dispatch => dispatch({type: RESET});
