import React from "react";
import PuffLoader from "react-spinners/PuffLoader";
import styles from "./LoadingSpinner.module.scss";

function LoadingSpinner({ size }) {
  return (
    <div className={styles.container}>
      <PuffLoader color="#dddddd" />
    </div>
  );
}

export default LoadingSpinner;
