import { GET_PACKAGE_SHARE, RESET_PACKAGE_SHARE } from "../actionTypes";

const packageShareReducer = (state = [], action) => {
  switch (action.type) {
    case GET_PACKAGE_SHARE:
      return action.payload;

    case RESET_PACKAGE_SHARE:
      return [];

    default:
      return state;
  }
};

export default packageShareReducer;
