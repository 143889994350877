import {
  GET_PACKAGE_QNA,
  ADD_PACKAGE_QNA,
  TOGGLE_PACKAGE_QNA,
  UPDATE_PACKAGE_QNA,
  RESET_PACKAGE_QNA
} from "../actionTypes";

const packageQnAReducer = (state = null, action) => {
  switch (action.type) {
    case GET_PACKAGE_QNA:
      return action.payload;

    case ADD_PACKAGE_QNA:
      return action.payload;

    case UPDATE_PACKAGE_QNA:
      return action.payload;

    case TOGGLE_PACKAGE_QNA:
      return action.payload;

    case RESET_PACKAGE_QNA:
      return [];

    default:
      return state;
  }
};

export default packageQnAReducer;
