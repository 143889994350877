import { GET_PREFERENCES, ADD_PREFERENCES, TOGGLE_PREFERENCES, UPDATE_PREFERENCES, RESET_PREFERENCES } from '../actionTypes'


const preferencesReducer = ( state = [], action ) => {
    
    switch(action.type){
        
        case GET_PREFERENCES:
            return action.payload

        case ADD_PREFERENCES:
            const pkg = state || { qna : [] };
            pkg.qna.push(...action.payload.qna)
            return {...pkg};

        case UPDATE_PREFERENCES:
            return action.payload;
        
        case TOGGLE_PREFERENCES:
            return action.payload;
        
        case RESET_PREFERENCES:
            return [];
      
        default:
            return state;
    }

}

export default preferencesReducer;