import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Table} from "../../components";
import {memberStatusAction} from "redux/actions";

function MemberStatusTab({clickedSP}) {
    const history = useHistory();
    const path = history.location.pathname;
    const id = clickedSP.id

    // States
    const [tableRows, setTableRows] = useState();
    const [status, setStatus] = useState({
        id: null,
        status: null,
        comment: ""
    });

    const dispatch = useDispatch();
    const memberStatus = useSelector(state => state.memberStatus?.org);

    useEffect(() => {
        dispatch(memberStatusAction.get());

        return () => {
        };
    }, []);

    useEffect(() => {
        let filtered = [memberStatus];

        if (!filtered?.length || !filtered[0]) {
            setTableRows();
            return;
        }
        // Convert object of objects of arrays to array
        filtered = Object.entries(filtered[0])?.map(([_, value]) => value);


        filtered = filtered.flat()?.filter(item => item.refererID == id);

        // Map table rows
        let data = filtered?.map(obj => ({
            id: obj.id,
            inviteeName: obj.inviteeName,
            inviteMethod: obj.inviteMethod,
            orgID: obj.orgID,
            subOrgID: obj.subOrgID,
            refererID: obj.refererID,
            mobile: obj.mobile,
            role: obj.role,
            status: obj.status,
            createdAt: `${new Date(obj.createdAt).toLocaleString()}`,
            updatedAt: `${new Date(obj.updatedAt).toLocaleString()}`
        }));

        setTableRows(data);
    }, [memberStatus]);

    // Table Header
    const tableHeaders = [
        {
            title: "ID",
            sortBy: "id"
        },
        {
            title: "Invitee Name",
            sortBy: "inviteeName"
        },
        {
            title: "Invite Method",
            sortBy: "inviteMethod"
        },
        {
            title: "Org"
        },
        {
            title: "Sub Org",
            sortBy: "subOrgID"
        },
        {
            title: "Referer ID",
            sortBy: "refererID"
        },
        {
            title: "Mobile"
        },
        {
            title: "Role"
        },
        {
            title: "Status"
        },
        {
            title: "Created",
            sortBy: "createdAt"
        },
        {
            title: "Updated",
            sortBy: "updatedAt"
        }
    ];

    return (
        <div>
            <Table headers={tableHeaders} data={tableRows}/>
        </div>
    );
}

export default MemberStatusTab;