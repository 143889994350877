import {
    ADD_FAQ,
    GET_FAQ,
    TOGGLE_FAQ,
    UPDATE_FAQ,
    RESET_FAQ,
} from "../actionTypes";

const faqAreasReducer = (state = [], action) => {
    switch (action.type) {
        case GET_FAQ:
            return action.payload;

        case ADD_FAQ:
            return [...state, action.payload];

        case UPDATE_FAQ:
            return state.map((item) =>
                item.id === action.payload.id ? action.payload : item
            );

        case TOGGLE_FAQ:
            return state.map((item) =>
                item.id !== action.payload.id
                    ? item
                    : {...item, enabled: !item.enabled}
            );

        case RESET_FAQ:
            return [];

        default:
            return state;
    }
};

export default faqAreasReducer;
