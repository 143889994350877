import React, {useState} from "react";
import styles from "./../styles.module.scss";
import {TextInput, Button, FilterDropdown} from "components";
import {useDispatch, useSelector} from "react-redux";

export default function CategoryDetails({onClick, details, setDetails}) {
    const categories = useSelector(state => state.categories);
    const filteredCategories = categories?.map(category => category.name); //make an arry of category names
    const categoryIDs = categories?.map(category => category.id);

    return (
        <div className={styles.main__container}>
            <TextInput
                label="Name"
                placeholder="Name"
                name="name"
                onChange={setDetails}
                value={details?.name}
            />
            <TextInput
                label="Description"
                placeholder="Description"
                name="description"
                onChange={setDetails}
                value={details?.description}
                textarea={true}
            />
            <TextInput
                label="Display Name"
                placeholder="Display Name"
                name="displayName"
                onChange={setDetails}
                value={details?.displayName}
                textarea={true}
            />
            <TextInput
                label="Display Order"
                placeholder="Display Order"
                name="displayOrder"
                onChange={setDetails}
                value={details?.displayOrder}
            />
            <div className={styles.btn__container}>
                <Button title="Next" onClick={onClick}/>
            </div>
        </div>
    );
}
