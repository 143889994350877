import React from "react";
import {TextInput, Modal} from "components";

export default function Comment({closeModal, data, onSave, handleChange}) {
    return (
        <Modal title={"Add Comment"} closeModal={closeModal} onSave={onSave}>
            <TextInput
                label="Comment"
                placeholder="Comment"
                name="comment"
                onChange={handleChange}
                value={data?.comment}
                textarea={true}
            />
        </Modal>
    );
}
