import {
  GET_PACKAGE_ADDON,
  ADD_PACKAGE_ADDON,
  TOGGLE_PACKAGE_ADDON,
  UPDATE_PACKAGE_ADDON,
  RESET_PACKAGE_ADDON
} from "../actionTypes";

const packageAddonReducer = (state = [], action) => {
  switch (action.type) {
    case GET_PACKAGE_ADDON:
      return action.payload;

    case ADD_PACKAGE_ADDON:
      return [...(state || []), action.payload];

    case UPDATE_PACKAGE_ADDON:
      return state?.map(category =>
        category.id === action.payload.id ? action.payload : category
      );

    case TOGGLE_PACKAGE_ADDON:
      return state?.map(category =>
        category.id !== action.payload
          ? category
          : { ...category, enabled: !category.enabled }
      );

    case RESET_PACKAGE_ADDON:
      return [];

    default:
      return state;
  }
};

export default packageAddonReducer;
