import React, {useState} from 'react';
import {Box, Tab, Tabs, useTheme} from "@material-ui/core";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import OrdersTab from "./OrdersTab";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            style={{backgroundColor: "white"}}
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <p>{children}</p>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function DashboardOrders({
                             datesFilter,
                             onDateChange,
                             data,
                             setData,
                             response,
                             filter,
                             onChange,
                             setOrdersMeta,
                             setOrdersStatus
                         }) {
    const theme = useTheme();

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        if(newValue === 0) {
            setOrdersStatus("Completed")
        } else if (newValue === 1) {
            setOrdersStatus("Scheduled")
        } else if (newValue === 2) {
            setOrdersStatus("ReScheduled")
        } else if (newValue === 3) {
            setOrdersStatus("Routed")
        } else if (newValue === 4) {
            setOrdersStatus("ProviderAssigned")
        } else if (newValue === 5) {
            setOrdersStatus("ProviderOnTheWay")
        } else if (newValue === 6) {
            setOrdersStatus("ProviderArrived")
        } else if (newValue === 7) {
            setOrdersStatus("ProviderOTPSent")
        } else if (newValue === 8) {
            setOrdersStatus("ProviderOTPConfirmed")
        } else if (newValue === 9) {
            setOrdersStatus("InProgress")
        } else {
            setOrdersStatus("AdditionalCharges")
        }
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        let newValue = index
        if(newValue === 0) {
            setOrdersStatus("Completed")
        } else if (newValue === 1) {
            setOrdersStatus("Scheduled")
        } else if (newValue === 2) {
            setOrdersStatus("ReScheduled")
        } else if (newValue === 3) {
            setOrdersStatus("Routed")
        } else if (newValue === 4) {
            setOrdersStatus("ProviderAssigned")
        } else if (newValue === 5) {
            setOrdersStatus("ProviderOnTheWay")
        } else if (newValue === 6) {
            setOrdersStatus("ProviderArrived")
        } else if (newValue === 7) {
            setOrdersStatus("ProviderOTPSent")
        } else if (newValue === 8) {
            setOrdersStatus("ProviderOTPConfirmed")
        } else if (newValue === 9) {
            setOrdersStatus("InProgress")
        } else {
            setOrdersStatus("AdditionalCharges")
        }
        setValue(index);
    };

    return (
        <div className={'generic-tabs-container'}>
            <Box display="flex" justifyContent="left" width="100%">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    indicatorColor={'primary'}
                    scrollButtons="auto"
                >
                    <Tab label="Completed" {...a11yProps(0)} />
                    <Tab label="Scheduled"
                         style={{}} {...a11yProps(1)} />
                    <Tab label="ReScheduled"
                         style={{}} {...a11yProps(2)} />
                    <Tab label="Routed"
                         style={{}} {...a11yProps(3)} />
                    <Tab label="ProviderAssigned"
                         style={{}} {...a11yProps(4)} />
                    <Tab label="ProviderOnTheWay"
                         style={{}} {...a11yProps(5)} />
                    <Tab label="ProviderArrived"
                         style={{}} {...a11yProps(6)} />
                    <Tab label="ProviderOTPSent"
                         style={{}} {...a11yProps(7)} />
                    <Tab label="ProviderOTPConfirmed"
                         style={{}} {...a11yProps(8)} />
                    <Tab label="InProgress"
                         style={{}} {...a11yProps(9)} />
                    <Tab label="AdditionalCharges"
                         style={{}} {...a11yProps(10)} />
                </Tabs>
            </Box>

            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                disabled={true}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel className={'generic-tab-panel'} value={value} index={0}
                          dir={theme.direction}>
                    <OrdersTab
                        status={'Completed'}
                        response={response}
                        data={data}
                        setData={setData}
                        filter={filter}
                        datesFilter={datesFilter}
                        onDateChange={onDateChange}
                        onChange={onChange}
                        setOrdersMeta={setOrdersMeta}
                    />
                </TabPanel>

                <TabPanel className={'generic-tab-panel'} value={value} index={1} dir={theme.direction}>
                    <OrdersTab
                        status={'Scheduled'}
                        response={response}
                        data={data}
                        setData={setData}
                        filter={filter}
                        datesFilter={datesFilter}
                        onDateChange={onDateChange}
                        onChange={onChange}
                        setOrdersMeta={setOrdersMeta}
                    />
                </TabPanel>

                <TabPanel className={'generic-tab-panel'} value={value} index={2} dir={theme.direction}>
                    <OrdersTab
                        status={'ReScheduled'}
                        response={response}
                        data={data}
                        setData={setData}
                        filter={filter}
                        datesFilter={datesFilter}
                        onDateChange={onDateChange}
                        onChange={onChange}
                        setOrdersMeta={setOrdersMeta}
                    />
                </TabPanel>
                <TabPanel className={'generic-tab-panel'} value={value} index={3} dir={theme.direction}>
                    <OrdersTab
                        status={'Routed'}
                        response={response}
                        data={data}
                        setData={setData}
                        filter={filter}
                        datesFilter={datesFilter}
                        onDateChange={onDateChange}
                        onChange={onChange}
                        setOrdersMeta={setOrdersMeta}
                    />
                </TabPanel>
                <TabPanel className={'generic-tab-panel'} value={value} index={4} dir={theme.direction}>
                    <OrdersTab
                        status={'ProviderAssigned'}
                        response={response}
                        data={data}
                        setData={setData}
                        filter={filter}
                        datesFilter={datesFilter}
                        onDateChange={onDateChange}
                        onChange={onChange}
                        setOrdersMeta={setOrdersMeta}
                    />
                </TabPanel>
                <TabPanel className={'generic-tab-panel'} value={value} index={5} dir={theme.direction}>
                    <OrdersTab
                        status={'ProviderOnTheWay'}
                        response={response}
                        data={data}
                        setData={setData}
                        filter={filter}
                        datesFilter={datesFilter}
                        onDateChange={onDateChange}
                        onChange={onChange}
                        setOrdersMeta={setOrdersMeta}
                    />
                </TabPanel>
                <TabPanel className={'generic-tab-panel'} value={value} index={6} dir={theme.direction}>
                    <OrdersTab
                        status={'ProviderArrived'}
                        response={response}
                        data={data}
                        setData={setData}
                        filter={filter}
                        datesFilter={datesFilter}
                        onDateChange={onDateChange}
                        onChange={onChange}
                        setOrdersMeta={setOrdersMeta}
                    />
                </TabPanel>
                <TabPanel className={'generic-tab-panel'} value={value} index={7} dir={theme.direction}>
                    <OrdersTab
                        status={'ProviderOTPSent'}
                        response={response}
                        data={data}
                        setData={setData}
                        filter={filter}
                        datesFilter={datesFilter}
                        onDateChange={onDateChange}
                        onChange={onChange}
                        setOrdersMeta={setOrdersMeta}
                    />
                </TabPanel>
                <TabPanel className={'generic-tab-panel'} value={value} index={8} dir={theme.direction}>
                    <OrdersTab
                        status={'ProviderOTPConfirmed'}
                        response={response}
                        data={data}
                        setData={setData}
                        filter={filter}
                        datesFilter={datesFilter}
                        onDateChange={onDateChange}
                        onChange={onChange}
                        setOrdersMeta={setOrdersMeta}
                    />
                </TabPanel>

                <TabPanel className={'generic-tab-panel'} value={value} index={9} dir={theme.direction}>
                    <OrdersTab
                        status={'InProgress'}
                        response={response}
                        data={data}
                        setData={setData}
                        filter={filter}
                        datesFilter={datesFilter}
                        onDateChange={onDateChange}
                        onChange={onChange}
                        setOrdersMeta={setOrdersMeta}
                    />
                </TabPanel>

                <TabPanel className={'generic-tab-panel'} value={value} index={10} dir={theme.direction}>
                    <OrdersTab
                        status={'AdditionalCharges'}
                        response={response}
                        data={data}
                        setData={setData}
                        filter={filter}
                        datesFilter={datesFilter}
                        onDateChange={onDateChange}
                        onChange={onChange}
                        setOrdersMeta={setOrdersMeta}
                    />
                </TabPanel>


            </SwipeableViews>

        </div>
    );
}

export default DashboardOrders;