import axios from "axios";
import {ORDERS, LOADING} from "../actionTypes";
import {Who_Obj, AWS_CART_8000} from "configurations";
import {adminToken} from "../../helper";

const {SET_TRUE, SET_FALSE} = LOADING;

const {GET} = ORDERS;

export const get = id => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken()
        axios
            .get(
                `https://cgapp.clykk.com/cart/api/v2/int/cart-management/cart?orderId=${id}`,
                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            )
            .then(({data}) => {
                console.log('ran this')
                dispatch({type: GET, payload: data});
            })
            .catch(err => {
                console.log(err);
                dispatch({type: GET, payload: []});
            })
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};
