import React, {useEffect, useState} from "react";
import styles from "./styles.module.scss";
import {
    Table,
    TextInput,
    SearchButton,
    LightTextButton,
    Icon,
    SwitchButton
} from "components";
import {useDispatch, useSelector} from "react-redux";
import {rolesAction} from "redux/actions";
import RolesPopup from "../popups/Roles/RolesPopup";
import {MdEdit} from "react-icons/md";

const tableHeaders = [
    "ID",
    {title: "Role Name", sortBy: "roleName"},
    "Created At",
    "Created By",
    "Updated At",
    "Updated By",
    "Enabled",
    "Edit"
    // "Delete"
];

export default function RolesPanel() {
    // Disptach redux action to get roles
    const dispatch = useDispatch();
    const {roles, userRole} = useSelector(state => ({
        roles: state.roles,
        userRole: state.userRole?.filter(role => role.module === "Users")
    }));

    // Component states
    const [tableRows, setTableRows] = useState();
    const [name, setName] = useState("");
    const [editableDetails, setEditableDetails] = useState();

    // Effects
    useEffect(() => {
        if (userRole?.find(item => item.privilege === "Read"))
            dispatch(rolesAction.get());
    }, [dispatch]);

    useEffect(() => {
        let filtered = roles;

        if (!filtered?.length) {
            setTableRows();
            return;
        }

        filtered = filtered.filter(
            item => item?.role_name?.toLowerCase().indexOf(name?.toLowerCase()) !== -1
        );

        // Custom Functions
        const data = filtered?.map(obj => ({
            roleId: `${obj?.role_id}`,
            roleName: `${obj?.role_name}`,
            createdAt: `${new Date(obj?.created_at).toLocaleString()}`,
            createdBy: obj?.created_by,
            updatedAt: `${new Date(obj?.updated_at).toLocaleString()}`,
            updatedBy: obj?.updated_by,
            enabled: (
                <SwitchButton
                    enabled={obj?.enabled}
                    onClick={
                        userRole?.find(item => item.privilege === "Enable")
                            ? () =>
                                dispatch(
                                    obj?.enabled
                                        ? rolesAction.inactive(obj)
                                        : rolesAction.active(obj)
                                )
                            : null
                    }
                />
            ),
            edit: (
                <Icon
                    ReactIcon={MdEdit}
                    name="MdEdit"
                    color="primary"
                    onClick={() =>
                        userRole?.find(item => item.privilege === "Update")
                            ? setEditableDetails(obj)
                            : null
                    }
                />
            )
            // delete: (
            //   <Icon
            //     color="danger"
            //     onClick={() => dispatch(rolesAction.remove(obj))}
            //   />
            // )
        }));

        setTableRows(data);
    }, [roles, name, dispatch]);

    // handle input change
    const handleChange = setter => event => {
        setter(event.target.value);
    };

    return (
        <div>
            <div className={styles.filter}>
                <TextInput
                    label="Role Name"
                    name="role_name"
                    value={name}
                    onChange={handleChange(setName)}
                />
                <SearchButton/>
                <LightTextButton onClick={() => setName("")}/>
            </div>

            <Table headers={tableHeaders} data={tableRows}/>

            {editableDetails && (
                <RolesPopup
                    data={editableDetails}
                    closeModal={() => setEditableDetails()}
                />
            )}
        </div>
    );
}
