import { SUBS_DETAIL } from "../actionTypes";

export default function subsDetailReducer(state = null, action) {
  const { GET, RESET } = SUBS_DETAIL;

  switch (action.type) {
    case GET:
      return action.payload;

    case RESET:
      return null;
    default:
      return state;
  }
}
