import React, { useState, useEffect } from "react";
import { SideModal, Tabs } from "components";
import { modalTabRoutes } from "../tab-routes";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const CustomerSideModal = () => {
  // Routers
  const { id } = useParams();

  const customers = useSelector(state => state.customers?.users);
  const [titles, setTitles] = useState();

  useEffect(() => {
    const customer = customers?.find(item => item?.userId == id);

    const titleArr = [
      { key: "Name", value: customer?.firstName + " " + customer?.lastName },
      { key: "Mobile", value: customer?.mobile }
    ];
    setTitles(titleArr);
  }, [id]);

  return (
    <SideModal title="Customer ID" titles={titles?.length ? [...titles] : []}>
      <Tabs routes={modalTabRoutes} />
    </SideModal>
  );
};

export default CustomerSideModal;
