import axios from "axios";

import { GET_CART, RESET_CART, LOADING } from "../actionTypes";
import { AWS_CART_8000 } from "configurations";
import { adminToken } from "helper";
import { snackAction } from "redux/actions";

const { SET_TRUE, SET_FALSE } = LOADING;

export const get = id => {
  return async dispatch => {
    dispatch({ type: SET_TRUE });

    adminToken()
      .then(token =>
        axios.get(`${AWS_CART_8000}/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
      )
      .then(res => {
        dispatch({ type: GET_CART, payload: res.data });
      })
      .catch(err => dispatch(snackAction.add(err)))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const reset = () => dispatch => dispatch({ type: RESET_CART });
