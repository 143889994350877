import React from "react";
import BeatLoader from "react-spinners/BeatLoader";
import styles from "./Loader.module.scss";

export default function Loader({ size }) {
  return (
    <div className={styles.container}>
      <BeatLoader color="#dddddd" size={size} />
    </div>
  );
}
