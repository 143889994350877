import axios from "axios";

import {
  GET_CUSTOMERS,
  TOGGLE_CUSTOMERS,
  RESET_CUSTOMERS,
  LOADING
} from "../actionTypes";
import { AWS_URL_UM_INT_8089 } from "configurations";
import { adminToken } from "../../helper";

const { SET_TRUE, SET_FALSE } = LOADING;

export const get = (pageNumber, pageSize) => {
  return dispatch => {
    dispatch({ type: SET_TRUE });

    adminToken()
      .then(token =>
        axios.get(
          `${AWS_URL_UM_INT_8089}/get-user-list?pageNumber=${pageNumber}&pageSize=${
            pageSize || 10
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
      )
      .then(res => {
        dispatch({ type: GET_CUSTOMERS, payload: res.data.data });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const search = ({ pageNumber, query }) => {
  return dispatch => {
    dispatch({ type: SET_TRUE });

    adminToken()
      .then(token =>
        axios.get(
          `${AWS_URL_UM_INT_8089}/get-user-list?pageNumber=${pageNumber}&pageSize={10}&q=${query}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
      )
      .then(res => {
        dispatch({ type: GET_CUSTOMERS, payload: res.data.data });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const toggle = id => {
  return async dispatch => {
    dispatch({ type: SET_TRUE });
    let token = await adminToken();
    axios
      .patch(
        `${AWS_URL_UM_INT_8089}/user/${id}/toggle`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(res => {
        dispatch({ type: TOGGLE_CUSTOMERS, payload: res.data.data });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const reset = () => dispatch => dispatch({ type: RESET_CUSTOMERS });
