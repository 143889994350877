import OrganizationPanel from "./panels/OrganizationPanel";
import SubOrganizationPanel from "./panels/SubOrganizationPanel";
import OrgDocumentsPanel from "./panels/OrgDocumentsPanel";
import SubOrgDocumentsPanel from "./panels/SubOrgDocumentsPanel";
import OrgProfile from "./panels/OrgProfile";
import SubOrgProfile from "./panels/SubOrgProfile";
import OrgMemberStatus from "./panels/OrgMemberStatus";
import SubOrgMemberStatus from "./panels/SubOrgMemberStatus";
import SPProfile from "./panels/SPProfile";
import OrgModal from "./modals/OrgModal";
import SubOrgModal from "./modals/SubOrgModal";
import Dummy from "./panels/Dummy";

const tabRoutes = [
    {
        name: "Organization",
        path: "/organization",
        component: <OrganizationPanel/>,
        modalRoutes: [
            {
                name: "",
                path: "/organization/organization/:id",
                component: <OrgModal/>,
            },
        ],
    },
    {
        name: "Sub Organization",
        path: "/sub-organization",
        component: <SubOrganizationPanel/>,
        modalRoutes: [
            {
                name: "",
                path: "/organization/sub-organization/:id",
                component: <SubOrgModal/>,
            },
        ],
    },
];

export default tabRoutes;

export const orgModalRoutes = [
    {
        name: "Organization Profile",
        path: "/profile",
        component: <OrgProfile/>,
    },
    {
        name: "Address",
        path: "/address",
        component: <Dummy/>,
    },
    {
        name: "Owner",
        path: "/owner",
        component: <SPProfile/>,
    },
    {
        name: "Manager",
        path: "/manager",
        component: <SPProfile/>,
    },
    {
        name: "Member & Status",
        path: "/member-status",
        component: <OrgMemberStatus/>,
    },
    {
        name: "Documents",
        path: "/documents",
        component: <OrgDocumentsPanel/>,
    },
];

export const subOrgModalRoutes = [
    {
        name: "Sub Organization Profile",
        path: "/profile",
        component: <SubOrgProfile/>,
    },
    {
        name: "Address",
        path: "/address",
        component: <Dummy/>,
    },
    {
        name: "Owner",
        path: "/owner",
        component: <SPProfile/>,
    },
    {
        name: "Manager",
        path: "/manager",
        component: <SPProfile/>,
    },
    {
        name: "Member & Status",
        path: "/member-status",
        component: <SubOrgMemberStatus/>,
    },
    {
        name: "Documents",
        path: "/documents",
        component: <SubOrgDocumentsPanel/>,
    },
];
