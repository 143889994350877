import { GET_C_SUBSCRIPTIONS, RESET_C_SUBSCRIPTIONS } from "../actionTypes";

const cSubscriptionReducer = (state = [], action) => {
  switch (action.type) {
    case GET_C_SUBSCRIPTIONS:
      return action.payload;

    case RESET_C_SUBSCRIPTIONS:
      return [];

    default:
      return state;
  }
};

export default cSubscriptionReducer;
