import Orders from "./panels/Orders";
import Requirements from "./panels/Requirements";
import Checklist from "./panels/Checklist";
import Details from "./panels/Details";
import Subscription from "./panels/Subscription";
import BookingDetails from "./panels/BookingDetails";
import SideModal from "./modals/SideModal";

const tabRoutes = [
    {
        name: "Orders",
        path: "/orders",
        component: <Orders/>,
        modalRoutes: [
            {
                name: "",
                path: "/orders/orders/:id",
                component: <SideModal/>
            }
        ]
    }
];

export default tabRoutes;

export const modalTabRoutes = [
    {
        name: "Booking Details",
        path: "/booking-details",
        component: <BookingDetails/>
    },
    {
        name: "Subscriptions",
        path: "/subscriptions",
        component: <Subscription/>
    },
    {
        name: "Package Details",
        path: "/package-details",
        component: <Details/>
    },
    {
        name: "Customer Requirements",
        path: "/customer-requirements",
        component: <Requirements/>
    },
    {
        name: "SP Checklist",
        path: "/sp-checklist",
        component: <Checklist/>
    }
];
