import axios from "axios";
import {
    GET_FAQ,
    TOGGLE_FAQ,
    UPDATE_FAQ,
    ADD_FAQ,
    RESET_FAQ,
    LOADING
} from "../actionTypes";
import {AWS_FAQ_7070, BASE_URL_FAQ} from "configurations";
import {adminToken} from "helper";
import uploadImage from "helper/uploadImage";

const {SET_TRUE, SET_FALSE} = LOADING;

export const get = () => {
    return dispatch => {
        dispatch({type: SET_TRUE});
        adminToken()
            .then(token =>
                axios.get(`${AWS_FAQ_7070}/faq-areas`, {
                    headers: {Authorization: `Bearer ${token}`}
                })
            )
            .then(res => {
                dispatch({type: GET_FAQ, payload: res.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const add = (details = {}) => {
    const {name, displayName, description, displayOrder, image} = details;

    return async dispatch => {
        try {
            const imgUrl = await uploadImage(image, "faq-management");

            const token = await adminToken();

            const res = await axios.post(
                `${AWS_FAQ_7070}/faq-areas`,
                {
                    description,
                    displayName,
                    name,
                    displayOrder: parseInt(displayOrder),
                    image: imgUrl
                },
                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            );

            dispatch({type: ADD_FAQ, payload: res.data});
        } catch (err) {
            console.log(err);
        } finally {
            dispatch({type: SET_FALSE});
        }
    };
};

// Update
export const update = (details = {}) => {
    const {name, displayName, description, displayOrder, id, image} = details;

    return async dispatch => {
        try {
            dispatch({type: SET_TRUE});
            let imgUrl;

            // Request to Upload Image
            if (typeof packageImage === "object")
                imgUrl = await uploadImage(image, "faq-management");

            const token = await adminToken();

            const finalRes = await axios.put(
                `${BASE_URL_FAQ}/faq-areas/${id}`,
                {
                    description,
                    displayName,
                    name,
                    displayOrder: parseInt(displayOrder),
                    image: imgUrl || image
                },
                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            );

            dispatch({type: UPDATE_FAQ, payload: finalRes.data});
        } catch (err) {
            console.log(err);
        } finally {
            dispatch({type: SET_FALSE});
        }
    };
};

export const toggle = id => {
    return dispatch => {
        dispatch({type: SET_TRUE});

        adminToken()
            .then(token =>
                axios.patch(
                    `${AWS_FAQ_7070}/faq-areas/${id}`,
                    {},
                    {headers: {Authorization: `Bearer ${token}`}}
                )
            )
            .then(res => dispatch({type: TOGGLE_FAQ, payload: res.data}))
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const reset = () => dispatch => dispatch({type: RESET_FAQ});
