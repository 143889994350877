import React, {useEffect, useState, useMemo} from "react";
import styles from "./styles.module.scss";
import {
    Table,
    TextInput,
    SearchButton,
    LightTextButton,
    FilterDropdown,
    SwitchButton,
    ImageStamp,
    Icon
} from "components";
import {useDispatch, useSelector} from "react-redux";
import {faqAreasAction, countryAction} from "redux/actions";
import FAQAreasPopup from "../popups/FAQAreas/FAQAreasPopup";
import {MdEdit} from "react-icons/md";

const FAQAreas = () => {
    // Disptach redux action to get faq areas
    const dispatch = useDispatch();
    const {faqAreas, userRole} = useSelector(state => ({
        faqAreas: state.faqAreas,
        userRole: state.userRole?.filter(role => role.module === "FAQ")
    }));

    // Component States
    const [tableRows, setTableRows] = useState();
    const [editableDetail, setEditableDetails] = useState();
    const [isPopupVisible, setPopupVisible] = useState();

    // Filter States
    const [status, setStatus] = useState();
    const [faqName, setFaqName] = useState();

    // Initially get the faq
    useEffect(() => {
        if (userRole?.find(item => item.privilege === "Read")) {
            dispatch(faqAreasAction.get());
            dispatch(countryAction.get());
        }

        return () => dispatch(faqAreasAction.reset());
    }, [dispatch]);

    useEffect(() => {
        let filtered = faqAreas;

        // filter based on Status
        filtered = filtered?.filter(item =>
            status === "Enabled"
                ? item.enabled
                : status === "Disabled"
                    ? !item.enabled
                    : item
        );

        // filter based on faq area name
        if (faqName) {
            filtered = filtered.filter(
                item => item.name.toLowerCase().indexOf(faqName?.toLowerCase()) !== -1
            );
        }

        // Custom Functions
        const data = filtered.map(item => ({
            id: `${item.id}`,
            name: `${item.name}`,
            description: `${item.description}`,
            image: <ImageStamp src={item.image}/>,
            displayName: `${item.displayName}`,
            displayOrder: `${item.displayOrder}`,
            createdAt: `${new Date(item.createdAt).toLocaleString()}`,
            createdBy: item.createdBy,
            updatedAt: `${new Date(item.updatedAt).toLocaleString()}`,
            updatedBy: item.updatedBy,
            enabled: (
                <SwitchButton
                    enabled={item.enabled}
                    onClick={
                        userRole?.find(item => item.privilege === "Enable")
                            ? () => dispatch(faqAreasAction.toggle(item.id))
                            : null
                    }
                />
            ),
            edit: (
                <Icon
                    ReactIcon={MdEdit}
                    name="MdEdit"
                    color="primary"
                    onClick={
                        userRole?.find(item => item.privilege === "Update")
                            ? () => {
                                setEditableDetails(item);
                                setPopupVisible(true);
                            }
                            : null
                    }
                    className="clickable"
                />
            )
        }));

        setTableRows(data);
    }, [faqAreas, status, faqName]);

    // Table header
    const tableHeaders = useMemo(() => [
        {
            title: "ID"
        },
        {
            title: "FAQ Area",
            sortBy: "name"
        },
        {
            title: "Description"
        },
        {
            title: "Image"
        },
        {
            title: "Display Name"
        },
        {
            title: "Display Order",
            sortBy: "displayOrder"
        },
        {
            title: "Created At",
            sortBy: "createdAt"
        },
        {
            title: "Created By"
        },
        {
            title: "Updated At",
            sortBy: "updatedAt"
        },
        {
            title: "Updated By"
        },
        {
            title: "Enabled"
        },
        {
            title: "Edit"
        }
    ]);

    return (
        <div>
            <div className={styles.filter}>
                <TextInput
                    search
                    label="FAQ Area Name"
                    name="FAQ Area Name"
                    value={faqName}
                    onChange={e => setFaqName(e.target.value)}
                />
                <FilterDropdown
                    onChange={e => setStatus(e.target.value)}
                    label="Status"
                    options={["All", "Enabled", "Disabled"]}
                />
                <SearchButton/>
                <LightTextButton onClick={() => setFaqName("")}/>
            </div>
            <Table headers={tableHeaders} data={tableRows}/>

            {isPopupVisible && (
                <FAQAreasPopup
                    data={editableDetail}
                    closeModal={() => setPopupVisible(false)}
                />
            )}
        </div>
    );
};

export default FAQAreas;
