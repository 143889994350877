import axios from "axios";

import {
    GET_SP_APP_PREFERENCES,
    TOGGLE_SP_APP_PREFERENCES,
    TOGGLE_ANS_SP_APP_PREFERENCES,
    UPDATE_SP_APP_PREFERENCES,
    ADD_SP_APP_PREFERENCES,
    RESET_SP_APP_PREFERENCES,
    LOADING
} from "../actionTypes";
import {adminToken} from "../../helper";

const {SET_TRUE, SET_FALSE} = LOADING;
export const get = () => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .get(`https://cgapp.clykk.com/sp/clykk/int/sp/v1/user/preferences`, {
                headers: {Authorization: `Bearer ${token}`}
            })
            .then(res => {
                console.log("hello I am here")
                const data = Object.values(res.data.data);
                dispatch({type: GET_SP_APP_PREFERENCES, payload: data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const add = (details = {}) => {
    console.log(details);
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        console.log(token)
        axios
            .post(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/user/preferences`,
                details,
                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            )
            .then(res =>
                dispatch({type: ADD_SP_APP_PREFERENCES, payload: res.data.data})
            )
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

// Update Question
export const update = (details = {}) => {
    console.log(details);
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .put(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/user/preferences/${
                    details?.id
                }`,

                details,

                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            )
            .then(res => {
                dispatch({type: UPDATE_SP_APP_PREFERENCES, payload: res.data.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

// Update Answer
export const updateAnswer = (details = {}) => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .put(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/user/preferences/${
                    details?.pid
                }/option/${details.id}`,

                details,

                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            )
            .then(res => {
                dispatch({type: UPDATE_SP_APP_PREFERENCES, payload: res.data.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

// ADd Answer
export const addAnswer = (details = {}) => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .post(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/user/preferences/${
                    details?.pid
                }/option`,

                details,

                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            )
            .then(res => {
                dispatch({type: UPDATE_SP_APP_PREFERENCES, payload: res.data.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const toggle = id => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .patch(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/user/preferences/${id}`,
                {},
                {headers: {Authorization: `Bearer ${token}`}}
            )
            .then(res =>
                dispatch({type: TOGGLE_SP_APP_PREFERENCES, payload: res.data.data})
            )
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const toggleAns = (id, oid) => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .patch(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/user/preferences/${id}/option/${oid}`,
                {},
                {headers: {Authorization: `Bearer ${token}`}}
            )
            .then(res =>
                dispatch({
                    type: TOGGLE_ANS_SP_APP_PREFERENCES,
                    payload: res.data.data
                })
            )
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const reset = () => dispatch =>
    dispatch({type: RESET_SP_APP_PREFERENCES});
