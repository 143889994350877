import React from "react";
import styles from "./styles.module.scss";

const TextLink = ({text, url, onClick}) => {
    return (
        <p className={styles.link} onClick={onClick}>
            {text}
        </p>
    );
};

export default TextLink;
