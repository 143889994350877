import React, {useEffect, useMemo} from "react";
import {
    SwitchButton,
    TableVertical,
    DocumentButton,
    StatusButton
} from "components";
import {useHistory} from "react-router-dom";
import {serviceProviderAction, ownerAction} from "redux/actions";
import {useDispatch, useSelector} from "react-redux";

export default function OwnerProfile() {
    // Routers
    const history = useHistory();
    const path = history.location.pathname;
    const pathArr = path.split("/");
    const id = pathArr[3];
    const tab = pathArr[2];
    const role = pathArr[pathArr.length - 1];

    // Redux
    const dispatch = useDispatch();
    const org = useSelector(state =>
        tab === "organization"
            ? state?.org?.find(item => item.id == id)
            : state?.subOrg?.find(item => item.id == id)
    );
    const profile = useSelector(state => state?.owner);

    useEffect(() => {
        dispatch(ownerAction.get(role === "owner" ? org?.ownerID : org?.managerID));
    }, [org]);

    // Custom Functions
    const data = {
        firstName: profile?.firstName,
        lastName: `${profile?.lastName}`,
        email: `${profile?.email}`,
        emailVerified: `${profile?.emailVerified ? "Verified" : "Not Verified"}`,
        countryCode: `${profile?.countryCode}`,
        mobile: `${profile?.mobile}`,
        mobileVerified: `${profile?.mobileVerified ? "Verified" : "Not Verified"}`,
        notificationEnabled: (
            <SwitchButton enabled={profile?.notificationEnabled}/>
        ),
        dob: `${new Date(profile?.dob).toLocaleString()}`,
        anniversary: `${new Date(profile?.anniversary).toLocaleString()}`,
        lastLoginAt: `${new Date(profile?.lastLoginAt).toLocaleString()}`,
        deviceId: profile?.deviceId,
        deviceType: profile?.deviceType,
        deviceToken: profile?.deviceToken,
        referralCode: profile?.referralCode,
        picture: profile?.picture,
        orgID: profile?.orgID,
        subOrgID: profile?.subOrgID,
        location: profile?.location,
        rating: profile?.rating,
        status: (
            <StatusButton
                title={profile?.status}
                options={["onboarding", "approved", "rejected"]}
                onChange={status =>
                    dispatch(serviceProviderAction.changeStatus(profile.userId, status))
                }
            />
        ),
        documents: (
            <DocumentButton
                approved={
                    profile?.documents?.filter(doc => doc.status === "approved").length
                }
                uploaded={profile?.documents?.filter(doc => doc.documentURL).length}
                total={profile?.documents?.length}
                onClick={() =>
                    history.push(
                        `/${path.split("/", 2)[1]}/service-providers/${
                            profile?.userId
                        }/documents`
                    )
                }
            />
        ),
        enabled: (
            <SwitchButton
                enabled={profile?.enabled}
                onClick={() => dispatch(serviceProviderAction.toggle(profile.userId))}
            />
        )
    };

    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "First Name"
            },
            {
                title: "Last Name"
            },
            {
                title: "Email"
            },
            {
                title: "Email Verified"
            },
            {
                title: "Country Code"
            },
            {
                title: "Mobile"
            },
            {
                title: "Mobile Verified"
            },
            {
                title: "Notification Enabled"
            },
            {
                title: "DOB"
            },
            {
                title: "Anniversary"
            },
            {
                title: "Last Login At"
            },
            {
                title: "Device ID"
            },
            {
                title: "Device Type"
            },
            {
                title: "Device Token"
            },
            {
                title: "Referral Code"
            },
            {
                title: "Picture"
            },
            {
                title: "Organization"
            },
            {
                title: "Sub Organziation"
            },
            {
                title: "Location"
            },
            {
                title: "Rating"
            },
            {
                title: "Status"
            },
            {
                title: "Documents"
            },
            {
                title: "Enabled"
            }
        ],
        []
    );

    return (
        <div>
            <TableVertical
                heading="Profile Details"
                headers={tableHeaders}
                data={data}
            />
        </div>
    );
}
