import React from "react";
import styles from "./Button.module.scss";

const Button = ({ title, onClick, style }) => (
  <button
    className={styles.button}
    onClick={onClick}
    style={{
      cursor: typeof onClick === "function" ? "pointer" : "auto",
      ...style
    }}>
    {title}
  </button>
);

export default Button;
