import {
    GET_MODULES,
    ADD_MODULE,
    UPDATE_MODULE,
    DELETE_MODULE,
    RESET_MODULES,
    ACTIVE_MODULE,
    INACTIVE_MODULE,
    LOADING,
    ADD_SNACK
} from "../actionTypes";
import axios from "axios";
import Cookies from "js-cookie";

import {BASE_URL_NEW} from "configurations";

const {SET_TRUE, SET_FALSE} = LOADING;

export const get = () => {
    return dispatch => {
        dispatch({type: SET_TRUE});

        // const {token} = JSON.parse(Cookies.get("user"));
        const {token} = JSON.parse(localStorage.getItem("user"));



        axios
            .get(`${BASE_URL_NEW}/getallmodules`, {
                headers: {
                    Authorization: `bearer ${token}`
                }
            })
            .then(res => {
                dispatch({type: GET_MODULES, payload: res.data?.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const add = (details = {}) => {
    return dispatch => {
        dispatch({type: SET_TRUE});

        // const {token} = JSON.parse(Cookies.get("user"));
        const {token} = JSON.parse(localStorage.getItem("user"));

        axios
            .post(`${BASE_URL_NEW}/addmodule`, details, {
                headers: {
                    Authorization: `bearer ${token}`
                }
            })
            .then(res => {
                dispatch({type: ADD_MODULE, payload: res.data?.data});
                dispatch({
                    type: ADD_SNACK,
                    payload: `${res.data?.data?.module_name} has been created`
                });
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const update = details => {
    return dispatch => {
        dispatch({type: SET_TRUE});

        // const {token} = JSON.parse(Cookies.get("user"));
        const {token} = JSON.parse(localStorage.getItem("user"));

        axios
            .post(`${BASE_URL_NEW}/updatemodule/${details?.module_id}`, details, {
                headers: {
                    Authorization: `bearer ${token}`
                }
            })
            .then(res => {
                dispatch({type: UPDATE_MODULE, payload: res.data?.data});
                dispatch({
                    type: ADD_SNACK,
                    payload: `A module updated to ${details?.module_name}`
                });
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

// remove a specific module
export const remove = details => {
    return dispatch => {
        dispatch({type: SET_TRUE});

        // const {token} = JSON.parse(Cookies.get("user"));
        const {token} = JSON.parse(localStorage.getItem("user"));

        axios
            .get(`${BASE_URL_NEW}/deletemodule/${details?.module_id}`, {
                headers: {
                    Authorization: `bearer ${token}`
                }
            })
            .then(res => {
                dispatch({type: DELETE_MODULE, payload: res.data?.data});
                dispatch({
                    type: ADD_SNACK,
                    payload: `${details?.module_name} has been removed`
                });
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

// active a specific module
export const active = details => {
    return dispatch => {
        dispatch({type: SET_TRUE});

        // const {token} = JSON.parse(Cookies.get("user"));
        const {token} = JSON.parse(localStorage.getItem("user"));

        axios
            .get(`${BASE_URL_NEW}/activemodule/${details?.module_id}`, {
                headers: {
                    Authorization: `bearer ${token}`
                }
            })
            .then(res => {
                dispatch({type: ACTIVE_MODULE, payload: res.data?.users});
                dispatch({
                    type: ADD_SNACK,
                    payload: `${details?.module_name} has been activated`
                });
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

// inactive a specific module
export const inactive = details => {
    return dispatch => {
        dispatch({type: SET_TRUE});

        // const {token} = JSON.parse(Cookies.get("user"));
        const {token} = JSON.parse(localStorage.getItem("user"));

        axios
            .get(`${BASE_URL_NEW}/inactivemodule/${details?.module_id}`, {
                headers: {
                    Authorization: `bearer ${token}`
                }
            })
            .then(res => {
                dispatch({type: INACTIVE_MODULE, payload: res.data?.users});
                dispatch({
                    type: ADD_SNACK,
                    payload: `${details?.module_name} has been activated`
                });
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const reset = () => ({type: RESET_MODULES});
