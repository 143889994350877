import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import tabRoutes from "./tab-routes";
import { Tabs } from "components";
import SPPreferencePopup from "./popups/SPAppPreference/SPAppPreferencePopup";
import CPreferencePopup from "./popups/CAppPreference/PreferencePopup";
import { useSelector } from "react-redux";

const popups = [
  {
    title: "+ New SP Preference",
    path: "sp-app-preference",
    component: closeModal => <SPPreferencePopup closeModal={closeModal} />
  },
  {
    title: "+ New Preference",
    path: "app-preference",
    component: closeModal => <CPreferencePopup closeModal={closeModal} />
  }
];

const AppPreferences = () => {
  const location = useLocation();
  const currentLocation = location.pathname.split("/", 3)[2];

  // States
  const [modal, setModal] = useState(); // Popup open or close state

  const userRole = useSelector(state =>
    state.userRole?.filter(role => role.module === "App Preferences")
  );

  return (
    <>
      {/* Panels */}
      <Tabs
        routes={tabRoutes}
        title={popups.find(popup => currentLocation === popup.path)?.title}
        onClick={
          userRole?.find(item => item.privilege === "Create")
            ? () => setModal(currentLocation)
            : null
        }
      />

      {
        // Popups
        modal &&
          popups?.length &&
          popups.map(
            popup =>
              currentLocation === popup.path &&
              popup.component(() => setModal(false))
          )
      }
    </>
  );
};

export default AppPreferences;
