import {
    ADD_CITY,
    GET_CITY,
    TOGGLE_CITY,
    UPDATE_CITY,
    RESET_CITY,
} from "../actionTypes";

const cityReducer = (state = [], action) => {
    switch (action.type) {
        case GET_CITY:
            return action.payload;

        case ADD_CITY:
            return action.payload;

        case UPDATE_CITY:
            return state.map((item) =>
                item.id === action.payload.id ? action.payload : item
            );

        case TOGGLE_CITY:
            return state.map((item) =>
                item.id !== action.payload.id
                    ? item
                    : {...item, enabled: !item.enabled}
            );

        case RESET_CITY:
            return [];

        default:
            return state;
    }
};

export default cityReducer;
