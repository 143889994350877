import axios from "axios";
import {
  GET_LOCATION,
  TOGGLE_LOCATION,
  RESET_LOCATION,
  SET_ERROR,
  LOADING
} from "../actionTypes";
import { AWS_LOCATION_8888 } from "configurations";
import { adminToken } from "helper";

const { SET_TRUE, SET_FALSE } = LOADING;

export const get = (countryID, stateID, cityID) => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.get(
          `${AWS_LOCATION_8888}/country/${countryID}/state/${stateID}/city/${cityID}/location/detail`,
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        )
      )
      .then(res => {
        dispatch({ type: GET_LOCATION, payload: res.data });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const add = (countryID, stateID, cityID, name, pincode) => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.post(
          `${AWS_LOCATION_8888}/country/${countryID}/state/${stateID}/city/${cityID}/location`,
          {
            name,
            pincode: parseInt(pincode),
            enabled: true
          },
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        )
      )
      .then(res => {
        dispatch(get(countryID, stateID, cityID));
      })
      .catch(err =>
        dispatch({ type: SET_ERROR, payload: "Location already exist." })
      );
  };
};

export const toggle = id => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.patch(
          `${AWS_LOCATION_8888}/country/${id}`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
      )
      .then(res => dispatch({ type: TOGGLE_LOCATION, payload: res.data }))
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const remove = (countryID, stateID, cityID, id) => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.delete(
          `${AWS_LOCATION_8888}/country/${countryID}/state/${stateID}/city/${cityID}/location/${id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        )
      )
      .then(res => dispatch(get(countryID, stateID, cityID)))
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const reset = () => dispatch => dispatch({ type: RESET_LOCATION });
