import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import {
  Table,
  SearchButton,
  LightTextButton,
  FilterDropdown,
  SwitchButton,
  TextInput
} from "components";
import { cityAction } from "redux/actions";

const City = () => {
  // States
  const [tableRows, setTableRows] = useState();

  // Filter States
  const [status, setStatus] = useState();
  const [searchText, setSearchText] = useState();

  // Redux actions
  const dispatch = useDispatch();
  const { city, userRole } = useSelector(state => ({
    city: state.city,
    country: state.country,
    userRole: state.userRole?.filter(role => role.module === "Location")
  }));

  useEffect(() => {
    if (userRole?.find(item => item.privilege === "Read"))
      dispatch(cityAction.getAll());

    return () => {
      if (city?.length) dispatch(cityAction.reset());
    };
  }, []);

  useEffect(() => {
    if (!city?.length) {
      setTableRows();
      return;
    }

    let filtered = city;

    // filter based on Status
    filtered = filtered?.filter(item =>
      status === "Enabled"
        ? item.enabled
        : status === "Disabled"
        ? !item.enabled
        : item
    );

    // filter based on package name
    if (searchText) {
      filtered =
        filtered?.length &&
        filtered.filter(
          item =>
            item?.name.toLowerCase().indexOf(searchText?.toLowerCase()) !== -1
        );
    }

    // Map table rows
    const data = filtered?.map(item => ({
      id: item?.id,
      name: `${item?.name}`,
      deletedAt: `${
        item?.deletedAt && new Date(item.deletedAt).toLocaleString()
      }`,
      enabled: <SwitchButton enabled={item.enabled} />
    }));

    setTableRows(data);
  }, [city, status, searchText]);

  // Table Header
  const tableHeaders = useMemo(
    () => [
      {
        title: "ID"
      },
      {
        title: "City",
        sortBy: "name"
      },
      {
        title: "Deleted At"
      },
      {
        title: "Enabled"
      }
    ],
    [tableRows]
  );

  return (
    <div>
      <div className={styles.filter}>
        <TextInput
          search
          label="City"
          name="searchText"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
        <FilterDropdown
          onChange={e => setStatus(e.target.value)}
          label="Status"
          value={status}
          options={["All", "Enabled", "Disabled"]}
        />
        <SearchButton />
        <LightTextButton
          onClick={() => {
            setSearchText("");
            setStatus("");
          }}
        />
      </div>
      <Table headers={tableHeaders} data={tableRows} />
    </div>
  );
};

export default City;
