import { ROUTING } from "../actionTypes";

export default function routingReducer(state = null, action) {
  const { GET, ADD, UPDATE, RESET } = ROUTING;

  switch (action.type) {
    case GET:
      return action.payload;

    case ADD:
      return action.payload;

    case UPDATE:
      return action.payload;

    case RESET:
      return null;

    default:
      return state;
  }
}
