import React, {useEffect, useState} from 'react';
import ReactApexChart from "react-apexcharts";
import {useDispatch, useSelector} from "react-redux";
import {adminToken} from "../../helper";
import axios from "axios";
import {categoryAction} from "redux/actions";

function DashboardServiceProviderOverview({datesFilter, setDatesFilter}) {
    const dispatch = useDispatch();
    const [data, setData] = useState([])
    const [response, setResponse] = useState([])

    const {categories, userRole} = useSelector(state => ({
        categories: state.categories,
        userRole: state.userRole?.filter(role => role.module === "Category")
    }));

    useEffect(() => {
        if (userRole?.find(item => item.privilege === "Read"))
            dispatch(categoryAction.get());

        return dispatch(categoryAction.reset());
    }, [dispatch]);

    const [sums, setSums] = useState([])

    const onDateChange = (e) => {
        console.log(e.target.value)
        setDatesFilter(
            {...datesFilter, [e.target.name]: e.target.value}
        )
    }

    useEffect(() => {
        adminToken()
            .then(token =>
                axios.get(
                    `https://cgapp.clykk.com/sp/clykk/int/sp/v1/get-user-list?pageNumber=1&pageSize=100000000000`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                ).then(res => {
                    setResponse(res.data.users)
                    // setCustomers(response.data.users)
                })
            )
    }, [])

    useEffect(() => {
        let _dates = []
        let _data = []

        let data = response

        data = data.filter((user) => {
            return user.createdAt > datesFilter.end && user.createdAt < datesFilter.start
        })

        data.map(sp => {
            if (!_dates.includes(sp.createdAt.split('T')[0])) {
                _dates.push(sp.createdAt.split('T')[0])
            }
        })

        _dates.reverse().map((date, index) => {
            const service_providers = data.filter(item => item.createdAt.split('T')[0] === date)
            service_providers.map(service_provider => {
                const category = categories?.find(item => item.id == service_provider.categoryID)?.name
                if (_data.filter(item => item.name === category).length === 0 && category) {
                    _data.push({
                        name: category,
                        data: [...Array(index).fill(0), 1]
                    })
                } else {
                    _data.map(item => {
                        if (item.name === category) {
                            if (index !== item.data.length - 1) {
                                item.data = [...item.data, 1]
                            } else {
                                item.data[item.data.length - 1] = item.data[item.data.length - 1] + 1
                            }
                        }
                        return item
                    })
                }
            })
        })

        _data.map(item => {
            item.data = [...item.data, ...Array(_dates.length - item.data.length).fill(0)]
        })

        setData(_data)
        setLabels(_dates)

        let _sums = []

        _dates.map((item, index) => {
            let sum = 0
            _data.map(item => sum = sum + item.data[index])
            _sums.push(sum)
        })

        setSums(_sums)
    }, [datesFilter, response])

    const [labels, setLabels] = useState([])

    let state = {
        series: data,
        options: {
            grid: {
                show: false
            },
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        total: {
                            enabled: true,
                            offsetX: 0,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900
                            }
                        }
                    }
                },
            },
            xaxis: {
                categories: labels,
            },
            yaxis: {
                title: {
                    text: undefined
                },
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + "K"
                    }
                }
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
            }
        },
    };

    let state_line = {
        series: [{
            name: "sum of SP",
            data: sums,
            color: "#03f3a0"
        }],
        options: {
            grid: {
                show: false
            },
            chart: {
                width: '100%',
                type: 'area',
            },
            dataLabels: {
                enabled: false
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        total: {
                            enabled: true,
                            offsetX: 0,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900
                            }
                        }
                    }
                },
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                categories: labels,
            },
            yaxis: {
                labels: {
                    formatter: function (val) {
                        return val
                    }
                }
            },
            tooltip: {
                style: {
                    color: 'red'
                },
                x: {
                    format: 'dd/MM/yy'
                },
            }
        },
    }

    const return_sum = (index) => {
        let sum = 0
        data.map(item => sum = sum + item.data[index])
        return sum
    }

    const return_col_sums = (data) => {
        let sums = []

        data.map((item) => {
            let _sum = 0
            item.data.map((_item) => {
                _sum += _item
            })
            sums.push(_sum)
        })
        let _sum = 0
        sums.map(item => _sum += item)
        sums.push(_sum)

        return sums
    }

    const return_growth = (index) => {
        if (labels.length - 1 > index + 1) {
            let sum1 = 0
            let sum2 = 0
            data.map(item => sum1 = sum1 + item.data[index])
            data.map(item => sum2 = sum2 + item.data[index + 1])

            return (((sum1 - sum2) / sum2) * 100).toFixed(2)
        } else {
            return null
        }
    }

    return (
        !data ?
            <div style={{width: '100%', height: '100%', display: ' flex'}}>
                <div className="spinner-grow text-warning" role="status"
                     style={{margin: "auto", width: "5rem", height: "5rem"}}>
                </div>
            </div>
            :
            <>
                <div style={{height: 370, overflowY: "auto"}}>
                    <table className="table" style={{border: '1px solid #e2e2e2'}}>
                        <thead style={{position: "sticky", top: 0}}>
                        <tr style={{backgroundColor: '#eaeff0'}}>
                            <th scope="col" style={{
                                width: 150,
                                textAlign: "left",
                                fontSize: 13,
                                padding: 5,
                                position: "sticky",
                                top: "0"
                            }}>Date
                            </th>

                            {data.map(item => {
                                return (
                                    <th scope="col" style={{
                                        width: 150,
                                        textAlign: "left",
                                fontSize: 13,
                                        padding: 5,
                                        position: "sticky",
                                        top: "0"
                                    }}>
                                        {item.name}
                                    </th>
                                )
                            })}
                            <th scope="col" style={{
                                width: 150,
                                textAlign: "left",
                                fontSize: 13,
                                padding: 5,
                                position: "sticky",
                                top: "0"
                            }}>Sum
                            </th>
                            <th scope="col" style={{
                                width: 150,
                                textAlign: "left",
                                fontSize: 13,
                                padding: 5,
                                position: "sticky",
                                top: "0"
                            }}>Growth
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{padding: 5, border: "none"}}>Sum</td>
                            {return_col_sums(data).map((item) => {
                                return (
                                    <td style={{padding: 5, border: "none"}}>
                                        {item}
                                    </td>
                                )
                            })}
                            <td style={{padding: 5, border: "none"}}></td>
                        </tr>
                        {labels.map((item, index) => {
                            return (
                                <>
                                    <tr>
                                        <td style={{fontWeight: 600, padding: 5, border: "none"}}>
                                            {labels[index]}
                                        </td>
                                        {data.map(item => {
                                            return (
                                                <td style={{padding: 5, border: "none"}}>
                                                    {item.data[index]}
                                                </td>
                                            )
                                        })}
                                        <td style={{padding: 5, border: "none"}}>
                                            {return_sum(index)}
                                        </td>
                                        <td style={{padding: 5, border: "none"}}>
                                            {return_growth(index)} %
                                        </td>
                                    </tr>
                                </>
                            )
                        })}
                        </tbody>


                    </table>
                </div>

                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                        <ReactApexChart options={state_line.options} series={state_line.series} type="area" height={300}/>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                        <div style={{height: 300, overflowY: "auto"}}>
                            <ReactApexChart options={state.options} series={state.series} type="bar" height={400}/>
                        </div>
                    </div>
                </div>
            </>
    );
}

export default DashboardServiceProviderOverview;