import React, { useState, useEffect } from "react";
import { TextInput, Modal } from "components";
import { useDispatch } from "react-redux";
import { cAppPreferencesAction } from "redux/actions";

export default function UpdateQuestion({ closeModal, data }) {
  const [details, setDetails] = useState({
    categoryName: "",
    categoryHelpText: "",
    categoryHelpMessage: "",
    displayOrder: ""
  });

  const dispatch = useDispatch();

  // If data exist (to edit)
  useEffect(() => {
    if (data)
      setDetails({
        id: data.id,
        categoryName: data.categoryName,
        categoryHelpText: data.categoryHelpText,
        categoryHelpMessage: data.categoryHelpMessage,
        displayOrder: data.displayOrder
      });
  }, [data]);

  const handleChange = e => {
    setDetails({
      ...details,
      [e.target.name]:
        isNaN(e.target.value) || !e.target.value
          ? e.target.value
          : parseInt(e.target.value)
    });
  };

  const onSave = () => {
    closeModal();
    if (data) return dispatch(cAppPreferencesAction.update(details));
    dispatch(cAppPreferencesAction.add(details));
  };

  return (
    <Modal
      title={data ? "Edit Questions" : "Create Questions"}
      closeModal={closeModal}
      onSave={onSave}>
      <TextInput
        label="Category Name"
        placeholder="Category Name"
        name="categoryName"
        onChange={handleChange}
        value={details?.categoryName}
      />
      <TextInput
        label="Category Help Text"
        placeholder="Category Help Text"
        name="categoryHelpText"
        onChange={handleChange}
        value={details?.categoryHelpText}
      />
      <TextInput
        label="Category Help Message"
        placeholder="Category Help Message"
        name="categoryHelpMessage"
        onChange={handleChange}
        value={details?.categoryHelpMessage}
      />
      <TextInput
        label="Display Order"
        placeholder="Display Order"
        name="displayOrder"
        onChange={handleChange}
        value={details?.displayOrder}
      />
    </Modal>
  );
}
