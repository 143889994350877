import axios from "axios";
import { SUBSCRIPTION_ROUTING, LOADING } from "../actionTypes";
import { AWS_ROUTING } from "configurations";
import { adminToken } from "helper";
import {manualRoutingAction, subscriptionRoutingAction} from "redux/actions";

const { SET_TRUE, SET_FALSE } = LOADING;

const { GET } = SUBSCRIPTION_ROUTING;


export const get = id => {
  return async dispatch => {
    dispatch({ type: SET_TRUE });

    const token = await adminToken();

    axios
      .get(`${AWS_ROUTING}/getroutingdetails?subscription_id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(({ data }) => {
        dispatch({ type: GET, payload: data?.data });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const post = ({ subscription_id, sp_ids }) => {
  return async dispatch => {
    dispatch({ type: SET_TRUE });

    const token = await adminToken();

    axios
      .post(
        `${AWS_ROUTING}/sendmanualrouting`,
        { subscription_id, sp_ids },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(res => {
        alert(res.data.message);
        dispatch(manualRoutingAction.get(subscription_id));
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};
