import {
  GET_ROLES,
  ADD_ROLE,
  DELETE_ROLE,
  UPDATE_ROLE,
  ACTIVE_ROLE,
  INACTIVE_ROLE,
  ADD_SNACK,
  LOADING
} from "../actionTypes";
import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL_NEW } from "configurations";

const { SET_TRUE, SET_FALSE } = LOADING;

// Get all roles
export const get = () => {
  return dispatch => {
    dispatch({ type: SET_TRUE });

    // const {token} = JSON.parse(Cookies.get("user"));
const {token} = JSON.parse(localStorage.getItem("user"));

    axios
      .get(`${BASE_URL_NEW}/getallrole`, {
        headers: {
          Authorization: `bearer ${token}`
        }
      })
      .then(res => dispatch({ type: GET_ROLES, payload: res.data?.data }))
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

// add a new role
export const add = details => {
  return dispatch => {
    dispatch({ type: SET_TRUE });

    // const {token} = JSON.parse(Cookies.get("user"));
const {token} = JSON.parse(localStorage.getItem("user"));

    axios
      .post(`${BASE_URL_NEW}/addrole`, details, {
        headers: {
          Authorization: `bearer ${token}`
        }
      })
      .then(res => {
        dispatch({ type: ADD_ROLE, payload: res.data?.data });
        dispatch({
          type: ADD_SNACK,
          payload: `${res.data?.data?.role_name} has been created`
        });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

// update a specific role
export const update = details => {
  return dispatch => {
    dispatch({ type: SET_TRUE });

    // const {token} = JSON.parse(Cookies.get("user"));
const {token} = JSON.parse(localStorage.getItem("user"));

    axios
      .post(`${BASE_URL_NEW}/updaterole/${details?.role_id}`, details, {
        headers: {
          Authorization: `bearer ${token}`
        }
      })
      .then(res => {
        dispatch({ type: UPDATE_ROLE, payload: res.data?.data });
        dispatch({
          type: ADD_SNACK,
          payload: `A role updated to ${details?.role_name}`
        });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

// remove a specific role
export const remove = details => {
  return dispatch => {
    dispatch({ type: SET_TRUE });

    // const {token} = JSON.parse(Cookies.get("user"));
const {token} = JSON.parse(localStorage.getItem("user"));

    axios
      .get(`${BASE_URL_NEW}/deleterole/${details?.role_id}`, {
        headers: {
          Authorization: `bearer ${token}`
        }
      })
      .then(res => {
        dispatch({ type: DELETE_ROLE, payload: res.data?.data });
        dispatch({
          type: ADD_SNACK,
          payload: `${details?.role_name} has been removed`
        });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

// active a specific role
export const active = details => {
  return dispatch => {
    dispatch({ type: SET_TRUE });

    // const {token} = JSON.parse(Cookies.get("user"));
const {token} = JSON.parse(localStorage.getItem("user"));

    axios
      .get(`${BASE_URL_NEW}/activerole/${details?.role_id}`, {
        headers: {
          Authorization: `bearer ${token}`
        }
      })
      .then(res => {
        dispatch({ type: ACTIVE_ROLE, payload: res.data?.users });
        dispatch({
          type: ADD_SNACK,
          payload: `${details?.role_name} has been activated`
        });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

// inactive a specific role
export const inactive = details => {
  return dispatch => {
    dispatch({ type: SET_TRUE });

    // const {token} = JSON.parse(Cookies.get("user"));
const {token} = JSON.parse(localStorage.getItem("user"));

    axios
      .get(`${BASE_URL_NEW}/inactiverole/${details?.role_id}`, {
        headers: {
          Authorization: `bearer ${token}`
        }
      })
      .then(res => {
        dispatch({ type: INACTIVE_ROLE, payload: res.data?.users });
        dispatch({
          type: ADD_SNACK,
          payload: `${details?.role_name} has been deactivated`
        });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};
