import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import {
  Table,
  SearchButton,
  LightTextButton,
  FilterDropdown,
  SwitchButton,
  TextInput
} from "components";
import {
  countryAction,
  stateAction,
  cityAction,
  locationAction
} from "redux/actions";

const Location = () => {
  // States
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [tableRows, setTableRows] = useState();

  // Filter States
  const [status, setStatus] = useState();
  const [searchText, setSearchText] = useState();

  // Redux actions
  const dispatch = useDispatch();

  const { country, state, city, location, userRole } = useSelector(state => ({
    country: state.country,
    state: state.state,
    city: state.city,
    location: state.location,
    userRole: state.userRole?.filter(role => role.module === "Location")
  }));

  const filteredCity = city?.map(item => item.name) || [];
  const filteredCountry = country?.map(item => item.name) || [];
  const filteredState = state?.map(item => item.name) || [];

  useEffect(() => {
    if (userRole?.find(item => item.privilege === "Read"))
      dispatch(countryAction.get());

    return () => {
      if (country?.length) dispatch(countryAction.reset());
      if (state?.length) dispatch(stateAction.reset());
      if (city?.length) dispatch(cityAction.reset());
      if (location?.length) dispatch(locationAction.reset());
    };
  }, []);

  useEffect(() => {
    if (!location?.length) {
      setTableRows();
      return;
    }

    let filtered = location;

    // filter based on Status
    filtered = filtered?.filter(item =>
      status === "Enabled"
        ? item.enabled
        : status === "Disabled"
        ? !item.enabled
        : item
    );

    // get state name
    const stateName = state?.find(item => item.id == selectedState);

    // filter based on package name
    if (searchText) {
      filtered =
        filtered?.length &&
        filtered.filter(
          item =>
            item?.name.toLowerCase().indexOf(searchText?.toLowerCase()) !== -1
        );
    }

    // Map table rows
    const data = filtered?.map(item => ({
      id: item?.id,
      name: `${item?.name}`,
      state: stateName?.name,
      pincode: `${item?.pincode}`,
      lat: `${item?.lat}`,
      long: `${item?.long}`,
      deletedAt: `${
        item?.deletedAt && new Date(item.deletedAt).toLocaleString()
      }`,
      enabled: <SwitchButton enabled={item.enabled} />
    }));

    setTableRows(data);
  }, [location, status, searchText]);

  useEffect(() => {
    setSelectedState(0);
    setSelectedCity(0);
    if (selectedCountry === "Pick a country" || !selectedCountry) return;
    dispatch(stateAction.get(selectedCountry));
  }, [selectedCountry]);

  useEffect(() => {
    setSelectedCity(0);
    if (selectedState === "Pick a state" || !selectedState) return;
    dispatch(cityAction.get(selectedCountry, selectedState));
  }, [selectedState]);

  const onSearch = () => {
    if (selectedCity === "Pick a city" || !selectedCity) return;
    dispatch(locationAction.get(selectedCountry, selectedState, selectedCity));
  };

  // Table Header
  const tableHeaders = useMemo(
    () => [
      {
        title: "ID"
      },
      {
        title: "Location",
        sortBy: "name"
      },
      {
        title: "State"
      },
      {
        title: "Pin Code"
      },
      {
        title: "Latitude"
      },
      {
        title: "Longitude"
      },
      {
        title: "Deleted At"
      },
      {
        title: "Enabled"
      }
    ],
    [tableRows]
  );

  return (
    <div>
      <div className={styles.filter}>
        <FilterDropdown
          onChange={e => setSelectedCountry(e.target.value)}
          value={selectedCountry}
          label="Country"
          options={["Pick a country", ...filteredCountry]}
          values={country?.length && [0, ...country?.map(item => item.id)]}
        />
        <FilterDropdown
          onChange={e => setSelectedState(e.target.value)}
          value={selectedState}
          label="State"
          options={["Pick a state", ...filteredState]}
          values={state?.length && [0, ...state?.map(item => item.id)]}
        />
        <FilterDropdown
          onChange={e => setSelectedCity(e.target.value)}
          value={selectedCity}
          label="City"
          options={["Pick a city", ...filteredCity]}
          values={city?.length && [0, ...city?.map(item => item.id)]}
        />
        <TextInput
          search
          label="Location"
          name="location"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
        <FilterDropdown
          onChange={e => setStatus(e.target.value)}
          label="Status"
          value={status}
          options={["All", "Enabled", "Disabled"]}
        />
        <SearchButton
          onClick={
            userRole?.find(item => item.privilege === "Read") ? onSearch : null
          }
        />
        <LightTextButton
          onClick={() => {
            setSearchText("");
            setStatus("");
          }}
        />
      </div>
      <Table headers={tableHeaders} data={tableRows} />
    </div>
  );
};

export default Location;
