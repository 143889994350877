import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Table} from "components";
import {useHistory} from "react-router-dom";
import {spOrdersAction} from "redux/actions";

export default function Orders() {
    // Routers
    const history = useHistory();
    const path = history.location.pathname;
    const id = path.split("/")[3];

    // States
    const [tableRows, setTableRows] = useState();

    const dispatch = useDispatch();
    const spOrders = useSelector(state => state.spOrders);

    useEffect(() => {
        dispatch(spOrdersAction.get(id));

        return () => {
            spOrdersAction.reset();
        };
    }, []);

    useEffect(() => {
        let filtered = spOrders;
        if (!filtered?.length) {
            setTableRows();
            return;
        }

        // Map table rows
        filtered = filtered?.map(obj => ({
            id: obj?.id,
            user_id: obj?.user_id,
            cart_id: obj?.cart_id,
            subscription_id: obj?.subscription_id,
            sub_category_id: obj?.sub_category_id,
            routing_before: obj?.routing_before,
            status: obj?.status,
            no_of_route: obj?.no_of_route,
            assigned_provider_id: obj?.assigned_provider_id,
            commission_from: obj?.commission_from,
            commission_to: obj?.commission_to,
            accept_provider_id: obj?.accept_provider_id,
            type: obj?.type,
            createdAt: `${new Date(obj?.created_at).toLocaleString()}`,
            updatedAt: `${new Date(obj?.updated_at).toLocaleString()}`
        }));

        setTableRows(filtered);
    }, [spOrders]);

    // Table Header
    const tableHeaders = [
        {title: "Id"},
        {title: "User Id"},
        {title: "Cart Id"},
        {title: "Subscription Id"},
        {title: "Sub Category Id"},
        {title: "Routing Before"},
        {title: "Status"},
        {title: "No Of Route"},
        {title: "Assigned Provider Id"},
        {title: "Commission From"},
        {title: "Commission To"},
        {title: "Accept Provider Id"},
        {title: "Type"},
        {title: "Created At"},
        {title: "Updated At"}
    ];

    return (
        <div>
            <Table headers={tableHeaders} data={tableRows}/>
        </div>
    );
}
