import React, { useState, useEffect } from "react";
import { Modal, FilterDropdown } from "components";
import { useDispatch, useSelector } from "react-redux";
import { defaultPrivilegesAction } from "redux/actions";

export default function DefaultPrivilegePopup({ closeModal, data }) {
  const dispatch = useDispatch();
  const { roles, modules, privileges } = useSelector(state => state);

  const [details, setDetails] = useState({
    role: "",
    module: "",
    privilege: ""
  });

  console.log(details);
  useEffect(() => {
    setDetails(data);
  }, [data]);

  const onSave = () => {
    if (data) {
      dispatch(defaultPrivilegesAction.update(details));
    } else {
      dispatch(defaultPrivilegesAction.add(details));
    }
    closeModal();
  };

  const handleChange = setter => event =>
    setter(prev => ({
      ...prev,
      [event.target.name]: event.target.value
    }));

  return (
    <Modal
      title={data ? "Edit Privilege" : "Create New Privilege"}
      closeModal={closeModal}
      onSave={onSave}>
      <FilterDropdown
        label="Select Role"
        name="role"
        options={[
          "Select Role",
          ...(roles?.map(role => role?.role_name) || [])
        ]}
        values={[0, ...(roles?.map(role => role?.role_id) || [])]}
        onChange={handleChange(setDetails)}
        value={details?.role}
      />

      <FilterDropdown
        label="Select Module"
        name="module"
        options={[
          "Select Module",
          ...(modules?.map(module => module?.module_name) || [])
        ]}
        values={[0, ...(modules?.map(module => module?.module_id) || [])]}
        onChange={handleChange(setDetails)}
        value={details?.module}
      />

      <FilterDropdown
        label="Select Privilege"
        name="privilege"
        options={[
          "Select Privilege",
          ...(privileges?.map(privilege => privilege?.name) || [])
        ]}
        values={[
          0,
          ...(privileges?.map(privilege => privilege?.privilege_id) || [])
        ]}
        onChange={handleChange(setDetails)}
        value={details?.privilege}
      />
    </Modal>
  );
}
