import React from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {Invoice} from "components";

export default function BookingDetails() {
    // Routers
    const id = useHistory().location.pathname.split("/")[3];

    // Redux
    const order = useSelector(state =>
        state.orders?.find(item => item.cartID == id)
    );
    return (
        <div>
            <Invoice
                serviceAddress={order?.serviceAddress?.line2}
                deliveryAddress={order?.deliveryAddress?.line2}
                serviceDate={order?.schedule?.startDate}
                serviceTime={order?.schedule?.serviceTime}
                schedule={order?.schedule}
                items={order?.items}
            />
        </div>
    );
}
