import React from 'react';
import {useSelector} from "react-redux";
import {UnlimitedTable} from "../../../components";

function RequirementsTab({id}) {
    // Redux
    const order = useSelector(state =>
        state.orders
            ?.find(item => item.cartID == id)
            ?.qnA.filter(item => item.questionType === "requirements")
    );

    return (
        <div>
            {order?.length
                ? order.map((qna, index) => (
                    <UnlimitedTable
                        key={index}
                        question={qna.questionName}
                        answers={qna.answers}
                    />
                ))
                : null}
        </div>
    );
}

export default RequirementsTab;