import React, {useState, useEffect} from "react";
import {TextInput, FilterDropdown, Alert, Button} from "components";
import {useDispatch, useSelector} from "react-redux";
import {
    stateAction,
    cityAction,
    errorAction,
    subOrgAction
} from "redux/actions";
import styles from "../styles.module.scss";

export default function CategoryDetails({onClick, details, setDetails}) {
    const dispatch = useDispatch();
    const country = useSelector(state => state.country);
    const state = useSelector(state => state.state);
    const city = useSelector(state => state.city);
    const org = useSelector(state => state.org);
    const subOrg = useSelector(state => state.subOrg);

    // Get states based on country
    useEffect(() => {
        if (details?.country) dispatch(stateAction.get(details?.country));
        else {
            dispatch(stateAction.reset());
        }
    }, [details?.country]);

    // Get cities based on country & state
    useEffect(() => {
        if (details?.country && details?.state)
            dispatch(cityAction.get(details?.country, details?.state));
        else {
            dispatch(cityAction.reset());
        }
    }, [details?.state]);

    // Get sub-organizations base on organziation
    useEffect(() => {
        if (details?.orgID) dispatch(subOrgAction.get());
        else {
            dispatch(subOrgAction.reset());
        }
    }, [details?.orgID]);

    return (
        <div className={styles.main__container}>
            <Alert/>
            <TextInput
                label="Title"
                name="title"
                onChange={setDetails}
                value={details?.title}
                textarea={true}
            />
            <TextInput
                label="Description"
                name="description"
                onChange={setDetails}
                value={details?.description}
                textarea={true}
            />
            <FilterDropdown
                onChange={setDetails}
                value={details?.discount}
                label="Discount"
                name="discount"
                options={[
                    "Pick a discount",
                    "Deal",
                    "Offer",
                    "Coupon",
                    "Earned Credit"
                ]}
                values={[0, "deal", "offer", "coupon", "earnedCredit"]}
            />
            <FilterDropdown
                onChange={setDetails}
                value={details?.discountType}
                label="Discount Type"
                name="discountType"
                options={["Pick a discount type", "Percent", "Value"]}
                values={[0, "percent", "value"]}
            />
            <TextInput
                label="Min Discount"
                name="minDiscount"
                onChange={setDetails}
                value={details?.minDiscount}
                type="number"
            />
            <TextInput
                label="Max Discount"
                placeholder="Max Discount"
                name="maxDiscount"
                onChange={setDetails}
                value={details?.maxDiscount}
                type="number"
            />
            <TextInput
                label="Launch Date"
                placeholder="Launch Date"
                name="launchAt"
                type="date"
                onChange={setDetails}
                value={details?.launchAt?.split("T")[0]}
            />
            <TextInput
                label="Expire Date"
                placeholder="Expire Date"
                name="expireAt"
                type="date"
                onChange={setDetails}
                value={details?.expireAt?.split("T")[0]}
            />
            <FilterDropdown
                onChange={setDetails}
                value={details?.status}
                label="Status"
                name="status"
                options={["Pending", "Approved", "Expired", "Canceled"]}
                values={["pending", "approved", "expired", "canceled"]}
            />
            <div className={styles.btn__container}>
                <Button title="Next" onClick={onClick}/>
            </div>
        </div>
    );
}
