import {auth, firebaseApp} from "../../firebase";
import axios from "axios";
import {BASE_URL_NEW} from "../../configurations";
import {userAction} from "redux/actions";

export const sendCode = (phone, isAgree, setLoading, confirmation, setConfirmation, setRawPhone, setPhoneCard, intervals, setIntervals, setTimer, setPercent) => {
    if (!phone) return alert("Please add your phone number.");
    if (!isAgree)
        return alert("Please accept the terms of use and privacy policy.");

    setLoading(true);

    const recaptcha = new firebaseApp.auth.RecaptchaVerifier(
        "recaptcha__container",
        {
            size: "invisible"
        }
    );

    //user phone number
    const number = "+" + phone;

    // Send OTP to number using firebase
    auth
        .signInWithPhoneNumber(number, recaptcha)
        .then(confirmationResult => {
            console.log(confirmationResult);
            // save confirmation function
            setConfirmation(confirmationResult);
            setPhoneCard(false);
            countTick(intervals, setIntervals, setTimer, setPercent);
        })
        .catch(err => {
            alert(err.message);
        })
        .finally(_ => setLoading(false));
};

export const confirmCode = (setLoading, code, dispatch, confirmation, phone, countryCode, rawPhone, intervals) => {
    setLoading(true);

    if (!code) return;
    confirmation
        .confirm(code)
        .then(user =>
            axios.post(`${BASE_URL_NEW}/login`, {
                mobile: `+${phone}`
            })
        )
        .then(res =>
            dispatch(
                userAction.getProfile({
                    mobile: `+${phone}`,
                    password: res.data.otp,
                    countryCode,
                    rawPhone
                })
            )
        )
        .catch(err => {
            alert(err.message);
        })
        .finally(_ => {
            clearInterval(intervals);
            setLoading(false);
        });
};

// Count Down
const countTick = (intervals, setIntervals, setTimer, setPercent) => {
    clearInterval(intervals);

    let sec = 59;
    let min = 2;
    let timeout = (min + 1) * (sec + 1);

    setIntervals(setInterval(() => {
        if (sec < 0) {
            sec = 59;
            min--;
        }

        setTimer(`0${min}:${sec < 10 ? "0" : ""}${sec}`);
        setPercent(timeout);

        if (min === 0 && sec === 0) {
            clearInterval(intervals);
        }
        timeout--;
        sec--;
    }, 1000));
};