import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "../styles.module.scss";
import { TextInput, Button, FilterDropdown, CheckBox } from "components";
import { serviceRulesAction, subCategoriesAction } from "redux/actions";

export default function Details({ onClick, details, setDetails, edit }) {
  return (
    <div className={styles.main__container}>
      <TextInput
        label="Add On Name"
        placeholder="Add On Name"
        name="addonName"
        onChange={setDetails}
        value={details?.addonName}
      />
      <TextInput
        label="Add On Description"
        placeholder="Add On Description"
        name="addonDescription"
        onChange={setDetails}
        value={details?.addonDescription}
      />
      <TextInput
        label="Base Price"
        placeholder="Base Price"
        name="basePrice"
        onChange={setDetails}
        value={details?.basePrice}
      />
      <TextInput
        label="Selling Price"
        placeholder="Selling Price"
        name="sellingPrice"
        onChange={setDetails}
        value={details?.sellingPrice}
      />
      <TextInput
        label="Display Order"
        placeholder="Display Order"
        name="displayOrder"
        onChange={setDetails}
        value={details?.displayOrder}
      />
      <div className={styles.btn__container}>
        <Button title="Next" onClick={onClick} />
      </div>
    </div>
  );
}
