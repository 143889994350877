import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import tabRoutes from "./tab-routes";
import { Tabs } from "components";
import CountryPopup from "./popups/CountryPopup";
import StatePopup from "./popups/StatePopup";
import CityPopup from "./popups/CityPopup";
import LocationPopup from "./popups/LocationPopup";
import { useSelector } from "react-redux";

const popups = [
  {
    title: "+ New Country",
    path: "country",
    component: closeModal => <CountryPopup closeModal={closeModal} />
  },
  {
    title: "+ New State",
    path: "state",
    component: closeModal => <StatePopup closeModal={closeModal} />
  },
  {
    title: "+ New City",
    path: "city",
    component: closeModal => <CityPopup closeModal={closeModal} />
  },
  {
    title: "+ New Location",
    path: "location",
    component: closeModal => <LocationPopup closeModal={closeModal} />
  }
];

const FAQ = () => {
  const location = useLocation();
  const currentLocation = location.pathname.split("/", 3)[2];

  const userRole = useSelector(state =>
    state.userRole?.filter(role => role.module === "Category")
  );

  // States
  const [modal, setModal] = useState(); // Popup open or close state

  return (
    <>
      {/* Panels */}
      <Tabs
        routes={tabRoutes}
        title={popups.find(popup => currentLocation === popup.path)?.title}
        onClick={
          userRole?.find(item => item.privilege === "Create")
            ? () => setModal(currentLocation)
            : null
        }
      />

      {
        // Popups
        modal &&
          popups?.length &&
          popups.map(
            popup =>
              currentLocation === popup.path &&
              popup.component(() => setModal(false))
          )
      }
    </>
  );
};

export default FAQ;
