import React, {useState} from "react";
import {useLocation, Switch, Route} from "react-router-dom";
import tabRoutes from "./tab-routes";
import {Tabs} from "components";

const popups = [];

export default function Documents() {
    const location = useLocation();
    const currentLocation = location.pathname.split("/", 3)[2];

    // States
    const [modal, setModal] = useState(); // Popup open or close state

    return (
        <>
            {/* Panels */}
            <Tabs
                routes={tabRoutes}
                title={popups.find(popup => currentLocation === popup.path)?.title}
                onClick={() => setModal(currentLocation)}
            />

            {
                // Popups
                modal &&
                popups?.length &&
                popups.map(
                    popup =>
                        currentLocation === popup.path &&
                        popup.component(() => setModal(false))
                )
            }

            {/* Modals */}

            <Switch>
                {tabRoutes?.length &&
                    tabRoutes.map(route => {
                        if (route?.modalRoutes?.length)
                            return (
                                <Route path={`${route?.modalRoutes[0]?.path}`}>
                                    {route?.modalRoutes[0]?.component}
                                </Route>
                            );
                    })}
            </Switch>
        </>
    );
}
