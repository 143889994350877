import {adminToken} from "../../helper";
import axios from "axios";
import {LOADING, SP_Commision} from "../actionTypes";

const {
    GET
} = SP_Commision;
const {SET_TRUE, SET_FALSE} = LOADING;

export const get = () => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .get(
                `https://v2app.clykk.com/sp_payments/public/api/getsppayments`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(res => {
                dispatch({type: GET, payload: res.data.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
}
