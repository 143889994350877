export default function internalSearch(searchFrom, searchBy, searchIn) {
    if (!searchFrom?.[searchBy] || !searchIn?.length || !searchBy)
        return searchIn;

    // Check if the searching values are not a number
    if (
        isNaN(parseInt(searchIn?.[0]?.[searchBy])) &&
        isNaN(parseInt(searchFrom?.[searchBy]))
    ) {
        const filteredRows =
            searchIn?.length &&
            searchIn.filter(
                (item) =>
                    item[searchBy]?.toString()?.toLowerCase().includes(searchFrom?.[searchBy]?.toLowerCase())
            );

        return filteredRows;
    } else {
        const filteredRows =
            searchIn?.length &&
            searchIn.filter(
                (item) =>
                    item[searchBy]?.toString().indexOf(searchFrom?.[searchBy]) !== -1
            );

        return filteredRows;
    }
}
