import React, {useEffect, useState, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import styles from "./styles.module.scss";
import {
    Table,
    SearchButton,
    LightTextButton,
    FilterDropdown,
    SwitchButton,
    ImageStamp,
    TextLink,
    TextInput,
    Icon
} from "components";
import {faqAreasAction, faqScreensAction, faqInfoAction} from "redux/actions";
import FAQInfoPopup from "../popups/FAQInfo/FAQInfoPopup";
import {useHistory} from "react-router-dom";
import {MdEdit} from "react-icons/md";

const FAQInfo = () => {
    // Routers
    const history = useHistory();
    const currentLocation = history.location.pathname;

    // States
    const [selectedFaqArea, setSelectedFaqArea] = useState();
    const [selectedFaqScreen, setSelectedFaqScreen] = useState();
    const [tableRows, setTableRows] = useState();
    const [editableDetail, setEditableDetails] = useState();
    const [isPopupVisible, setPopupVisible] = useState();

    // Filter States
    const [status, setStatus] = useState();
    const [faqName, setFaqName] = useState();

    // Redux actions
    const dispatch = useDispatch();
    const {faqInfo, faqAreas, faqScreens, userRole} = useSelector(state => ({
        faqInfo: state.faqInfo,
        faqAreas: state.faqAreas,
        faqScreens: state.faqScreens,
        userRole: state.userRole?.filter(role => role.module === "FAQ")
    }));

    const filteredFaqAreas = faqAreas?.map(item => item.name) || [];

    const filteredFaqScreens = faqScreens?.map(item => item.name) || [];

    useEffect(() => {
        if (userRole?.find(item => item.privilege === "Read"))
            dispatch(faqAreasAction.get());

        return () => {
            dispatch(faqAreasAction.reset());

            if (faqScreens?.length) dispatch(faqScreensAction.reset());
            if (faqInfo?.length) dispatch(faqInfoAction.reset());
        };
    }, []);

    useEffect(() => {
        let filtered = faqInfo && [faqInfo];

        if (!filtered?.length) {
            setTableRows();
            return;
        }

        // filter based on Status
        filtered = filtered?.filter(item =>
            status === "Enabled"
                ? item.enabled
                : status === "Disabled"
                    ? !item.enabled
                    : item
        );

        // get sub category name by sub category Id
        const faqScreen = faqScreens?.find(
            item => item.id == filtered?.[0]?.faqScreenID
        );

        // filter based on faq name
        if (faqName) {
            filtered = filtered?.filter(
                item =>
                    item.faqName.toLowerCase().indexOf(faqName?.toLowerCase()) !== -1
            );
        }
        console.log(filtered);

        // Map table rows
        const data = filtered?.map(obj => ({
            faqName: (
                <TextLink
                    text={obj.faqName}
                    onClick={() => history.push(`${currentLocation}/${obj.id}`)}
                />
            ),
            faqScreen: `${faqScreen?.name}`,
            shortDescription: obj.shortDescription,
            faqImage: <ImageStamp src={obj.faqImage}/>,
            rating: obj.rating,
            likes: obj.likes,
            displayOrder: obj.displayOrder,
            createdAt: `${new Date(obj.createdAt).toLocaleString()}`,
            updatedAt: `${new Date(obj.updatedAt).toLocaleString()}`,
            enabled: (
                <SwitchButton
                    enabled={obj.enabled}
                    onClick={
                        userRole?.find(item => item.privilege === "Enable")
                            ? () => dispatch(faqInfoAction.toggle(obj.faqScreenID, obj.id))
                            : null
                    }
                />
            ),
            edit: (
                <Icon
                    ReactIcon={MdEdit}
                    name="MdEdit"
                    color="primary"
                    onClick={
                        userRole?.find(item => item.privilege === "Update")
                            ? () => {
                                setEditableDetails(obj);
                                setPopupVisible(true);
                            }
                            : null
                    }
                    className="clickable"
                />
            )
        }));

        setTableRows(data);
    }, [faqInfo, status, faqName]);

    // Get faq screens based on faq area selection
    useEffect(() => {
        setSelectedFaqScreen(0);
        if (selectedFaqArea === "Pick a faq area" || !selectedFaqArea) return;
        dispatch(faqScreensAction.get(selectedFaqArea));
    }, [selectedFaqArea]);

    // Search based on selected faq screen
    const onSearch = () => {
        if (selectedFaqScreen === "Pick a faq screen" || !selectedFaqScreen) return;
        dispatch(faqInfoAction.get(selectedFaqScreen));
    };

    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "FAQ Name",
                sortBy: "faqName"
            },
            {
                title: "FAQ Screen"
            },
            {
                title: "Description"
            },
            {
                title: "Image"
            },
            {
                title: "Likes"
            },
            {
                title: "Ratings"
            },
            {
                title: "Display Order",
                sortBy: "displayOrder"
            },
            {
                title: "Created At",
                sortBy: "createdAt"
            },
            {
                title: "Updated At",
                sortBy: "updatedAt"
            },
            {
                title: "Enabled"
            },
            {
                title: "Edit"
            }
        ],
        [tableRows]
    );

    return (
        <div>
            <div className={styles.filter}>
                <FilterDropdown
                    onChange={e => setSelectedFaqArea(e.target.value)}
                    value={selectedFaqArea}
                    label="FAQ Area Name"
                    options={["Pick a faq area", ...filteredFaqAreas]}
                    values={faqAreas?.length && [0, ...faqAreas?.map(item => item.id)]}
                />
                <FilterDropdown
                    onChange={e => setSelectedFaqScreen(e.target.value)}
                    value={selectedFaqScreen}
                    label="FAQ Screen Name"
                    options={["Pick a faq screen", ...filteredFaqScreens]}
                    values={
                        faqScreens?.length && [0, ...faqScreens?.map(item => item.id)]
                    }
                />
                <TextInput
                    search
                    label="FAQ Name"
                    name="faqName"
                    value={faqName}
                    onChange={e => setFaqName(e.target.value)}
                />
                <FilterDropdown
                    onChange={e => setStatus(e.target.value)}
                    label="Status"
                    options={["All", "Enabled", "Disabled"]}
                />
                <SearchButton
                    onClick={
                        userRole?.find(item => item.privilege === "Read") ? onSearch : null
                    }
                />
                <LightTextButton
                    onClick={() => {
                        setFaqName("");
                        setSelectedFaqArea(0);
                        setSelectedFaqScreen(0);
                    }}
                />
            </div>
            <Table headers={tableHeaders} data={tableRows}/>
            {isPopupVisible && (
                <FAQInfoPopup
                    data={editableDetail}
                    closeModal={() => setPopupVisible(false)}
                />
            )}
        </div>
    );
};

export default FAQInfo;
