import axios from "axios";

import {SP_PREFERENCES, LOADING} from "../actionTypes";
import {SERVER_IP_2, PORT_9090, Who_Obj} from "configurations";
import {adminToken} from "../../helper";

const {GET, ADD, UPDATE, TOGGLE, RESET} = SP_PREFERENCES;

const {SET_TRUE, SET_FALSE} = LOADING;
export const get = id => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .get(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/user/${id}/preferences`,
                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            )
            .then(res => {
                const data = Object.values(res.data.data);
                dispatch({type: GET, payload: data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const add = (pid, details = {}) => {
    return dispatch => {
        dispatch({type: SET_TRUE});
        axios
            .post(
                `https://cgapp.clykk.com/sp/clykk/ext/sp/v1/user/preferences`,
                {
                    qna: [details]
                },
                {
                    headers: {who: Who_Obj}
                }
            )
            .then(res => dispatch({type: ADD, payload: res.data}))
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

// Update Package
export const update = (pid, details = {}) => {
    return dispatch => {
        dispatch({type: SET_TRUE});
        axios
            .put(
                `${SERVER_IP_2 + PORT_9090}`,
                {
                    ...details
                },
                {
                    headers: {who: Who_Obj}
                }
            )
            .then(res => dispatch({type: UPDATE, payload: res.data}))
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const toggle = (pid, id) => {
    return dispatch => {
        dispatch({type: SET_TRUE});
        axios
            .patch(`${SERVER_IP_2 + PORT_9090}`, {}, {headers: {who: Who_Obj}})
            .then(res => dispatch({type: TOGGLE, payload: res.data}))
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const reset = () => dispatch => dispatch({type: RESET});
