import { GET_USERS, ADD_SNACK, LOADING } from "../actionTypes";
import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL_NEW } from "configurations";

// Get all admins
const { SET_TRUE, SET_FALSE } = LOADING;

export const get = () => {
  return dispatch => {
    dispatch({ type: SET_TRUE });

    // const {token} = JSON.parse(Cookies.get("user"));
const {token} = JSON.parse(localStorage.getItem("user"));

    axios
      .get(`${BASE_URL_NEW}/users`, {
        headers: {
          Authorization: `bearer ${token}`
        }
      })
      .then(res => {
        dispatch({ type: GET_USERS, payload: res.data?.users });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

// enable or disable admin
export const toggle = user => {
  return dispatch => {
    dispatch({ type: SET_TRUE });

    // const {token} = JSON.parse(Cookies.get("user"));
const {token} = JSON.parse(localStorage.getItem("user"));

    axios
      .get(
        `${BASE_URL_NEW}/${user.enabled ? "userinactive" : "useractive"}/${
          user.admin_id
        }`,
        {
          headers: {
            Authorization: `bearer ${token}`
          }
        }
      )
      .then(res => {
        dispatch({ type: GET_USERS, payload: res.data?.users });
        dispatch({
          type: ADD_SNACK,
          payload: `${user.first_name} has been ${
            user.enabled ? "disabled" : "enabled"
          }`
        });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};
