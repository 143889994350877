// Admin Roles
export const GET_ADMIN_ROLES = "GET_ADMIN_ROLES";
export const ADD_ADMIN_ROLE = "ADD_ADMIN_ROLE";
export const UPDATE_ADMIN_ROLE = "UPDATE_ADMIN_ROLE";
export const DELETE_ADMIN_ROLE = "DELETE_ADMIN_ROLE";

// Address
export const GET_ADDRESS = "GET_ADDRESS";
export const ADD_ADDRESS = "ADD_ADDRESS";
export const TOGGLE_ADDRESS = "TOGGLE_ADDRESS";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const RESET_ADDRESS = "RESET_ADDRESS";

// Category
export const GET_CATEGORIES = "GET_CATEGORIES";
export const ADD_CATEGORIES = "ADD_CATEGORIES";
export const TOGGLE_CATEGORY = "TOGGLE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const RESET_CATEGORY = "RESET_CATEGORY";

// City
export const GET_CITY = "GET_CITY";
export const ADD_CITY = "ADD_CITY";
export const TOGGLE_CITY = "TOGGLE_CITY";
export const UPDATE_CITY = "UPDATE_CITY";
export const REMOVE_CITY = "REMOVE_CITY";
export const RESET_CITY = "RESET_CITY";

// Customers
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const ADD_CUSTOMERS = "ADD_CUSTOMERS";
export const TOGGLE_CUSTOMERS = "TOGGLE_CUSTOMERS";
export const UPDATE_CUSTOMERS = "UPDATE_CUSTOMERS";
export const RESET_CUSTOMERS = "RESET_CUSTOMERS";

// Customer Preferences
export const CUSTOMER_PREFERENCES = {
  GET: "GET_C_PREFERENCES",
  ADD: "ADD_C_PREFERENCES",
  TOGGLE: "TOGGLE_C_PREFERENCES",
  UPDATE: "UPDATE_C_PREFERENCES",
  RESET: "RESET_C_PREFERENCES"
};

// Customer App Preferences
export const CUSTOMER_APP_PREFERENCES = {
  GET: "GET_C_APP_PREFERENCES",
  ADD: "ADD_C_APP_PREFERENCES",
  TOGGLE: "TOGGLE_C_APP_PREFERENCES",
  TOGGLE_ANS: "TOGGLE_ANS_C_APP_PREFERENCES",
  UPDATE: "UPDATE_C_APP_PREFERENCES",
  RESET: "RESET_C_APP_PREFERENCES"
};

// Country
export const GET_COUNTRY = "GET_COUNTRY";
export const ADD_COUNTRY = "ADD_COUNTRY";
export const TOGGLE_COUNTRY = "TOGGLE_COUNTRY";
export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const REMOVE_COUNTRY = "REMOVE_COUNTRY";
export const RESET_COUNTRY = "RESET_COUNTRY";

// Cart
export const GET_CART = "GET_CART";
export const ADD_CART = "ADD_CART";
export const TOGGLE_CART = "TOGGLE_CART";
export const UPDATE_CART = "UPDATE_CART";
export const RESET_CART = "RESET_CART";

// Customer Subscriptions
export const GET_C_SUBSCRIPTIONS = "GET_C_SUBSCRIPTIONS";
export const RESET_C_SUBSCRIPTIONS = "RESET_C_SUBSCRIPTIONS";

// Default Privileges
export const GET_DEFAULT_PRIVILEGES = "GET_DEFAULT_PRIVILEGES";
export const ADD_DEFAULT_PRIVILEGE = "ADD_DEFAULT_PRIVILEGE";
export const UPDATE_DEFAULT_PRIVILEGE = "UPDATE_DEFAULT_PRIVILEGE";
export const DELETE_DEFAULT_PRIVILEGE = "DELETE_DEFAULT_PRIVILEGE";
export const ACTIVE_DEFAULT_PRIVILEGE = "ACTIVE_DEFAULT_PRIVILEGE";
export const INACTIVE_DEFAULT_PRIVILEGE = "INACTIVE_DEFAULT_PRIVILEGE";

// Discounts
export const DISCOUNTS = {
  GET: "GET_DISCOUNTS",
  ADD: "ADD_DISCOUNTS",
  TOGGLE: "TOGGLE_DISCOUNTS",
  UPDATE: "UPDATE_DISCOUNTS",
  RESET: "RESET_DISCOUNTS"
};

// Documents
export const DOCUMENTS = {
  GET: "GET_DOCUMENTS",
  ADD: "ADD_DOCUMENTS",
  TOGGLE: "TOGGLE_DOCUMENTS",
  UPDATE: "UPDATE_DOCUMENTS",
  RESET: "RESET_DOCUMENTS"
};

// FAQ Areas
export const GET_FAQ = "GET_FAQ";
export const ADD_FAQ = "ADD_FAQ";
export const TOGGLE_FAQ = "TOGGLE_FAQ";
export const UPDATE_FAQ = "UPDATE_FAQ";
export const RESET_FAQ = "RESET_FAQ";

// FAQ Screens
export const GET_FAQ_SCREENS = "GET_FAQ_SCREENS";
export const ADD_FAQ_SCREENS = "ADD_FAQ_SCREENS";
export const TOGGLE_FAQ_SCREENS = "TOGGLE_FAQ_SCREENS";
export const UPDATE_FAQ_SCREENS = "UPDATE_FAQ_SCREENS";
export const RESET_FAQ_SCREENS = "RESET_FAQ_SCREENS";

// FAQ Info
export const GET_FAQ_INFO = "GET_FAQ_INFO";
export const ADD_FAQ_INFO = "ADD_FAQ_INFO";
export const TOGGLE_FAQ_INFO = "TOGGLE_FAQ_INFO";
export const UPDATE_FAQ_INFO = "UPDATE_FAQ_INFO";
export const RESET_FAQ_INFO = "RESET_FAQ_INFO";

// FAQ Topic
export const GET_FAQ_TOPIC = "GET_FAQ_TOPIC";
export const ADD_FAQ_TOPIC = "ADD_FAQ_TOPIC";
export const TOGGLE_FAQ_TOPIC = "TOGGLE_FAQ_TOPIC";
export const UPDATE_FAQ_TOPIC = "UPDATE_FAQ_TOPIC";
export const RESET_FAQ_TOPIC = "RESET_FAQ_TOPIC";

// Home Greeting
export const GET_HOME_GREETING = "GET_HOME_GREETING";
export const ADD_HOME_GREETING = "ADD_HOME_GREETING";
export const TOGGLE_HOME_GREETING = "TOGGLE_HOME_GREETING";
export const UPDATE_HOME_GREETING = "UPDATE_HOME_GREETING";
export const RESET_HOME_GREETING = "RESET_HOME_GREETING";

// Location
export const GET_LOCATION = "GET_LOCATION";
export const ADD_LOCATION = "ADD_LOCATION";
export const TOGGLE_LOCATION = "TOGGLE_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const REMOVE_LOCATION = "REMOVE_LOCATION";
export const RESET_LOCATION = "RESET_LOCATION";

// Loading
export const LOADING = {
  SET_TRUE: "LOADING",
  SET_FALSE: "NOT_LOADING"
};

// Manage Users/Admins
export const GET_USERS = "GET_USERS";
export const TOGGLE_USER = "TOGGLE_USER";

// Modules
export const GET_MODULES = "GET_MODULES";
export const UPDATE_MODULE = "UPDATE_MODULE";
export const DELETE_MODULE = "DELETE_MODULE";
export const ADD_MODULE = "ADD_MODULE";
export const ACTIVE_MODULE = "ACTIVE_MODULE";
export const INACTIVE_MODULE = "INACTIVE_MODULE";
export const RESET_MODULES = "RESET_MODULES";

// Modules
export const GET_MANUAL_ROUTING = "GET_MANUAL_ROUTING";
export const RESET_MANUAL_ROUTING = "RESET_MANUAL_ROUTING";

// Member & Status
export const MEMBER_STATUS = {
  GET: "GET_MEMBER_STATUS",
  ADD: "ADD_MEMBER_STATUS",
  TOGGLE: "TOGGLE_MEMBER_STATUS",
  UPDATE: "UPDATE_MEMBER_STATUS",
  RESET: "RESET_MEMBER_STATUS"
};

// Orders
export const ORDERS = {
  GET: "GET_ORDERS",
  ADD: "ADD_ORDERS",
  TOGGLE: "TOGGLE_ORDERS",
  UPDATE: "UPDATE_ORDERS",
  RESET: "RESET_ORDERS"
};

// Owner
export const OWNER = {
  GET: "GET_OWNER"
};

// Organizations
export const ORG = {
  GET: "GET_ORG",
  ADD: "ADD_ORG",
  TOGGLE: "TOGGLE_ORG",
  UPDATE: "UPDATE_ORG",
  RESET: "RESET_ORG",
  REJECT_DOC: "REJECT_ORG_DOC",
  CHANGE_DOC_STATUS: "CHANGE_STATUS_ORG_DOC"
};

// Organization Documents
export const GET_ORG_DOC = "GET_ORG_DOC";
export const ADD_ORG_DOC = "ADD_ORG_DOC";
export const TOGGLE_ORG_DOC = "TOGGLE_ORG_DOC";
export const UPDATE_ORG_DOC = "UPDATE_ORG_DOC";
export const RESET_ORG_DOC = "RESET_ORG_DOC";

// Packages
export const GET_PACKAGES = "GET_PACKAGES";
export const ADD_PACKAGE = "ADD_PACKAGE";
export const TOGGLE_PACKAGE = "TOGGLE_PACKAGE";
export const UPDATE_PACKAGE = "UPDATE_PACKAGE";
export const RESET_PACKAGE = "RESET_PACKAGE";

// Package Add On
export const GET_PACKAGE_ADDON = "GET_PACKAGE_ADDON";
export const ADD_PACKAGE_ADDON = "ADD_PACKAGE_ADDON";
export const TOGGLE_PACKAGE_ADDON = "TOGGLE_PACKAGE_ADDON";
export const UPDATE_PACKAGE_ADDON = "UPDATE_PACKAGE_ADDON";
export const RESET_PACKAGE_ADDON = "RESET_PACKAGE_ADDON";

// Package QnA
export const GET_PACKAGE_QNA = "GET_PACKAGE_QNA";
export const ADD_PACKAGE_QNA = "ADD_PACKAGE_QNA";
export const TOGGLE_PACKAGE_QNA = "TOGGLE_PACKAGE_QNA";
export const UPDATE_PACKAGE_QNA = "UPDATE_PACKAGE_QNA";
export const RESET_PACKAGE_QNA = "RESET_PACKAGE_QNA";

// Privileges
export const GET_PRIVILEGES = "GET_PRIVILEGES";
export const ADD_PRIVILEGE = "ADD_PRIVILEGE";
export const UPDATE_PRIVILEGE = "UPDATE_PRIVILEGE";

// Package Like
export const GET_PACKAGE_LIKE = "GET_PACKAGE_LIKE";
export const RESET_PACKAGE_LIKE = "RESET_PACKAGE_LIKE";

// Package Share
export const GET_PACKAGE_SHARE = "GET_PACKAGE_SHARE";
export const RESET_PACKAGE_SHARE = "RESET_PACKAGE_SHARE";

// Roles
export const GET_ROLES = "GET_ROLES";
export const ADD_ROLE = "ADD_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";
export const ACTIVE_ROLE = "ACTIVE_ROLE";
export const INACTIVE_ROLE = "INACTIVE_ROLE";
export const RESET_ROLES = "RESET_ROLES";

// Routing
export const ROUTING = {
  GET: "GET_ROUTING",
  ADD: "ADD_ROUTING",
  UPDATE: "UPDATE_ROUTING",
  RESET: "RESET_ROUTING"
};

// Sub Categories
export const GET_SUB_CATEGORIES = "GET_SUB_CATEGORIES";
export const ADD_SUB_CATEGORIES = "ADD_SUB_CATEGORIES";
export const TOGGLE_SUB_CATEGORY = "TOGGLE_SUB_CATEGORY";
export const UPDATE_SUB_CATEGORY = "UPDATE_SUB_CATEGORY";
export const RESET_SUB_CATEGORY = "RESET_SUB_CATEGORY";

// Service Rules
export const GET_SERVICE_RULES = "GET_SERVICE_RULES";

// Sub Category Location
export const GET_SUB_CATEGORY_LOCATION = "GET_SUB_CATEGORY_LOCATION";
export const ADD_SUB_CATEGORIES_LOCATION = "ADD_SUB_CATEGORIES_LOCATION";
export const TOGGLE_SUB_CATEGORY_LOCATION = "TOGGLE_SUB_CATEGORY_LOCATION";
export const UPDATE_SUB_CATEGORY_LOCATION = "UPDATE_SUB_CATEGORY_LOCATION";

// State
export const GET_STATE = "GET_STATE";
export const ADD_STATE = "ADD_STATE";
export const TOGGLE_STATE = "TOGGLE_STATE";
export const UPDATE_STATE = "UPDATE_STATE";
export const REMOVE_STATE = "REMOVE_STATE";
export const RESET_STATE = "RESET_STATE";

// Service Provider App Preferences
export const GET_PREFERENCES = "GET_PREFERENCES";
export const ADD_PREFERENCES = "ADD_PREFERENCES";
export const TOGGLE_PREFERENCES = "TOGGLE_PREFERENCES";
export const UPDATE_PREFERENCES = "UPDATE_PREFERENCES";
export const RESET_PREFERENCES = "RESET_SP_APP_PREFERENCES";

// Service Provider App Preferences
export const GET_SP_APP_PREFERENCES = "GET_SP_APP_PREFERENCES";
export const ADD_SP_APP_PREFERENCES = "ADD_SP_APP_PREFERENCES";
export const TOGGLE_SP_APP_PREFERENCES = "TOGGLE_SP_APP_PREFERENCES";
export const TOGGLE_ANS_SP_APP_PREFERENCES = "TOGGLE_ANS_SP_APP_PREFERENCES";
export const UPDATE_SP_APP_PREFERENCES = "UPDATE_SP_APP_PREFERENCES";
export const RESET_SP_APP_PREFERENCES = "RESET_SP_APP_PREFERENCES";

// Service Provider Orders
export const GET_SP_ORDERS = "GET_SP_ORDERS";
export const ADD_SP_ORDERS = "ADD_SP_ORDERS";
export const TOGGLE_SP_ORDERS = "TOGGLE_SP_ORDERS";
export const UPDATE_SP_ORDERS = "UPDATE_SP_ORDERS";
export const RESET_SP_ORDERS = "RESET_SP_ORDERS";

// Sub Organizations
export const SUB_ORG = {
  GET: "GET_SUB_ORG",
  ADD: "ADD_SUB_ORG",
  TOGGLE: "TOGGLE_SUB_ORG",
  UPDATE: "UPDATE_SUB_ORG",
  RESET: "RESET_SUB_ORG",
  REJECT_DOC: "REJECT_SUB_ORG_DOC",
  CHANGE_DOC_STATUS: "CHANGE_STATUS_SUB_ORG_DOC"
};

// Service Provider
export const SERVICE_PROVIDER = {
  GET: "GET_SERVICE_PROVIDER",
  ADD: "ADD_SERVICE_PROVIDER",
  TOGGLE: "TOGGLE_SERVICE_PROVIDER",
  UPDATE: "UPDATE_SERVICE_PROVIDER",
  RESET: "RESET_SERVICE_PROVIDER",
  REJECT_DOC: "REJECT_DOC_SERVICE_PROVIDER",
  TOGGLE_STATUS: "TOGGLE_STATUS_SERVICE_PROVIDER",
  CHANGE_DOC_STATUS: "CHANGE_DOC_STATUS_SERVICE_PROVIDER"
};

// SP Commision
export const SP_Commision = {
  GET: "GET_SP_Commision",
  // ADD: "ADD_SERVICE_PROVIDER",
  // TOGGLE: "TOGGLE_SERVICE_PROVIDER",
  // UPDATE: "UPDATE_SERVICE_PROVIDER",
  // RESET: "RESET_SERVICE_PROVIDER",
  // REJECT_DOC: "REJECT_DOC_SERVICE_PROVIDER",
  // TOGGLE_STATUS: "TOGGLE_STATUS_SERVICE_PROVIDER",
  // CHANGE_DOC_STATUS: "CHANGE_DOC_STATUS_SERVICE_PROVIDER"
};

// SP Service Settings
export const SP_SERVICE_SETTINGS = {
  GET: "GET_SP_SERVICE_SETTINGS",
  ADD: "ADD_SP_SERVICE_SETTINGS",
  TOGGLE: "TOGGLE_SP_SERVICE_SETTINGS",
  UPDATE: "UPDATE_SP_SERVICE_SETTINGS",
  RESET: "RESET_SP_SERVICE_SETTINGS"
};

// Sub category
export const SP_SUB_CATEGORIES = {
  GET: "GET_SP_SUB_CATEGORIES",
  RESET: "RESET_SP_SUB_CATEGORIES"
};

// Service Provider Prefernces
export const SP_PREFERENCES = {
  GET: "GET_SP_PREFERENCES",
  ADD: "ADD_SP_PREFERENCES",
  TOGGLE: "TOGGLE_SP_PREFERENCES",
  UPDATE: "UPDATE_SP_PREFERENCES",
  RESET: "RESET_SP_PREFERENCES"
};

// Service Provider Address
export const SP_ADDRESS = {
  GET: "GET_SP_ADDRESS",
  ADD: "ADD_SP_ADDRESS",
  TOGGLE: "TOGGLE_SP_ADDRESS",
  UPDATE: "UPDATE_SP_ADDRESS",
  RESET: "RESET_SP_ADDRESS"
};

// Subscription
export const SUBSCRIPTION = {
  GET: "GET_SUBSCRIPTION",
  ADD: "ADD_SUBSCRIPTION",
  TOGGLE: "TOGGLE_SUBSCRIPTION",
  UPDATE: "UPDATE_SUBSCRIPTION",
  RESET: "RESET_SUBSCRIPTION"
};

// Subscription Details
export const SUBS_DETAIL = {
  GET: "GET_SUBS_DETAIL",
  RESET: "RESET_SUBS_DETAIL"
};

// Subscriotion Routing Details
export const SUBSCRIPTION_ROUTING = {
  GET: "GET_SUBSCRIPTION_ROUTING",
  ADD: "ADD_SUBSCRIPTION_ROUTING",
  TOGGLE: "TOGGLE_SUBSCRIPTION_ROUTING",
  UPDATE: "UPDATE_SUBSCRIPTION_ROUTING",
  RESET: "RESET_SUBSCRIPTION_ROUTING"
};

// User
export const GET_USER = "GET_USER";
export const ADD_USER = "ADD_USER";
export const RESET_USER = "RESET_USER";

// User Roles
export const GET_USER_ROLES = "GET_USER_ROLES";
export const ADD_USER_ROLE = "ADD_USER_ROLE";
export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE";
export const DELETE_USER_ROLE = "DELETE_USER_ROLE";

// User Likes
export const GET_USER_LIKES = "GET_USER_LIKES";
export const RESET_USER_LIKES = "RESET_USER_LIKES";

// User Shares
export const GET_USER_SHARES = "GET_USER_SHARES";
export const RESET_USER_SHARES = "RESET_USER_SHARES";

export const SET_ERROR = "SET_ERROR";

export const ADD_SNACK = "ADD_SNACK";
