import * as types from '../actionTypes';

const usersReducer = ( state = [], action ) => {
    
    switch(action.type){
        
        case types.GET_USERS:
            return action.payload;
        
        case types.TOGGLE_USER:
            return state.map( user => user.adminID !== action.payload ? user : {...user, enabled: !user.enabled})
            
        default:
            return state;
    }

}

export default usersReducer;