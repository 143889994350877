import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import styles from "./styles.module.scss";
import {
    Table,
    SearchButton,
    LightTextButton,
    FilterDropdown,
    SwitchButton,
    TextLink,
    StatusButton,
    TextInput,
    DocumentButton
} from "components";
import {categoryAction, subOrgAction} from "redux/actions";
import {useHistory, useLocation} from "react-router-dom";
import {internalSearch} from "helper";

const initialState = {
    id: "",
    orgName: "",
    category: ""
};

export default function SubOrganizationPanel() {
    // Routers
    const history = useHistory();
    const currentLocation = history.location.pathname;
    const locationSearch = useLocation().search;
    const query = new URLSearchParams(locationSearch);
    const orgID = query.get("orgID");
    const name = query.get("orgName");

    // States
    const [tableRows, setTableRows] = useState();

    // Filter States
    const [status, setStatus] = useState();
    const [filterState, setFilterState] = useState(initialState);

    // Redux actions
    const dispatch = useDispatch();

    const {subOrg, categories, userRole} = useSelector(state => ({
        subOrg: state.subOrg,
        categories: state.categories,
        userRole: state.userRole?.filter(role => role.module === "Organization")
    }));

    // Search based on category & sub category name
    const onSearch = () => {
        dispatch(subOrgAction.get(orgID));
    };

    // One-time Effects
    useEffect(() => {
        setFilterState({
            ...filterState,
            id: query.get("subOrgID"),
            orgID: query.get("orgID")
        });

        if (userRole?.find(item => item.privilege === "Read"))
            dispatch(subOrgAction.get(orgID));
        if (
            !categories?.length &&
            userRole?.find(item => item.privilege === "Read")
        )
            dispatch(categoryAction.get());

        return () => {
            dispatch(subOrgAction.reset());
        };
    }, []);

    useEffect(() => {
        if (!subOrg?.length) {
            setTableRows();
            return;
        }

        let filtered = subOrg;

        // filter based on Status
        filtered = filtered?.filter(item =>
            status === "Enabled"
                ? item.enabled
                : status === "Disabled"
                    ? !item.enabled
                    : item
        );

        filtered = internalSearch(filterState, "id", filtered);
        filtered = internalSearch(filterState, "orgName", filtered);

        // Map table rows
        let data = filtered?.map(obj => ({
            id: (
                <TextLink
                    text={obj.id}
                    onClick={() =>
                        history.push(
                            `/${currentLocation.split("/", 2)[1]}/sub-organization/${obj?.id}`
                        )
                    }
                />
            ),
            orgID: name,
            name: obj?.orgName,
            city: obj.cityID,
            addressID: obj?.addressID,
            category:
                categories.find(item => item.id == obj.category)?.name || obj.category,
            createdAt: `${new Date(obj.createdAt).toLocaleString()}`,
            updatedAt: `${new Date(obj.updatedAt).toLocaleString()}`,
            status: (
                <StatusButton
                    title={obj?.status}
                    options={["pending", "approved", "rejected"]}
                    onChange={status =>
                        dispatch(subOrgAction.changeStatus(obj.id, status))
                    }
                />
            ),
            documents: (
                <DocumentButton
                    approved={
                        obj?.documents?.filter(doc => doc.status === "approved").length
                    }
                    uploaded={obj?.documents?.filter(doc => doc.documentURL).length}
                    total={obj?.documents?.length}
                    onClick={() =>
                        history.push(
                            `/${currentLocation.split("/", 2)[1]}/sub-organization/${
                                obj?.id
                            }/documents`
                        )
                    }
                />
            ),
            enabled: (
                <SwitchButton
                    enabled={obj.enabled}
                    onClick={
                        userRole?.find(item => item.privilege === "Enable")
                            ? () => dispatch(subOrgAction.toggle(obj.id))
                            : null
                    }
                />
            )
        }));

        data = internalSearch(filterState, "category", data);

        setTableRows(data);
    }, [subOrg, status, filterState]);

    // Table Header
    const tableHeaders = [
        {
            title: "ID",
            sortBy: "id"
        },
        {
            title: "Organization Name"
        },
        {
            title: "Sub Organization"
        },
        {
            title: "City"
        },
        {
            title: "Address"
        },
        {
            title: "Category"
        },
        {
            title: "Created",
            sortBy: "createdAt"
        },
        {
            title: "Updated",
            sortBy: "updatedAt"
        },
        {
            title: "Status"
        },
        {
            title: "Documents"
        },
        {
            title: "Enabled"
        }
    ];

    return (
        <div>
            <div className={styles.filter}>
                <TextInput
                    search
                    label="Sub Org Name"
                    name="orgName"
                    value={filterState?.orgName}
                    onChange={e =>
                        setFilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
                <TextInput
                    search
                    label="Category"
                    name="category"
                    value={filterState?.categpry}
                    onChange={e =>
                        setFilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
                <FilterDropdown
                    label="City"
                    options={["Select a city"]}
                    name="city"
                    onChange={e =>
                        setFilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
                <FilterDropdown
                    onChange={e => setStatus(e.target.value)}
                    label="Status"
                    options={["All", "Enabled", "Disabled"]}
                />
                <SearchButton
                    onClick={
                        userRole?.find(item => item.privilege === "Read") ? onSearch : null
                    }
                />
                <LightTextButton onClick={() => setFilterState(initialState)}/>
            </div>
            <Table headers={tableHeaders} data={tableRows}/>
        </div>
    );
}
