import React from "react";
import styles from "./styles.module.scss";
import { ImageStampXL } from "../index";

const MultiTable = ({ question, answers }) => {
  return (
    <div className={styles.table__container}>
      {/* Question Table */}
      <table>
        <thead>
          <tr>
            <th>{question}</th>
          </tr>
        </thead>
      </table>

      {/* Answer Table */}
      <table>
        {answers?.map((answer, index) => (
          <>
            <thead className={styles.answer}>
              <tr>
                <td key={index}>{answer?.answer}</td>
              </tr>
            </thead>

            <tbody>
              {answer.customerAnswer?.length &&
              answer?.answerType === "text" ? (
                answer.customerAnswer?.map((item, i) => (
                  <tr>
                    <td>{item?.answer}</td>
                  </tr>
                ))
              ) : answer.customerAnswer?.length &&
                answer?.answerType === "upload" ? (
                <tr
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: 100
                  }}>
                  {answer.customerAnswer?.map((item, i) => (
                    <ImageStampXL src={item?.answer} key={i} />
                  ))}
                </tr>
              ) : (
                <tr>
                  <td></td>
                </tr>
              )}
            </tbody>
          </>
        ))}
      </table>
    </div>
  );
};

export default MultiTable;
