import {
    GET_PRIVILEGES,
    ADD_PRIVILEGE,
    UPDATE_PRIVILEGE,
    ADD_SNACK,
    LOADING
} from "../actionTypes";
import axios from "axios";
import Cookies from "js-cookie";

import {BASE_URL_NEW} from "configurations";

const {SET_TRUE, SET_FALSE} = LOADING;

export const get = () => {
    return dispatch => {
        dispatch({type: SET_TRUE});

        // const {token} = JSON.parse(Cookies.get("user"));
        const {token} = JSON.parse(localStorage.getItem("user"));

        axios
            .get(`${BASE_URL_NEW}/getallprivileges`, {
                headers: {
                    Authorization: `bearer ${token}`
                }
            })
            .then(res => dispatch({type: GET_PRIVILEGES, payload: res.data?.data}))
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

// add a new privilege
export const add = details => {
    return dispatch => {
        dispatch({type: SET_TRUE});

        // const {token} = JSON.parse(Cookies.get("user"));
        const {token} = JSON.parse(localStorage.getItem("user"));

        axios
            .post(`${BASE_URL_NEW}/addprivilege`, details, {
                headers: {
                    Authorization: `bearer ${token}`
                }
            })
            .then(res => {
                dispatch({type: ADD_PRIVILEGE, payload: res.data?.data});
                dispatch({
                    type: ADD_SNACK,
                    payload: `${res.data?.data?.name} has been created`
                });
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

// update a specific privileges
export const update = details => {
    return dispatch => {
        dispatch({type: SET_TRUE});

        // const {token} = JSON.parse(Cookies.get("user"));
        const {token} = JSON.parse(localStorage.getItem("user"));

        axios
            .post(
                `${BASE_URL_NEW}/updateprivilege/${details?.privilege_id}`,
                details,
                {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                }
            )
            .then(res => {
                dispatch({type: UPDATE_PRIVILEGE, payload: res.data?.data});
                dispatch({
                    type: ADD_SNACK,
                    payload: `A privilege updated to ${details?.name}`
                });
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};
