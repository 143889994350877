import {
  ADD_CUSTOMERS,
  GET_CUSTOMERS,
  TOGGLE_CUSTOMERS,
  UPDATE_CUSTOMERS,
  RESET_CUSTOMERS
} from "../actionTypes";

const customersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CUSTOMERS:
      return action.payload;

    case ADD_CUSTOMERS:
      return action.payload;

    case UPDATE_CUSTOMERS:
      return state.map(item =>
        item.id === action.payload.id ? action.payload : item
      );

    case TOGGLE_CUSTOMERS:
      return {
        ...state,
        users: state.users.map(item =>
          item.userId === action.payload.userId ? action.payload : item
        )
      };

    case RESET_CUSTOMERS:
      return null;

    default:
      return state;
  }
};

export default customersReducer;
