import {SERVICE_PROVIDER} from "../actionTypes";

export default function serviceProviderReducer(state = null, action) {
    const {
        GET,
        ADD,
        UPDATE,
        TOGGLE,
        RESET,
        TOGGLE_STATUS,
        REJECT_DOC,
        CHANGE_DOC_STATUS,
    } = SERVICE_PROVIDER;

    switch (action.type) {
        case GET:
            return action.payload;

        case ADD:
            return action.payload;

        case UPDATE:
            return state.map((item) =>
                item.id === action.payload.id ? action.payload : item
            );

        case TOGGLE:
            return {
                ...state,
                users: state.users.map((item) =>
                    item.userId !== action.payload.userId
                        ? item
                        : {...item, enabled: !item.enabled}
                ),
            };

        case TOGGLE_STATUS:
            return {
                ...state,
                users: state.users.map((item) =>
                    item.userId === action.payload.userId ? action.payload : item
                ),
            };

        case REJECT_DOC:
            return {
                ...state,
                users: state.users.map((item) =>
                    item.userId == action.payload.sid
                        ? {...item, documents: action.payload.documents}
                        : item
                ),
            };

        case CHANGE_DOC_STATUS:
            console.log(action.payload);
            return {
                ...state,
                users: state.users.map((item) =>
                    item.userId == action.payload.spID
                        ? {...item, documents: action.payload.documents}
                        : item
                ),
            };

        case RESET:
            return null;

        default:
            return state;
    }
}
