import React, {useState} from "react";
import {useDispatch} from "react-redux";
import styles from "./styles.module.scss";
import {
    GrayCard,
    PhoneInput,
    GreenButton,
    GreenButtonSm,
    CountDown,
    AuthInput,
    LoadingSpinner
} from "components";
import logo from "assets/img/logo.png";
import axios from "axios";
import {BASE_URL_NEW} from "configurations";

import {userAction} from "redux/actions";
import {useHistory} from "react-router-dom";
import {auth, firebaseApp} from "../../firebase";

let interval, confirmaton;

const SignUp = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    // States
    const [phoneCard, setPhoneCard] = useState(true);
    const [timer, setTimer] = useState();
    const [percent, setPercent] = useState();
    const [mobile, setPhone] = useState("");
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({
        fname: "",
        lname: "",
        email: "",
        created_by: 1,
        updated_by: 1,
        device_id: 1,
        device_type: "web_admin"
    });

    const handleChange = setter => event =>
        setter(prev => ({...prev, [event.target.name]: event.target.value}));

    // Send OTP code to mobile number
    const sendCode = () => {
        if (!mobile) return;

        setLoading(true);

        const recaptcha = new firebaseApp.auth.RecaptchaVerifier(
            "recaptcha__container",
            {
                size: "invisible"
            }
        );

        //user mobile number
        const number = "+" + mobile;

        // Send OTP to number using firebase
        auth
            .signInWithPhoneNumber(number, recaptcha)
            .then(confirmationResult => {
                console.log(confirmationResult);
                // save confirmation function
                confirmaton = confirmationResult;
                setPhoneCard(false);
                countTick();
            })
            .catch(err => {
                alert(err.message);
            })
            .finally(_ => setLoading(false));
    };

    const confirmCode = () => {
        setLoading(true);
        console.log({
            ...user,
            mobile: `+${mobile}`
        });

        if (!code) return;
        confirmaton
            .confirm(code)
            .then(_ =>
                axios.post(`${BASE_URL_NEW}/register`, {
                    ...user,
                    mobile: `+${mobile}`
                })
            )
            .then(_ =>
                axios.post(`${BASE_URL_NEW}/login`, {
                    mobile: `+${mobile}`
                })
            )
            .then(res =>
                dispatch(
                    userAction.getProfile({
                        mobile: `+${mobile}`,
                        password: res.data.otp
                    })
                )
            )
            .catch(err => {
                alert(err.message);
            })
            .finally(_ => {
                clearInterval(interval);
                setLoading(false);
            });
    };

    /* const sendCode = () => {
      if (!mobile) return;

      setLoading(true);

      axios
        .post(`${BASE_URL_NEW}/register`, {
          ...user,
          mobile
        })
        .then(res => {
          console.log(res.data);
          alert("Your account has been created, please login.");
          history.push("/login");
        })
        .catch(err =>
          alert(
            err.message +
              " - " +
              "It looks like account already exist, please try to sign in."
          )
        )
        .finally(_ => setLoading(false));
    };

    const confirmCode = () => {
      setLoading(true);

      if (!code) return;

      dispatch(userAction.getProfile({ mobile, password: code }))
        .then(() => clearInterval(interval))
        .catch(err => alert(err.message))
        .finally(() => {
          setLoading(false);
        });
    }; */

    // Count Down
    const countTick = () => {
        clearInterval(interval);

        let sec = 59;
        let min = 2;
        let timeout = (min + 1) * (sec + 1);

        interval = setInterval(() => {
            if (sec < 0) {
                sec = 59;
                min--;
            }

            setTimer(`0${min}:${sec < 10 ? "0" : ""}${sec}`);
            setPercent(timeout);

            if (min === 0 && sec === 0) {
                clearInterval(interval);
            }
            timeout--;
            sec--;
        }, 1000);
    };

    return (
        <div className={styles.container}>
            <div className={`${styles.sub__container} ${styles.green}`}></div>
            <div className={styles.sub__container}>
                <div className={styles.top__bar}>
                    <img src={logo} className={styles.logo} alt="CLYKK"/>

                    <div className={styles.signup__container}>
                        <span>Member of CLYKK?</span>
                        <GreenButtonSm
                            title="Sign In"
                            onClick={() => history.push("/login")}
                        />
                    </div>
                </div>

                {phoneCard ? (
                    <GrayCard>
                        {loading ? (
                            <LoadingSpinner/>
                        ) : (
                            <>
                                <p className={styles.heading}>Register</p>

                                <AuthInput
                                    name="fname"
                                    value={user.fname}
                                    placeholder="First name"
                                    onChange={handleChange(setUser)}
                                />

                                <AuthInput
                                    name="lname"
                                    value={user.lname}
                                    placeholder="Last name"
                                    onChange={handleChange(setUser)}
                                />

                                <AuthInput
                                    name="email"
                                    value={user.email}
                                    placeholder="Email"
                                    onChange={handleChange(setUser)}
                                />

                                <PhoneInput onChange={setPhone} value={mobile}/>

                                <div className={styles.text__container}>
                                    <p>
                                        We will send you a One Time Password (OTP) SMS. Standard
                                        carrier rates may apply.
                                    </p>
                                </div>

                                <GreenButton title="Register" onClick={sendCode}/>

                                <div className={styles.text__container}>
                                    <input type="checkbox" style={styles.checkbox}/>
                                    <p>
                                        By clicking, I accept{" "}
                                        <a href="http://www.clykk.com/terms" target="_blank">
                                            terms of use
                                        </a>{" "}
                                        and{" "}
                                        <a
                                            href="http://www.clykk.com/privacy-policy"
                                            target="_blank">
                                            privacy policy.
                                        </a>
                                    </p>
                                </div>
                            </>
                        )}

                        <div id="recaptcha__container"></div>
                    </GrayCard>
                ) : (
                    <GrayCard>
                        {loading ? (
                            <LoadingSpinner/>
                        ) : (
                            <>
                                <p className={styles.heading}>Sign In</p>
                                <div className={styles.text__container}>
                                    <p style={{marginBottom: 20}}>
                                        We have sent an OTP to your contact number, kindly enter the
                                        SMS code.
                                    </p>
                                </div>

                                <AuthInput
                                    placeholder="Enter your code"
                                    value={code}
                                    onChange={e => setCode(e.target.value)}
                                />
                                <CountDown
                                    percentage={100 - Math.floor((percent / 180) * 100)}
                                    value={timer}
                                />

                                <GreenButton title="Sign Up" onClick={confirmCode}/>

                                <div className={styles.text__container}>
                                    {timer === "00:00" && (
                                        <p>
                                            Didn't receive a code?{" "}
                                            <span onClick={sendCode}>Resend Code</span>
                                        </p>
                                    )}
                                </div>
                            </>
                        )}
                    </GrayCard>
                )}
            </div>
        </div>
    );
};

export default SignUp;
