import axios from "axios";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import admin from "../layout/Admin";

export default async function adminToken(mobileNo) {
    // const mobile = mobileNo || Cookies.getJSON("user")?.mobile;
    // const adminAccessToken = Cookies.get("admin_access_token");

    const mobile = mobileNo || JSON.parse(localStorage.getItem("user"))?.mobile;

    const adminAccessToken = localStorage.getItem("admin_access_token");

    const {exp} = adminAccessToken ? jwtDecode(adminAccessToken) : {};

    console.log("Token Expired", exp > Math.floor(Date.now() / 1000));
    if (exp > Math.floor(Date.now() / 1000)) return adminAccessToken;

    console.log("Refreshing token");

    const {data} = await axios.post(
        "https://cgapp.clykk.com/admin/api/v2/int/admin_dashboard/admin",
        {
            mobile
        }
    );

    // http://cgapp.clykk.com:9090
    // http://ec2-3-108-220-85.ap-south-1.compute.amazonaws.com:9090

    // Cookies.set("admin_access_token", data?.access_token);
    localStorage.setItem("admin_access_token", data?.access_token);

    return data?.access_token;
}
