import React, { useEffect, useState, useMemo } from "react";
import styles from "./styles.module.scss";
import {
  Table,
  TextInput,
  SearchButton,
  LightTextButton,
  FilterDropdown,
  SwitchButton,
  Icon
} from "components";
import { useDispatch, useSelector } from "react-redux";
import { countryAction } from "redux/actions";

const Country = () => {
  // Disptach redux action to get faq areas
  const dispatch = useDispatch();
  const { country, userRole } = useSelector(state => ({
    country: state.country,
    userRole: state.userRole?.filter(role => role.module === "Location")
  }));

  // Component States
  const [tableRows, setTableRows] = useState();

  // Filter States
  const [status, setStatus] = useState();
  const [searchText, setSearchText] = useState();

  useEffect(() => {
    if (userRole?.find(item => item.privilege === "Read"))
      dispatch(countryAction.get());

    return () => dispatch(countryAction.reset());
  }, [dispatch]);

  useEffect(() => {
    let filtered = country;

    // filter based on Status
    filtered = filtered?.filter(item =>
      status === "Enabled"
        ? item.enabled
        : status === "Disabled"
        ? !item.enabled
        : item
    );

    // filter based on name
    if (searchText) {
      filtered = filtered.filter(
        item =>
          item.name.toLowerCase().indexOf(searchText?.toLowerCase()) !== -1
      );
    }

    // Custom Functions
    const data = filtered.map(item => ({
      id: item.id,
      name: item.name,
      deletedAt: `${
        item?.deletedAt && new Date(item.deletedAt).toLocaleString()
      }`,
      enabled: (
        <SwitchButton
          enabled={item.enabled}
          onClick={() => dispatch(countryAction.toggle(item.id))}
        />
      )
      // edit: (
      //   <Icon
      //     color="danger"
      //     onClick={() => dispatch(countryAction.remove(item.id))}
      //   />
      // )
    }));

    setTableRows(data);
  }, [country, status, searchText]);

  // Table header
  const tableHeaders = useMemo(() => [
    {
      title: "ID"
    },
    {
      title: "Name",
      sortBy: "name"
    },
    {
      title: "Deleted At"
    },
    {
      title: "Enabled"
    }
  ]);

  return (
    <div>
      <div className={styles.filter}>
        <TextInput
          search
          label="Country Name"
          name="Country Name"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
        <FilterDropdown
          onChange={e => setStatus(e.target.value)}
          label="Status"
          value={status}
          options={["All", "Enabled", "Disabled"]}
        />
        <SearchButton />
        <LightTextButton
          onClick={() => {
            setSearchText("");
            setStatus("");
          }}
        />
      </div>
      <Table headers={tableHeaders} data={tableRows} />
    </div>
  );
};

export default Country;
