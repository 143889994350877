import { CUSTOMER_PREFERENCES } from "../actionTypes";

export default function cPreference(state = null, action) {
  const { ADD, GET, TOGGLE, UPDATE, RESET } = CUSTOMER_PREFERENCES;

  switch (action.type) {
    case GET:
      return action.payload;

    case ADD:
      return action.payload;

    case UPDATE:
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );

    case TOGGLE:
      return state.map((item) =>
        item.id !== action.payload.id
          ? item
          : { ...item, enabled: !item.enabled }
      );

    case RESET:
      return null;

    default:
      return state;
  }
}
