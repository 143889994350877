import React from "react";
import styles from "./styles.module.scss";

export default function CheckBox({
  title,
  onChange,
  value,
  name,
  checked,
  marginRight,
  marginTop
}) {
  return (
    <div
      className={styles.text__container}
      style={{
        marginRight: marginRight ?? 25,
        marginTop: marginTop ?? 15
      }}>
      <input
        type="checkbox"
        style={styles.checkbox}
        onClick={onChange}
        checked={checked}
        value={value}
        name={name}
      />
      <label>{title}</label>
    </div>
  );
}
