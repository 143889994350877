import HomeGreetingPanel from "./panels/HomeGreetingPanel";

const tabRoutes = [
  {
    name: "Home Greeting",
    path: "/home-greeting",
    component: <HomeGreetingPanel />
  }
];

export default tabRoutes;
