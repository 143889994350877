import { SP_SERVICE_SETTINGS } from "../actionTypes";

export default function serviceProviderReducer(state = null, action) {
  const { GET, ADD, UPDATE, TOGGLE, RESET } = SP_SERVICE_SETTINGS;

  switch (action.type) {
    case GET:
      return action.payload;

    case ADD:
      return action.payload;

    case UPDATE:
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );

    case TOGGLE:
      return action.payload;

    case RESET:
      return null;

    default:
      return state;
  }
}
