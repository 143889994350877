import React, {useState} from 'react';
import {useLocation} from "react-router-dom";
import {Tabs} from "../../components";
import tabRoutes, {spnTabRoutes} from "../ServiceProviders/tab-routes";
import PackagesPopup from "../ServiceProviders/popups/Packages/PackagesPopup";
import ServiceProviderNewPanel from "./ServiceProviderNewPanel";

const popups = [
    {
        title: "+ New Package",
        path: "packages",
        component: closeModal => <PackagesPopup closeModal={closeModal}/>
    }
];

function ServiceProviderNew({}) {
    const location = useLocation();
    const currentLocation = location.pathname.split("/", 3)[1];

    // States
    const [modal, setModal] = useState(); // Popup open or close state

    return (
        <>
            {/* Panels */}
            <Tabs
                routes={spnTabRoutes}
                title={popups.find(popup => currentLocation === popup.path)?.title}
                onClick={() => setModal(currentLocation)}
            />
        </>
    );
}

export default ServiceProviderNew;