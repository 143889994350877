import React from "react";
import styles from "./Icon.module.scss";

export default function Icon({ReactIcon, onClick, name, color, size}) {
    return (
        <ReactIcon
            size={size || "20"}
            onClick={onClick}
            style={{
                cursor: typeof onClick === "function" ? "pointer" : "auto"
            }}
            className={`clickable ${color ? styles?.[color] : styles.success}`}
        />
    );
}
