import React, {useEffect, useState, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NoData, Table} from "components";
import {useHistory} from "react-router-dom";
import {subscriptionRoutingAction} from "redux/actions";

export default function Subscription() {
    // Routers
    const id = useHistory().location.pathname.split("/")[3];

    // Redux
    const dispatch = useDispatch();
    const {subscriptionRouting} = useSelector(state => state);

    const [tableRows, setTableRows] = useState([]);

    useEffect(() => {
        dispatch(subscriptionRoutingAction.get(id));
    }, [dispatch]);

    useEffect(() => {
        let filtered = subscriptionRouting;

        if (!filtered?.length) {
            setTableRows();
            return;
        }

        // Map table rows
        filtered = filtered?.map(obj => {
            return {
                routing: {
                    id: obj?.id,
                    sub_category_id: obj?.sub_category_id,
                    routing_before: obj?.routing_before,
                    status: obj?.status,
                    type: obj?.type,
                    no_of_route: obj?.no_of_route,
                    assigned_provider_id: obj?.assigned_provider_id,
                    commission_from: obj?.commission_from,
                    commission_to: obj?.commission_to,
                    created_at: `${new Date(obj?.created_at).toLocaleString()}`,
                    updated_at: `${new Date(obj?.updated_at).toLocaleString()}`
                },
                routingDetails: obj.getroutngdetails?.map(objDetail => ({
                    id: objDetail?.id,
                    routing_before: objDetail?.routing_before,
                    status: objDetail?.status,
                    no_of_route: objDetail?.no_of_route,
                    provider_id: objDetail?.provider_id,
                    sp_routing_id: objDetail?.sp_routing_id,
                    commission: objDetail?.commission,
                    created_at: `${new Date(objDetail?.created_at).toLocaleString()}`,
                    updated_at: `${new Date(objDetail?.updated_at).toLocaleString()}`
                }))
            };
        });

        setTableRows(filtered);
    }, [subscriptionRouting]);

    // Table Header
    const secTableHeaders = useMemo(
        () => [
            {
                title: "ID",
                sortBy: "id"
            },
            {title: "Routing Before"},
            {title: "Status"},
            {title: "Routes"},
            {title: "Provider"},
            {title: "SP Routing"},
            {title: "Commission"},
            {
                title: "Created",
                sortBy: "createdAt"
            },
            {
                title: "Updated",
                sortBy: "updatedAt"
            }
        ],
        [tableRows]
    );

    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "ID",
                sortBy: "id"
            },
            {title: "Sub Category"},
            {title: "Routing Before"},
            {title: "Status"},
            {title: "Type"},
            {title: "Routes"},
            {title: "Assigned"},
            {title: "From"},
            {title: "To"},
            {
                title: "Created",
                sortBy: "createdAt"
            },
            {
                title: "Updated",
                sortBy: "updatedAt"
            }
        ],
        [tableRows]
    );

    return (
        <>
            {tableRows?.length ? (
                tableRows.map(item => (
                    <>
                        <br/>
                        <h4>Routing</h4>
                        <Table headers={tableHeaders} data={[item.routing]}/>
                        <br/>
                        <h4>Routing Details</h4>
                        <Table headers={secTableHeaders} data={item.routingDetails}/>
                    </>
                ))
            ) : (
                <NoData/>
            )}
        </>
    );
}
