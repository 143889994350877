import React from 'react';
import {useSelector} from "react-redux";
import {NoData, UnlimitedTable} from "../../../components";

function RoutingHead(props) {
  
  return (
    <div>
      <h4>Routing Close Summary</h4>
        {props.tableRows?.length ? (
            props.tableRows.map(item => (
                item.routingDetails.map(detail => {
                    if(detail.status == 'CLOSE'){
                       return(
                        <>
                        <table border="1" style={{width: '100%'}}>
                            <tr>
                                <th>ID</th>
                                <th>Routing Before</th>
                                <th>Status</th>
                                <th>Routes</th>
                                <th>Provider</th>
                                <th>SP Routing</th>
                                <th>Commission</th>
                                <th>Created</th>
                                <th>Updated</th>
                            </tr>
                            <tr>
                                <td>{detail.id}</td>
                                <td>{detail.routing_before}</td>
                                <td>{detail.status}</td>
                                <td>{detail.no_of_route}</td>
                                <td>{detail.provider_id}</td>
                                <td>{detail.sp_routing_id}</td>
                                <td>{detail.commission}</td>
                                <td>{detail.created_at}</td>
                                <td>{detail.updated_at}</td>
                            </tr>
                        </table>
                        </>
                       ) 
                    }
                })
            
            ))
        ) : (
            <NoData/>
        )}
        
      <hr></hr>
    </div>
  );
}


export default RoutingHead;