import React, { useMemo } from "react";
import {
  SwitchButton,
  TableVertical,
  StatusButton,
  DocumentButton
} from "components";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { orgAction } from "redux/actions";

export default function OrgProfile() {
  const dispatch = useDispatch();

  // Routers
  const history = useHistory();
  const path = history.location.pathname;
  const id = path.split("/")[3];

  // Redux
  const { organization, userRole } = useSelector(state => ({
    organization: state?.org?.find(item => item.id == id),
    userRole: state.userRole?.filter(role => role.module === "Organization")
  }));

  // Custom Functions
  const data = {
    id: organization?.id,
    orgName: organization?.orgName,
    ownerID: organization?.ownerID,
    managerID: organization?.managerID,
    registrationNumber: organization?.registrationNumber,
    category: organization?.category,
    cityID: organization?.cityID,
    addressID: organization?.addressID,
    status: (
      <StatusButton
        title={organization?.status}
        options={["pending", "approved", "rejected"]}
        onChange={status =>
          dispatch(orgAction.changeStatus(organization.id, status))
        }
      />
    ),
    rejectionReason: organization?.rejectionReason,
    createdAt: `${new Date(organization?.createdAt).toLocaleString()}`,
    updatedAt: `${new Date(organization?.updatedAt).toLocaleString()}`,
    documents: (
      <DocumentButton
        approved={
          organization?.documents?.filter(doc => doc.status === "approved")
            .length
        }
        uploaded={
          organization?.documents?.filter(doc => doc.documentURL).length
        }
        total={organization?.documents?.length}
        onClick={() =>
          history.push(
            `/${path.split("/", 2)[1]}/organization/${
              organization?.id
            }/documents`
          )
        }
      />
    ),
    enabled: (
      <SwitchButton
        enabled={organization?.enabled}
        onClick={
          userRole?.find(item => item.privilege === "Enable")
            ? () => dispatch(orgAction.toggle(organization.id))
            : null
        }
      />
    )
  };

  // Table Header
  const tableHeaders = useMemo(
    () => [
      {
        title: "Id"
      },
      {
        title: "Organization Name"
      },
      {
        title: "Owner"
      },
      {
        title: "Manager"
      },
      {
        title: "Registration No."
      },
      {
        title: "Category"
      },
      {
        title: "City"
      },
      {
        title: "Address"
      },
      {
        title: "Status"
      },
      {
        title: "Rejected Reason"
      },
      {
        title: "Created"
      },
      {
        title: "Updated"
      },
      {
        title: "Documents"
      },
      {
        title: "Enabled"
      }
    ],
    []
  );

  return (
    <div>
      <TableVertical
        heading="Organization Details"
        headers={tableHeaders}
        data={data}
      />
    </div>
  );
}
