import {
  GET_SUB_CATEGORIES,
  ADD_SUB_CATEGORIES,
  TOGGLE_SUB_CATEGORY,
  UPDATE_SUB_CATEGORY,
  RESET_SUB_CATEGORY,
} from "../actionTypes";

const subCategoriesReducer = (state = [], action) => {
  switch (action.type) {
    case GET_SUB_CATEGORIES:
      return action.payload;

    case ADD_SUB_CATEGORIES:
      return [...state, action.payload];

    case UPDATE_SUB_CATEGORY:
      return state.map((category) =>
        category.id === action.payload.id ? action.payload : category
      );

    case TOGGLE_SUB_CATEGORY:
      return state.map((category) =>
        category.id !== action.payload
          ? category
          : { ...category, enabled: !category.enabled }
      );

    case RESET_SUB_CATEGORY:
      return [];

    default:
      return state;
  }
};

export default subCategoriesReducer;
