import React, {useState, useEffect} from "react";
import {SideModal, Tabs} from "components";
import {subOrgModalRoutes} from "../tab-routes";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

export default function SubOrgModal() {
    // Routers
    const {id} = useParams();

    const subOrg = useSelector(state => state.subOrg);
    const [titles, setTitles] = useState();

    useEffect(() => {
        const orgDetail = subOrg?.find(item => item?.id == id);

        const titleArr = [
            {key: "Sub Organization Name", value: orgDetail?.orgName}
        ];
        setTitles(titleArr);
    }, [id]);

    return (
        <SideModal title="Sub Organization ID" titles={titles}>
            <Tabs routes={subOrgModalRoutes}/>
        </SideModal>
    );
}
