import React, { useState, useEffect } from "react";
import { TextInput, Modal } from "components";
import { useDispatch } from "react-redux";
import { rolesAction } from "redux/actions";

export default function RolesPopup({ closeModal, data }) {
  const dispatch = useDispatch();

  const [details, setDetails] = useState({
    role_name: ""
  });

  useEffect(() => {
    console.log(data);
    setDetails(data);
  }, [data]);

  const onSave = () => {
    if (data) {
      dispatch(rolesAction.update(details));
    } else {
      dispatch(rolesAction.add(details));
    }
    closeModal();
  };

  const handleChange = setter => event =>
    setter(prev => ({ ...prev, [event.target.name]: event.target.value }));

  return (
    <Modal
      title={data ? "Edit Role" : "Create New Role"}
      closeModal={closeModal}
      onSave={onSave}>
      <TextInput
        label="Name"
        placeholder="Name"
        name="role_name"
        onChange={handleChange(setDetails)}
        value={details?.role_name}
      />
    </Modal>
  );
}
