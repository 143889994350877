import {
    ADD_ADDRESS,
    GET_ADDRESS,
    TOGGLE_ADDRESS,
    UPDATE_ADDRESS,
    RESET_ADDRESS,
} from "../actionTypes";

const addressReducer = (state = null, action) => {
    switch (action.type) {
        case GET_ADDRESS:
            return action.payload;

        case ADD_ADDRESS:
            return action.payload;

        case UPDATE_ADDRESS:
            return state.map((item) =>
                item.id === action.payload.id ? action.payload : item
            );

        case TOGGLE_ADDRESS:
            return state.map((item) =>
                item.id !== action.payload.id
                    ? item
                    : {...item, enabled: !item.enabled}
            );

        case RESET_ADDRESS:
            return null;

        default:
            return state;
    }
};

export default addressReducer;
