import React from 'react';
import styles from './GreenButton.module.scss';

const GreenButton = ({title, onClick}) => (
    <button className={styles.green__btn} onClick={onClick}>
         {title}
    </button>
);

export default GreenButton;
