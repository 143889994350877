import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "components";
import { useHistory } from "react-router-dom";
import { memberStatusAction } from "redux/actions";

export default function OrgDocumentsPanel() {
  // Routers
  const history = useHistory();
  const path = history.location.pathname;
  const id = path.split("/")[3];

  // States
  const [tableRows, setTableRows] = useState();
  const [status, setStatus] = useState({
    id: null,
    status: null,
    comment: ""
  });

  const dispatch = useDispatch();
  const memberStatus = useSelector(state => state.memberStatus);

  useEffect(() => {
    dispatch(memberStatusAction.get());

    return () => {};
  }, []);

  useEffect(() => {
    let filtered = memberStatus?.org;
    if (!filtered) {
      setTableRows();
      return;
    }

    filtered = Object.values(filtered);
    filtered = filtered?.filter(item => item?.[0]?.orgID == id)?.flat();
    console.log(filtered);
    // Map table rows
    let data = filtered?.map(obj => ({
      id: obj.id,
      inviteeName: obj.inviteeName,
      inviteMethod: obj.inviteMethod,
      orgID: obj.orgID,
      subOrgID: obj.subOrgID,
      refererID: obj.refererID,
      mobile: obj.mobile,
      role: obj.role,
      status: obj.status,
      createdAt: `${new Date(obj.createdAt).toLocaleString()}`,
      updatedAt: `${new Date(obj.updatedAt).toLocaleString()}`
    }));

    setTableRows(data);
  }, [memberStatus]);

  // Table Header
  const tableHeaders = [
    {
      title: "ID",
      sortBy: "id"
    },
    {
      title: "Invitee Name",
      sortBy: "inviteeName"
    },
    {
      title: "Invite Method",
      sortBy: "inviteMethod"
    },
    {
      title: "Org"
    },
    {
      title: "Sub Org",
      sortBy: "subOrgID"
    },
    {
      title: "Referer ID",
      sortBy: "refererID"
    },
    {
      title: "Mobile"
    },
    {
      title: "Role"
    },
    {
      title: "Status"
    },
    {
      title: "Created",
      sortBy: "createdAt"
    },
    {
      title: "Updated",
      sortBy: "updatedAt"
    }
  ];

  return (
    <div>
      <Table headers={tableHeaders} data={tableRows} />
    </div>
  );
}
