import {useEffect, useState} from "react";
import "./App.css";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import {LoadingSpinner, SnackBar} from "components";
import Admin from "layout/Admin";
import authRoutes from "auth-routes";
import {useDispatch, useSelector} from "react-redux";
import {handleAuth} from "helper/handleAuth";

function App() {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        handleAuth(dispatch);
    }, [dispatch]);

    useEffect(() => {
        setLoading(false);
    }, [user]);

    if (loading) return <LoadingSpinner/>;

    return (
        <>
            <Router>
                <Switch>
                    {!user ? (
                        <>
                            <Redirect from="/" to="/login"/>
                            {authRoutes?.length &&
                                authRoutes.map((router, index) => (
                                    <Route path={router.path} key={index}>
                                        {router.component}
                                    </Route>
                                ))}
                        </>
                    ) : (
                        <Route path="/">
                            <Admin/>
                        </Route>
                    )}
                </Switch>
            </Router>
            <SnackBar/>
        </>
    );
}

export default App;

// cpanel: https://142.44.137.204:2083/
// user: v2adminclykk
// pass  h_=WxhA;~5K!
