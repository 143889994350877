import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/sass/main.scss";
import SnackbarProvider from "react-simple-snackbar";
import {ErrorBoundary} from "ErrorBoundary";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./store";

ReactDOM.render(
    <React.StrictMode>
        {/*<ErrorBoundary>*/}
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <SnackbarProvider>
                        <App/>
                    </SnackbarProvider>
                </PersistGate>
            </Provider>
        {/*</ErrorBoundary>*/}
    </React.StrictMode>,
    document.getElementById("root")
);

reportWebVitals();
