import React from 'react';
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {UnlimitedTable} from "../../../components";

function ChecklistTab({clickedSubscription}) {
    // Routers
    const id = clickedSubscription.id;

    // Redux
    const order = useSelector(state =>
        state.subsDetail?.qnA.filter(item => item.questionType === "checklist")
    );

    return (
        <div>
            {order?.map((qna, index) => (
                <UnlimitedTable
                    key={index}
                    question={qna.questionName}
                    answers={qna.answers}
                />
            ))}
        </div>
    );
}

export default ChecklistTab;