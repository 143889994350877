import axios from "axios";
import cookies from "js-cookie";

export default async function customerToken({mobile, countryCode}) {
    const {data} = await axios.post(
        "https://cgapp.clykk.com/um/clykk/ext/um/v1/sign-in",
        {
            mobile,
            countryCode
        }
    );

    return data?.access_token;
}
