import React, {useEffect, useState, useMemo} from "react";
import {MdEdit} from "react-icons/md";
import styles from "./styles.module.scss";
import {
    MultiTable,
    SearchButton,
    LightTextButton,
    FilterDropdown,
    SwitchButton,
    Button
} from "components";
import PackageQnAPopup from "../popups/PackageQnA/PackageQnAPopup";
import {useHistory} from "react-router-dom";
import {packageQnAAction} from "redux/actions";
import {useDispatch, useSelector} from "react-redux";

const PackageQnA = () => {
    // Routers
    const pid = useHistory().location.pathname.split("/")[3];

    // Redux
    const dispatch = useDispatch();

    const {packageQnA, userRole} = useSelector(state => ({
        packageQnA: state.packageQnA?.qna,
        userRole: state.userRole?.filter(role => role.module === "Users")
    }));

    // States
    const [tableRows, setTableRows] = useState();
    const [editableDetail, setEditableDetails] = useState();
    const [isPopupVisible, setPopupVisible] = useState();

    // Filter States
    const [status, setStatus] = useState();

    // Fetch Package Add On
    useEffect(() => {
        if (userRole?.find(item => item.privilege === "Read"))
            dispatch(packageQnAAction.get(pid));

        return () => dispatch(packageQnAAction.reset());
    }, [dispatch]);

    useEffect(() => {
        if (!packageQnA?.length) return;
        const data = packageQnA?.map(qna => {
            let modifiedQA = {
                ...qna,
                edit: (
                    <MdEdit
                        size="20"
                        color="#528ff0"
                        onClick={() => {
                            setEditableDetails(qna);
                            console.log(qna);
                            setPopupVisible(true);
                        }}
                        className="clickable"
                    />
                )
            };

            modifiedQA.answers = qna.answers.map(ans => ({
                answerType: ans.answerType,
                answer: ans.answer,
                sortOrder: ans.sortOrder,
                createdAt: `${new Date(ans.createdAt).toLocaleString()}`,
                updatedAt: `${new Date(ans.updatedAt).toLocaleString()}`,
                enabled: (
                    <SwitchButton
                        enabled={ans.enabled}
                        onClick={
                            userRole?.find(item => item.privilege === "Enable")
                                ? () => {
                                    console.log("packageQnA =>", packageQnA)
                                    dispatch(
                                        packageQnAAction.toggle(pid, ans.id)
                                    )
                                }
                                : null
                        }
                    />
                )
            }));

            return modifiedQA;
        });
        setTableRows(data);
    }, [packageQnA, status]);

    // Search
    const onSearch = () => {
    };

    // MultiTable Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "Question Type"
            },
            {
                title: "Question Name"
            },
            {
                title: "Sort Order"
            },
            {
                title: "Edit"
            }
        ],
        []
    );

    const secTableHeaders = useMemo(
        () => [
            {
                title: "Answer Type"
            },
            {
                title: "Answer Name"
            },
            {
                title: "Sort Order"
            },
            {
                title: "Created At"
            },
            {
                title: "Updated At"
            },
            {
                title: "Enabled"
            }
        ],
        []
    );

    return (
        <div>
            <div className={styles.filter}>
                <FilterDropdown
                    onChange={e => setStatus(e.target.value)}
                    label="Status"
                    options={["All", "Enabled", "Disabled"]}
                />
                <SearchButton onClick={onSearch}/>
                <LightTextButton/>

                <div className={styles.float__end}>
                    <Button
                        title="+ New Package QnA"
                        onClick={() => {
                            setEditableDetails();
                            setPopupVisible(true);
                        }}
                    />
                </div>
            </div>

            {tableRows?.length
                ? tableRows.map((qna, index) => (
                    <MultiTable
                        key={index}
                        headers={tableHeaders}
                        data={[
                            {
                                questionType: qna.questionType,
                                questionName: qna.questionName,
                                questionSortOrder: qna.questionSortOrder,
                                edit: qna.edit
                            }
                        ]}
                        secHeaders={secTableHeaders}
                        secData={qna.answers}
                    />
                ))
                : null}

            {isPopupVisible && (
                <PackageQnAPopup
                    data={editableDetail}
                    closeModal={() => setPopupVisible(false)}
                />
            )}
        </div>
    );
};

export default PackageQnA;
