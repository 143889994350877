import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import { FixedMultiTable, ImageStamp, NoData, TextLink } from "components";
import { useSelector, useDispatch } from "react-redux";
import { subsDetailAction } from "redux/actions";
import { useLocation } from "react-router";

export default function BookingDetails({ customer }) {
  const id = useLocation().pathname.split("/")[3];

  const dispatch = useDispatch();
  const subsDetail = useSelector(state => state?.subsDetail);
  
  useEffect(() => {
    dispatch(subsDetailAction.get(id));
  }, [id]);

  if (!subsDetail) return <NoData />;

  return (
    <div className={styles.container}>
      <p>Hello {customer}</p>

      <h4>
        Thank you for your Service Booking with CLYKK Service India Private
        Limited
      </h4>
      <p>(customer.support@clykk.com)</p>

      <p>
        It may take a few moments for this transaction to appear in your
        account.
      </p>

      <h4>Marketplace</h4>
      <p>CLYKK Service India Private Limited</p>
      <p>customer.support@clykk.com</p>

      <div className={styles.row}>
        <h4>Service Date: </h4>
        <p>{subsDetail?.serviceDate}</p>
        <h4>Service Time: </h4>
        <p>{subsDetail?.serviceTime}</p>
      </div>

      <div className={styles.row}>
        <div>
          <h4>Service Address: </h4>
          <p>{subsDetail?.serviceAddress?.line1}</p>
          <TextLink
            text={subsDetail?.serviceAddress?.line2}
            onClick={() =>
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${subsDetail?.serviceAddress?.latitude},${subsDetail?.serviceAddress?.longitude}`,
                "_blank"
              )
            }
          />
          <p>{subsDetail?.serviceAddress?.line3}</p>
          <p>{subsDetail?.serviceAddress?.landmark}</p>
          <p>{subsDetail?.serviceAddress?.pincode}</p>
          <p>{subsDetail?.serviceAddress?.city}</p>
          <p>{subsDetail?.serviceAddress?.state}</p>
          <p>{subsDetail?.serviceAddress?.country}</p>
        </div>
        <div>
          <h4>Delivery Address: </h4>
          <p>{subsDetail?.deliveryAddress?.line1}</p>
          <TextLink
            text={subsDetail?.deliveryAddress?.line2}
            onClick={() =>
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${subsDetail?.deliveryAddress?.latitude},${subsDetail?.deliveryAddress?.longitude}`,
                "_blank"
              )
            }
          />
          <p>{subsDetail?.deliveryAddress?.line3}</p>
          <p>{subsDetail?.deliveryAddress?.landmark}</p>
          <p>{subsDetail?.deliveryAddress?.pincode}</p>
          <p>{subsDetail?.deliveryAddress?.city}</p>
          <p>{subsDetail?.deliveryAddress?.state}</p>
          <p>{subsDetail?.deliveryAddress?.country}</p>
        </div>
      </div>

      <FixedMultiTable
        headers={["Package", " ", "Qty", "Base Price", "Selling Price"]}
        data={
          subsDetail?.package
            ? [
                {
                  packageImage: (
                    <ImageStamp src={subsDetail?.package.packageImage} />
                  ),
                  packageName: subsDetail?.package.packageName,
                  qty: subsDetail?.package?.itemCount,
                  basePrice: (
                    <span
                      style={{
                        textDecorationLine: "line-through",
                        color: "red"
                      }}>
                      &#8377; {subsDetail?.package?.basePrice?.toFixed(2)}
                    </span>
                  ),
                  sellingPrice: (
                    <span>
                      &#8377;{subsDetail?.package?.sellingPrice?.toFixed(2)}
                    </span>
                  )
                }
              ]
            : null
        }
        secHeaders={["AddOns", " ", "Qty", "Base Price", "Selling Price"]}
        secData={subsDetail?.addOns?.map(item => ({
          packageImage: <ImageStamp src={item.packageImage} />,
          packageName: item?.packageName,
          qty: item?.itemCount,
          basePrice: (
            <p style={{ textDecorationLine: "line-through", color: "red" }}>
              &#8377; {item?.basePrice}
            </p>
          ),
          sellingPrice: <p>&#8377;{item?.sellingPrice}</p>
        }))}
      />

      <div className={styles.amounts}>
        <div className={styles.row}>
          <h4>Sub Total:</h4>
          <p>
            &#8377;
            {`${subsDetail?.transaction?.[0]?.total?.toFixed(2)}`}
          </p>
        </div>
        <div className={styles.row}>
          <h4>Coupon:</h4>
          <p>{subsDetail?.transaction?.[0]?.discountType}</p>
        </div>
        <div className={styles.row}>
          <h4>Coupon Value:</h4>
          <p>&#8377; {subsDetail?.transaction?.[0]?.discount}</p>
        </div>
        <div className={styles.row}>
          <h4>Tax Amount:</h4>
          <p>&#8377; {subsDetail?.transaction?.[0]?.taxAmount?.toFixed(2)}</p>
        </div>
        <div className={styles.row}>
          <h4>Payment Method:</h4>
          <p>{subsDetail?.transaction?.[0]?.paymentType}</p>
        </div>
        <div className={styles.row}>
          <h4>Total:</h4>
          <p>
            &#8377;
            {`${subsDetail?.transaction?.[0]?.payableAmount?.toFixed(2)}`}
          </p>
        </div>
      </div>
    </div>
  );
}
