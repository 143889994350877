import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from "react-redux";
import {ImageStamp, StatusButton, Table} from "../../../components";
import { customerCouponsAction } from "redux/actions";

function ReferAndEarnTab({clickedCustomer}) {

    const currentLocation = clickedCustomer.id

    // States
    const [tableRows, setTableRows] = useState();
    const [coupons, setCoupons] = useState();

    // Redux actions
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(customerCouponsAction.get(currentLocation)).then(data =>
            setCoupons(data)
        );
    }, []);

    useEffect(() => {
        let filtered = coupons;
        console.log(filtered);

        if (!filtered?.length) {
            setTableRows();
            return;
        }

        filtered = filtered?.filter(item => item?.title === "CLYKK SHARE & EARN");

        // Map table rows
        const data = filtered?.map(obj => ({
            title: obj.title,
            image: <ImageStamp src={obj.image}/>,
            discount: obj.discount,
            discountType: obj.discountType,
            discountCode: obj.discountCode,
            minDiscount: obj.minDiscount,
            maxDiscount: obj.maxDiscount,
            launchAt: obj.launchAt,
            expireAt: obj.expireAt,
            status: (
                <StatusButton options={["pending", "approved"]} title={obj.status}/>
            ),
            createdAt: `${new Date(obj.createdAt).toLocaleString()}`,
            updatedAt: `${new Date(obj.updatedAt).toLocaleString()}`
        }));

        setTableRows(data);
    }, [coupons]);

    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "Title"
            },
            {
                title: "Image"
            },
            {
                title: "Discount"
            },
            {
                title: "Discount Type"
            },
            {
                title: "Discount Code"
            },
            {
                title: "Min"
            },
            {
                title: "Max"
            },
            {
                title: "Launch"
            },
            {
                title: "Expire",
                sortBy: "expireAt"
            },
            {
                title: "Status",
                sortBy: "status"
            },
            {
                title: "Created"
            },
            {
                title: "Updated"
            }
        ],
        [tableRows]
    );

    return <Table headers={tableHeaders} data={tableRows}/>;
}

export default ReferAndEarnTab;