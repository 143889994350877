import { GET_SERVICE_RULES, LOADING } from "../actionTypes";
import axios from "axios";
import { AWS_URL_CM_8090 } from "configurations";
import { adminToken } from "helper";

const { SET_TRUE, SET_FALSE } = LOADING;
export const get = () => {
  return dispatch => {
    dispatch({ type: SET_TRUE });

    adminToken()
      .then(token =>
        axios.get(`${AWS_URL_CM_8090}/service-rules`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
      )
      .then(res => dispatch({ type: GET_SERVICE_RULES, payload: res.data }))
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};
