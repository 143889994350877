import React, {useState} from 'react';
import {IoIosClose} from "react-icons/io";
import {Box, makeStyles, Tab, Tabs, useTheme} from "@material-ui/core";
import PropTypes from "prop-types";
import ProfileTab from "../../Report/Tabs/ProfileTab";
import AddressTab from "../../Report/Tabs/AddressTab";
import CartTab from "../../Report/Tabs/CartTab";
import TodoTab from "../../Report/Tabs/TodoTab";
import SubscriptionsTab from "../../Report/Tabs/SubscriptionsTab";
import BookingDetailsTab from "./BookingDetailsTab";
import SubscriptionTab from "./SubscriptionTab";
import DetailsTab from "./DetailsTab";
import RequirementsTab from "./RequirementsTab";
import ChecklistTab from "./ChecklistTab";

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


const useStyles = makeStyles((theme) => ({
    indicator: {
        backgroundColor: "#6CD7BB"
    }
}))

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            style={{backgroundColor: "white"}}
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <p>{children}</p>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function OrderModal({clickedOrder, handleCloseModal}) {
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [modalStyle] = useState(getModalStyle);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const classes = useStyles()

    return (
        <div style={modalStyle} className={'modal-paper'}>
            <div className={'modal-top-bar'}>
                <IoIosClose
                    size={20}
                    className={'modal-close'}
                    onClick={handleCloseModal}
                />
            </div>

            <div style={{margin: "0 0 10px 0"}}>
                <h5>Order ID: {clickedOrder.cartID}</h5>
            </div>

            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                classes={{
                    indicator: classes.indicator
                }}>
                <Tab label="Booking Details" {...a11yProps(0)} />
                <Tab label="Subscriptions"
                     style={{}} {...a11yProps(1)} />
                <Tab label="Package Detail"
                     {...a11yProps(2)} />
                <Tab label="Customer Requirements"
                     {...a11yProps(3)} />
                <Tab label="SP Checklist"
                     {...a11yProps(4)} />
            </Tabs>

            <div className={'modal-body'}>
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <BookingDetailsTab id={clickedOrder.cartID}/>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <SubscriptionTab id={clickedOrder.cartID}/>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <DetailsTab id={clickedOrder.cartID}/>
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                    <RequirementsTab id={clickedOrder.cartID}/>
                </TabPanel>
                <TabPanel value={value} index={4} dir={theme.direction}>
                    <ChecklistTab id={clickedOrder.cartID}/>
                </TabPanel>
            </div>

        </div>
    );
}

export default OrderModal;