import { LOADING } from "../actionTypes";

export default function loadingReducer(state = null, action) {
  const { SET_TRUE, SET_FALSE } = LOADING;

  switch (action.type) {
    case SET_TRUE:
      return true;

    case SET_FALSE:
      return null;

    default:
      return state;
  }
}
