import React, {useEffect, useState} from "react";
import styles from "./styles.module.scss";
import {MdKeyboardArrowRight, MdKeyboardArrowLeft} from "react-icons/md";
import {
    HiOutlineChevronDoubleLeft,
    HiOutlineChevronDoubleRight,
} from "react-icons/hi";

export default function PageNavigator({pageCount, currentPage, onClick}) {
    console.log('currentPage---'+currentPage, pageCount)
    const init = pageCount - currentPage >= 5 ? currentPage : pageCount - 4;
    const size = currentPage + 4 <= pageCount ? currentPage + 4 : pageCount;

    const createItem = () => {
        if (!pageCount) return;

        const items = [];

        for (let i = init > 0 ? init : 1; i > 0 && i <= size; i++) {
            items.push(
                <li
                    className={currentPage === i ? styles.active : ""}
                    onClick={() => onClick(i)}
                    key={i}
                >
                    {i}
                </li>
            );
        }


        return items;
    };

    const lastItem = () => {
        if (!pageCount) return;
        const items = [];

        for (let i = pageCount - 2; i <= pageCount; i++) {
            items.push(
                <li
                    className={currentPage === i ? styles.active : ""}
                    onClick={() => onClick(i)}
                    key={i}
                >
                    {i}
                </li>
            );
        }


        return items;
    }

    return (
        <nav className={styles.container}>
            <ul>
                {currentPage > 4 && (
                    <li onClick={() => onClick(init - 4)}>
                        <HiOutlineChevronDoubleLeft size={10}/>
                    </li>
                )}
                {currentPage > 1 && (
                    <li onClick={() => onClick(currentPage - 1)}>
                        <MdKeyboardArrowLeft/>
                    </li>
                )}
                {createItem()}
                {pageCount - 6 > currentPage &&
                    <>
                        <p style={{margin: "0 10px"}}>......</p>
                        {lastItem()}
                    </>
                }
                {/* {pageCount && pageCount !== currentPage && (
                    <li onClick={() => onClick(currentPage + 1)}>
                        <MdKeyboardArrowRight/>
                    </li>
                )}
                {pageCount && pageCount !== currentPage && (
                    <li onClick={() => onClick(size)}>
                        <HiOutlineChevronDoubleRight size={10}/>
                    </li>
                )} */}
            </ul>
        </nav>
    );
}
