import {
  GET_SP_APP_PREFERENCES,
  ADD_SP_APP_PREFERENCES,
  TOGGLE_SP_APP_PREFERENCES,
  TOGGLE_ANS_SP_APP_PREFERENCES,
  UPDATE_SP_APP_PREFERENCES,
  RESET_SP_APP_PREFERENCES,
} from "../actionTypes";

export default function spAppPreferencesReducer(state = null, action) {
  switch (action.type) {
    case GET_SP_APP_PREFERENCES:
      return action.payload;

    case ADD_SP_APP_PREFERENCES:
      console.log(action.payload);
      const newState = state?.length
        ? [...state, action.payload]
        : [action.payload];
      return newState;

    case UPDATE_SP_APP_PREFERENCES:
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );

    case TOGGLE_SP_APP_PREFERENCES:
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );

    case TOGGLE_ANS_SP_APP_PREFERENCES:
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );

    case RESET_SP_APP_PREFERENCES:
      return null;

    default:
      return state;
  }
}
