import Routing from "./panels/Routing";
import SideModal from "./modals/SideModal";

const tabRoutes = [
  {
    name: "Routing",
    path: "/routing",
    component: <Routing />,
    modalRoutes: [
      {
        name: "",
        path: "/orders/orders/:id",
        component: <SideModal />,
      },
    ],
  },
];

export default tabRoutes;

export const modalTabRoutes = [];
