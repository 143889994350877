import React, { useEffect, useState, useMemo } from "react";
import { Table } from "components";
import { useHistory } from "react-router-dom";
import { packageShareAction } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";

const PackageShare = () => {
  // Routers
  const pid = useHistory().location.pathname.split("/")[3];

  // Redux
  const dispatch = useDispatch();

  const { packageShare, userRole } = useSelector(state => ({
    packageShare: state.packageShare,
    userRole: state.userRole?.filter(role => role.module === "Users")
  }));

  // States
  const [tableRows, setTableRows] = useState();

  // Fetch Package Add On
  useEffect(() => {
    if (userRole?.find(item => item.privilege === "Read"))
      dispatch(packageShareAction.get(pid));

    return () => dispatch(packageShareAction.reset());
  }, [dispatch]);

  useEffect(() => {
    let filtered = packageShare?.[0]?.users;

    if (!filtered?.length) return;

    // Map table rows
    const data = filtered?.map(obj => ({
      id: obj?.id,
      firstName: obj?.firstName,
      lastName: obj?.lastName,
      sharedVia: obj?.sharedVia,
      receiverName: obj?.receiverName,
      contactInfo: obj?.contactInfo,
      packageShareCreatedAt: new Date(
        obj?.packageShareCreatedAt
      ).toLocaleString()
    }));

    setTableRows(data);
  }, [packageShare]);

  // Table Header
  const tableHeaders = useMemo(
    () => [
      {
        title: "ID"
      },
      {
        title: "First Name"
      },
      {
        title: "Last Name"
      },
      {
        title: "Shared Via"
      },
      {
        title: "Receiver Name"
      },
      {
        title: "Contact Info"
      },
      {
        title: "Created At",
        sortBy: "packageShareCreatedAt"
      }
    ],
    [tableRows]
  );

  return <Table headers={tableHeaders} data={tableRows} />;
};

export default PackageShare;
