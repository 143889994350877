import React, { useState } from "react";
import styles from "./../styles.module.scss";
import { ImageUpload, Button } from "components";

export default function CategoryImage({ onClick, onImageSelect, imageSrc }) {
  return (
    <div className={styles.main__container}>
      <ImageUpload image={imageSrc} onImageSelect={onImageSelect} />
      <div className={styles.btn__container}>
        <Button title="Save" onClick={onClick} />
      </div>
    </div>
  );
}
