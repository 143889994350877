import React, {useEffect, useState, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import styles from "./styles.module.scss";
import {
    Table,
    SearchButton,
    LightTextButton,
    FilterDropdown,
    SwitchButton,
    ImageStamp,
    TextLink,
    TextInput,
    Status,
    Search
} from "components";
import {customersAction} from "redux/actions";
import PackagesPopup from "../popups/Packages/PackagesPopup";
import {useHistory, useLocation} from "react-router-dom";
import * as XLSX from "xlsx";
import {Modal} from "@material-ui/core";
import ReportModal from "../../Report/ReportModal";
import {adminToken} from "../../../helper";
import axios from "axios";
import {AWS_URL_UM_INT_8089} from "../../../configurations";
import SubscriptionModal from "../modals/SubscriptionModal";

const initialState = {
    userId: "",
    firstName: "",
    lastName: "",
    mobile: "",
    search: ""
};

export default function CustomersPanel() {
    // Routers
    const {push, location} = useHistory();

    const [openModal, setOpenModal] = useState(false)

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false)

    const handleOpenSubscriptionModal = () => {
        setOpenSubscriptionModal(true)
    }

    const handleCloseSubscriptionModal = () => {
        setOpenSubscriptionModal(false)
    }

    const [clickedCustomer, setClickedCustomer] = useState(null)
    const [clickedSubscription, setClickedSubscription] = useState(null)

    // Query based filter
    const locationSearch = useLocation().search;
    const query = new URLSearchParams(locationSearch);
    const userID = query.get("userID");

    // States
    const [tableRows, setTableRows] = useState();
    const [editableDetail, setEditableDetails] = useState();
    const [isPopupVisible, setPopupVisible] = useState();

    // const [filterState, setFilterState] = useState(false)

    // Filter States
    const [status, setStatus] = useState();
    const [filterState, setfilterState] = useState(initialState);

    // Redux actions
    const dispatch = useDispatch();
    const {customers, userRole} = useSelector(state => ({
        customers: state.customers,
        userRole: state.userRole?.filter(role => role.module === "Customers")
    }));


    useEffect(() => {
        if (userRole?.find(item => item.privilege === "Read"))
            dispatch(customersAction.get(1));
        return () => dispatch(customersAction.reset());
    }, []);

    const onPageChange = e => dispatch(customersAction.get(e));

    useEffect(() => {
        console.log('length =>', customers?.users?.length)
        if (!customers?.users?.length) {
            setTableRows();
            return;
        }

        let filtered = customers.users;
        if (userID) {
            filtered = filtered?.filter(user => user.userId == userID);
        }

        // filter based on Status
        filtered = filtered?.filter(item =>
            status === "Enabled"
                ? item.enabled
                : status === "Disabled"
                    ? !item.enabled
                    : item
        );

        filtered = internalSearch("userId", filtered);
        filtered = internalSearch("firstName", filtered);
        filtered = internalSearch("lastName", filtered);
        filtered = internalSearch("mobile", filtered);

        let newData = [];

        // Map table rows
        filtered?.forEach(obj => {
            const data = {
                userId: (
                    <TextLink
                        text={obj?.userId}
                        onClick={() => {
                            // push(`${location.pathname}/${obj?.userId}`)
                            setClickedCustomer({id: obj?.userId, mobile: obj?.mobile, name: obj?.firstName + " " + obj?.lastName})
                            handleOpenModal()
                        }}
                    />
                ),
                firstName: obj?.firstName,
                lastName: obj?.lastName,
                picture: <ImageStamp src={obj?.picture}/>,
                mobile: obj?.mobile,
                lastLoginAt: `${new Date(obj?.lastLoginAt).toLocaleString()}`,
                createdAt: `${new Date(obj?.createdAt).toLocaleString()}`,
                updatedAt: `${new Date(obj?.updatedAt).toLocaleString()}`,
                active: <Status active={obj?.active}/>,
                enabled: (
                    <SwitchButton
                        enabled={obj?.enabled}
                        onClick={
                            userRole?.find(item => item.privilege === "Enable")
                                ? () => dispatch(customersAction.toggle(obj?.userId))
                                : null
                        }
                    />
                )
            };
            newData.push(data);
        });

        setTableRows(newData);
    }, [customers, status, filterState]);

    // Search based on category & sub category name
    const onSearch = () => {
    };

    // internal search
    const internalSearch = (searchBy, rows) => {
        if (!filterState?.[searchBy]) return rows;

        const filteredRows =
            rows?.length &&
            rows.filter(
                item =>
                    item[searchBy]
                        ?.toString()
                        .toLowerCase()
                        .indexOf(filterState?.[searchBy]?.toLowerCase()) !== -1
            );

        return filteredRows;
    };

    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "Customer ID",
                sortBy: "userId"
            },
            {
                title: "First Name",
                sortBy: "firstName"
            },
            {
                title: "Last Name",
                sortBy: "lastName"
            },
            {
                title: "Image"
            },
            {
                title: "Contact"
            },
            {
                title: "Last Login",
                sortBy: "lastLoginAt"
            },
            {
                title: "Created At",
                sortBy: "createdAt"
            },
            {
                title: "Updated At",
                sortBy: "updatedAt"
            },
            {
                title: "Active"
            },
            {
                title: "Enabled"
            }
        ],
        [tableRows]
    );
    //v2-app
    const exportDataFunc = () => {
        adminToken()
            .then(token =>
                axios.get(
                    `${AWS_URL_UM_INT_8089}/get-user-list?pageNumber=1&pageSize=${customers?.count}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                ).then((response) => {
                        const rows = response.data.data.users.map(item => {
                            return {
                                "First Name": item.firstName,
                                "Last Name": item.lastName,
                                "Mobile": item.mobile,
                                "Last Login At": item.lastLoginAt,
                                "Payment Status": item.payment_status,
                                "Created At": item.createdAt,
                                "Updated At": item.updatedAt,
                                "Active": item.active,
                                "Enabled": item.enabled
                            }
                        })

                        const worksheet = XLSX.utils.json_to_sheet(rows);
                        const workbook = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
                        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
                        XLSX.writeFile(workbook, "DataSheet.xlsx");
                    }
                )
            )
    }

    return (
        <div>
            <div className={styles.filter}>
                <Search
                    name="search"
                    value={filterState?.search}
                    onChange={e =>
                        setfilterState({...filterState, [e.target.name]: e.target.value})
                    }
                    onClick={() =>
                        dispatch(
                            customersAction.search({
                                pageNumber: 1,
                                query: filterState?.search
                            })
                        )
                    }
                    clear={() => {
                        setfilterState({...filterState, 'search': ""})
                        dispatch(
                            customersAction.search({
                                pageNumber: 1,
                                query: ""
                            })
                        )
                    }}
                />
                <button className={'btn btn-primary m-auto'} style={{margin: 10}}
                        onClick={exportDataFunc}
                >Export Data
                </button>

                {/*<LightTextButton onClick={() => setfilterState(initialState)}/>*/}
            </div>

            <Table
                headers={tableHeaders}
                data={tableRows}
                current={customers?.pageNumber}
                totalPage={Math.ceil(customers?.count / 10)}
                onPageChange={onPageChange}
                state={filterState !== initialState}
                length={tableRows?.length}
                r_length={customers?.users?.length}
                t_length={customers?.count}
                exportData={false}
                // exportDataFunc={exportDataFunc}
            />

            {isPopupVisible && (
                <PackagesPopup
                    data={editableDetail}
                    closeModal={() => setPopupVisible(false)}
                />
            )}

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <ReportModal clickedCustomer={clickedCustomer} handleCloseModal={handleCloseModal}
                             setClickedSubscription={setClickedSubscription}
                             handleOpenSubscriptionModal={handleOpenSubscriptionModal}/>
            </Modal>

            <Modal
                open={openSubscriptionModal}
                onClose={handleCloseSubscriptionModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <SubscriptionModal clickedSubscription={clickedSubscription} handleCloseModal={handleCloseSubscriptionModal}/>
            </Modal>


        </div>
    );
}
