import React from "react";
import {UnlimitedTable} from "components";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

export default function Checklist() {
    // Routers
    const id = useHistory().location.pathname.split("/")[3];

    // Redux
    const order = useSelector(state =>
        state.orders
            ?.find(item => item.cartID == id)
            ?.qnA.filter(item => item.questionType === "checklist")
    );

    return (
        <div>
            {order?.map((qna, index) => (
                <UnlimitedTable
                    key={index}
                    question={qna.questionName}
                    answers={qna.answers}
                />
            ))}
        </div>
    );
}
