import axios from "axios";
import {MEMBER_STATUS, LOADING} from "../actionTypes";
import {adminToken} from "../../helper";

const {GET} = MEMBER_STATUS;
const {SET_FALSE, SET_TRUE} = LOADING;

export const get = () => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken()
        axios
            .get(`https://cgapp.clykk.com/sp/clykk/int/sp/v1/invitations`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            .then(res => {
                dispatch({type: GET, payload: res.data.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};
