import React from "react";
import styles from "./styles.module.scss";
import {IoIosClose} from "react-icons/io";
import {TransparentBG} from "../index";

export default function TabModal({
                                     tabTitle,
                                     tabs,
                                     tabIndex,
                                     setTabIndex,
                                     closeModal
                                 }) {
    return (
        <TransparentBG>
            <div className={styles.content__container}>
                <IoIosClose
                    size={20}
                    className={styles.icon__close}
                    onClick={closeModal}
                />

                <div className={styles.sidebar}>
                    <h6>{tabTitle}</h6>

                    <div className={styles.tabs__container}>
                        {tabs?.length
                            ? tabs.map(({name}, index) => (
                                <div
                                    key={`tab-${index}`}
                                    className={`${styles.tab} ${
                                        index === tabIndex && styles.active
                                    }`}
                                    onClick={() => setTabIndex(index)}>
                                    {name}
                                </div>
                            ))
                            : null}
                    </div>
                </div>
                <div className={styles.component__container}>
                    {tabs?.length
                        ? tabs.map(({component}, index) => {
                            if (tabIndex === index)
                                return (
                                    <React.Fragment key={`tab-component-${index}`}>
                                        {component}
                                    </React.Fragment>
                                );
                            else return null;
                        })
                        : null}
                </div>
            </div>
        </TransparentBG>
    );
}
