import React from "react";
import styles from "./../styles.module.scss";
import { TextInput, Button } from "components";
import { IoIosClose } from "react-icons/io";

export default function Details({
  onClick,
  details,
  setDetails,
  addAnswer,
  removeAnswer
}) {
  return (
    <div className={styles.main__container}>
      {details?.options?.length
        ? details?.options.map((ans, index) => (
            <div className={styles.ans__container} key={index}>
              <IoIosClose
                size={20}
                className={styles.icon__close}
                onClick={() => removeAnswer(index)}
              />
              <TextInput
                label="Option Name"
                placeholder="Option Name"
                name="optionName"
                onChange={e => setDetails(e, index)}
                value={ans.optionName}
              />
              <TextInput
                label="Option Text"
                placeholder="Option Text"
                name="optionText"
                onChange={e => setDetails(e, index)}
                value={ans.optionText}
              />
            </div>
          ))
        : null}

      <div className={styles.add__btn__container}>
        <Button title="Add Option" onClick={addAnswer} />
      </div>
      <div className={styles.add__btn__container}>
        <Button title="Save" onClick={onClick} />
      </div>
    </div>
  );
}
