import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";

const NotAcceptRouteMsg = (props) => {
    if(props.notarcount == 0){
        return '';//<p className={styles.newsubnotfound}>{props.newsubmsg}</p>;
    }else{
        return (
            <>
                <b data-bs-toggle="collapse" data-bs-target="#Route" style={{cursor:'pointer'}}><b style={{color:'green'}}>{props.notarcount}</b> Route Not Accepted Tried 2 Times </b>
                <div id="Route" className={'collapse'}>
                    <div className={styles.newsubfound} dangerouslySetInnerHTML={{ __html: props.notaccerou}} />
                </div>
            </>
        )
    }
};

export default NotAcceptRouteMsg;
