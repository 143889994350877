import Country from './panels/Country';
import State from './panels/State';
import City from './panels/City';
import Location from './panels/Location';

const tabRoutes = [
    {
        name: 'Country',
        path: '/country',
        component: <Country />
    },
    {
        name: 'State',
        path: '/state',
        component: <State />
    },
    {
        name: 'City',
        path: '/city',
        component: <City />
    },
    {
        name: 'Location',
        path: '/location',
        component: <Location />
    }
]

export default tabRoutes;
