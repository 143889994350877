import {
    GET_ADMIN_ROLES,
    ADD_ADMIN_ROLE,
    UPDATE_ADMIN_ROLE,
    DELETE_ADMIN_ROLE
} from "../actionTypes";

const adminRolesReducer = (state = [], action) => {
    switch (action.type) {
        case GET_ADMIN_ROLES:
            return action.payload;

        case ADD_ADMIN_ROLE:
            return [action.payload, ...state];

        case UPDATE_ADMIN_ROLE:
            return state.map(item =>
                item?.role_map_id === action.payload?.role_map_id
                    ? action.payload
                    : item
            );

        case DELETE_ADMIN_ROLE:
            return action.payload;

        default:
            return state;
    }
};

export default adminRolesReducer;
