import {
    GET_USER_ROLES,
    ADD_USER_ROLE,
    UPDATE_USER_ROLE,
    DELETE_USER_ROLE
} from "../actionTypes";

const userRoleReducer = (state = [], action) => {
    switch (action.type) {
        case GET_USER_ROLES:
            return action.payload;

        case ADD_USER_ROLE:
            return [action.payload, ...state];

        case UPDATE_USER_ROLE:
            return state.map(item =>
                item?.role_map_id === action.payload?.role_map_id
                    ? action.payload
                    : item
            );

        case DELETE_USER_ROLE:
            return action.payload;

        default:
            return state;
    }
};

export default userRoleReducer;
