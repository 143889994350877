import React from "react";
import styles from "./styles.module.scss";

export default function NoData() {
  return (
    <div className={styles.container}>
      <h4>No data available.</h4>
    </div>
  );
}
