import React from "react";
import styles from "./styles.module.scss";

const Status = ({ active }) => (
  <div
    className={`${styles.status} ${active ? styles.active : styles.inactive}`}
  ></div>
);

export default Status;
