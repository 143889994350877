import {
  GET_SUB_CATEGORY_LOCATION,
  ADD_SUB_CATEGORIES_LOCATION,
  UPDATE_SUB_CATEGORY_LOCATION,
  TOGGLE_SUB_CATEGORY_LOCATION,
  LOADING
} from "../actionTypes";
import axios from "axios";
import { AWS_URL_CM_8090 } from "configurations";
import { adminToken } from "helper";

const { SET_TRUE, SET_FALSE } = LOADING;

export const get = subCategoryId => {
  return async dispatch => {
    try {
      dispatch({ type: SET_TRUE });

      const token = await adminToken();

      const res = await axios.get(
        `${AWS_URL_CM_8090}/sub-categories/${subCategoryId}/location`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      dispatch({ type: GET_SUB_CATEGORY_LOCATION, payload: res.data });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({ type: SET_FALSE });
    }
  };
};

export const add = details => {
  const { subCategoryID, location, city } = details;

  console.log({ subCategoryID, location: parseInt(location), city });

  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.post(
          `${AWS_URL_CM_8090}/sub-categories/${subCategoryID}/location`,
          {
            location: parseInt(location),
            city
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
      )
      .then(res => {
        dispatch({ type: ADD_SUB_CATEGORIES_LOCATION, payload: res.data });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const update = details => {
  const { id, subCategoryID, location, city } = details;

  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.put(
          `${AWS_URL_CM_8090}/sub-categories/${subCategoryID}/location/${id}`,
          {
            location: parseInt(location),
            city
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
      )
      .then(res => {
        dispatch({ type: UPDATE_SUB_CATEGORY_LOCATION, payload: res.data });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const toggle = (subCategoryId, subCategoryLocationId) => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.patch(
          `${AWS_URL_CM_8090}/sub-categories/${subCategoryId}/location/${subCategoryLocationId}`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
      )
      .then(res =>
        dispatch({ type: TOGGLE_SUB_CATEGORY_LOCATION, payload: res.data.id })
      )
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};
