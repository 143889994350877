import React from 'react';
import styles from './CountDown.module.scss';
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CountDown = ({percentage, value}) => (
    <div className={styles.count__down}>
        <CircularProgressbar
            value={percentage}
            text={value || "00:00"}
            strokeWidth={2}
            styles={{
                path: {
                    stroke: `rgba(108, 215, 187, ${percentage / 100})`
                },
                text: {
                    fill: '#58666E',
                    fontSize: '24px',
                    fontWeight: 'bolder'
                },
            }}
        />
    </div>
);

export default CountDown;