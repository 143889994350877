import React from "react";
import styles from "./styles.module.scss";
import {Button, Loader, TextInput} from "components";
import {
    Switch,
    Route,
    withRouter,
    useRouteMatch,
    Redirect
} from "react-router-dom";
import {useSelector} from "react-redux";

const Tabs = ({
                  routes,
                  location: {pathname},
                  history,
                  title,
                  onClick,
                  search = null
              }) => {
    const {url} = useRouteMatch();

    const loading = useSelector(state => state.loading);

    return (
        <div className={styles.tabs}>
            <div className={styles.header}>
                <ul>
                    {routes?.length &&
                        routes.map((item, index) => (
                            <li
                                className={
                                    pathname === url + item.path.split("(")[0] ||
                                    pathname === item.path.split("(")[0] ||
                                    `/${pathname.split("/")[2]}` === item.path
                                        ? styles.active
                                        : ""
                                }
                                key={`li-${index}`}
                                onClick={() => history.push(url + item.path.split("(")[0])}>
                                {item.name}
                            </li>
                        ))}
                </ul>

                {search && (
                    <div className={styles.text__input__container}>
                        <TextInput search={search} {...search} />
                    </div>
                )}

                <div className={styles.btnContainer}>
                    {loading ? (
                        <Loader size={12}/>
                    ) : title ? (
                        <Button title={title} onClick={onClick}/>
                    ) : null}
                </div>
            </div>

            <div className={styles.panel__wrapper}>
                <Switch>
                    <Redirect exact from={url} to={pathname + routes?.[0]?.path}/>
                    {routes?.length
                        ? routes.map((router, index) => (
                            <Route path={url + router?.path} key={index}>
                                {router.component}
                            </Route>
                        ))
                        : null}
                </Switch>
            </div>
        </div>
    );
};

export default withRouter(Tabs);
