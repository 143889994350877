import React, { useState, useMemo } from "react";
import { TextInput, Modal, FilterDropdown } from "components";
import { useDispatch, useSelector } from "react-redux";
import { stateAction } from "redux/actions";

export default function StatePopup({ closeModal }) {
  const [state, setState] = useState();
  const [selectedCountry, setSelectedCountry] = useState();

  const dispatch = useDispatch();
  const country = useSelector(state => state.country);

  const onSave = () => {
    if (!state || !selectedCountry) return;
    dispatch(stateAction.add(selectedCountry, state));
    closeModal();
  };

  console.log(selectedCountry);

  return (
    <Modal title="Create New State" closeModal={closeModal} onSave={onSave}>
      <FilterDropdown
        onChange={e => setSelectedCountry(e.target.value)}
        value={selectedCountry}
        label="Country"
        options={
          country?.length
            ? ["Pick a country", ...country?.map(item => item.name)]
            : ["Pick a country"]
        }
        values={country?.length ? [0, ...country?.map(item => item.id)] : [0]}
      />
      <TextInput
        label="State"
        placeholder="State"
        name="state"
        onChange={e => setState(e.target.value)}
        value={state}
      />
    </Modal>
  );
}
