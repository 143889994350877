import { GET_PACKAGE_LIKE, RESET_PACKAGE_LIKE } from "../actionTypes";

const packageLikeReducer = (state = [], action) => {
  switch (action.type) {
    case GET_PACKAGE_LIKE:
      return action.payload;

    case RESET_PACKAGE_LIKE:
      return [];

    default:
      return state;
  }
};

export default packageLikeReducer;
