import React from "react";
import styles from "./styles.module.scss";

const FilterDropdown = ({ label, options, values = [], ...props }) => {
  return (
    <div className={styles.filter__input} style={{width: 250, display: 'inline-block'}}>
      <p>{label}</p>
      <select {...props} style={{width: 250}}>
        {options?.length &&
          options.map((option, i) => (
            <option key={option + i} value={values[i] ?? option}>
              {option}
            </option>
          ))}
      </select>
    </div>
  );
};

export default FilterDropdown;
