import axios from "axios";

import { LOADING } from "../actionTypes";
import { SERVER_IP_2, PORT_8090, AWS_URL_DM_8095 } from "configurations";
import { adminToken } from "helper";

const { SET_TRUE, SET_FALSE } = LOADING;

export const get = id => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    return adminToken()
      .then(token =>
        axios.get(`${AWS_URL_DM_8095}/city/27/user/${id}?default=true`, {
          headers: { Authorization: `Bearer ${token}` }
        })
      )

      .then(res => res.data)
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};
