import CouponsPanel from "./panels/CouponsPanel";

const tabRoutes = [
  {
    name: "Coupons",
    path: "/coupons",
    component: <CouponsPanel />
  }
  // {
  //   name: "Deals",
  //   path: "/deals",
  //   component: <DealsPanel />,
  // },
  // {
  //   name: "Offers",
  //   path: "/offers",
  //   component: <OffersPanel />,
  // },
];

export default tabRoutes;
