import axios from "axios";

import {
  GET_FAQ_TOPIC,
  TOGGLE_FAQ_TOPIC,
  UPDATE_FAQ_TOPIC,
  ADD_FAQ_TOPIC,
  RESET_FAQ_TOPIC,
  LOADING
} from "../actionTypes";
import { AWS_FAQ_7070 } from "configurations";
import { adminToken } from "helper";

const { SET_TRUE, SET_FALSE } = LOADING;
export const get = id => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.get(`${AWS_FAQ_7070}/faq-info/${id}/topics`, {
          headers: { Authorization: `Bearer ${token}` }
        })
      )
      .then(res => {
        dispatch({ type: GET_FAQ_TOPIC, payload: res.data });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const add = (pid, details = {}) => {
  return dispatch => {
    dispatch({ type: SET_TRUE });

    adminToken()
      .then(token =>
        axios.post(
          `${AWS_FAQ_7070}/faq-info/${pid}/topics/add-qna`,
          details,

          {
            headers: { Authorization: `Bearer ${token}` }
          }
        )
      )
      .then(res => dispatch({ type: ADD_FAQ_TOPIC, payload: res.data }))
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

// Update Package
export const update = (pid, details = {}) => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.put(`${AWS_FAQ_7070}/faq-info/${pid}/topics`, details, {
          headers: { Authorization: `Bearer ${token}` }
        })
      )
      .then(res => {
        console.log(res.data);
        dispatch({ type: UPDATE_FAQ_TOPIC, payload: res.data });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

// Add Answers
export const addAnswers = (pid, details = {}) => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.post(`${AWS_FAQ_7070}/faq-info/${pid}/topics/add-qna`, details, {
          headers: { Authorization: `Bearer ${token}` }
        })
      )
      .then(res => {
        console.log(res.data);
        dispatch({ type: UPDATE_FAQ_TOPIC, payload: res.data });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const toggle = (pid, id) => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.patch(
          `${AWS_FAQ_7070}/faq-info/${pid}/topics/${id}`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
      )
      .then(res => dispatch({ type: TOGGLE_FAQ_TOPIC, payload: res.data }))
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const reset = () => dispatch => dispatch({ type: RESET_FAQ_TOPIC });
