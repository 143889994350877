import React, { useState, useEffect } from "react";
import { TextInput, Modal, FilterDropdown, Alert } from "components";
import { useDispatch, useSelector } from "react-redux";
import { cityAction, documentsAction } from "redux/actions";

export default function DocumentsPopup({ closeModal, data }) {
  const [documentName, setDocumentName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [referer, setReferer] = useState();

  const dispatch = useDispatch();
  const categories = useSelector(state => state.categories);
  const city = useSelector(state => state.city);

  // One time effect
  useEffect(() => {
    if (data) {
      setDocumentName(data?.Documentname);
      setSelectedCategory(data?.CategoryID);
      setSelectedCity(data?.Cityid);
      setReferer(data?.Referer);
    }

    dispatch(cityAction.getAll());
  }, []);

  const onSave = () => {
    if (!documentName || !selectedCity) return;
    dispatch(
      documentsAction.add({
        categoryID: parseInt(selectedCategory),
        cityID: parseInt(selectedCity),
        referer,
        documentName: [documentName]
      })
    );
    closeModal();
  };

  return (
    <Modal
      title={data ? "Edit Document" : "Setup New Document"}
      closeModal={closeModal}
      onSave={onSave}>
      <Alert />
      <FilterDropdown
        onChange={e => setSelectedCategory(e.target.value)}
        value={selectedCategory}
        label="Category"
        options={
          categories?.length
            ? ["Pick a category", ...categories?.map(item => item.name)]
            : ["Pick a category"]
        }
        values={
          categories?.length ? [0, ...categories?.map(item => item.id)] : [0]
        }
      />

      <FilterDropdown
        onChange={e => setSelectedCity(e.target.value)}
        value={selectedCity}
        label="City"
        options={
          city?.length
            ? ["Pick a city", ...city?.map(item => item.name)]
            : ["Pick a city"]
        }
        values={city?.length ? [0, ...city?.map(item => item.id)] : [0]}
      />
      <FilterDropdown
        onChange={e => setReferer(e.target.value)}
        value={referer}
        label="Referer"
        options={[
          "Select a Referer",
          "Organziation",
          "Sub Organization",
          "Service Provider"
        ]}
        values={[0, "org", "subOrg", "sp"]}
      />
      <TextInput
        label="Document Name"
        placeholder="Document Name"
        name="documentName"
        onChange={e => setDocumentName(e.target.value)}
        value={documentName}
      />
    </Modal>
  );
}
