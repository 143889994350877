import axios from "axios";
import { GET_MANUAL_ROUTING, LOADING } from "../actionTypes";
import { AWS_ROUTING } from "configurations";
import { adminToken } from "helper";

const { SET_TRUE, SET_FALSE } = LOADING;

export const get = id => {
  return async dispatch => {
    dispatch({ type: SET_TRUE });

    const token = await adminToken();

    axios
      .get(`${AWS_ROUTING}/getsplist?subscription_id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(({ data }) => {
        dispatch({ type: GET_MANUAL_ROUTING, payload: data?.data });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};
