import React from "react";
import styles from "./styles.module.scss";
import avatar from "../../assets/img/avatar.png";
import {MdAccountCircle} from "react-icons/md";
import {auth} from "../../firebase";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../redux/actions/userAction";
import {useHistory} from "react-router-dom";

const ProfileCard = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const {admin_id, first_name, last_name, email, picture} =
    useSelector(state => state.user) || {};

    return (
        <div className={styles.profile__card}>
            <div className={styles.top__area}>
                <img src={picture} alt=""/>

                <div className={styles.name__container}>
                    <p className={styles.username}>{first_name + " " + last_name}</p>
                    <span className={styles.user__id}>{admin_id}</span>
                </div>

                <button className={styles.copy__btn}>Copy Merchant Id</button>
            </div>

            <div className={styles.bottom__area}>
                <p className={styles.caption}>Logged in as</p>

                <div className={styles.email__container}>
                    <MdAccountCircle size="24" color="#BDC3C6"/>
                    <p className={styles.email}>{email}</p>
                </div>

                <button
                    className={styles.logout__btn}
                    onClick={() => {
                        logout(history)
                    }}
                >
                    Logout
                </button>

                <div className={styles.line}/>

                <p className={styles.caption}>
                    Partner with us and start earning on every referal
                </p>

                <input
                    type="button"
                    value="Explore Partner Program"
                    className={styles.explore__btn}
                />
            </div>
        </div>
    );
};

export default ProfileCard;
