import axios from "axios";

import {SERVICE_PROVIDER, LOADING} from "../actionTypes";
import {IMAGE_URL, Who_Obj} from "configurations";
import {adminToken} from "../../helper";

const {
    GET,
    UPDATE,
    TOGGLE,
    RESET,
    TOGGLE_STATUS,
    REJECT_DOC,
    CHANGE_DOC_STATUS
} = SERVICE_PROVIDER;

const {SET_TRUE, SET_FALSE} = LOADING;
export const get = (pageNumber, pageSize) => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .get(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/get-user-list?pageNumber=${pageNumber}&pageSize=${
                    pageSize || 10
                }`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(res => {
                dispatch({type: GET, payload: res.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const add = data => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();

        axios
            .post(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/org-management/documents`,
                {
                    ...data
                },
                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            )
            .then(res => {
                dispatch({type: GET, payload: res.data.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

// Update
export const update = (details = {}) => {
    const {name, displayName, description, displayOrder, id, image} = details;

    return async dispatch => {
        dispatch({type: SET_TRUE});
        let imageRes;

        // Request to Upload Image
        if (typeof image === "object") {
            const formData = new FormData();
            formData.append("imageFile", image);
            imageRes = await axios.post(`${IMAGE_URL}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
        }

        const finalRes = await axios.put(
            `https://cgapp.clykk.com/location/country/${id}`,
            {
                description,
                displayName,
                name,
                displayOrder: parseInt(displayOrder),
                image: imageRes?.data.imgUrl || image
            },
            {
                headers: {who: JSON.stringify(Who_Obj)}
            }
        );

        dispatch({type: UPDATE, payload: finalRes.data});
    };
};

export const toggle = id => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .patch(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/user/${id}/toggle`,
                {},
                {
                    headers: {Authorization: `Bearer ${token}`}
                }
            )
            .then(res => {
                dispatch({type: TOGGLE, payload: res.data.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const remove = (cid, sid, id) => {
    return dispatch => {
        dispatch({type: SET_TRUE});
        axios
            .delete(
                `http://cgapp.clykk.com/location/country/${cid}/state/${sid}/city/${id}`,
                {
                    headers: {who: Who_Obj}
                }
            )
            .then(res => dispatch(get(cid, sid)))
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const changeStatus = (id, status) => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .put(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/user/${id}/status/${status}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(res => {
                dispatch({type: TOGGLE_STATUS, payload: res.data.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const changeDocStatus = (spID, {id, comment, status}) => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .put(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/org-management/document/${id}`,
                {comment, status},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(res => {
                dispatch({
                    type: CHANGE_DOC_STATUS,
                    payload: {spID, documents: res.data}
                });
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const rejectDoc = (sid, did) => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        console.log("Calling API");
        axios
            .delete(
                `http://cgapp.clykk.com/sp/clykk/int/sp/v1/user/${sid}/document/${did}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(res => {
                console.log(res);
                dispatch({
                    type: REJECT_DOC,
                    payload: {documents: res.data, sid}
                });
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const reset = () => dispatch => dispatch({type: RESET});
