import React, {useState} from "react";
import {useLocation, Switch, Route} from "react-router-dom";
import tabRoutes from "./tab-routes";
import {Tabs} from "components";
import PackagesPopup from "./popups/Packages/PackagesPopup";

const popups = [
    {
        title: "+ New Package",
        path: "packages",
        component: closeModal => <PackagesPopup closeModal={closeModal}/>
    }
];

export default function Subscription() {
    const location = useLocation();
    const currentLocation = location.pathname.split("/", 3)[1];

    // States
    const [modal, setModal] = useState(); // Popup open or close state

    return (
        <>
            {/* Panels */}
            <Tabs
                routes={tabRoutes}
                title={popups.find(popup => currentLocation === popup.path)?.title}
                onClick={() => setModal(currentLocation)}
            />

            {
                // Popups
                modal &&
                popups?.length &&
                popups.map(
                    popup =>
                        currentLocation === popup.path &&
                        popup.component(() => setModal(false))
                )
            }

            {/* Modals */}
            <Switch>
                <Route path={`${tabRoutes[0]?.modalRoutes[0]?.path}`}>
                    {tabRoutes[0]?.modalRoutes[0]?.component}
                </Route>
            </Switch>
        </>
    );
}
