import axios from "axios";

import {SP_SUB_CATEGORIES, LOADING} from "../actionTypes";
import {IMAGE_URL, Who_Obj} from "configurations";
import {adminToken} from "../../helper";

const {GET, RESET} = SP_SUB_CATEGORIES;

const {SET_TRUE, SET_FALSE} = LOADING;

export const get = id => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .get(
                `https://cgapp.clykk.com/catalog/api/v2/catalog-manager/category/${id}/sub-categories`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(res => {
                console.log(res.data);
                dispatch({type: GET, payload: res.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const reset = () => dispatch => dispatch({type: RESET});
