import React from "react";
import styles from "./../styles.module.scss";
import { Button, FilterDropdown } from "components";
import { useDispatch, useSelector } from "react-redux";
import { subCategoriesAction } from "redux/actions";

export default function CategoryDetails({ onClick, details, setDetails }) {
  const dispatch = useDispatch();
  const categories = useSelector(state => state.categories);
  const subCategories = useSelector(state => state.subCategories);

  const get = categoryID => {
    if (!categoryID) return;
    dispatch(subCategoriesAction.get(categoryID));
  };

  return (
    <div className={styles.main__container}>
      <FilterDropdown
        label="Category"
        name="category"
        onChange={e => {
          setDetails(e);
          get(e.target.value);
        }}
        value={details?.category}
        options={
          categories?.length
            ? ["Pick a category", ...categories?.map(item => item.name)]
            : ["Pick a category"]
        }
        values={
          categories?.length ? [0, ...categories?.map(item => item.id)] : [0]
        }
      />

      <FilterDropdown
        label="Sub Category"
        name="subCategoryID"
        onChange={setDetails}
        value={details?.subCategoryID}
        options={
          subCategories?.length
            ? ["Pick a sub category", ...subCategories?.map(item => item.name)]
            : ["Pick a sub category"]
        }
        values={
          subCategories?.length
            ? [0, ...subCategories?.map(item => item.id)]
            : [0]
        }
      />

      <div className={styles.btn__container}>
        <Button title="Next" onClick={onClick} />
      </div>
    </div>
  );
}
