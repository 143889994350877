import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {Table} from "../../../components";

function TransactionsTab(props) {
    // Redux

    const transaction = useSelector(state => state.subsDetail?.transaction);

    const [tableRows, setTableRows] = useState();

    useEffect(() => {
        let filtered = transaction;
        if (!filtered?.length) {
            setTableRows();
            return;
        }

        console.log(filtered);
        // Map table rows
        filtered = filtered?.map(obj => ({
            id: obj?.id,
            total: obj?.total,
            discount: obj?.discount,
            discountType: obj?.discountType,
            discountCode: obj?.discountCode,
            payableAmount: obj?.payableAmount,
            amountBeforeTax: obj?.amountBeforeTax,
            taxAmount: obj?.taxAmount,
            orderID: obj?.orderID,
            paymentType: obj?.paymentType,
            isVerified: `${obj?.isVerified}`,
            collectionStatus: obj?.collectionStatus,
            createdAt: `${new Date(obj?.createdAt).toLocaleString()}`,
            updatedAt: `${new Date(obj?.updatedAt).toLocaleString()}`
        }));

        setTableRows(filtered);
    }, []);

    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "ID",
                sortBy: "id"
            },
            {
                title: "Total"
            },
            {
                title: "Discount"
            },
            {
                title: "D Type"
            },
            {
                title: "D Code"
            },
            {
                title: "Payable"
            },
            {title: "Before Tax"},
            {title: "Tax"},
            {title: "Order"},
            {title: "P Type"},
            {title: "Verified"},
            {title: "Collection Status"},
            {title: "created"},
            {
                title: "Updated",
                sortBy: "updatedAt"
            }
        ],
        [tableRows]
    );

    return <Table headers={tableHeaders} data={tableRows}/>;
}

export default TransactionsTab;