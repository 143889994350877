import React from "react";
import styles from "./styles.module.scss";
import {MdEdit} from "react-icons/md";

const TableVertical = ({heading, headers, data, onEdit}) => {
    return (
        <div className={styles.table__container}>
            <table>
                <thead>
                <tr>
                    <th colSpan="2" className={styles.main__heading}>
                        {heading}
                        {onEdit && (
                            <MdEdit
                                size="20"
                                color="#528ff0"
                                className={styles.icon}
                                onClick={onEdit}
                            />
                        )}
                    </th>
                </tr>
                </thead>
                <tbody>
                {headers?.length &&
                    headers.map((header, index) => (
                        <tr key={index}>
                            <th>{header?.title || header}</th>
                            <td>{Object.values(data)[index]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TableVertical;
