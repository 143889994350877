import React, {useEffect, useState, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import styles from "./styles.module.scss";
import {
    Table,
    TextInput,
    SearchButton,
    LightTextButton,
    FilterDropdown,
    SwitchButton,
    ImageStamp,
    Icon
} from "components";
import {faqAreasAction, faqScreensAction} from "redux/actions";
import FAQScreensPopup from "../popups/FAQScreens/FAQScreensPopup";
import {MdEdit} from "react-icons/md";

const SubCategory = () => {
    // States
    const [selectedFAQArea, setSelectFAQArea] = useState();
    const [tableRows, setTableRows] = useState([]);
    const [editableDetail, setEditableDetails] = useState();
    const [isPopupVisible, setPopupVisible] = useState();

    // Filter States
    const [status, setStatus] = useState();
    const [faqScreenName, setFaqScreenName] = useState();

    // Redux actions
    const dispatch = useDispatch();
    const {faqScreens, faqAreas, userRole} = useSelector(state => ({
        faqScreens: state.faqScreens,
        faqAreas: state.faqAreas,
        userRole: state.userRole?.filter(role => role.module === "FAQ")
    }));
    const faqAreasNames = faqAreas?.map(item => item.name); //make an arry of faq areas names

    useEffect(() => {
        if (userRole?.find(item => item.privilege === "Read"))
            dispatch(faqAreasAction.get());
        return () => {
            dispatch(faqAreasAction.reset());
            dispatch(faqScreensAction.reset());
        };
    }, [dispatch]);

    useEffect(() => {
        let filtered = faqScreens;

        // filter based on Status
        filtered = filtered?.filter(item =>
            status === "Enabled"
                ? item.enabled
                : status === "Disabled"
                    ? !item.enabled
                    : item
        );

        // filter based on faq areas name
        if (faqScreenName) {
            filtered = filtered.filter(
                subCategory =>
                    subCategory.name
                        .toLowerCase()
                        .indexOf(faqScreenName?.toLowerCase()) !== -1
            );
        }

        // get category name by category Id
        const faqAreaName =
            filtered?.length &&
            faqAreas?.find(item => item.id == filtered[0]?.faqAreaID);

        // Map table rows
        const data = filtered?.map(item => ({
            faqAreaName: `${faqAreaName?.name}`,
            name: `${item.name}`,
            description: `${item.description}`,
            image: <ImageStamp src={item.image}/>,
            displayName: `${item.displayName}`,
            displayOrder: `${item.displayOrder}`,
            createdAt: `${new Date(item.createdAt).toLocaleString()}`,
            createdBy: item.createdBy,
            updatedAt: `${new Date(item.updatedAt).toLocaleString()}`,
            updatedBy: item.updatedBy,
            enabled: (
                <SwitchButton
                    enabled={item.enabled}
                    onClick={
                        userRole?.find(item => item.privilege === "Enable")
                            ? () => dispatch(faqScreensAction.toggle(item.faqAreaID, item.id))
                            : null
                    }
                />
            ),
            edit: (
                <Icon
                    ReactIcon={MdEdit}
                    name="MdEdit"
                    color="primary"
                    onClick={
                        userRole?.find(item => item.privilege === "Update")
                            ? () => {
                                setEditableDetails(item);
                                setPopupVisible(true);
                            }
                            : null
                    }
                    className="clickable"
                />
            )
        }));

        setTableRows(data);
    }, [faqScreens, status, faqScreenName]);

    // Search based on category name
    const onSearch = () => {
        if (selectedFAQArea === "Pick a category" || !selectedFAQArea)
            return dispatch(faqScreensAction.reset());
        dispatch(faqScreensAction.get(selectedFAQArea));
    };

    // Table Header
    const tableHeaders = [
        {
            title: "FAQ Area"
        },
        {
            title: "FAQ Screen",
            sortBy: "name"
        },
        {
            title: "Description"
        },
        {
            title: "Image"
        },
        {
            title: "Display Name"
        },
        {
            title: "Display Order",
            sortBy: "displayOrder"
        },
        {
            title: "Created At",
            sortBy: "createdAt"
        },
        {
            title: "Created By"
        },
        {
            title: "Updated At",
            sortBy: "updatedAt"
        },
        {
            title: "Updated By"
        },
        {
            title: "Enabled"
        },
        {
            title: "Edit"
        }
    ];

    return (
        <div>
            <div className={styles.filter}>
                <FilterDropdown
                    onChange={e => setSelectFAQArea(e.target.value)}
                    value={selectedFAQArea}
                    label="FAQ Areas Name"
                    name="faqAreaName"
                    options={["Pick a faq area", ...faqAreasNames]}
                    values={[0, ...faqAreas?.map(item => item.id)]}
                />
                <TextInput
                    search
                    label="FAQ Screen Name"
                    name="faqScreenName"
                    value={faqScreenName}
                    onChange={e => setFaqScreenName(e.target.value)}
                />
                <FilterDropdown
                    onChange={e => setStatus(e.target.value)}
                    label="Status"
                    options={["All", "Enabled", "Disabled"]}
                />
                <SearchButton
                    onClick={
                        userRole?.find(item => item.privilege === "Read") ? onSearch : null
                    }
                />
                <LightTextButton
                    onClick={() => {
                        setFaqScreenName("");
                        setSelectFAQArea(0);
                    }}
                />
            </div>
            <Table headers={tableHeaders} data={tableRows}/>
            {isPopupVisible && (
                <FAQScreensPopup
                    data={editableDetail}
                    closeModal={() => setPopupVisible(false)}
                />
            )}
        </div>
    );
};

export default SubCategory;
