import React from 'react';
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {Invoice} from "../../../components";

function BookingDetailsTab({id}) {
    // Redux
    const order = useSelector(state =>
        state.orders?.find(item => item.cartID == id)
    );
    return (
        <div>
            <Invoice
                serviceAddress={order?.serviceAddress?.line2}
                deliveryAddress={order?.deliveryAddress?.line2}
                serviceDate={order?.schedule?.startDate}
                serviceTime={order?.schedule?.serviceTime}
                schedule={order?.schedule}
                items={order?.items}
            />
        </div>
    );
}

export default BookingDetailsTab;