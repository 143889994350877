import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import styles from "./styles.module.scss";
import {
    Table,
    SearchButton,
    LightTextButton,
    FilterDropdown,
    SwitchButton,
    TextLink,
    StatusButton,
    TextInput,
    DocumentButton
} from "components";
import {categoryAction, orgAction} from "redux/actions";
import {useHistory, useLocation} from "react-router-dom";
import {internalSearch} from "helper";

const initialState = {
    id: "",
    orgName: "",
    category: ""
};

export default function OrganizationPanel() {
    // Routers
    const history = useHistory();
    const currentLocation = history.location.pathname;
    const locationSearch = useLocation().search;

    // States
    const [tableRows, setTableRows] = useState();

    // Filter States
    const [status, setStatus] = useState("All");
    const [filterState, setFilterState] = useState(initialState);

    // Redux actions
    const dispatch = useDispatch();

    const {org, categories, userRole} = useSelector(state => ({
        org: state.org,
        categories: state.categories,
        userRole: state.userRole?.filter(role => role.module === "Organization")
    }));

    // One-time Effects
    useEffect(() => {
        const query = new URLSearchParams(locationSearch).get("id");
        setFilterState({...filterState, id: query});

        if (userRole?.find(item => item.privilege === "Read"))
            dispatch(orgAction.get());
        return () => {
            dispatch(orgAction.reset());
        };
    }, []);

    useEffect(() => {
        if (
            !categories?.length &&
            userRole?.find(item => item.privilege === "Read")
        )
            dispatch(categoryAction.get());
    }, []);

    useEffect(() => {
        let filtered = org;

        if (!filtered?.length) {
            setTableRows();
            return;
        }

        // filter based on Status
        // filtered = filtered?.filter(item =>
        //     status === "Enabled"
        //         ? item.enabled
        //         : status === "Disabled"
        //             ? !item.enabled
        //             : item
        // );

        if(status !== 'All') {
            filtered = filtered?.filter(item => item.status === status.toLowerCase());
        }

        filtered = internalSearch(filterState, "id", filtered);
        filtered = internalSearch(filterState, "orgName", filtered);

        // Map table rows
        let data = filtered?.map(obj => ({
            id: (
                <TextLink
                    text={obj.id}
                    onClick={() =>
                        history.push(
                            `/${currentLocation.split("/", 2)[1]}/organization/${obj.id}`
                        )
                    }
                />
            ),
            orgName: (
                <TextLink
                    text={obj.orgName}
                    onClick={() =>
                        history.push(
                            `/${currentLocation.split("/", 2)[1]}/sub-organization?orgID=${
                                obj.id
                            }&orgName=${obj.orgName}`
                        )
                    }
                />
            ),
            city: obj.cityID,
            category:
                categories?.find(item => item.id == obj.category)?.name || obj.category,
            createdAt: `${new Date(obj.createdAt).toLocaleString()}`,
            updatedAt: `${new Date(obj.updatedAt).toLocaleString()}`,
            status: (
                <StatusButton
                    title={obj?.status}
                    options={["pending", "approved", "rejected"]}
                    onChange={status => dispatch(orgAction.changeStatus(obj.id, status))}
                />
            ),
            documents: (
                <DocumentButton
                    approved={
                        obj?.documents?.filter(doc => doc.status === "approved").length
                    }
                    uploaded={obj?.documents?.filter(doc => doc.documentURL).length}
                    total={obj?.documents?.length}
                    onClick={() =>
                        history.push(
                            `/${currentLocation.split("/", 2)[1]}/organization/${
                                obj?.id
                            }/documents`
                        )
                    }
                />
            ),
            enabled: (
                <SwitchButton
                    enabled={obj.enabled}
                    onClick={
                        userRole?.find(item => item.privilege === "Enable")
                            ? () => dispatch(orgAction.toggle(obj.id))
                            : null
                    }
                />
            )
        }));

        data = internalSearch(filterState, "category", data);

        setTableRows(data);
    }, [org, status, filterState]);

    // Table Header
    const tableHeaders = [
        {
            title: "ID",
            sortBy: "id"
        },
        {
            title: "Organization Name"
        },
        {
            title: "City"
        },
        {
            title: "Category"
        },
        {
            title: "Created",
            sortBy: "createdAt"
        },
        {
            title: "Updated",
            sortBy: "updatedAt"
        },
        {
            title: "Status"
        },
        {
            title: "Documents"
        },
        {
            title: "Enabled"
        }
    ];

    return (
        <div>
            <div className={styles.filter}>
                <TextInput
                    search
                    label="Org Name"
                    name="orgName"
                    value={filterState?.orgName}
                    onChange={e =>
                        setFilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
                <TextInput
                    search
                    label="Category"
                    name="category"
                    value={filterState?.category}
                    onChange={e =>
                        setFilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
                <FilterDropdown
                    onChange={e => setStatus(e.target.value)}
                    label="City"
                    options={["Select a city"]}
                />
                <FilterDropdown
                    onChange={e => setStatus(e.target.value)}
                    label="Status"
                    options={["All", "Approved", "Rejected", "Onboarding"]}
                />
                <SearchButton/>
                <LightTextButton onClick={() => setFilterState(initialState)}/>
            </div>
            <Table headers={tableHeaders} data={tableRows}/>
        </div>
    );
}
