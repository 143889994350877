import axios from "axios";

import {ORG, LOADING} from "../actionTypes";
import {adminToken} from "../../helper";

const {GET, TOGGLE, RESET, REJECT_DOC, CHANGE_DOC_STATUS} = ORG;

const {SET_TRUE, SET_FALSE} = LOADING;
export const get = () => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .get(`https://cgapp.clykk.com/sp/clykk/int/sp/v1/org-management/org`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(res => {
                dispatch({type: GET, payload: res.data.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const changeStatus = (id, status) => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .put(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/org-management/${id}/status/${status}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(res => {
                dispatch(get());
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const changeDocStatus = (orgID, {id, comment, status}) => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .put(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/org-management/document/${id}`,
                {comment, status},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(res => {
                dispatch({
                    type: CHANGE_DOC_STATUS,
                    payload: {orgID, documents: res.data.data}
                });
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const rejectDoc = (oid, did) => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        console.log("Calling API");
        axios
            .delete(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/org-management/${oid}/documents/${did}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(res => {
                dispatch({
                    type: REJECT_DOC,
                    payload: {oid, documents: res.data.data}
                });
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const toggle = id => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .patch(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/org-management/${id}/toggle`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(res => {
                dispatch({type: TOGGLE, payload: res.data.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const reset = () => dispatch => dispatch({type: RESET});
