import axios from "axios";
import {
  GET_FAQ_INFO,
  TOGGLE_FAQ_INFO,
  UPDATE_FAQ_INFO,
  ADD_FAQ_INFO,
  RESET_FAQ_INFO,
  LOADING
} from "../actionTypes";
import { AWS_FAQ_7070, IMAGE_URL } from "configurations";
import { adminToken } from "helper";
import uploadImage from "helper/uploadImage";

const { SET_TRUE, SET_FALSE } = LOADING;
export const get = id => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.get(`${AWS_FAQ_7070}/faq-screens/${id}/faq-info`, {
          headers: { Authorization: `Bearer ${token}` }
        })
      )
      .then(res => {
        dispatch({ type: GET_FAQ_INFO, payload: res.data });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const add = (details = {}) => {
  const { faqName, shortDescription, displayOrder, faqScreenID, faqImage } =
    details;

  return async dispatch => {
    try {
      dispatch({ type: SET_TRUE });

      const imgUrl = await uploadImage(faqImage, "faq-management");

      const token = await adminToken();

      const res = await axios
        .post(
          `${AWS_FAQ_7070}/faq-screens/${faqScreenID}/faq-info`,
          {
            shortDescription,
            faqName,
            faqScreenID,
            displayOrder: parseInt(displayOrder),
            enabled: true,
            faqImage: imgUrl
          },
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        )
        .then(res => dispatch({ type: ADD_FAQ_INFO, payload: res.data }))
        .catch(err => console.log(err))
        .finally(_ => dispatch({ type: SET_FALSE }));
    } catch (err) {
      console.log(err);
    } finally {
    }
  };
};

// Update
export const update = (details = {}) => {
  const { faqName, shortDescription, displayOrder, faqScreenID, faqImage, id } =
    details;

  return async dispatch => {
    try {
      dispatch({ type: SET_TRUE });
      let imgUrl;

      // Request to Upload Image
      if (typeof packageImage === "object")
        imgUrl = await uploadImage(faqImage, "faq-management");

      const token = await adminToken();

      const finalRes = await axios.put(
        `${AWS_FAQ_7070}/faq-screens/${faqScreenID}/faq-info/${id}`,
        {
          shortDescription,
          faqName,
          displayOrder: parseInt(displayOrder),
          faqImage: imgUrl || faqImage
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      dispatch({ type: UPDATE_FAQ_INFO, payload: finalRes.data });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch({ type: SET_FALSE });
    }
  };
};

export const toggle = (aid, id) => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.patch(
          `${AWS_FAQ_7070}/faq-screens/${aid}/faq-info/${id}`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
      )
      .then(res => dispatch({ type: TOGGLE_FAQ_INFO, payload: res.data }))
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const reset = () => dispatch => dispatch({ type: RESET_FAQ_INFO });
