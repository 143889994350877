import axios from "axios";
import { IMAGE_URL } from "configurations";
import adminToken from "helper/adminToken";

export default function uploadImage(imageUrl, manager) {
  const formData = new FormData();
  formData.append("imageFile", imageUrl);

  return adminToken().then(token =>
    axios
      .post(`${IMAGE_URL}/${manager}/images`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.data.url)
  );
}
