import React, {useState, useMemo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    discountsAction,
    categoryAction,
    orgAction,
    countryAction,
    customersAction
} from "redux/actions";
import {TabModal} from "components";
import Details from "./Details";
import ImageFileUpload from "./ImageFileUpload";
import ForWhom from "./ForWhom";

export default function ({closeModal, data}) {
    // redux
    const dispatch = useDispatch();
    const org = useSelector(state => state.org);
    const categories = useSelector(state => state.categories);
    const country = useSelector(state => state.country);
    const customers = useSelector(state => state.customers?.users);

    // states
    const [tabIndex, setTabIndex] = useState(0); //Popup tab active or inactive state
    const [details, setDetails] = useState({
        minDiscount: "0.00",
        maxDiscount: "0.00"
    });

    // useEffects
    useEffect(() => {
        if (!data) return;
        setDetails(data);
    }, []);

    // useEffects
    useEffect(() => {
        // Get initial data
        if (!org?.length) dispatch(orgAction.get());
        if (!categories?.length) dispatch(categoryAction.get());
        if (!country?.length) dispatch(countryAction.get());
        if (!customers?.length) dispatch(customersAction.get());
    }, []);

    // Handle change in Details Form
    const handleChange = (e, extra) => {
        const name = e.target.name;
        let value = e.target.value;

        if (name === "minDiscount" || name === "maxDiscount") {
            if (isNaN(value) || value < 0) return;
            value = value;
        } else value = value ? (isNaN(value) ? value : parseInt(value)) : "";

        if (name === "cityID")
            return setDetails({
                ...details,
                [name]: value,
                cityName: extra?.name
            });

        setDetails({
            ...details,
            [name]: value
        });
    };

    // Handle Image Select
    const handleImageSelect = e => setDetails({...details, image: e});

    // On Submit Data to Server
    const submit = () => {
        setTabIndex(0);
        closeModal();

        if (data && data?.duplicate)
            return dispatch(discountsAction.duplicate(details));
        else if (data) return dispatch(discountsAction.update(details));
        else dispatch(discountsAction.add(details));
    };

    // Popup tabs routes
    const modalTabs = useMemo(
        () => [
            {
                name: "Discount Details",
                component: (
                    <Details
                        onClick={e => setTabIndex(1)}
                        details={details}
                        setDetails={handleChange}
                    />
                )
            },
            {
                name: "For Whom",
                component: (
                    <ForWhom
                        onClick={e => setTabIndex(2)}
                        details={details}
                        setDetails={handleChange}
                    />
                )
            },
            {
                name: "Discount Image",
                component: (
                    <ImageFileUpload
                        onClick={submit}
                        onImageSelect={handleImageSelect}
                        imageSrc={details?.image}
                    />
                )
            }
        ],
        [details]
    );

    return (
        <TabModal
            tabTitle={data ? "Edit Discount" : "Create New Discount"}
            tabs={modalTabs}
            tabIndex={tabIndex}
            setTabIndex={index => setTabIndex(index)}
            closeModal={() => {
                setTabIndex(0);
                closeModal();
            }}
        />
    );
}
