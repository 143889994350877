import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdEdit } from "react-icons/md";
import styles from "./styles.module.scss";
import {
  Table,
  SearchButton,
  LightTextButton,
  FilterDropdown,
  SwitchButton,
  ImageStamp,
  TextLink,
  TextInput
} from "components";
import {
  subCategoriesAction,
  categoryAction,
  packagesAction,
  serviceRulesAction
} from "redux/actions";
import PackagesPopup from "../popups/Packages/PackagesPopup";
import { useHistory } from "react-router-dom";

const PackagePanel = () => {
  // Routers
  const history = useHistory();
  const currentLocation = history.location.pathname;

  // States
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSubCategory, setSelectedSubCategory] = useState();
  const [tableRows, setTableRows] = useState();
  const [editableDetail, setEditableDetails] = useState();
  const [isPopupVisible, setPopupVisible] = useState();

  // Filter States
  const [status, setStatus] = useState();
  const [packageName, setPackageName] = useState();

  // Redux actions
  const dispatch = useDispatch();

  const { packages, categories, serviceRules, subCategories, userRole } =
    useSelector(state => ({
      packages: state.packages,
      categories: state.categories,
      serviceRules: state.serviceRules,
      subCategories: state.subCategories,
      userRole: state.userRole?.filter(role => role.module === "Package")
    }));

  const filteredCategories = categories?.map(category => category.name); // make an arry of category names

  const filteredSubCategories =
    subCategories?.map(subCategory => subCategory.name) || []; // make an arry of sub category names

  useEffect(() => {
    if (!categories?.length)
      // fetch categories if not available
      dispatch(categoryAction.get());

    if (!serviceRules?.length)
      // fetch service rules if not available
      dispatch(serviceRulesAction.get());
  }, [dispatch]);

  useEffect(() => {
    if (!packages?.length) {
      setTableRows();
      return;
    }

    let filtered = packages;

    // filter based on Status
    filtered = filtered?.filter(item =>
      status === "Enabled"
        ? item.enabled
        : status === "Disabled"
        ? !item.enabled
        : item
    );

    // get sub category name by sub category Id
    const subCategory =
      filtered?.length &&
      subCategories.find(
        subCategory => subCategory.id == filtered[0]?.subcategoryID
      );
    const serviceRule =
      filtered?.length &&
      serviceRules.find(
        serviceRule => serviceRule.id == filtered[0]?.subCategoryServiceRuleID
      );

    // filter based on package name
    if (packageName) {
      filtered =
        filtered?.length &&
        filtered.filter(
          item =>
            item.packageName
              .toLowerCase()
              .indexOf(packageName?.toLowerCase()) !== -1
        );
    }

    // Map table rows
    const data = filtered?.map(obj => ({
      packageName: (
        <TextLink
          text={obj.packageName}
          onClick={() => history.push(`${currentLocation}/${obj.id}`)}
        />
      ),
      subCategory: `${subCategory.name}`,
      cityName: `${obj.cityName}`,
      serviceRule: serviceRule?.type,
      packageImage: <ImageStamp src={obj.packageImage} />,
      basePrice: obj.basePrice,
      sellingPrice: obj.sellingPrice,
      displayOrder: obj.displayOrder,
      rating: obj.rating,
      likes: obj.likes,
      offer: obj.offer,
      offerURL: obj.offerURL,
      tags: obj.tags,
      createdAt: `${new Date(obj.createdAt).toLocaleString()}`,
      updatedAt: `${new Date(obj.updatedAt).toLocaleString()}`,
      enabled: (
        <SwitchButton
          enabled={obj.enabled}
          onClick={
            userRole?.find(item => item.privilege === "Update")
              ? () => dispatch(packagesAction.toggle(obj))
              : null
          }
        />
      ),
      edit: (
        <MdEdit
          size="20"
          color="#528ff0"
          onClick={
            userRole?.find(item => item.privilege === "Update")
              ? () => {
                  setEditableDetails(obj);
                  setPopupVisible(true);
                }
              : null
          }
          className="clickable"
        />
      )
    }));

    setTableRows(data);
  }, [packages, status, packageName]);

  // Get sub categories based on category selection
  useEffect(() => {
    setSelectedSubCategory(0);
    if (selectedCategory === "Pick a category" || !selectedCategory) return;
    const categoryId = categories?.find(
      category => selectedCategory === category.name && category
    );
    dispatch(subCategoriesAction.get(categoryId?.id));
  }, [selectedCategory]);

  // Search based on category & sub category name
  const onSearch = () => {
    if (
      selectedSubCategory === "Pick a sub category" ||
      !selectedSubCategory ||
      !userRole?.find(item => item.privilege === "Read")
    )
      return;
    const categoryId = subCategories.find(
      category => selectedSubCategory === category.name && category
    );
    dispatch(packagesAction.get(categoryId.id));
  };

  // Table Header
  const tableHeaders = [
    {
      title: "Package Name"
    },
    {
      title: "Sub Category"
    },
    {
      title: "City",
      sortBy: "cityName"
    },
    {
      title: "Delivery Type"
    },
    {
      title: "Image"
    },
    {
      title: "Base"
    },
    {
      title: "Selling"
    },
    {
      title: "Display",
      sortBy: "displayOrder"
    },
    {
      title: "Ratings"
    },
    {
      title: "Likes"
    },
    {
      title: "Offer"
    },
    {
      title: "Offer URL"
    },
    {
      title: "Tags"
    },
    {
      title: "Created",
      sortBy: "createdAt"
    },
    {
      title: "Updated",
      sortBy: "updatedAt"
    },
    {
      title: "Enabled"
    },
    {
      title: "Edit"
    }
  ];

  return (
    <div>
      <div className={styles.filter}>
        <FilterDropdown
          onChange={e => setSelectedCategory(e.target.value)}
          value={selectedCategory}
          label="Category Name"
          options={
            filteredCategories?.length
              ? ["Pick a category", ...filteredCategories]
              : ["Pick a category"]
          }
        />
        <FilterDropdown
          onChange={e => setSelectedSubCategory(e.target.value)}
          value={selectedSubCategory}
          label="Sub Category Name"
          options={
            filteredSubCategories?.length
              ? ["Pick a sub category", ...filteredSubCategories]
              : ["Pick a sub category"]
          }
        />
        <TextInput
          search
          label="Package Name"
          name="packageName"
          value={packageName}
          onChange={e => setPackageName(e.target.value)}
        />
        <FilterDropdown
          onChange={e => setStatus(e.target.value)}
          label="Status"
          value={status}
          options={["All", "Enabled", "Disabled"]}
        />
        <SearchButton onClick={onSearch} />
        <LightTextButton
          onClick={() => {
            setStatus("");
            setPackageName("");
          }}
        />
      </div>
      <Table headers={tableHeaders} data={tableRows} />
      {isPopupVisible && (
        <PackagesPopup
          data={editableDetail}
          closeModal={() => setPopupVisible(false)}
        />
      )}
    </div>
  );
};

export default PackagePanel;
