import React, { useMemo } from "react";
import {
  SwitchButton,
  TableVertical,
  StatusButton,
  DocumentButton
} from "components";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { subOrgAction } from "redux/actions";

export default function OrgProfile() {
  const dispatch = useDispatch();

  // Routers
  const history = useHistory();
  const path = history.location.pathname;
  const id = path.split("/")[3];

  // Redux
  const { subOrg, userRole } = useSelector(state => ({
    subOrg: state?.subOrg?.find(item => item.id == id),
    userRole: state.userRole?.filter(role => role.module === "Organization")
  }));

  // Custom Functions
  const data = {
    id: subOrg?.id,
    orgName: subOrg?.orgName,
    orgID: subOrg?.orgID,
    ownerID: subOrg?.ownerID,
    managerId: subOrg?.managerID,
    registrationNumber: subOrg?.registrationNumber,
    category: subOrg?.category,
    cityID: subOrg?.cityID,
    addressID: subOrg?.addressID,
    status: (
      <StatusButton
        title={subOrg?.status}
        options={["pending", "approved", "rejected"]}
        onChange={status =>
          dispatch(subOrgAction.changeStatus(subOrg.id, status))
        }
      />
    ),
    rejectionReason: subOrg?.rejectionReason,
    createdAt: `${new Date(subOrg?.createdAt).toLocaleString()}`,
    updatedAt: `${new Date(subOrg?.updatedAt).toLocaleString()}`,
    documents: (
      <DocumentButton
        approved={
          subOrg?.documents?.filter(doc => doc.status === "approved").length
        }
        uploaded={subOrg?.documents?.filter(doc => doc.documentURL).length}
        total={subOrg?.documents?.length}
        onClick={() =>
          history.push(path.slice(0, path.lastIndexOf("/")) + "/documents")
        }
      />
    ),
    enabled: (
      <SwitchButton
        enabled={subOrg?.enabled}
        onClick={
          userRole?.find(item => item.privilege === "Enable")
            ? () => dispatch(subOrgAction.toggle(subOrg.id))
            : null
        }
      />
    )
  };

  // Table Header
  const tableHeaders = useMemo(
    () => [
      {
        title: "Id"
      },
      {
        title: "Sub Organization Name"
      },
      {
        title: "Organization"
      },
      {
        title: "Owner"
      },
      {
        title: "Manager"
      },
      {
        title: "Registration No."
      },
      {
        title: "Category"
      },
      {
        title: "City"
      },
      {
        title: "Address"
      },
      {
        title: "Status"
      },
      {
        title: "Rejected Reason"
      },
      {
        title: "Created"
      },
      {
        title: "Updated"
      },
      {
        title: "Documents"
      },
      {
        title: "Enabled"
      }
    ],
    []
  );

  return (
    <div>
      <TableVertical
        heading="Organization Details"
        headers={tableHeaders}
        data={data}
      />
    </div>
  );
}
