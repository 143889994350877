import axios from "axios";

import {
    GET_PACKAGE_QNA,
    TOGGLE_PACKAGE_QNA,
    UPDATE_PACKAGE_QNA,
    ADD_PACKAGE_QNA,
    RESET_PACKAGE_QNA,
    LOADING
} from "../actionTypes";
import {AWS_URL_CM_8090} from "configurations";
import {adminToken} from "helper";

const {SET_TRUE, SET_FALSE} = LOADING;
export const get = id => {
    return async dispatch => {
        dispatch({type: SET_TRUE});

        const token = await adminToken();

        axios
            .get(`${AWS_URL_CM_8090}/package/${id}/qna`, {
                headers: {Authorization: `Bearer ${token}`}
            })
            .then(res => {
                dispatch({type: GET_PACKAGE_QNA, payload: res.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const add = (pid, details = {}) => {
    return dispatch => {
        dispatch({type: SET_TRUE});
        adminToken()
            .then(token =>
                axios.post(
                    `${AWS_URL_CM_8090}/package/${pid}/qna`,
                    {
                        qna: [details]
                    },
                    {
                        headers: {Authorization: `Bearer ${token}`}
                    }
                )
            )
            .then(res => dispatch({type: ADD_PACKAGE_QNA, payload: res.data}))
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

// Update Package
export const update = (pid, details = {}) => {
    return dispatch => {
        dispatch({type: SET_TRUE});
        adminToken()
            .then(token =>
                axios.patch(`${AWS_URL_CM_8090}/package/${pid}/qna`, details, {
                    headers: {Authorization: `Bearer ${token}`}
                })
            )

            .then(({data}) => dispatch({type: UPDATE_PACKAGE_QNA, payload: data}))
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const toggle = (pid, details = {}) => {
    return dispatch => {
        dispatch({type: SET_TRUE});
        adminToken()
            .then(token =>
                axios.patch(`${AWS_URL_CM_8090}/package/${pid}/qna`, details, {
                    headers: {Authorization: `Bearer ${token}`}
                })
            )
            .then(res => dispatch({type: TOGGLE_PACKAGE_QNA, payload: res.data}))
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const reset = () => dispatch => dispatch({type: RESET_PACKAGE_QNA});
