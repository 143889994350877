import React, {useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    FilterDropdown,
    ImageStamp,
    LightTextButton, Search,
    SearchButton,
    Status,
    SwitchButton, Table,
    TextInput,
    TextLink
} from "../../components";
import * as XLSX from "xlsx";
import styles from "../Customers/panels/styles.module.scss";
import PackagesPopup from "../Customers/popups/Packages/PackagesPopup";
import {customersAction} from "redux/actions";
import {Modal} from "@material-ui/core";
import ReportModal from "./ReportModal";

const initialState = {
    userId: "",
    firstName: "",
    lastName: "",
    mobile: "",
    search: ""
};

function ReportPanel() {

    return (
        <></>
    );
}

export default ReportPanel;