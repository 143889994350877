import Orders from "./panels/Orders";
import Requirements from "./panels/Requirements";
import Checklist from "./panels/Checklist";
import Details from "./panels/Details";
import Subscription from "./panels/Subscription";
import BookingDetails from "./panels/BookingDetails";
import AdditionalBills from "./panels/AdditionalBills";
import Transactions from "./panels/Transactions";
import Routing from "./panels/Routing";
import Dummy from "./panels/Dummy";
import ManualRouting from "./panels/ManualRouting";
import SubscriptionSideModal from "./modals/SubscriptionSideModal";

const tabRoutes = [
    {
        name: "Subscription",
        path: "/orders",
        component: <Orders/>,
        modalRoutes: [
            {
                name: "",
                path: "/subscription/orders/:id",
                component: <SubscriptionSideModal/>
            }
        ]
    }
];

export default tabRoutes;

export const modalTabRoutes = [
    {
        name: "Booking Details",
        path: "/booking-details",
        component: <BookingDetails/>
    },
    {
        name: "Subscription",
        path: "/subscription",
        component: <Subscription/>
    },
    {
        name: "Package Details",
        path: "/package-details",
        component: <Details/>
    },
    {
        name: "Customer Requirements",
        path: "/customer-requirements",
        component: <Requirements/>
    },
    {
        name: "SP Checklist",
        path: "/sp-checklist",
        component: <Checklist/>
    },
    {
        name: "Additional Bills",
        path: "/additional-bills",
        component: <AdditionalBills/>
    },
    {
        name: "Transactions",
        path: "/transactions",
        component: <Transactions/>
    },
    {
        name: "Rating",
        path: "/rating",
        component: <Dummy/>
    },
    {
        name: "Routing",
        path: "/routing",
        component: <Routing/>
    },
    {
        name: "Manual Routing",
        path: "/manual-routing",
        component: <ManualRouting/>
    }
];
