import React, {useEffect, useMemo, useState} from 'react';
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Status, SwitchButton, Table} from "../../components";
import {spServiceSettingsAction,spSubCategoriesAction} from "redux/actions";
import categories from '../../category.json';

function ServiceSettingsTab({clickedSP}) {
    const location = useLocation();
    const id = clickedSP.id;
    const cat_id = clickedSP.categoryID;

    // States
    const [tableRows, setTableRows] = useState();

    // Filter States
    const [status, setStatus] = useState();
    const [filterState, setfilterState] = useState();

    // Redux actions
    const dispatch = useDispatch();
    const spServiceSettings = useSelector(state => state.spServiceSettings);
    const sub_ctegories = useSelector(state => state.spSubCategories);

    useEffect(() => {
        dispatch(spSubCategoriesAction.get(cat_id));
        dispatch(spServiceSettingsAction.get(id));
        return () => dispatch(spServiceSettingsAction.reset());
    }, []);

    useEffect(() => {
        if (!spServiceSettings?.length) {
            setTableRows();
            return;
        }

        let filtered = spServiceSettings;

        // filter based on Status
        filtered = filtered?.filter(item =>
            status === "Enabled"
                ? item.enabled
                : status === "Disabled"
                    ? !item.enabled
                    : item
        );
        console.log('sub_ctegories');
        console.log(sub_ctegories);
        console.log('sub_ctegories');
        // Map table rows
        const data = filtered?.map(obj => (
            {
                ID: obj.ID,
                // SpID: obj.SpID,
                // CategoryID: obj.CategoryID,
                SubcategoryID: obj.SubcategoryID,
                //SubCategoryName: categories.find(category => category.subcategory_id == obj.SubcategoryID)?.subcat_name,
                SubCategoryName: sub_ctegories.find(category => category.id == obj.SubcategoryID)?.displayName,

                S2C: <Status active={obj.S2C}/>,
                C2S: <Status active={obj.C2S}/>,
                S2VC: <Status active={obj.S2VC}/>,
                S2C2C: <Status active={obj.S2C2C}/>,
                Enabled: (
                    <SwitchButton
                        enabled={obj.Enabled}
                        onClick={() => dispatch(spServiceSettingsAction.toggle(id, obj.ID))}
                    />
                ),

                CreatedAt: `${new Date(obj.CreatedAt).toLocaleString()}`,
                UpdatedAt: `${new Date(obj.UpdatedAt).toLocaleString()}`
            }));

        setTableRows(data);
    }, [spServiceSettings, status, filterState]);

    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "ID"
            },
            // {
            //     title: "SP ID"
            // },
            // {
            //     title: "Category ID"
            // },
            {
                title: "Sub Category ID"
            },
            {
                title: "Sub Category Name"
            },
            {
                title: "S2C"
            },
            {
                title: "C2S"
            },
            {
                title: "S2VC"
            },
            {
                title: "S2C2C"
            },
            {
                title: "Enabled"
            },
            {
                title: "Created"
            },
            {
                title: "Updated"
            }
        ],
        [tableRows]
    );

    return <Table headers={tableHeaders} data={tableRows}/>;
};

export default ServiceSettingsTab;