import React, {useEffect, useState, useMemo} from "react";
import {
    Table,
    TextInput,
    SearchButton,
    LightTextButton,
    Icon,
    FilterDropdown
} from "components";
import {useDispatch, useSelector} from "react-redux";
import styles from "./styles.module.scss";
import {internalSearch} from "helper";
import {
    routingAction,
    categoryAction,
    subCategoriesAction,
    serviceRulesAction
} from "redux/actions";
import RoutingPopup from "../popups/Routing/RoutingPopup";
import {MdEdit} from "react-icons/md";

const initialFilterState = {
    CityID: "",
    serviceRule: 0,
    category: 0,
    subCategory: 0
};

export default function Routing() {
    const [filterState, setfilterState] = useState(initialFilterState);
    const [tableRows, setTableRows] = useState();
    const [isPopupVisible, setPopupVisible] = useState();
    const [editableDetail, setEditableDetails] = useState();

    const dispatch = useDispatch();

    const {routing, userRole, categories, subCategories, serviceRules} =
        useSelector(state => ({
            serviceRules: state.serviceRules,
            categories: state.categories,
            subCategories: state.subCategories,
            routing: state.routing,
            userRole: state.userRole?.filter(role => role.module === "Settings")
        }));

    // Load initially required data
    useEffect(() => {
        dispatch(categoryAction.get());
        dispatch(serviceRulesAction.get());
    }, [dispatch]);

    // Get sub categories based on category selection
    const onCategorySelect = e => {
        const {value} = e.target;

        setfilterState(prev => ({...prev, category: value}));

        if (!value) return;

        dispatch(subCategoriesAction.get(value));
    };

    const onSearch = () => {
        const {subCategory, serviceRule} = filterState;

        if (
            !subCategory &&
            !serviceRule &&
            !userRole?.find(item => item.privilege === "Read")
        )
            return;

        dispatch(routingAction.get(subCategory, serviceRule));
    };

    useEffect(() => {
        let filtered = routing ? [routing] : null;
        if (!filtered?.length) return setTableRows(null);

        filtered = internalSearch(filterState, "CityID", filtered);

        // Map table rows
        filtered = filtered?.map(obj => ({
            CityID: obj?.CityID,
            SubCategoryID: obj?.SubCategoryID,
            RuleID: obj?.RuleID,
            RouteBefore: obj?.RouteBefore,
            ServiceDuration: obj?.ServiceDuration,
            OpenTime: obj?.OpenTime,
            CloseTime: obj?.CloseTime,
            MinCommission: obj?.MinCommission?.toFixed(2),
            MaxCommission: obj?.MaxCommission?.toFixed(2),
            CreatedAt: `${new Date(obj?.CreatedAt).toLocaleString()}`,
            UpdateAt: `${new Date(obj?.UpdateAt).toLocaleString()}`,
            edit: (
                <Icon
                    ReactIcon={MdEdit}
                    name="MdEdit"
                    color="primary"
                    onClick={
                        userRole?.find(item => item.privilege === "Update")
                            ? () => {
                                setEditableDetails(obj);
                                setPopupVisible(true);
                            }
                            : null
                    }
                    className="clickable"
                />
            )
        }));

        setTableRows(filtered);
    }, [routing, filterState]);

    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "City",
                sortBy: "CityID"
            },
            {
                title: "Sub Category",
                sortBy: "SubCategoryID"
            },
            {
                title: "Rule",
                sortBy: "RuleID"
            },
            {
                title: "Route Before"
            },
            {
                title: "Service Duration"
            },
            {
                title: "Open"
            },
            {
                title: "Close"
            },
            {
                title: "Min Commission"
            },
            {
                title: "Max Commission"
            },
            {
                title: "Created",
                sortBy: "CreatedAt"
            },
            {
                title: "Updated",
                sortBy: "UpdateAt"
            },
            {
                title: "Edit"
            }
        ],
        [tableRows]
    );

    return (
        <div>
            <div className={styles.filter}>
                <FilterDropdown
                    onChange={onCategorySelect}
                    value={filterState?.category}
                    label="Category"
                    options={[
                        "Pick a category",
                        ...(categories?.map(category => category.name) || [])
                    ]}
                    values={[0, ...(categories?.map(category => category.id) || [])]}
                />
                <FilterDropdown
                    onChange={e =>
                        setfilterState({...filterState, subCategory: e.target.value})
                    }
                    value={filterState?.subCategory}
                    label="Sub Category"
                    options={[
                        "Pick a sub category",
                        ...(subCategories?.map(subCategory => subCategory.name) || [])
                    ]}
                    values={[
                        0,
                        ...(subCategories?.map(subCategory => subCategory.id) || [])
                    ]}
                />
                <FilterDropdown
                    onChange={e =>
                        setfilterState({...filterState, serviceRule: e.target.value})
                    }
                    value={filterState?.serviceRule}
                    label="Service Rule"
                    options={[
                        "Pick a service rule",
                        ...(serviceRules?.map(serviceRule => serviceRule.type) || [])
                    ]}
                    values={[
                        0,
                        ...(serviceRules?.map(serviceRule => serviceRule.id) || [])
                    ]}
                />
                <TextInput
                    search
                    label="City"
                    name="CityID"
                    value={filterState?.CityID}
                    onChange={e =>
                        setfilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
                <SearchButton onClick={onSearch}/>
                <LightTextButton onClick={() => setfilterState(initialFilterState)}/>
            </div>
            <Table headers={tableHeaders} data={tableRows}/>
            {isPopupVisible && (
                <RoutingPopup
                    data={editableDetail}
                    closeModal={() => setPopupVisible(false)}
                />
            )}
        </div>
    );
}
