import React, {useEffect} from "react";
import styles from "./styles.module.scss";
import {Route, Switch, useLocation, Redirect} from "react-router-dom";
import {Sidebar, Navbar} from "../components";

// Routes
import routes from "../routes";
import {useDispatch} from "react-redux";
import {validateAuth} from "helper/handleAuth";

const Admin = () => {
    const {pathname} = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (dispatch) validateAuth(dispatch);
    }, [pathname, dispatch]);

    return (
        <div className={styles.wrapper}>
            <Sidebar/>

            <div className={styles.view}>
                <Navbar/>
                <Switch>
                    <>
                        <Redirect to={pathname === "/login" ? "/home" : pathname}/>
                        {routes?.length &&
                            routes.map((router, index) => (
                                <Route path={router.path} key={index}>
                                    {router.component}
                                </Route>
                            ))}
                    </>
                </Switch>
            </div>
        </div>
    );
};

export default Admin;
