import React, {useEffect, useState} from 'react';
import {Box, makeStyles, Tab, Tabs, useTheme} from "@material-ui/core";
import PropTypes from "prop-types";
import {IoIosClose} from "react-icons/io";
import ProfileTab from "./ProfileTab";
import ServiceSettingsTab from "./ServiceSettingsTab";
import AddressTab from "./AddressTab";
import PreferencesTab from "./PreferencesTab";
import MemberStatusTab from "./MemberStatusTab";
import SpDocumentsTab from "./SPDocumentsTab";
import Orders from "../ServiceProviders/panels/Orders";
import OrdersTab from "./OrdersTab";
import adminToken from "../../helper/adminToken";
import axios from "axios";

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


const useStyles = makeStyles((theme) => ({
    indicator: {
        backgroundColor: "#6CD7BB"
    }
}))

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            style={{backgroundColor: "white"}}
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <p>{children}</p>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function SpNewModal({clickedSP, handleCloseModal, value, setValue, setClickedSubscription, handleOpenSubscriptionModal}) {
    const theme = useTheme();
    const [modalStyle] = useState(getModalStyle);

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const changeValue = (newValue) => {
        setValue(newValue)
    }
    const classes = useStyles()

    const [serviceProvider, setServiceProvider] = useState(null)

    useEffect(() => {
        adminToken().then(token =>
            axios
                .get(`https://cgapp.clykk.com/sp/clykk/int/sp/v1/get-user-profile?userId=${clickedSP?.id}`, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(res => setServiceProvider(res.data))
        );
    }, [])

    return (
        clickedSP &&
        <>
            <div style={modalStyle} className={'modal-paper'}>
                <div className={'modal-top-bar'}>
                    <IoIosClose
                        size={20}
                        className={'modal-close'}
                        onClick={handleCloseModal}
                    />
                </div>

                <div style={{margin: "0 0 10px 0"}}>
                    <h5>Service Provider ID: {clickedSP.id}</h5>
                    <div style={{display: 'flex', marginTop: 10}}>
                        <p style={{marginRight: 20}}>Name: {serviceProvider?.firstName + " " + serviceProvider?.lastName}</p>
                        <p style={{marginRight: 20}}>Mobile: {serviceProvider?.mobile}</p>
                        {/*<p style={{marginRight: 20}}>Category ID: {clickedSP.categoryID}</p>*/}
                        {/*<p>Category Name: {clickedSP.categoryName}</p>*/}
                    </div>
                </div>

                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    classes={{
                        indicator: classes.indicator
                    }}>
                    <Tab label="Profile" {...a11yProps(0)} />
                    <Tab label="Service Settings"
                         style={{}} {...a11yProps(1)} />
                    <Tab label="Address"
                         {...a11yProps(2)} />
                    <Tab label="Preferences"
                         {...a11yProps(3)} />
                    <Tab label="Member & Status"
                         {...a11yProps(4)} />
                    <Tab label="Documents"
                         {...a11yProps(5)} />
                    <Tab label="Orders"
                         {...a11yProps(6)} />
                </Tabs>

                <div className={'modal-body'}>
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <ProfileTab serviceProvider={serviceProvider}/>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <ServiceSettingsTab clickedSP={clickedSP}/>
                    </TabPanel>
                    <TabPanel value={value} index={2} dir={theme.direction}>
                        <AddressTab clickedSP={clickedSP}/>
                    </TabPanel>
                    <TabPanel value={value} index={3} dir={theme.direction}>
                        <PreferencesTab clickedSP={clickedSP}/>
                    </TabPanel>
                    <TabPanel value={value} index={4} dir={theme.direction}>
                        <MemberStatusTab clickedSP={clickedSP}/>
                    </TabPanel>
                    <TabPanel value={value} index={5} dir={theme.direction}>
                        <SpDocumentsTab clickedSP={clickedSP}/>
                    </TabPanel>
                    <TabPanel value={value} index={6} dir={theme.direction}>
                        <OrdersTab clickedSP={clickedSP} setClickedSubscription={setClickedSubscription}
                                   handleOpenSubscriptionModal={handleOpenSubscriptionModal}/>
                    </TabPanel>
                </div>

            </div>
        </>
    );
}

export default SpNewModal;