import AppPreference from "./panels/AppPreference";
import SPAppPreference from "./panels/SPAppPreference";

const tabRoutes = [
  {
    name: "Customer App Preference",
    path: "/app-preference",
    component: <AppPreference />,
  },
  {
    name: "SP App Preference",
    path: "/sp-app-preference",
    component: <SPAppPreference />,
  },
];

export default tabRoutes;
