import React, { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { subCategoryLocationAction } from "redux/actions";
import { TabModal } from "components";
import CategoryDetails from "./CategoryDetails";
import LocationDetails from "./LocationDetails";

export default function CategoryPopup({ closeModal, data }) {
  // redux
  const dispatch = useDispatch();

  // states
  const [tabIndex, setTabIndex] = useState(0);
  const [details, setDetails] = useState();

  // useEffects
  useEffect(() => {
    if (!data) return;
    setDetails(data);
  }, []);

  // Handle change in Form
  const handleChange = e => {
    setDetails(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  // On Submit Data to Server
  const submit = () => {
    setTabIndex(0);
    closeModal();

    if (data) return dispatch(subCategoryLocationAction.update(details));
    dispatch(subCategoryLocationAction.add(details));
  };

  // Popup tabs routes
  const modalTabs = useMemo(
    () => [
      {
        name: "Category Details",
        component: (
          <CategoryDetails
            onClick={e => setTabIndex(1)}
            details={details}
            setDetails={handleChange}
          />
        )
      },
      {
        name: "Location Details",
        component: (
          <LocationDetails
            onClick={submit}
            details={details}
            setDetails={handleChange}
          />
        )
      }
    ],
    [details]
  );

  return (
    <TabModal
      tabTitle={
        data ? "Edit Sub Category Location" : "Create New Sub Category Location"
      }
      tabs={modalTabs}
      tabIndex={tabIndex}
      setTabIndex={index => setTabIndex(index)}
      closeModal={() => {
        setTabIndex(0);
        closeModal();
      }}
    />
  );
}
