import React from "react";
import styles from "./styles.module.scss";

const LightTextButton = props => (
  <button className={styles.button} {...props}>
    Clear
  </button>
);

export default LightTextButton;
