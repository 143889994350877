import {
  GET_SUB_CATEGORY_LOCATION,
  TOGGLE_SUB_CATEGORY_LOCATION,
  ADD_SUB_CATEGORIES_LOCATION,
  UPDATE_SUB_CATEGORY_LOCATION,
} from "../actionTypes";

const subCategoryLocationReducer = (state = [], action) => {
  switch (action.type) {
    case GET_SUB_CATEGORY_LOCATION:
      return action.payload;

    case ADD_SUB_CATEGORIES_LOCATION:
      return [...state, action.payload];

    case UPDATE_SUB_CATEGORY_LOCATION:
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );

    case TOGGLE_SUB_CATEGORY_LOCATION:
      return state.map((item) =>
        item.id !== action.payload ? item : { ...item, enabled: !item.enabled }
      );

    default:
      return state;
  }
};

export default subCategoryLocationReducer;
