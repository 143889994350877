import React, { useEffect } from "react";
import { FilterDropdown, Alert, Button, SearchTextInput } from "components";
import { useDispatch, useSelector } from "react-redux";
import {
  cityAction,
  subOrgAction,
  subCategoriesAction,
  packagesAction
} from "redux/actions";
import styles from "../styles.module.scss";

export default function ForWhom({ onClick, details, setDetails }) {
  const dispatch = useDispatch();
  const { city, org, subOrg, categories, subCategories, packages, customers } =
    useSelector(state => state) || {};

  const { users } = customers || {};

  // Get cities based on country & state
  useEffect(() => {
    dispatch(cityAction.getAll());
  }, []);

  // Get sub-organizations base on organziation
  useEffect(() => {
    if (details?.orgID) dispatch(subOrgAction.get(details?.orgID));
    else {
      dispatch(subOrgAction.reset());
    }
  }, [details?.orgID]);

  // Get sub-categories
  useEffect(() => {
    if (details?.categoryID)
      dispatch(subCategoriesAction.get(details.categoryID));
    else {
      dispatch(subCategoriesAction.reset());
    }
  }, [details?.categoryID]);

  // Get packages
  useEffect(() => {
    if (details?.subcategoryID)
      dispatch(packagesAction.get(details.subcategoryID));
    else {
      dispatch(packagesAction.reset());
    }
  }, [details?.subcategoryID]);

  return (
    <div className={styles.main__container}>
      <Alert />
      <FilterDropdown
        onChange={e =>
          setDetails(
            e,
            city?.find(item => item.id == e.target.value)
          )
        }
        value={details?.cityID}
        label="City"
        name="cityID"
        options={
          city?.length
            ? ["Pick a city", ...city?.map(item => item.name)]
            : ["Pick a city"]
        }
        values={city?.length ? [0, ...city?.map(item => item.id)] : [0]}
      />
      <FilterDropdown
        onChange={setDetails}
        value={details?.orgID}
        label="Organization"
        name="orgID"
        options={
          org?.length
            ? ["Pick a organization", ...org?.map(item => item?.orgName)]
            : ["Pick a organization"]
        }
        values={org?.length ? [0, ...org?.map(item => item?.id)] : [0]}
      />
      <FilterDropdown
        onChange={setDetails}
        value={details?.suborgID}
        label="Sub Organization"
        name="suborgID"
        options={
          subOrg?.length
            ? ["Pick a sub organization", ...subOrg?.map(item => item?.name)]
            : ["Pick a sub organization"]
        }
        values={subOrg?.length ? [0, ...subOrg?.map(item => item?.id)] : [0]}
      />
      <FilterDropdown
        onChange={setDetails}
        value={details?.categoryID}
        label="Categories"
        name="categoryID"
        options={
          categories?.length
            ? ["Pick a category", ...categories?.map(item => item?.name)]
            : ["Pick a category"]
        }
        values={
          categories?.length ? [0, ...categories?.map(item => item?.id)] : [0]
        }
      />
      <FilterDropdown
        onChange={setDetails}
        value={details?.subcategoryID}
        label="Sub Categories"
        name="subcategoryID"
        options={
          subCategories?.length
            ? ["Pick a sub category", ...subCategories?.map(item => item?.name)]
            : ["Pick a sub category"]
        }
        values={
          subCategories?.length
            ? [0, ...subCategories?.map(item => item?.id)]
            : [0]
        }
      />
      <FilterDropdown
        onChange={setDetails}
        value={details?.packageID}
        label="Packages"
        name="packageID"
        options={
          packages?.length
            ? ["Pick a package", ...packages?.map(item => item?.packageName)]
            : ["Pick a package"]
        }
        values={
          packages?.length ? [0, ...packages?.map(item => item?.id)] : [0]
        }
      />
      <SearchTextInput
        onChange={setDetails}
        value={details?.userID}
        label="User"
        name="userID"
        options={
          users?.length
            ? [
                "Pick a user",
                ...users?.map(item => `${item?.firstName} ${item?.lastName}`)
              ]
            : null
        }
        values={users?.length ? [0, ...users?.map(item => item?.userId)] : [0]}
      />
      <div className={styles.btn__container}>
        <Button title="Next" onClick={onClick} />
      </div>
    </div>
  );
}
