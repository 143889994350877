import { SUB_ORG } from "../actionTypes";

export default function subOrgReducer(state = null, action) {
  const {
    GET,
    ADD,
    TOGGLE,
    UPDATE,
    RESET,
    REJECT_DOC,
    CHANGE_DOC_STATUS,
  } = SUB_ORG;
  switch (action.type) {
    case GET:
      return action.payload;

    case ADD:
      return action.payload;

    case UPDATE:
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );

    case TOGGLE:
      console.log(action.payload);
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );

    case REJECT_DOC:
      return state.map((item) =>
        item.id == action.payload.sid
          ? { ...item, documents: action.payload.documents }
          : item
      );

    case CHANGE_DOC_STATUS:
      return state.map((item) =>
        item.id == action.payload.subOrgID
          ? { ...item, documents: action.payload.documents }
          : item
      );

    case RESET:
      return null;

    default:
      return state;
  }
}
