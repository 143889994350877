import {
  GET_DEFAULT_PRIVILEGES,
  ADD_DEFAULT_PRIVILEGE,
  UPDATE_DEFAULT_PRIVILEGE,
  DELETE_DEFAULT_PRIVILEGE,
  ACTIVE_DEFAULT_PRIVILEGE,
  INACTIVE_DEFAULT_PRIVILEGE,
  ADD_SNACK,
  LOADING
} from "../actionTypes";
import axios from "axios";
import Cookies from "js-cookie";

import { BASE_URL_NEW } from "configurations";

const { SET_TRUE, SET_FALSE } = LOADING;

export const get = () => {
  return dispatch => {
    dispatch({ type: SET_TRUE });

    // const {token} = JSON.parse(Cookies.get("user"));
const {token} = JSON.parse(localStorage.getItem("user"));

    axios
      .get(`${BASE_URL_NEW}/getalldefaultroleprivileges`, {
        headers: {
          Authorization: `bearer ${token}`
        }
      })
      .then(res =>
        dispatch({ type: GET_DEFAULT_PRIVILEGES, payload: res.data?.data })
      )
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

// add a new privilege
export const add = details => {
  return dispatch => {
    dispatch({ type: SET_TRUE });

    // const {token} = JSON.parse(Cookies.get("user"));
const {token} = JSON.parse(localStorage.getItem("user"));

    axios
      .post(`${BASE_URL_NEW}/adddefaultroleprivilege`, details, {
        headers: {
          Authorization: `bearer ${token}`
        }
      })
      .then(res => {
        if (res.data?.type === "error")
          throw new Error(JSON.stringify(res.data?.message));
        dispatch({ type: ADD_DEFAULT_PRIVILEGE, payload: res.data?.data });
        dispatch({
          type: ADD_SNACK,
          payload: `New default privilege has been created`
        });
      })
      .catch(err => {
        dispatch({
          type: ADD_SNACK,
          payload: err.message
        });
        console.log(err);
      })
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

// update a specific privileges
export const update = details => {
  return dispatch => {
    dispatch({ type: SET_TRUE });

    // const {token} = JSON.parse(Cookies.get("user"));
const {token} = JSON.parse(localStorage.getItem("user"));

    axios
      .post(
        `${BASE_URL_NEW}/updatedefaultroleprivilege/${details?.default_role_p_id}`,
        details,
        {
          headers: {
            Authorization: `bearer ${token}`
          }
        }
      )
      .then(res => {
        if (res.data?.type === "error")
          throw new Error(JSON.stringify(res.data?.message));
        dispatch({ type: UPDATE_DEFAULT_PRIVILEGE, payload: res.data?.data });
        dispatch({
          type: ADD_SNACK,
          payload: `${details?.default_role_p_id} has been updated`
        });
      })
      .catch(err => {
        dispatch({
          type: ADD_SNACK,
          payload: err.message
        });
        console.log(err);
      })
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const remove = id => {
  return dispatch => {
    dispatch({ type: SET_TRUE });

    // const {token} = JSON.parse(Cookies.get("user"));
const {token} = JSON.parse(localStorage.getItem("user"));

    axios
      .get(`${BASE_URL_NEW}/deletedefaultroleprivilege/${id}`, {
        headers: {
          Authorization: `bearer ${token}`
        }
      })
      .then(res => {
        dispatch({ type: DELETE_DEFAULT_PRIVILEGE, payload: res.data?.data });

        dispatch({
          type: ADD_SNACK,
          payload: `${id} has been removed`
        });
      })
      .catch(err => {
        dispatch({
          type: ADD_SNACK,
          payload: err.message
        });
        console.log(err);
      })
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const active = id => {
  return dispatch => {
    dispatch({ type: SET_TRUE });

    // const {token} = JSON.parse(Cookies.get("user"));
const {token} = JSON.parse(localStorage.getItem("user"));

    axios
      .get(`${BASE_URL_NEW}/activefaultroleprivilege/${id}`, {
        headers: {
          Authorization: `bearer ${token}`
        }
      })
      .then(res => {
        dispatch({ type: ACTIVE_DEFAULT_PRIVILEGE, payload: res.data?.users });

        dispatch({
          type: ADD_SNACK,
          payload: `${id} has been activated`
        });
      })
      .catch(err => {
        dispatch({
          type: ADD_SNACK,
          payload: err.message
        });
        console.log(err);
      })
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const inactive = id => {
  return dispatch => {
    dispatch({ type: SET_TRUE });

    // const {token} = JSON.parse(Cookies.get("user"));
const {token} = JSON.parse(localStorage.getItem("user"));

    axios
      .get(`${BASE_URL_NEW}/inactivefaultroleprivilege/${id}`, {
        headers: {
          Authorization: `bearer ${token}`
        }
      })
      .then(res => {
        dispatch({
          type: INACTIVE_DEFAULT_PRIVILEGE,
          payload: res.data?.users
        });

        dispatch({
          type: ADD_SNACK,
          payload: `${id} has been deactivated`
        });
      })
      .catch(err => {
        dispatch({
          type: ADD_SNACK,
          payload: err.message
        });
        console.log(err);
      })
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};
