import React, {useState} from "react";
import {useLocation} from "react-router-dom";
import {Tabs} from "components";
import userRoutes from "./users-routes";
import {Switch, Route} from "react-router-dom";
import ManageUsers from "./modals/ManageUsers";
import ModulesPopup from "./popups/Modules/ModulesPopup";
import RolesPopup from "./popups/Roles/RolesPopup";
import Privileges from "./popups/Privileges";
import DefaultPrivilege from "./popups/DefaultPrivilege";
import AdminRoles from "./popups/AdminRoles";
import {useSelector} from "react-redux";

const popups = [
    {
        title: "+ New Module",
        path: "modules",
        component: closeModal => <ModulesPopup closeModal={closeModal}/>
    },
    {
        title: "+ New Role",
        path: "roles",
        component: closeModal => <RolesPopup closeModal={closeModal}/>
    },
    {
        title: "+ New Privilege",
        path: "privileges",
        component: closeModal => <Privileges closeModal={closeModal}/>
    },
    {
        title: "+ New Default Privilege",
        path: "default-privileges",
        component: closeModal => <DefaultPrivilege closeModal={closeModal}/>
    },
    {
        title: "+ Dashboard Privilege",
        path: "dashboard-privileges",
        component: closeModal => <AdminRoles closeModal={closeModal}/>
    }
];

const Users = () => {
    const location = useLocation();

    const currentLocation = location.pathname.split("/", 3)[2];

    const userRole = useSelector(state =>
        state.userRole?.filter(role => role.module === "Users")
    );

    // States
    const [modal, setModal] = useState(); // Popup open or close state
    return (
        <>
            {/* Panels */}
            <Tabs
                title={popups.find(popup => currentLocation === popup.path)?.title}
                onClick={
                    userRole?.find(item => item.privilege === "Create")
                        ? () => setModal(currentLocation)
                        : null
                }
                routes={userRoutes}
            />

            {
                // Popups
                modal &&
                popups?.length &&
                popups.map(
                    popup =>
                        currentLocation === popup.path &&
                        popup.component(() => setModal(false))
                )
            }

            {/* Modals */}
            <Switch>
                <Route path={`${userRoutes[0].subRotes[0].path}`}>
                    <ManageUsers/>
                </Route>
            </Switch>
        </>
    );
};

export default Users;
