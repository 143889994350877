import axios from "axios";

import {
  GET_C_SUBSCRIPTIONS,
  RESET_C_SUBSCRIPTIONS,
  LOADING
} from "../actionTypes";
import { AWS_CART_8000 } from "configurations";
import { adminToken } from "helper";
import { snackAction } from "redux/actions";

const { SET_TRUE, SET_FALSE } = LOADING;

export const get = (id, pageNumber) => {
  return async dispatch => {
    dispatch({ type: SET_TRUE });
    console.log({ id, pageNumber });

    adminToken()
      .then(token =>
        axios.get(
          `${AWS_CART_8000}/subscriptions?uid=${id}&pagenumber=${pageNumber}&pagesize=10`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
      )
      .then(res => {
        dispatch({ type: GET_C_SUBSCRIPTIONS, payload: res.data });
      })
      .catch(err => dispatch(snackAction.add(err)))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const reset = () => dispatch =>
  dispatch({ type: RESET_C_SUBSCRIPTIONS });
