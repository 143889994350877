import React, {useState} from 'react';
import {Route, Switch, useLocation} from "react-router-dom";
import {Tabs} from "../components";
import tabRoutes, {SPCommisionsRoutes} from "./ServiceProviders/tab-routes";
import PackagesPopup from "../views/ServiceProviders/popups/Packages/PackagesPopup";

const popups = [
    {
        title: "+ New Package",
        path: "packages",
        component: closeModal => <PackagesPopup closeModal={closeModal}/>
    }
];

const SPCommissions = () => {
    const location = useLocation();
    const currentLocation = location.pathname.split("/", 3)[1];
    // States
    const [modal, setModal] = useState(); // Popup open or close state

    return (
        <>
            {/* Panels */}
            <Tabs
                routes={SPCommisionsRoutes}
                title={popups.find(popup => currentLocation === popup.path)?.title}
                onClick={() => setModal(currentLocation)}
            />

            {
                // Popups
                modal &&
                popups?.length &&
                popups.map(
                    popup =>
                        currentLocation === popup.path &&
                        popup.component(() => setModal(false))
                )
            }

            {/* Modals */}

            <Switch>
                <Route path={`${SPCommisionsRoutes[0]?.modalRoutes[0]?.path}`}>
                    {SPCommisionsRoutes[0]?.modalRoutes[0]?.component}
                </Route>
            </Switch>
        </>

    )
};

export default SPCommissions;