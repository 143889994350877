import {
    ADD_COUNTRY,
    GET_COUNTRY,
    TOGGLE_COUNTRY,
    UPDATE_COUNTRY,
    RESET_COUNTRY,
    REMOVE_COUNTRY,
} from "../actionTypes";

const countryReducer = (state = [], action) => {
    switch (action.type) {
        case GET_COUNTRY:
            return action.payload;

        case ADD_COUNTRY:
            return action.payload;

        case UPDATE_COUNTRY:
            return state.map((item) =>
                item.id === action.payload.id ? action.payload : item
            );

        case TOGGLE_COUNTRY:
            return state.map((item) =>
                item.id !== action.payload.id
                    ? item
                    : {...item, enabled: !item.enabled}
            );

        case REMOVE_COUNTRY:
            return state.filter((item) => item.id !== action.payload && item);

        case RESET_COUNTRY:
            return [];

        default:
            return state;
    }
};

export default countryReducer;
