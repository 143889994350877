import React, { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { spAppPreferencesAction } from "redux/actions";
import { TabModal } from "components";
import Question from "./Question";
import Answers from "./Answers";

export default function FAQTopics({ closeModal, data }) {
  // redux
  const dispatch = useDispatch();

  // states
  const [tabIndex, setTabIndex] = useState(0); //Popup tab active or inactive state
  const [details, setDetails] = useState();

  // useEffects
  useEffect(() => {
    if (!data) return;
    setDetails(data);
  }, [data]);

  // Handle change in Question Form
  const handleChange = (e, index) => {
    if (index === 0 || index) {
      const options = [...details?.options];
      options[index] = {
        ...options[index],
        [e.target.name]:
          isNaN(e.target.value) || !e.target.value
            ? e.target.value
            : parseInt(e.target.value)
      };

      setDetails({
        ...details,
        options
      });
      return;
    }

    setDetails({
      ...details,
      [e.target.name]:
        isNaN(e.target.value) || !e.target.value
          ? e.target.value
          : parseInt(e.target.value)
    });
  };

  const addAnswer = () => {
    const options = details?.options ? [...details?.options] : [];
    options.push({});
    setDetails({
      ...details,
      options
    });
  };

  const removeAnswer = index => {
    const options = [...details?.options] || [];
    options.splice(index, 1);
    setDetails({
      ...details,
      options
    });
  };

  // On Submit Data to Server
  const submit = () => {
    setTabIndex(0);
    closeModal();
    if (data) return dispatch(spAppPreferencesAction.update(details));
    dispatch(spAppPreferencesAction.add(details));
  };

  // Popup tabs routes
  const modalTabs = useMemo(
    () => [
      {
        name: "Preference Category",
        component: (
          <Question
            key="question"
            onClick={e => setTabIndex(1)}
            details={details}
            setDetails={handleChange}
            edit={data ? true : false}
          />
        )
      },
      {
        name: "Preference Options",
        component: (
          <Answers
            key="options"
            onClick={submit}
            details={details}
            setDetails={handleChange}
            addAnswer={addAnswer}
            removeAnswer={removeAnswer}
          />
        )
      }
    ],
    [details]
  );

  return (
    <TabModal
      tabTitle={data ? "Edit App Preference" : "Create New App Preference"}
      tabs={modalTabs}
      tabIndex={tabIndex}
      setTabIndex={index => setTabIndex(index)}
      closeModal={() => {
        setTabIndex(0);
        closeModal();
      }}
    />
  );
}
