import UsersPanel from "./panels/UsersPanel";
import RolesPanel from "./panels/RolesPanel";
import ModulesPanel from "./panels/ModulesPanel";
import PrivilegesPanel from "./panels/PrivilegesPanel";
import DefaultPrivilegePanel from "./panels/DefaultPrivilegePanel";
import AdminRolesPanel from "./panels/AdminRolesPanel";
import ManageUsers from "./modals/ManageUsers";

const UsersRoutes = [
    {
        name: "Users",
        path: "/users",
        component: <UsersPanel/>,
        subRotes: [
            {
                name: "Manage Users",
                path: "/users/users/:id",
                component: <ManageUsers/>
            }
        ]
    },
    {
        name: "Roles",
        path: "/roles",
        component: <RolesPanel/>
    },
    {
        name: "Modules",
        path: "/modules",
        component: <ModulesPanel/>
    },
    {
        name: "Privileges",
        path: "/privileges",
        component: <PrivilegesPanel/>
    },
    {
        name: "Default Privileges",
        path: "/default-privileges",
        component: <DefaultPrivilegePanel/>
    },
    {
        name: "Dashboard Privileges",
        path: "/dashboard-privileges",
        component: <AdminRolesPanel/>
    }
];

export default UsersRoutes;
