import axios from "axios";

import {
  GET_CITY,
  TOGGLE_CITY,
  UPDATE_CITY,
  RESET_CITY,
  LOADING
} from "../actionTypes";
import {
  AWS_LOCATION_8888,
  BASE_URL_LOCATION,
  IMAGE_URL,
  Who_Obj
} from "configurations";
import { adminToken } from "helper";

const { SET_TRUE, SET_FALSE } = LOADING;

export const getAll = () => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.get(`${AWS_LOCATION_8888}/country/city`, {
          headers: { Authorization: `Bearer ${token}` }
        })
      )
      .then(res => {
        dispatch({ type: GET_CITY, payload: res.data });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const get = (cid, sid) => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.get(`${AWS_LOCATION_8888}/country/${cid}/state/${sid}/city`, {
          headers: { Authorization: `Bearer ${token}` }
        })
      )
      .then(res => {
        dispatch({ type: GET_CITY, payload: res.data });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const add = (cid, sid, name) => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.post(
          `${AWS_LOCATION_8888}/country/${cid}/state/${sid}/city`,
          {
            name,
            enabled: true
          },
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        )
      )
      .then(res => {
        dispatch(get(cid, sid));
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

// Update
export const update = (details = {}) => {
  const { name, displayName, description, displayOrder, id, image } = details;

  return async dispatch => {
    dispatch({ type: SET_TRUE });
    let imageRes;

    // Request to Upload Image
    if (typeof image === "object") {
      const formData = new FormData();
      formData.append("imageFile", image);
      imageRes = await axios.post(`${IMAGE_URL}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    }

    const finalRes = await axios.put(
      `${BASE_URL_LOCATION}/country/${id}`,
      {
        description,
        displayName,
        name,
        displayOrder: parseInt(displayOrder),
        image: imageRes?.data.imgUrl || image
      },
      {
        headers: { who: JSON.stringify(Who_Obj) }
      }
    );

    dispatch({ type: UPDATE_CITY, payload: finalRes.data });
  };
};

export const toggle = id => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.patch(
          `${AWS_LOCATION_8888}/country/${id}`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
      )

      .then(res => dispatch({ type: TOGGLE_CITY, payload: res.data }))
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const remove = (cid, sid, id) => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.delete(
          `${AWS_LOCATION_8888}/country/${cid}/state/${sid}/city/${id}`,
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        )
      )
      .then(res => dispatch(get(cid, sid)))
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const reset = () => dispatch => dispatch({ type: RESET_CITY });
