import React, {useEffect} from "react";
import styles from "./../styles.module.scss";
import {TextInput, Button, FilterDropdown} from "components";
import {useDispatch, useSelector} from "react-redux";
import {cityAction} from "redux/actions";

export default function CategoryDetails({onClick, details, setDetails}) {
    const dispatch = useDispatch();

    const city = useSelector(state => state.city);

    useEffect(() => {
        dispatch(cityAction.getAll());

        return () => {
            dispatch(cityAction.reset());
        };
    }, []);

    return (
        <div className={styles.main__container}>
            <FilterDropdown
                onChange={setDetails}
                label="App Client"
                name="client"
                options={["Select app client", "Customer", "Service Provider"]}
                values={[0, "customer", "service-provider"]}
                value={details?.client}
            />
            <FilterDropdown
                onChange={setDetails}
                label="City"
                name="cityLocation"
                options={
                    city?.length
                        ? ["Select a city", ...city?.map(item => item.name)]
                        : ["Select a city"]
                }
                value={details?.cityLocation}
            />
            <TextInput
                label="Image Text"
                placeholder="Image Text"
                name="imageText"
                onChange={setDetails}
                value={details?.imageText}
                textarea={true}
            />
            <TextInput
                label="Image Help"
                placeholder="Image Help"
                name="imageHelp"
                onChange={setDetails}
                value={details?.imageHelp}
                textarea={true}
            />
            <TextInput
                label="Available From"
                placeholder="Available From"
                name="availableFrom"
                type="date"
                onChange={setDetails}
                value={details?.availableFrom?.split("T")[0]}
                textarea={true}
            />
            <TextInput
                label="Available To"
                placeholder="Available To"
                name="availableTo"
                type="date"
                onChange={setDetails}
                value={details?.availableTo?.split("T")[0]}
                textarea={true}
            />
            <div className={styles.btn__container}>
                <Button title="Next" onClick={onClick}/>
            </div>
        </div>
    );
}
