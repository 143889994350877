import React, { useState, useEffect } from "react";
import { SideModal, Tabs } from "components";
import { orgModalRoutes } from "../tab-routes";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function OrgModal() {
  // Routers
  const { id } = useParams();

  const org = useSelector(state => state.org);
  const [titles, setTitles] = useState();

  useEffect(() => {
    const orgDetail = org?.find(item => item?.id == id);

    const titleArr = [{ key: "Organization Name", value: orgDetail?.orgName }];
    setTitles(titleArr);
  }, [id]);

  return (
    <SideModal title="Organization ID" titles={titles}>
      <Tabs routes={orgModalRoutes} />
    </SideModal>
  );
}
