import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import styles from "./../styles.module.scss";
import {TextInput, Button, FilterDropdown, CheckBox} from "components";

export default function Details({onClick, details, setDetails}) {
    const faqAreas = useSelector(state => state.faqAreas);
    const faqAreasNames = faqAreas?.map(item => item.name); //make an arry of names
    const faqAreasIDs = faqAreas?.map(item => item.id);

    return (
        <div className={styles.main__container}>
            <FilterDropdown
                onChange={setDetails}
                name="faqAreaID"
                label="FAQ Area"
                options={["Pick a faq area", ...faqAreasNames]}
                value={details?.faqAreaID}
                values={[0, ...faqAreasIDs]}
            />
            <TextInput
                label="Name"
                placeholder="Name"
                name="name"
                onChange={setDetails}
                value={details?.name}
                textarea={true}
            />
            <TextInput
                label="Description"
                placeholder="Description"
                name="description"
                onChange={setDetails}
                value={details?.description}
                textarea={true}
            />
            <TextInput
                label="Display Name"
                placeholder="Display Name"
                name="displayName"
                onChange={setDetails}
                value={details?.displayName}
            />
            <TextInput
                label="Display Order"
                placeholder="Display Order"
                name="displayOrder"
                onChange={setDetails}
                value={details?.displayOrder}
            />
            <div className={styles.btn__container}>
                <Button title="Next" onClick={onClick}/>
            </div>
        </div>
    );
}
