import React, {useState} from "react";
import {useLocation} from "react-router-dom";
import categoryRoutes from "./category-routes";
import {Tabs} from "components";
import CategoryPopup from "./popups/Category/CategoryPopup";
import SubCategoryPopup from "./popups/SubCategory/SubCategoryPopup";
import LocationPopup from "./popups/SubCategoryLocation/LocationPopup";
import {useSelector} from "react-redux";

const popups = [
    {
        title: "+ New Category",
        path: "category",
        component: closeModal => <CategoryPopup closeModal={closeModal}/>
    },
    {
        title: "+ New Sub Category",
        path: "sub-category",
        component: closeModal => <SubCategoryPopup closeModal={closeModal}/>
    },
    {
        title: "+ Sub Category Location",
        path: "sub-category-location",
        component: closeModal => <LocationPopup closeModal={closeModal}/>
    }
];

const Users = () => {
    const location = useLocation();
    const currentLocation = location.pathname.split("/", 3)[2];

    const userRole = useSelector(state =>
        state.userRole?.filter(role => role.module === "Category")
    );

    // States
    const [modal, setModal] = useState(); // Popup open or close state

    return (
        <>
            {/* Panels */}
            <Tabs
                routes={categoryRoutes}
                title={popups.find(popup => currentLocation === popup.path)?.title}
                onClick={
                    userRole?.find(item => item.privilege === "Create")
                        ? () => setModal(currentLocation)
                        : null
                }
            />

            {
                // Popups
                modal &&
                popups?.length &&
                popups.map(
                    popup =>
                        currentLocation === popup.path &&
                        popup.component(() => setModal(false))
                )
            }
        </>
    );
};

export default Users;
