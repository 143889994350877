// Available PORTs
export const PORT_9090 = ":9090";

export const PORT_8090 = ":8090";

export const PORT_8089 = ":8089";

export const SERVER_IP_2 = "http://15.207.247.51";

// AWS URLs
export const AWS_URL_UM_8089 =
    "https://cgapp.clykk.com/um/clykk/ext/um/v1";

export const AWS_URL_UM_INT_8089 =
    "https://cgapp.clykk.com/um/clykk/int/um/v1";

export const AWS_URL_CM_8090 =
    "https://cgapp.clykk.com/catalog/api/v2/catalog-manager";

export const AWS_URL_DM_8095 =
    "https://cgapp.clykk.com/discount/api/v2/discount-management";

export const AWS_CART_8000 =
    "https://cgapp.clykk.com/cart/api/v2/int/cart-management/cart";

export const AWS_HOMEPAGE_8088 =
    "https://cgapp.clykk.com/homepage/clykk/ext/hpi/v2/app";

export const AWS_LOCATION_8888 = "https://cgapp.clykk.com/location/api/v2";

export const AWS_FAQ_7070 = "https://cgapp.clykk.com/faq/api/v2/faq-manager";

export const AWS_ROUTING =
    "http://v2app.clykk.com/routing_management/public/api";

export const AWS_ROUTING_SETTING =
    "https://cgapp.clykk.com/routing/api/v2/int/route-setting";

// Base URL New
export const BASE_URL_NEW = "http://v2app.clykk.com/public/api";

// Old Methods
export const BASE_URL = "http://52.66.175.3:9090/api/v2/int/admin_dashboard";

export const BASE_URL_FAQ = "http://52.66.175.3:7070/api/v2/faq-manager";

export const BASE_URL_LOCATION = "http://52.66.175.3:8888/api/v2";

export const BASE_URL_PREFERENCES =
    "http://52.66.175.3:8089/clykk/ext/um/v1/user/preferences";

export const IMAGE_URL = "https://cgapp.clykk.com/s3/api/s3manager";

export const BASE_URL_SP = "https://cgapp.clykk.com/um/clykk/int/sp/v1";

// Tokens
export const Who_Obj = JSON.stringify({
    token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklEIjo4LCJtb2JpbGUiOiI5ODg2ODYzMDUxIiwiZGV2aWNlSUQiOiIiLCJkZXZpY2VUeXBlIjoiIiwiYXV0aFRva2VuIjoiIiwiUm9sZSI6e30sImV4cCI6MTk1OTMyODk2Mn0.lCkZ9_PMHJ2VFocxBfEaAnkGmpQ3IMSOXbwsBw0eVkU"
});
