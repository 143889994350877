import axios from "axios";

import {
  GET_PREFERENCES,
  TOGGLE_PREFERENCES,
  UPDATE_PREFERENCES,
  ADD_PREFERENCES,
  RESET_PREFERENCES,
  LOADING
} from "../actionTypes";
import {
  BASE_URL_PREFERENCES,
  Who_Obj,
  AWS_URL_UM_INT_8089
} from "configurations";
import { adminToken } from "../../helper";

const { SET_TRUE, SET_FALSE } = LOADING;
export const get = id => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    adminToken()
      .then(token =>
        axios.get(`${AWS_URL_UM_INT_8089}/user/${id}/preferences`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
      )

      .then(res => {
        const data = Object.values(res.data.data);
        dispatch({ type: GET_PREFERENCES, payload: data });
      })
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const add = (pid, details = {}) => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    axios
      .post(
        `${BASE_URL_PREFERENCES}`,
        {
          qna: [details]
        },
        {
          headers: { who: Who_Obj }
        }
      )
      .then(res => dispatch({ type: ADD_PREFERENCES, payload: res.data }))
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

// Update Package
export const update = (pid, details = {}) => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    axios
      .put(
        `${BASE_URL_PREFERENCES}`,
        {
          ...details
        },
        {
          headers: { who: Who_Obj }
        }
      )
      .then(res => dispatch({ type: UPDATE_PREFERENCES, payload: res.data }))
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const toggle = (pid, id) => {
  return dispatch => {
    dispatch({ type: SET_TRUE });
    axios
      .patch(`${BASE_URL_PREFERENCES}`, {}, { headers: { who: Who_Obj } })
      .then(res => dispatch({ type: TOGGLE_PREFERENCES, payload: res.data }))
      .catch(err => console.log(err))
      .finally(_ => dispatch({ type: SET_FALSE }));
  };
};

export const reset = () => dispatch => dispatch({ type: RESET_PREFERENCES });
