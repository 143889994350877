import React from 'react';
import styles from './styles.module.scss';

export default function TransparentBG({children}) {

    return (
        <div className={styles.container}>
            <div className={styles.bg} />
            {children}
        </div>
    )
}
