import React, { useState, useEffect } from "react";
import { SideModal, Tabs, TextLink } from "components";
import { modalTabRoutes } from "../tab-routes";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const Package = () => {
  // Routers
  const { id } = useParams();

  // Redux
  const orders = useSelector(state => state.orders);

  const [titles, setTitles] = useState([]);

  useEffect(() => {
    const cart = orders?.find(item => item.cartID == id);
    const titleArr = [
      {
        key: "Transaction ID",
        value: (
          <TextLink
            text={cart.transactionID}
            onClick={() =>
              window.open(
                `https://dashboard.razorpay.com/app/payments/${cart?.transactionID}`,
                "_blank"
              )
            }
          />
        )
      },
      { key: "Customer ID", value: cart?.userID }
    ];
    setTitles(titleArr);
  }, [orders]);

  return (
    <SideModal title="Order ID" titles={[...titles]}>
      <Tabs routes={modalTabRoutes} />
    </SideModal>
  );
};

export default Package;
