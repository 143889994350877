import {useState} from 'react';
import ProfileTab from "./Tabs/ProfileTab";
import {makeStyles, Tabs, Box, Tab, useTheme} from "@material-ui/core";
import PropTypes from "prop-types";
import {IoIosClose} from "react-icons/io";
import AddressTab from "./Tabs/AddressTab";
import CartTab from "./Tabs/CartTab";
import TodoTab from "./Tabs/TodoTab";
import SubscriptionsTab from "./Tabs/SubscriptionsTab";
import PreferencesTab from "./Tabs/PreferencesTab";
import LikesTab from "./Tabs/LikesTab";
import SharesTab from "./Tabs/SharesTab";
import ReferAndEarnTab from "./Tabs/ReferAndEarnTab";
import CouponsTab from "./Tabs/CouponsTab";

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


const useStyles = makeStyles((theme) => ({
    indicator: {
        backgroundColor: "#6CD7BB"
    }
}))

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            style={{backgroundColor: "white"}}
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <p>{children}</p>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function ReportModal({clickedCustomer, handleCloseModal, setClickedSubscription, handleOpenSubscriptionModal}) {
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [modalStyle] = useState(getModalStyle);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const classes = useStyles()

    return (
        clickedCustomer &&
        <div style={modalStyle} className={'modal-paper'}>
            <div className={'modal-top-bar'}>
                <IoIosClose
                    size={20}
                    className={'modal-close'}
                    onClick={handleCloseModal}
                />
            </div>

            <div style={{margin: "0 0 10px 0"}}>
                <h5>Customer ID: {clickedCustomer.id}</h5>
                <div style={{display: 'flex', marginTop: 10}}>
                    <p style={{marginRight: 20}}>Name: {clickedCustomer.name}</p>
                    <p>Mobile: {clickedCustomer.mobile}</p>
                </div>
            </div>


            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                classes={{
                    indicator: classes.indicator
                }}>
                <Tab label="Profile" {...a11yProps(0)} />
                <Tab label="Address"
                     style={{}} {...a11yProps(1)} />
                <Tab label="Cart"
                     {...a11yProps(2)} />
                <Tab label="To-Do"
                     {...a11yProps(3)} />
                <Tab label="Subscription"
                     {...a11yProps(4)} />
                {/*<Tab label="Orders"*/}
                {/*     {...a11yProps(5)} />*/}
                <Tab label="Preferences"
                     {...a11yProps(5)} />
                <Tab label="Likes"
                     {...a11yProps(6)} />
                <Tab label="Shares"
                     {...a11yProps(7)} />
                <Tab label="Refer & Earn"
                     {...a11yProps(8)} />
                <Tab label="Coupons"
                     {...a11yProps(9)} />
            </Tabs>
            {/*<SwipeableViews*/}
            {/*    index={value}*/}
            {/*    onChangeIndex={handleChangeIndex}*/}
            {/*>*/}
            <div className={'modal-body'}>
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <ProfileTab clickedCustomer={clickedCustomer}/>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <AddressTab clickedCustomer={clickedCustomer}/>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <CartTab clickedCustomer={clickedCustomer}/>
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                    <TodoTab clickedCustomer={clickedCustomer}/>
                </TabPanel>
                <TabPanel value={value} index={4} dir={theme.direction}>
                    <SubscriptionsTab clickedCustomer={clickedCustomer} handleCloseModal={handleCloseModal} setClickedSubscription={setClickedSubscription} handleOpenSubscriptionModal={handleOpenSubscriptionModal}/>
                </TabPanel>
                {/*<TabPanel value={value} index={5} dir={theme.direction}>*/}
                {/*    <OrderTab clickedCustomer={clickedCustomer}/>*/}
                {/*</TabPanel>*/}
                <TabPanel value={value} index={5} dir={theme.direction}>
                    <PreferencesTab clickedCustomer={clickedCustomer}/>
                </TabPanel>
                <TabPanel value={value} index={6} dir={theme.direction}>
                    <LikesTab clickedCustomer={clickedCustomer}/>
                </TabPanel>
                <TabPanel value={value} index={7} dir={theme.direction}>
                    <SharesTab clickedCustomer={clickedCustomer}/>
                </TabPanel>
                <TabPanel value={value} index={8} dir={theme.direction}>
                    <ReferAndEarnTab clickedCustomer={clickedCustomer}/>
                </TabPanel>
                <TabPanel value={value} index={9} dir={theme.direction}>
                    <CouponsTab clickedCustomer={clickedCustomer}/>
                </TabPanel>
                {/*</SwipeableViews>*/}
            </div>
        </div>
    );
}

export default ReportModal;
