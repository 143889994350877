import axios from "axios";

import {
    GET_ADDRESS,
    TOGGLE_ADDRESS,
    UPDATE_ADDRESS,
    RESET_ADDRESS,
    LOADING
} from "../actionTypes";
import {
    SERVER_IP_2,
    PORT_8089,
    IMAGE_URL,
    Who_Obj,
    AWS_URL_UM_INT_8089
} from "configurations";
import {adminToken} from "../../helper";

const {SET_TRUE, SET_FALSE} = LOADING;

export const get = userID => {
    return dispatch => {
        dispatch({type: SET_TRUE});

        adminToken()
            .then(token =>
                axios.get(`${AWS_URL_UM_INT_8089}/get-address/${userID}`, {
                    headers: {Authorization: `Bearer ${token}`}
                })
            )
            .then(res => {
                dispatch({type: GET_ADDRESS, payload: res.data.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const add = (cid, sid, name) => {
    return dispatch => {
        dispatch({type: SET_TRUE});
        axios
            .post(
                `http://cgapp.clykk.com/location/country/${cid}/state/${sid}/city`,
                {
                    name,
                    enabled: true
                },
                {
                    headers: {who: Who_Obj}
                }
            )
            .then(res => {
                dispatch(get(cid, sid));
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

// Update
export const update = (details = {}) => {
    const {name, displayName, description, displayOrder, id, image} = details;

    return async dispatch => {
        dispatch({type: SET_TRUE});
        let imageRes;

        // Request to Upload Image
        if (typeof image === "object") {
            const formData = new FormData();
            formData.append("imageFile", image);
            imageRes = await axios.post(`${IMAGE_URL}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
        }

        const finalRes = await axios.put(
            `http://cgapp.clykk.com/location/country/${id}`,
            {
                description,
                displayName,
                name,
                displayOrder: parseInt(displayOrder),
                image: imageRes?.data.imgUrl || image
            },
            {
                headers: {who: JSON.stringify(Who_Obj)}
            }
        );

        dispatch({type: UPDATE_ADDRESS, payload: finalRes.data});
    };
};

export const toggle = id => {
    return dispatch => {
        dispatch({type: SET_TRUE});
        axios
            .patch(
                `http://cgapp.clykk.com/location/country/${id}`,
                {},
                {headers: {who: JSON.stringify(Who_Obj)}}
            )
            .then(res => dispatch({type: TOGGLE_ADDRESS, payload: res.data}))
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const remove = (cid, sid, id) => {
    return dispatch => {
        dispatch({type: SET_TRUE});
        axios
            .delete(
                `http://cgapp.clykk.com/location/country/${cid}/state/${sid}/city/${id}`,
                {
                    headers: {who: Who_Obj}
                }
            )
            .then(res => dispatch(get(cid, sid)))
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const reset = () => dispatch => dispatch({type: RESET_ADDRESS});
