import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdEdit } from "react-icons/md";
import styles from "./styles.module.scss";
import {
  Table,
  TextInput,
  SearchButton,
  LightTextButton,
  FilterDropdown,
  SwitchButton
} from "components";
import {
  categoryAction,
  subCategoriesAction,
  subCategoryLocationAction
} from "redux/actions";
import LocationPopup from "../popups/SubCategoryLocation/LocationPopup";
import { internalSearch } from "helper";

const tableHeaders = [
  "ID",
  "Sub Category ID",
  "Location",
  "City",
  "Created At",
  "Created By",
  "Updated At",
  "Updated By",
  "Enabled",
  "Edit"
];

const SubCategoryLocation = () => {
  // States
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSubCategory, setSelectedSubCategory] = useState();
  const [tableRows, setTableRows] = useState();
  const [editableDetail, setEditableDetails] = useState();
  const [isPopupVisible, setPopupVisible] = useState();

  // Filter States
  const [status, setStatus] = useState();
  const [searchFilter, setSearchFilter] = useState({
    city: ""
  });

  // Redux actions
  const dispatch = useDispatch();

  const { categories, subCategoriesName, subCategoryLocation, userRole } =
    useSelector(state => ({
      categories: state.categories,
      subCategoryLocation: state.subCategoryLocation,
      subCategoriesName: state.subCategories,
      userRole: state.userRole?.filter(role => role.module === "Category")
    }));

  const filteredCategories = categories?.map(category => category.name); //make an arry of category names

  const filteredSubCategories =
    subCategoriesName?.map(subCategory => subCategory.name) || []; //make an arry of category names

  // Load initially required data
  useEffect(() => {
    dispatch(categoryAction.get());
  }, [dispatch]);

  useEffect(() => {
    let filtered = subCategoryLocation;

    // filter based on Status
    filtered = filtered?.filter(subCategory =>
      status === "Enabled"
        ? subCategory.enabled
        : status === "Disabled"
        ? !subCategory.enabled
        : subCategory
    );

    filtered = internalSearch(searchFilter, "city", filtered);

    // Map table rows
    const data = filtered?.map(subCategory => ({
      id: `${subCategory.id}`,
      subcategoryID: `${selectedSubCategory}`,
      location: `${subCategory.location}`,
      city: `${subCategory.city}`,
      createdAt: `${new Date(subCategory.createdAt).toLocaleString()}`,
      createdBy: subCategory.createdBy,
      updatedAt: `${new Date(subCategory.updatedAt).toLocaleString()}`,
      updatedBy: subCategory.updatedBy,
      enabled: (
        <SwitchButton
          enabled={subCategory.enabled}
          onClick={() =>
            dispatch(
              subCategoryLocationAction.toggle(
                subCategory.subcategoryID,
                subCategory.location
              )
            )
          }
        />
      ),
      edit: (
        <MdEdit
          size="20"
          color="#528ff0"
          onClick={
            userRole?.find(item => item.privilege === "Update")
              ? () => {
                  setEditableDetails(subCategory);
                  setPopupVisible(true);
                }
              : null
          }
          className="clickable"
        />
      )
    }));

    setTableRows(data);
  }, [subCategoryLocation, status, searchFilter]);

  // Get sub categories based on category selection
  const onCategorySelect = value => {
    setSelectedCategory(value);

    if (value === "Pick a category" || !value) return;

    const categoryId = categories?.find(
      category => value === category.name && category
    );

    dispatch(subCategoriesAction.get(categoryId.id));
  };

  // Search based on category name
  const onSearch = () => {
    if (selectedSubCategory === "Pick a category" || !selectedSubCategory)
      return;
    const categoryId = subCategoriesName.find(
      category => selectedSubCategory === category.name && category
    );
    dispatch(subCategoryLocationAction.get(categoryId.id));
  };

  return (
    <div>
      <div className={styles.filter}>
        <FilterDropdown
          onChange={e => onCategorySelect(e.target.value)}
          value={selectedCategory}
          label="Category Name"
          options={["Pick a category", ...(filteredCategories || [])]}
        />
        <FilterDropdown
          onChange={e => setSelectedSubCategory(e.target.value)}
          value={selectedSubCategory}
          label="Sub Category Name"
          options={["Pick a sub category", ...(filteredSubCategories || [])]}
        />
        <TextInput
          search
          label="City"
          name="City"
          value={searchFilter?.city}
          onChange={e =>
            setSearchFilter(prev => ({ ...(prev || {}), city: e.target.value }))
          }
        />
        <FilterDropdown
          onChange={e => setStatus(e.target.value)}
          label="Status"
          value={status}
          options={["All", "Enabled", "Disabled"]}
        />
        <SearchButton
          onClick={
            userRole?.find(item => item.privilege === "Read") ? onSearch : null
          }
        />
        <LightTextButton
          onClick={() => {
            setSearchFilter({});
            setStatus({});
          }}
        />
      </div>
      <Table headers={tableHeaders} data={tableRows} />
      {isPopupVisible && (
        <LocationPopup
          data={editableDetail}
          closeModal={() => setPopupVisible(false)}
        />
      )}
    </div>
  );
};

export default SubCategoryLocation;
