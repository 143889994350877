import React, {useEffect, useState, useMemo} from "react";
import styles from "./styles.module.scss";
import {
    Table,
    TextInput,
    SearchButton,
    LightTextButton,
    FilterDropdown,
    SwitchButton,
    ImageStamp
} from "components";
import {useDispatch, useSelector} from "react-redux";
import {homeGreetingAction} from "redux/actions";
import {MdEdit} from "react-icons/md";
import HomeGreetingPopup from "../popups/HomeGreeting/HomeGreetingPopup";
import {internalSearch} from "helper";

export default function HomeGreetingPanel() {
    // Disptach redux action to get category
    const dispatch = useDispatch();

    const {homeGreeting, userRole} = useSelector(state => ({
        homeGreeting: state.homeGreeting,
        userRole: state.userRole?.filter(role => role.module === "Home Greeting")
    }));

    // Component States
    const [tableRows, setTableRows] = useState();
    const [editableDetail, setEditableDetails] = useState();
    const [isPopupVisible, setPopupVisible] = useState();

    // Filter States
    const [status, setStatus] = useState();
    const [client, setClient] = useState(0);
    const [filter, setFilter] = useState({
        cityLocation: ""
    });

    // Initially get the homeGreeting
    useEffect(() => {
        if (userRole?.find(item => item.privilege === "Read"))
            dispatch(homeGreetingAction.get());

        return () => dispatch(homeGreetingAction.reset());
    }, [dispatch]);

    useEffect(() => {
        if (!homeGreeting?.length) return;

        let filtered = homeGreeting;
        // filter based on Status
        filtered = filtered?.filter(item =>
            status === "Enabled"
                ? item?.enabled
                : status === "Disabled"
                    ? !item?.enabled
                    : item
        );
        filtered = filtered?.filter(
            item => client === item?.client || (client == 0 && item)
        );

        filtered = internalSearch(filter, "cityLocation", filtered);

        // Custom Functions
        filtered = filtered.map(item => ({
            id: `${item?.id}`,
            client: `${item?.client}`,
            cityLocation: `${item?.cityLocation}`,
            imageText: `${item?.imageText}`,
            imageUrl: <ImageStamp src={item?.imageUrl}/>,
            imageHelp: `${item?.imageHelp}`,
            availableFrom: `${new Date(item?.availableFrom).toLocaleString()}`,
            availableTo: `${new Date(item?.availableTo).toLocaleString()}`,
            enabled: (
                <SwitchButton
                    enabled={item?.enabled}
                    onClick={
                        userRole?.find(item => item.privilege === "Enable")
                            ? () =>
                                dispatch(homeGreetingAction.toggle(item?.client, item?.id))
                            : null
                    }
                />
            ),
            edit: (
                <MdEdit
                    size="20"
                    color="#528ff0"
                    onClick={
                        userRole?.find(item => item.privilege === "Update")
                            ? () => {
                                setEditableDetails(item);
                                setPopupVisible(true);
                            }
                            : null
                    }
                    className="clickable"
                />
            )
        }));

        setTableRows(filtered);
    }, [homeGreeting, status, client, filter]);

    // Table header
    const tableHeaders = useMemo(() => [
        {
            title: "ID"
        },
        {
            title: "App Client"
        },
        {
            title: "City Location",
            sortBy: "cityLocation"
        },
        {
            title: "Image Text"
        },
        {
            title: "Image"
        },
        {
            title: "Image Help"
        },
        {
            title: "Available from",
            sortBy: "availableFrom"
        },
        {
            title: "Available to",
            sortBy: "availableTo"
        },
        {
            title: "Enabled"
        },
        {
            title: "Edit"
        }
    ]);

    return (
        <div>
            <div className={styles.filter}>
                <FilterDropdown
                    onChange={e => setClient(e.target.value)}
                    label="App client"
                    options={["All", "Customer", "Service Provider"]}
                    values={[0, "customer", "service-provider"]}
                />
                <TextInput
                    search
                    label="City Location"
                    name="cityLocation"
                    value={filter?.cityLocation}
                    onChange={e =>
                        setFilter(prev => ({...prev, cityLocation: e.target.value}))
                    }
                />
                <FilterDropdown
                    onChange={e => setStatus(e.target.value)}
                    label="Status"
                    options={["All", "Enabled", "Disabled"]}
                />
                <SearchButton/>
                <LightTextButton/>
            </div>
            <Table headers={tableHeaders} data={tableRows}/>

            {isPopupVisible && (
                <HomeGreetingPopup
                    data={editableDetail}
                    closeModal={() => setPopupVisible(false)}
                />
            )}
        </div>
    );
}
