import React, {useState, useMemo, useEffect} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {packageQnAAction} from "redux/actions";
import {TabModal} from "components";
import Question from "./Question";
import Answers from "./Answers";

export default function PackageQnA({closeModal, data}) {
    // Routers
    const pid = useHistory().location.pathname.split("/")[3];

    // redux
    const dispatch = useDispatch();

    // states
    const [tabIndex, setTabIndex] = useState(0); //Popup tab active or inactive state
    const [details, setDetails] = useState();

    // useEffects
    useEffect(() => {
        if (!data) return;
        setDetails(data);
    }, [data]);

    // Handle change in Question Form
    const handleChange = (e, index) => {
        if (index === 0 || index) {
            const answers = [...details?.answers];
            answers[index] = {
                ...answers[index],
                [e.target.name]:
                    isNaN(e.target.value) || !e.target.value
                        ? e.target.value
                        : parseInt(e.target.value)
            };

            setDetails({
                ...details,
                answers
            });
            return;
        }

        setDetails({
            ...details,
            [e.target.name]:
                isNaN(e.target.value) || !e.target.value
                    ? e.target.value
                    : parseInt(e.target.value)
        });
    };

    const addAnswer = () => {
        const answers = details?.answers ? [...details?.answers] : [];
        answers.push({});
        setDetails({
            ...details,
            answers
        });
    };

    const removeAnswer = index => {
        const answers = [...details?.answers] || [];
        answers.splice(index, 1);
        setDetails({
            ...details,
            answers
        });
    };

    // On Submit Data to Server
    const submit = () => {
        setTabIndex(0);
        closeModal();
        console.log(details);
        if (data) return dispatch(packageQnAAction.update(pid, details));
        dispatch(packageQnAAction.add(pid, details));
    };

    // Popup tabs routes
    const modalTabs = useMemo(
        () => [
            {
                name: "Question",
                component: (
                    <Question
                        key="question"
                        onClick={e => setTabIndex(1)}
                        details={details}
                        setDetails={handleChange}
                        edit={data ? true : false}
                    />
                )
            },
            {
                name: "Answers",
                component: (
                    <Answers
                        key="answers"
                        onClick={submit}
                        details={details}
                        setDetails={handleChange}
                        addAnswer={addAnswer}
                        removeAnswer={removeAnswer}
                    />
                )
            }
        ],
        [details]
    );

    return (
        <TabModal
            tabTitle={data ? "Edit QnA" : "Create New QnA"}
            tabs={modalTabs}
            tabIndex={tabIndex}
            setTabIndex={index => setTabIndex(index)}
            closeModal={() => {
                setTabIndex(0);
                closeModal();
            }}
        />
    );
}
