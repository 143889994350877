import React, {useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Table, TextLink} from "../../../components";
import {cartAction} from "redux/actions";

function TodoTab({clickedCustomer}) {
    const location = useLocation();
    const currentLocation = clickedCustomer.id;

    const {push} = useHistory();

    const [tableRows, setTableRows] = useState();

    // Filter States
    const [status, setStatus] = useState();
    const [filterState, setfilterState] = useState({status: "todo"});

    // Redux actions
    const dispatch = useDispatch();
    const cart = useSelector(state => state.cart);

    useEffect(() => {
        dispatch(cartAction.get(currentLocation));
        return () => dispatch(cartAction.reset());
    }, []);

    useEffect(() => {
        if (!cart?.length) {
            setTableRows();
            return;
        }

        let filtered = cart;

        // filter based on Status
        filtered = filtered?.filter(item =>
            status === "Enabled"
                ? item.enabled
                : status === "Disabled"
                    ? !item.enabled
                    : item
        );

        filtered = internalSearch("status", filtered);

        // Map table rows
        const data = filtered?.map(obj => ({
            cartID: (
                <TextLink
                    text={obj.cartID}
                    onClick={() => push(`/orders/orders?orderID=${obj.cartID}`)}
                />
            ),
            status: obj.status,
            packageName: obj.items.package.packageName,
            sellingPrice: obj.items.package.sellingPrice,
            address: obj.serviceAddress.city,
            dateTime: `${obj.schedule.startDate}, ${obj.schedule.serviceTime}`,
            subscriptionFrequency: obj.schedule.subscriptionFrequency,
            createdAt: `${new Date(obj.createdAt).toLocaleString()}`,
            updatedAt: `${new Date(obj.updatedAt).toLocaleString()}`
        }));

        setTableRows(data);
    }, [cart, status, filterState]);

    // Search based on category & sub category name
    const onSearch = () => {
    };

    // internal search
    const internalSearch = (searchBy, rows) => {
        if (!filterState?.[searchBy]) return rows;

        const filteredRows =
            rows?.length &&
            rows.filter(
                item =>
                    item[searchBy]
                        ?.toString()
                        .toLowerCase()
                        .indexOf(filterState?.[searchBy]?.toLowerCase()) !== -1
            );

        return filteredRows;
    };

    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "Cart ID"
            },
            {
                title: "Status"
            },
            {
                title: "Package"
            },
            {
                title: "Price"
            },
            {
                title: "Address"
            },
            {
                title: "Date/Time"
            },
            {
                title: "Subscription"
            },
            {
                title: "Created"
            },
            {
                title: "Updated"
            }
        ],
        [tableRows]
    );

    return <Table headers={tableHeaders} data={tableRows}/>;

    return (
        <div></div>
    );
}

export default TodoTab;