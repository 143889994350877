import PackagesPanel from "./panels/PackagesPanel";
import PackageAddOn from "./panels/PackageAddOn";
import PackageQnA from "./panels/PackageQnA";
import PackageLike from "./panels/PackageLike";
import PackageShare from "./panels/PackageShare";
import Package from "./modals/Package";

const tabRoutes = [
    {
        name: "Packages",
        path: "/packages",
        component: <PackagesPanel/>,
        modalRoutes: [
            {
                name: "",
                path: "/packages/packages/:id",
                component: <Package/>
            }
        ]
    }
];

export default tabRoutes;

export const modalTabRoutes = [
    {
        name: "Package Add On",
        path: "/package-add-on",
        component: <PackageAddOn/>
    },
    {
        name: "Package QA",
        path: "/package-qa",
        component: <PackageQnA/>
    },
    {
        name: "Package Like",
        path: "/package-like",
        component: <PackageLike/>
    },
    {
        name: "Package Share",
        path: "/package-share",
        component: <PackageShare/>
    },
    {
        name: "Package Rating",
        path: "/package-rating",
        component: <PackagesPanel/>
    },
    {
        name: "Package Sub Org Map",
        path: "/package-sub-org-map",
        component: <PackagesPanel/>
    },
    {
        name: "Package City Map",
        path: "/package-city-map",
        component: <PackagesPanel/>
    }
];
