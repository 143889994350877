import React from "react";
import { useParams } from "react-router-dom";
import { TableVertical, SideModal } from "components";
import { useSelector } from "react-redux";
import { SwitchButton } from "components";

const userDetails = [
  "ID",
  "Picture",
  "First Name",
  "Last Name",
  "Email",
  "Email Verified",
  "Mobile",
  "Mobile Verified",
  "Created At",
  "Created By",
  "Updated At",
  "Updated By",
  "Enabled"
];

const ManageUsers = () => {
  // params
  const { id } = useParams();

  // redux
  const [user] = useSelector(state =>
    state.users.filter(user => user.adminID === id)
  );

  console.log(user);

  // Custom Functions
  const data = {
    adminID: user.adminID,
    picture: `${user.picture}`,
    firstName: `${user.firstName}`,
    lastName: `${user.lastName}`,
    email: `${user.email}`,
    emailVerified: `${user.emailVerified ? "Verified" : "Not Verified"}`,
    mobile: `${user.mobile}`,
    mobileVerified: `${user.mobileVerified ? "Verified" : "Not Verified"}`,
    createdAt: `${new Date(user.createdAt).toLocaleString()}`,
    createdBy: user.createdBy,
    updatedAt: `${new Date(user.updatedAt).toLocaleString()}`,
    updatedBy: user.updatedBy,
    enabled: <SwitchButton enabled={user.enabled} />
  };

  const role = user.role;

  return (
    <SideModal>
      <TableVertical heading="User Details" headers={userDetails} data={data} />
      <TableVertical heading="Roles" headers={role} data={role} />
    </SideModal>
  );
};

export default ManageUsers;
