import Category from "views/Category";
import Customers from "views/Customers";
import Dashboard from "views/Dashboard";
import Documents from "views/Documents";
import ServiceProviders from "views/ServiceProviders";
import FAQ from "views/FAQ";
import Invoices from "views/Invoices";
import Location from "views/Location";
import Orders from "views/Orders";
import Organization from "views/Organization";
import Packages from "views/Packages";
import DealsOffers from "views/DealsOffers";
import Reports from "views/Report/Reports";
import Settings from "views/Settings";
import SPCommissions from "views/SPCommissions";
import Users from "views/Users";
import AppPreference from "views/AppPreference";
import HomeGreeting from "views/HomeGreeting";
import Subscription from "views/Subscription";
import ReferAndEarn from "views/ReferAndEarn";

import {
    MdAccountBox, MdAssignmentTurnedIn, MdDescription, MdDomain,
    MdInsertChart, MdLibraryBooks,
    MdLocalMall,
    MdLocalOffer,
    MdLocalShipping,
    MdLocationOn, MdMonetizationOn, MdPeople, MdReceipt, MdSettings,
    MdToc, MdTransferWithinAStation
} from "react-icons/md";
import ServiceProviderNew from "./views/ServiceProviderNew/ServiceProviderNew";
import Chat from "./views/Chat/Chat";

const routes = [
    {
        name: "Home",
        path: "/home",
        Icon: <MdInsertChart size="14" color="#23B7E5"/>,
        component: <Dashboard/>
    },
    {
        name: "Users",
        path: "/users",
        Icon: <MdAccountBox size="14" color="#528FF0"/>,
        component: <Users/>,
        break: true
    },
    {
        name: "Category",
        path: "/category",
        Icon: <MdToc size="14" color="#528FF0"/>,
        component: <Category/>
    },
    {
        name: "Packages",
        path: "/packages",
        Icon: <MdLocalMall size="14" color="#27C24C"/>,
        component: <Packages/>
    },
    {
        name: "Discounts",
        path: "/discounts",
        Icon: <MdLocalOffer size="14" color="#27C24C"/>,
        component: <DealsOffers/>,
        break: true
    },
    {
        name: "Home Greeting",
        path: "/home-greeting",
        Icon: <MdLocalShipping size="14" color="#F19A5E"/>,
        component: <HomeGreeting/>
    },
    {
        name: "App Preferences",
        path: "/app-preferences",
        Icon: <MdLocalShipping size="14" color="#F19A5E"/>,
        component: <AppPreference/>
    },
    {
        name: "Location",
        path: "/location",
        Icon: <MdLocationOn size="14" color="#FBE165"/>,
        component: <Location/>
    },
    {
        name: "Documents",
        path: "/documents",
        Icon: <MdReceipt size="14" color="#F19A5E"/>,
        component: <Documents/>
    },
    {
        name: "FAQ",
        path: "/faq",
        Icon: <MdLocalShipping size="14" color="#F19A5E"/>,
        component: <FAQ/>,
        break: true
    },
    {
        name: "Customers",
        path: "/customers",
        Icon: <MdPeople size="14" color="#FBE165"/>,
        component: <Customers/>
    },
    {
        name: "Orders",
        path: "/orders",
        Icon: <MdAssignmentTurnedIn size="14" color="#23B7E5"/>,
        component: <Orders/>
    },
    {
        name: "Subscription",
        path: "/subscription",
        Icon: <MdAssignmentTurnedIn size="14" color="#23B7E5"/>,
        component: <Subscription/>
    },
    {
        name: "Invoices",
        path: "/invoices",
        Icon: <MdReceipt size="14" color="#27C24C"/>,
        component: <Invoices/>,
        break: true
    },
    {
        name: "Organization",
        path: "/organization",
        Icon: <MdDomain size="14" color="#27C24C"/>,
        component: <Organization/>
    },
    {
        name: "Service Providers (SP)",
        path: "/service-providers",
        Icon: <MdTransferWithinAStation size="14" color="#FBE165"/>,
        component: <ServiceProviders/>
    },
    {
        name: "SP Commissions",
        path: "/sp-commisions",
        Icon: <MdDescription size="14" color="#F19A5E"/>,
        component: <SPCommissions/>,
        break: true
    },
    {
        name: "Refer & Earn",
        path: "/refer-earn",
        Icon: <MdMonetizationOn size="14" color="#528FF0"/>,
        component: <ReferAndEarn/>
    },
    {
        name: "Chat",
        path: "/chat",
        Icon: <MdMonetizationOn size="14" color="#528FF0"/>,
        component: <Chat/>
    },
    // {
    //     name: "Reports",
    //     path: "/reports",
    //     Icon: <MdLibraryBooks size="14" color="#F05050"/>,
    //     component: <Reports/>
    // },
    // {
    //     name: "Service Providers (SP)",
    //     path: "/service-providers-new",
    //     Icon: <MdLibraryBooks size="14" color="#F05050"/>,
    //     component: <ServiceProviderNew/>
    // },
    {
        name: "Settings",
        path: "/settings",
        Icon: <MdSettings size="14" color="#FBE165"/>,
        component: <Settings/>
    }
];

export default routes;
