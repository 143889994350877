import React, { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { routingAction } from "redux/actions";
import { TabModal } from "components";
import CategoryDetails from "./CategoryDetails";
import LocationDetails from "./LocationDetails";
import RoutingDetails from "./RoutingDetails";

export default function RoutingPopup({ closeModal, data }) {
  // redux
  const dispatch = useDispatch();

  // states
  const [tabIndex, setTabIndex] = useState(0);
  const [details, setDetails] = useState();

  // useEffects
  useEffect(() => {
    if (!data) return;

    const {
      CityID,
      CloseTime,
      ID,
      LastServiceTime,
      MaxCommission,
      MinCommission,
      OpenTime,
      RouteBefore,
      RuleID,
      ServiceDuration,
      ServiceTime,
      SubCategoryID
    } = data;

    setDetails({
      cityID: CityID,
      closeTime: CloseTime,
      id: ID,
      lastServiceTime: LastServiceTime,
      maxCommission: MaxCommission,
      minCommission: MinCommission,
      openTime: OpenTime,
      routeBefore: RouteBefore,
      ruleID: RuleID,
      serviceDuration: ServiceDuration,
      serviceTime: ServiceTime,
      subCategoryID: SubCategoryID
    });
  }, [data]);

  useEffect(() => {
    console.log({ details });
  }, [details]);

  // Handle change in Form
  const handleChange = e => {
    setDetails(prev => ({
      ...prev,
      [e.target.name]: isNaN(e.target.value)
        ? e.target.value
        : parseFloat(e.target.value)
    }));
  };

  // On Submit Data to Server
  const submit = () => {
    if (!details?.subCategoryID || !details?.cityID || !details?.ruleID) {
      alert("City, Sub Category & Rule ID is mandatory.");
      return;
    }

    setTabIndex(0);
    closeModal();

    if (data) return dispatch(routingAction.update(details));
    dispatch(routingAction.add(details));
  };

  // Popup tabs routes
  const modalTabs = useMemo(
    () => [
      {
        name: "Category Details",
        component: (
          <CategoryDetails
            key={1}
            onClick={e => setTabIndex(1)}
            details={details}
            setDetails={handleChange}
          />
        )
      },
      {
        name: "Location Details",
        component: (
          <LocationDetails
            key={2}
            onClick={e => setTabIndex(2)}
            details={details}
            setDetails={handleChange}
          />
        )
      },
      {
        name: "Routing Details",
        component: (
          <RoutingDetails
            key={3}
            onClick={submit}
            details={details}
            setDetails={handleChange}
          />
        )
      }
    ],
    [details]
  );

  return (
    <TabModal
      tabTitle={data ? "Edit Routing" : "Create Routing"}
      tabs={modalTabs}
      tabIndex={tabIndex}
      setTabIndex={index => setTabIndex(index)}
      closeModal={() => {
        setTabIndex(0);
        closeModal();
      }}
    />
  );
}
