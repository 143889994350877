import axios from "axios";

import {CUSTOMER_PREFERENCES, LOADING} from "../actionTypes";
import {
    BASE_URL,
    IMAGE_URL,
    Who_Obj,
    SERVER_IP_2,
    PORT_8089, AWS_URL_UM_8089
} from "configurations";
import {customerTokenRefresher} from "../../helper";

const {GET, TOGGLE, UPDATE, RESET} = CUSTOMER_PREFERENCES;

const {SET_TRUE, SET_FALSE} = LOADING;
export const get = id => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await customerTokenRefresher();
        axios
            .get(`https://cgapp.clykk.com/um/clykk/ext/um/v1/user/preferences`, {
                headers: {
                    who: JSON.stringify({token})
                }
            })
            .then(res => {
                const data = Object.values(res.data.data);
                dispatch({type: GET, payload: data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const add = (cid, sid, name) => {
    return dispatch => {
        dispatch({type: SET_TRUE});
        axios
            .post(
                `${BASE_URL}/country/${cid}/state/${sid}/city`,
                {
                    name,
                    enabled: true
                },
                {
                    headers: {who: Who_Obj}
                }
            )
            .then(res => {
                dispatch(get(cid, sid));
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

// Update
export const update = (details = {}) => {
    const {name, displayName, description, displayOrder, id, image} = details;

    return async dispatch => {
        dispatch({type: SET_TRUE});
        let imageRes;

        // Request to Upload Image
        if (typeof image === "object") {
            const formData = new FormData();
            formData.append("imageFile", image);
            imageRes = await axios.post(`${IMAGE_URL}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
        }

        const finalRes = await axios.put(
            `${BASE_URL}/country/${id}`,
            {
                description,
                displayName,
                name,
                displayOrder: parseInt(displayOrder),
                image: imageRes?.data.imgUrl || image
            },
            {
                headers: {who: JSON.stringify(Who_Obj)}
            }
        );

        dispatch({type: UPDATE, payload: finalRes.data});
    };
};

export const toggle = id => {
    return dispatch => {
        dispatch({type: SET_TRUE});
        axios
            .patch(
                `${BASE_URL}/country/${id}`,
                {},
                {headers: {who: JSON.stringify(Who_Obj)}}
            )
            .then(res => dispatch({type: TOGGLE, payload: res.data}))
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const remove = (cid, sid, id) => {
    return dispatch => {
        dispatch({type: SET_TRUE});
        axios
            .delete(`${BASE_URL}/country/${cid}/state/${sid}/city/${id}`, {
                headers: {who: Who_Obj}
            })
            .then(res => dispatch(get(cid, sid)))
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const reset = () => dispatch => dispatch({type: RESET});
