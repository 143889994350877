import FAQAreas from './panels/FAQAreas';
import FAQScreens from './panels/FAQScreens';
import FAQInfo from './panels/FAQInfo';
import FAQTopic from './modals/FAQTopic';
import FAQTopics from './panels/FAQTopics';

const tabRoutes = [
    {
        name: 'FAQ Areas',
        path: '/faq-areas',
        component: <FAQAreas />
    },
    {
        name: 'FAQ Screens',
        path: '/faq-screens',
        component: <FAQScreens />
    },
    {
        name: 'FAQ Info',
        path: '/faq-info',
        component: <FAQInfo />,
        modalRoutes: [{
            name: '',
            path: '/faq/faq-info/:id',
            component: <FAQTopic />
        }]
    }
]

export default tabRoutes;

export const modalTabRoutes = [
    {
        name: 'FAQ Topics',
        path: '/faq-topics',
        component: <FAQTopics />
    }
]