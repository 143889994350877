import axios from "axios";
import {GET_PACKAGE_LIKE, RESET_PACKAGE_LIKE, LOADING} from "../actionTypes";
import {adminToken} from "../../helper";

const {SET_TRUE, SET_FALSE} = LOADING;
export const get = id => {
    return async dispatch => {
        dispatch({type: SET_TRUE});

        try {
            const token = await adminToken();

            const {data} = await axios.get(
                `https://cgapp.clykk.com/like/api/v2/user/packageLike/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            dispatch({type: GET_PACKAGE_LIKE, payload: data?.rs});
        } catch (err) {
            console.log(err);
        } finally {
            dispatch({type: SET_FALSE});
        }
    };
};

export const reset = () => dispatch => dispatch({type: RESET_PACKAGE_LIKE});
