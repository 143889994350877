import React from "react";
import { NoData, UnlimitedTable } from "components";
import { useSelector } from "react-redux";

export default function Requirements() {
  // Redux
  const order = useSelector(state =>
    state.subsDetail?.qnA.filter(item => item.questionType === "requirements")
  );

  return (
    <div>
      {order?.length ? (
        order.map((qna, index) => (
          <UnlimitedTable
            key={index}
            question={qna.questionName}
            answers={qna.answers}
          />
        ))
      ) : (
        <NoData />
      )}
    </div>
  );
}
