import React from "react";
import { TextInput, Button } from "components";
import styles from "./../styles.module.scss";

export default function RoutingDetails({ onClick, details, setDetails }) {
  return (
    <div className={styles.main__container}>
      <TextInput
        label="Route Before"
        placeholder="Route Before"
        name="routeBefore"
        onChange={setDetails}
        value={details?.routeBefore}
        type="number"
        min="0"
      />
      <TextInput
        label="Service Duration"
        placeholder="Service Duration"
        name="serviceDuration"
        onChange={setDetails}
        value={details?.serviceDuration}
        type="number"
        min="0"
      />
      <TextInput
        label="Open Time"
        placeholder="Open Time"
        name="openTime"
        onChange={setDetails}
        value={details?.openTime}
        type="time"
      />
      <TextInput
        label="Close Time"
        placeholder="Close Time"
        name="closeTime"
        onChange={setDetails}
        value={details?.closeTime}
        type="time"
      />
      <TextInput
        label="Min Commission"
        placeholder="Min Commission"
        name="minCommission"
        onChange={setDetails}
        value={details?.minCommission}
        type="number"
        min="0"
      />
      <TextInput
        label="Max Commission"
        placeholder="Max Commission"
        name="maxCommission"
        onChange={setDetails}
        value={details?.maxCommission}
        type="number"
        min="0"
      />

      <div className={styles.btn__container}>
        <Button title="Save" onClick={onClick} />
      </div>
    </div>
  );
}
