import React, { useState } from "react";
import { useLocation, Switch, Route } from "react-router-dom";
import tabRoutes from "./tab-routes";
import { Tabs } from "components";
import FAQAreasPopup from "./popups/FAQAreas/FAQAreasPopup";
import FAQScreensPopup from "./popups/FAQScreens/FAQScreensPopup";
import FAQInfoPopup from "./popups/FAQInfo/FAQInfoPopup";

const popups = [
  {
    title: "+ New FAQ Area",
    path: "faq-areas",
    component: closeModal => <FAQAreasPopup closeModal={closeModal} />
  },
  {
    title: "+ New FAQ Screen",
    path: "faq-screens",
    component: closeModal => <FAQScreensPopup closeModal={closeModal} />
  },
  {
    title: "+ New FAQ Info",
    path: "faq-info",
    component: closeModal => <FAQInfoPopup closeModal={closeModal} />
  }
];

const FAQ = () => {
  const location = useLocation();
  const currentLocation = location.pathname.split("/", 3)[2];

  // States
  const [modal, setModal] = useState(); // Popup open or close state

  return (
    <>
      {/* Panels */}
      <Tabs
        routes={tabRoutes}
        title={popups.find(popup => currentLocation === popup.path)?.title}
        onClick={() => setModal(currentLocation)}
      />

      {
        // Popups
        modal &&
          popups?.length &&
          popups.map(
            popup =>
              currentLocation === popup.path &&
              popup.component(() => setModal(false))
          )
      }

      {/* Modals */}
      <Switch>
        <Route path={`${tabRoutes[2]?.modalRoutes[0]?.path}`}>
          {tabRoutes[2]?.modalRoutes[0]?.component}
        </Route>
      </Switch>
    </>
  );
};

export default FAQ;
