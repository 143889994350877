import React, {useEffect, useState, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import styles from "./styles.module.scss";
import {
    Table,
    SearchButton,
    LightTextButton,
    FilterDropdown,
    SwitchButton,
    ImageStamp,
    TextLink,
    TextInput,
    DocumentButton,
    Status,
    StatusButton,
    Search
} from "components";
import TableSP from "components/Table/TableSP";
import {serviceProviderAction, categoryAction, customersAction} from "redux/actions";
import PackagesPopup from "../popups/Packages/PackagesPopup";
import {useHistory} from "react-router-dom";
import {adminToken, internalSearch} from "../../../helper";
import * as XLSX from "xlsx";
import {Modal} from "@material-ui/core";
import SpNewModal from "../../ServiceProviderNew/SPNewModal";
import SubscriptionModal from "../../Customers/modals/SubscriptionModal";
import axios from "axios";

const initialFilterState = {
    userId: "",
    firstName: "",
    lastName: "",
    mobile: "",
    category: ""
};

export default function ServiceProviderPanel() {
    // Routers
    const history = useHistory();
    const currentLocation = history.location.pathname;

    console.log(history.location);

    const [openModal, setOpenModal] = useState(false)
    const handleOpenModal = () => {
        setOpenModal(true)
    }
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false)

    const handleOpenSubscriptionModal = () => {
        setOpenSubscriptionModal(true)
    }

    const handleCloseSubscriptionModal = () => {
        setOpenSubscriptionModal(false)
    }

    const [clickedSubscription, setClickedSubscription] = useState(null)

    const [clickedSP, setClickedSP] = useState(null)

    const [value, setValue] = useState(0);

    // States
    const [tableRows, setTableRows] = useState();
    const [editableDetail, setEditableDetails] = useState();
    const [isPopupVisible, setPopupVisible] = useState();

    // Filter States
    const [status, setStatus] = useState("All");
    const [filterState, setFilterState] = useState(initialFilterState);

    // Redux actions
    const dispatch = useDispatch();
    const [serviceProvider, setServiceProvider] = useState([])

    useEffect(() => {
        adminToken().then(token => axios
            .get(
                `https://cgapp.clykk.com/sp/clykk/int/sp/v1/get-user-list?pageNumber=1&pageSize=100000000`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(res => {
                setServiceProvider(res.data);
                dispatch(serviceProviderAction.get(1,100000000))
            })
        )
    }, [])

    const {categories, userRole} = useSelector(state => ({
        // serviceProvider: state.serviceProvider,
        categories: state.categories,
        userRole: state.userRole?.filter(role => role.module === "Service Provider")
    }));

    useEffect(() => {
        if (userRole?.find(item => item.privilege === "Read")) {
            //dispatch(serviceProviderAction.get(1));
            dispatch(categoryAction.get());
        }

        //return () => dispatch(serviceProviderAction.reset());
    }, []);

    const onPageChange = e => {
        console.log("e =>", e)
        //dispatch(serviceProviderAction.get(e))
    }

    useEffect(() => {
        let filtered = serviceProvider?.users;

        if (!filtered?.length) {
            setTableRows();
            return;
        }

        // filter based on Status
        if (status !== 'All') {
            filtered = filtered?.filter(item => item.status === status.toLowerCase());
        }
        filtered = internalSearch(filterState, "userId", filtered);
        filtered = internalSearch(filterState, "firstName", filtered);
        filtered = internalSearch(filterState, "lastName", filtered);
        filtered = internalSearch(filterState, "mobile", filtered);
        // Map table rows
        filtered = filtered?.map(obj => ({
            userId: (
                <TextLink
                    text={obj.userId}
                    // onClick={() => history.push(`${currentLocation}/${obj.userId}`)}
                    onClick={() => {
                        setClickedSP({
                            id: obj?.userId,
                            mobile: obj?.mobile,
                            name: obj?.firstName + " " + obj?.lastName,
                            categoryID: obj?.categoryID,
                            categoryName: categories?.find(item => item.id == obj.categoryID)?.name
                        })
                        handleOpenModal()
                    }}
                />
            ),
            firstName: obj.firstName,
            lastName: obj.lastName,
            picture: <ImageStamp src={obj.picture}/>,
            mobile: obj.mobile,
            role: obj.role,
            category: categories?.find(item => item.id == obj.categoryID)?.name,
            createdAt: `${new Date(obj.createdAt).toLocaleString()}`,
            updatedAt: `${new Date(obj.updatedAt).toLocaleString()}`,
            orgID: (
                <TextLink
                    text={obj.orgID}
                    onClick={() =>
                        history.push(`/organization/organization?id=${obj.orgID}`)
                    }
                />
            ),
            subOrgID: (
                <TextLink
                    text={obj.subOrgID}
                    onClick={() =>
                        history.push(
                            `/organization/sub-organization?orgID=${obj.orgID}&subOrgID=${obj.subOrgID}`
                        )
                    }
                />
            ),
            rating: obj.rating,
            location: obj.location,
            status: (
                <StatusButton
                    title={obj?.status}
                    options={["onboarding", "approved", "rejected"]}
                    onChange={status =>
                        dispatch(serviceProviderAction.changeStatus(obj.userId, status))
                    }
                />
            ),
            documents: (
                <DocumentButton
                    approved={
                        obj?.documents?.filter(doc => doc.status === "approved").length
                    }
                    uploaded={obj?.documents?.filter(doc => doc.documentURL).length}
                    total={obj?.documents?.length}
                    // onClick={() =>
                    //     history.push(`${currentLocation}/${obj.userId}/documents`)
                    // }
                    onClick={() => {
                        setClickedSP({id: obj?.userId, mobile: obj?.mobile, name: obj?.firstName + " " + obj?.lastName})
                        handleOpenModal()
                        setValue(5)
                    }}
                />
            ),
            active: <Status active={obj.active}/>,
            enabled: (
                <SwitchButton
                    enabled={obj.enabled}
                    onClick={
                        userRole?.find(item => item.privilege === "Enable")
                            ? () => dispatch(serviceProviderAction.toggle(obj.userId))
                            : null
                    }
                />
            )
        }));


        filtered = internalSearch(filterState, "category", filtered);

        setTableRows(filtered);
    }, [serviceProvider, status, filterState]);

    // Search based on category & sub category name
    const onSearch = () => {
    };
   
    
    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "ID",
                sortBy: "userId"
            },
            {
                title: "First Name",
                sortBy: "firstName"
            },
            {
                title: "Last Name",
                sortBy: "lastName"
            },
            {
                title: "Image"
            },
            {
                title: "Contact"
            },
            {
                title: "Role"
            },
            {
                title: "Category"
            },
            {
                title: "Created",
                sortBy: "createdAt"
            },
            {
                title: "Updated",
                sortBy: "updatedAt"
            },
            {
                title: "Org"
            },
            {
                title: "Sub Org"
            },
            {
                title: "Rating"
            },
            {
                title: "Location"
            },
            {
                title: "Status"
            },
            {
                title: "Documents"
            },

            {
                title: "Online"
            },

            {
                title: "Enabled"
            }
        ],
        [tableRows]
    );
    const exportDataFunc = () => {

        console.log('tableRows ---->>>'+tableRows)
        const rows = tableRows.map(item => {
            return {
                "ID": item.userId.props.text,
                "First Name": item.firstName,
                "Last Name": item.lastName,
                "Contact": item.mobile,
                "Role": item.role,
                "Category": item.category,
                "Org": item.orgID.props.text,
                "Sub Org": item.subOrgID.props.text,
                "Status": item.status.props.text,
                "Rating": item.rating,
                "Location": item.location,
                "Enabled": item.enabled.props.enabled,
                "Created": item.createdAt,
                "Updated": item.updatedAt
            }
        })

        console.log('tableRows ---->>>'+rows)

        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "DataSheet.xlsx");
    }

    return (
        <div>
            <div className={styles.filter}>
                <TextInput
                    search
                    label="Service Provider ID"
                    name="userId"
                    value={filterState?.userId}
                    onChange={e =>
                        setFilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
                <TextInput
                    search
                    label="First Name"
                    name="firstName"
                    value={filterState?.firstName}
                    onChange={e =>
                        setFilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
                <TextInput
                    search
                    label="Last Name"
                    name="lastName"
                    value={filterState?.lastName}
                    onChange={e =>
                        setFilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
                <TextInput
                    search
                    label="Contact"
                    name="mobile"
                    value={filterState?.mobile}
                    onChange={e =>
                        setFilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
                <TextInput
                    search
                    label="Category"
                    name="category"
                    value={filterState?.category}
                    onChange={e =>
                        setFilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
                <FilterDropdown
                    onChange={e => setStatus(e.target.value)}
                    label="Status"
                    options={["All", "Approved", "Rejected", "Onboarding"]}
                />
                <SearchButton
                    onClick={
                        userRole?.find(item => item.privilege === "Read") ? onSearch : null
                    }
                />
                <LightTextButton onClick={() => setFilterState(initialFilterState)}/>
                <button className="styles_button__r_6_x"
                    onClick={exportDataFunc}
                >Export Data
                </button>
                {/*<Search/>*/}
            </div>

            {/*<Table headers={tableHeaders} data={tableRows} length={serviceProvider?.length} exportData={false}*/}
            {/*/>*/}

            <TableSP
                headers={tableHeaders}
                data={tableRows}
                current={serviceProvider?.pageNumber}
                totalPage={Math.ceil(tableRows?.count / 10)}
                onPageChange={onPageChange}
                state={filterState !== initialFilterState}
                length={tableRows?.length}
                r_length={serviceProvider?.users?.length}
                t_length={serviceProvider?.count}
                exportData={false}
            />

            {isPopupVisible && (
                <PackagesPopup
                    data={editableDetail}
                    closeModal={() => setPopupVisible(false)}
                />
            )}

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <SpNewModal clickedSP={clickedSP} handleCloseModal={handleCloseModal} value={value} setValue={setValue}
                            setClickedSubscription={setClickedSubscription}
                            handleOpenSubscriptionModal={handleOpenSubscriptionModal}/>
            </Modal>

            <Modal
                open={openSubscriptionModal}
                onClose={handleCloseSubscriptionModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <SubscriptionModal clickedSubscription={clickedSubscription} handleCloseModal={handleCloseSubscriptionModal}/>
            </Modal>

        </div>
    );
}
