import axios from "axios";

import {CUSTOMER_APP_PREFERENCES, LOADING} from "../actionTypes";
import {SERVER_IP_2, PORT_8089, AWS_URL_UM_8089, AWS_URL_UM_INT_8089} from "configurations";
import {adminToken, customerTokenRefresher} from "helper";

const {GET, ADD, UPDATE, RESET, TOGGLE, TOGGLE_ANS} =
    CUSTOMER_APP_PREFERENCES;

const {SET_TRUE, SET_FALSE} = LOADING;
export const get = () => {
    return async dispatch => {
        dispatch({type: SET_TRUE});

        const token = await adminToken();

        axios
            .get(`${AWS_URL_UM_8089}/user/preferences`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(res => {
                console.log("")
                const data = Object.values(res.data.data);
                dispatch({type: GET, payload: data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const add = (details = {}) => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .post(
                `https://cgapp.clykk.com/um/clykk/int/um/v1/user/preferences`,
                details,
                {
                    headers: {
                        who: JSON.stringify({token})
                    }
                }
            )
            .then(res => dispatch({type: ADD, payload: res.data.data}))
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

// Update Package
export const update = (details = {}) => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .put(
                `https://cgapp.clykk.com/um/clykk/int/um/v1/user/preferences/${
                    details?.id
                }`,
                details,
                {
                    headers: {
                        who: JSON.stringify({token})
                    }
                }
            )
            .then(res => {
                dispatch({type: UPDATE, payload: res.data.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

// Update Answer
export const updateAnswer = (details = {}) => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .put(
                `https://cgapp.clykk.com/um/clykk/int/um/v1/user/preferences/${
                    details.pid
                }/option/${details?.id}`,
                details,
                {
                    headers: {
                        who: JSON.stringify({token})
                    }
                }
            )
            .then(res => {
                console.log({res: res.data});
                dispatch({type: UPDATE, payload: res.data.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

// Add Answers
export const addAnswer = (details = {}) => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .post(
                `https://cgapp.clykk.com/um/clykk/int/um/v1/user/preferences/${
                    details.pid
                }/option`,
                details,
                {
                    headers: {
                        who: JSON.stringify({token})
                    }
                }
            )
            .then(res => {
                console.log({res: res.data});
                dispatch({type: UPDATE, payload: res.data.data});
            })
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const toggle = id => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .patch(
                `https://cgapp.clykk.com/um/clykk/int/um/v1/user/preferences/${id}`,
                {},
                {
                    headers: {
                        who: JSON.stringify({token})
                    }
                }
            )
            .then(res => dispatch({type: TOGGLE, payload: res.data.data}))
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const toggleAns = (id, oid) => {
    return async dispatch => {
        dispatch({type: SET_TRUE});
        const token = await adminToken();
        axios
            .patch(
                `https://cgapp.clykk.com/um/clykk/int/um/v1/user/preferences/${id}/option/${oid}`,
                {},
                {
                    headers: {
                        who: JSON.stringify({token})
                    }
                }
            )
            .then(res =>
                dispatch({
                    type: TOGGLE_ANS,
                    payload: res.data.data
                })
            )
            .catch(err => console.log(err))
            .finally(_ => dispatch({type: SET_FALSE}));
    };
};

export const reset = () => dispatch => dispatch({type: RESET});
