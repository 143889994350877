import React, {useEffect, useRef, useState} from 'react';
import firebase from "firebase";
import {BiSend} from "react-icons/bi";
import clsx from "clsx";

function ServiceProviderChat({parentDocName, conversationsName, sp_id, message_type}) {
    const containerRef = useRef(null);
    const [documents, setDocuments] = useState([]);
    const [filteredDocuments, setFilteredDocuments] = useState([]);

    const [search, setSearch] = useState("")
    const onSearchChange = (e) => {
        setSearch(e.target.value)
    }

    const [childDocuments, setChildDocuments] = useState({
        parent: null,
        documents: []
    });

    const [message, setMessage] = useState("")

    const [selectedDoc, setSelectedDoc] = useState({id: null})

    useEffect(() => {
        const unsubscribe = firebase.firestore().collection(parentDocName)
            .onSnapshot((querySnapshot) => {
                const docs = [];
                querySnapshot.forEach((doc) => {
                    docs.push({id: doc.id, ...doc.data()});
                });
                console.log("documents =>", docs)
                let documents = docs.sort((a, b) => b.created_at.seconds - a.created_at.seconds);
                setDocuments(documents);
                setFilteredDocuments(documents);
                if (documents.length > 0) {
                    handleParentDocClick(documents[0].id)
                }
            });
        return unsubscribe;
    }, []);

    const handleParentDocClick = async (parent) => {
        setMessage("")
        setSelectedDoc(parent)
        const parentDocRef = firebase.firestore().collection(parentDocName).doc(parent.id);
        await parentDocRef.update({unread_count: 0});

        const childCollectionRef = firebase.firestore().collection(`${parentDocName}/${parent.id}/${conversationsName}`);
        const childSnapshot = await childCollectionRef.orderBy('created_at').get();
        const childDocs = childSnapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}));

        const parentDoc = await parentDocRef.get();
        const parentData = parentDoc.data();

        console.log("childDocs =>", childDocs)

        setChildDocuments({
            parent: parent.id,
            documents: childDocs
        });
    };

    const handleAddChildDoc = async () => {
        const parentDocRef = firebase.firestore().collection(parentDocName).doc(childDocuments.parent);
        const now = new Date();
        await parentDocRef.update({created_at: now, unread_count: 0, last_message: message});

        const childCollectionRef = firebase.firestore().collection(`${parentDocName}/${childDocuments.parent}/${conversationsName}`);
        const childDocData = {
            additional_meta: "",
            created_at: firebase.firestore.FieldValue.serverTimestamp(),
            message: message,
            sp_id: sp_id ? childDocuments.parent : 0,
            sent_by: 0,
            type: 2,
            user_id: 0
        }
        const newChildDocRef = await childCollectionRef.add(childDocData);

        setMessage("")

        handleParentDocClick(selectedDoc)
        console.log(`Added new child document with ID: ${newChildDocRef.id}`);
    };

    useEffect(() => {
        const container = containerRef.current;
        container.scrollTop = container.scrollHeight;
    }, [childDocuments.documents])

    useEffect(() => {
        if (search !== "") {
            setFilteredDocuments(
                documents.filter(
                    (item) => {
                        if (item.user_details) {
                            return item.id === search ||
                                item.user_details[item.id].name.toLowerCase().includes(search.toLowerCase()) ||
                                item.user_details[item.id].phone.toLowerCase().includes(search.toLowerCase())
                        } else {
                            return item.id === search
                        }
                    }
                )
            )
        } else {
            setFilteredDocuments(documents)
        }
    }, [search])

    return (
        <div className={'my-4'}>
            <div className={'row g-0'}>
                <div className={'col-xl-2 col-lg-2 col-md-2 col-sm-2 col-sm-2 col-2'} style={{height: 700, overflowY: "auto"}}>
                    <input
                        src={search}
                        placeholder={'Search SP id, name or phone'}
                        className={'form-control shadow mb-2'}
                        onChange={onSearchChange}
                    />
                    {filteredDocuments.map(doc => {
                        return (
                            <div style={{
                                display: "flex",
                                padding: "10px 5px",
                                border: "0.5px solid #eeeee4",
                                cursor: "pointer",
                                backgroundColor: doc.id === selectedDoc.id ? "#e8e8e8" : "#FFFFFF"
                            }}
                                 onClick={() => {
                                     handleParentDocClick(doc)
                                 }}
                            >

                                <div style={{width: "80%"}}>
                                    <div style={{display: "flex"}}>
                                        <p style={{marginRight: 10}}>{doc.id} -</p>
                                        {doc.user_details &&
                                            <p>{message_type === 'Customer' ? doc.user_details[doc.user_id].name : doc.user_details[doc.id].name}</p>
                                        }
                                    </div>
                                    {doc.user_details &&
                                        <p style={{padding: 0, margin: 0}}>{
                                            message_type === 'Customer' ?
                                                doc.user_details[doc.user_id].phone
                                                :
                                                doc.user_details[doc.id].phone
                                        }
                                        </p>
                                    }
                                </div>
                                {doc.unread_count > 0 &&
                                    <div style={{
                                        margin: "auto 10px auto auto",
                                        width: 20,
                                        height: 20,
                                        backgroundColor: "#1fa855",
                                        color: "white",
                                        borderRadius: 9999
                                    }}>
                                        <p style={{
                                            padding: 0,
                                            margin: 0,
                                            fontSize: 12,
                                            textAlign: "center"
                                        }}>{doc.unread_count}</p>
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
                {selectedDoc &&
                    <div className={'col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 col-8'}>
                        <div style={{padding: "0 20px"}}>

                            <div style={{
                                width: "100%",
                                backgroundColor: "#FFFFFF",
                                padding: 10,
                                marginBottom: 10,
                                display: 'flex',
                                borderRadius: 10
                            }}>
                                {selectedDoc.user_details &&
                                    <p>
                                        SP ID: {selectedDoc.id} -
                                        {selectedDoc.user_details &&
                                            <span>
                                                {message_type === 'Customer' ?
                                                    selectedDoc.user_details[selectedDoc.user_id].name
                                                    :
                                                    selectedDoc.user_details[selectedDoc.id].name
                                                }
                                            </span>
                                        }
                                    </p>
                                }
                                {selectedDoc.user_details &&
                                    <p style={{marginLeft: "auto"}}>{
                                        message_type === 'Customer' ?
                                            selectedDoc.user_details[selectedDoc.user_id].phone
                                            :
                                            selectedDoc.user_details[selectedDoc.id].phone
                                    }</p>
                                }
                            </div>

                            <div style={{height: 550, overflowY: "auto", padding: "0 10px"}} ref={containerRef}>
                                {childDocuments.documents.map((item) => {
                                    return (
                                        <div style={{marginBottom: 5, display: "flex", flexDirection: "column"}}>
                                            <p className={'chat-meta-text chat-meta-text-container'}
                                               style={{
                                                   textAlign: item.type === 2 ? "right" : 'left',
                                                   margin: item.type === 2 ? "0 0 5px auto" : '0 auto 5px 0',
                                               }}>
                                                {item.type === 1 && message_type}
                                                {item.type === 2 && "Customer Support"}
                                            </p>
                                            <p className={clsx(item.type === 2 ? 'chat-sent' : 'chat-received', 'chat-message')}>
                                                {item.message}
                                            </p>
                                            <p className={'chat-meta-text'}
                                               style={{
                                                   textAlign: item.type === 2 ? "right" : 'left'
                                               }}>
                                                {new Date(item.created_at.seconds * 1000).toDateString()}
                                            </p>
                                        </div>
                                    )
                                })}
                            </div>

                            <div style={{display: "flex", marginTop: 20}}>
                                <input value={message} placeholder={'Type a message ...'} className={'form-control shadow-lg'}
                                       style={{width: "100%", fontSize: 14, padding: 10}}
                                       onChange={(e) => {
                                           setMessage(e.target.value)
                                       }}/>

                                <BiSend style={{margin: "auto 10px", fontSize: 22}}
                                        onClick={() => {
                                            handleAddChildDoc()
                                        }}
                                />
                            </div>

                        </div>
                    </div>
                }
            </div>


        </div>
    );
}

export default ServiceProviderChat;