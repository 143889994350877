import React, { useState } from "react";
import styles from "./styles.module.scss";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";

const FixedMultiTable = ({ headers, data, secHeaders, secData }) => {
  const [iconAt, setIconAt] = useState("");

  return (
    <div className={styles.table__container}>
      {/* Question Table */}
      <table>
        <thead>
          <tr>
            {headers?.length &&
              headers.map((header, index) => (
                <th
                  key={index}
                  onClick={() => {
                    if (!header?.onClick || !data?.length) return;
                    header.onClick();
                    setIconAt(header?.title);
                  }}
                  className={header?.icon && styles.sortable}>
                  {header?.title || header}

                  {header?.title === iconAt ? (
                    header?.sortIn === "ASC" ? (
                      <BsArrowDown size="12" />
                    ) : (
                      <BsArrowUp size="12" />
                    )
                  ) : null}
                </th>
              ))}
          </tr>
        </thead>

        <tbody>
          {data?.length
            ? data.map((item, i) => (
                <tr key={i}>
                  {Object.keys(item).map((keyName, j) => {
                    return <td key={j}>{item[keyName]}</td>;
                  })}
                </tr>
              ))
            : null}
        </tbody>
        <thead>
          <tr>
            {secHeaders?.length &&
              secHeaders.map((header, index) => (
                <th
                  key={index}
                  onClick={() => {
                    if (!header?.onClick || !data?.length) return;
                    header.onClick();
                    setIconAt(header?.title);
                  }}
                  className={header?.icon ? styles.sortable : ""}>
                  {header?.title || header}
                  {header?.icon && header?.title === iconAt && (
                    <BsArrowDown size="12" />
                  )}
                </th>
              ))}
          </tr>
        </thead>

        <tbody>
          {secData?.length
            ? secData.map((item, i) => (
                <tr key={i}>
                  {Object.keys(item).map((keyName, j) => {
                    return <td key={j}>{item[keyName]}</td>;
                  })}
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
};

export default FixedMultiTable;
