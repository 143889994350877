import React from "react";
import styles from "./StatusButton.module.scss";
import {BsCaretDownFill} from "react-icons/bs";

const StatusButton = ({title, options, values, onChange}) => (
    <div className={styles.status__btn__container}>
        <button
            className={`${styles.status__btn} ${
                options?.length
                    ? title === options[1]
                        ? styles.success
                        : title === options[2]
                            ? styles.danger
                            : styles.pending
                    : styles.pending
            }`}
        >
            <span>{title || "Pending"}</span>

            {options?.length && onChange && (
                <BsCaretDownFill className={styles.caret} size={14}/>
            )}
        </button>

        {options?.length && onChange && (
            <div className={styles.dropdown}>
                <ul>
                    {options.map((opt, index) => (
                        <li
                            key={index}
                            onClick={() =>
                                onChange &&
                                onChange(values?.length > index ? values[index] : opt)
                            }
                        >
                            {opt}
                        </li>
                    ))}
                </ul>
            </div>
        )}
    </div>
);

export default StatusButton;
