import {
  ADD_CART,
  GET_CART,
  TOGGLE_CART,
  UPDATE_CART,
  RESET_CART,
} from "../actionTypes";

const cartReducer = (state = null, action) => {
  switch (action.type) {
    case GET_CART:
      return action.payload;

    case ADD_CART:
      return action.payload;

    case UPDATE_CART:
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );

    case TOGGLE_CART:
      return state.map((item) =>
        item.id !== action.payload.id
          ? item
          : { ...item, enabled: !item.enabled }
      );

    case RESET_CART:
      return null;

    default:
      return state;
  }
};

export default cartReducer;
