import { GET_SERVICE_RULES } from '../actionTypes'


const serviceRuleReducer = ( state = [], action ) => {
    
    switch(action.type){
        
        case GET_SERVICE_RULES:
            return action.payload

        default:
            return state;
    }

}

export default serviceRuleReducer;