import { SP_PREFERENCES } from "../actionTypes";

export default function spPreferencesReducer(state = null, action) {
  const { GET, ADD, UPDATE, RESET, TOGGLE } = SP_PREFERENCES;

  switch (action.type) {
    case GET:
      return action.payload;

    case ADD:
      const pkg = state || { qna: [] };
      pkg.qna.push(...action.payload.qna);
      return { ...pkg };

    case UPDATE:
      return action.payload;

    case TOGGLE:
      return action.payload;

    case RESET:
      return null;

    default:
      return state;
  }
}
