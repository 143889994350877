import React from 'react';
import {useSelector} from "react-redux";
import {NoData, UnlimitedTable} from "../../../components";

function RequirementsTab(props) {
  // Redux
  const order = useSelector(state =>
    state.subsDetail?.qnA.filter(item => item.questionType === "requirements")
  );

  return (
    <div>
      {order?.length ? (
        order.map((qna, index) => (
          <UnlimitedTable
            key={index}
            question={qna.questionName}
            answers={qna.answers}
          />
        ))
      ) : (
        <NoData />
      )}
    </div>
  );
}


export default RequirementsTab;