import {
  GET_PACKAGES,
  ADD_PACKAGE,
  TOGGLE_PACKAGE,
  UPDATE_PACKAGE,
  RESET_PACKAGE
} from "../actionTypes";

const packagesReducer = (state = [], action) => {
  switch (action.type) {
    case GET_PACKAGES:
      return action.payload;

    case ADD_PACKAGE:
      return [...(state || []), action.payload];

    case UPDATE_PACKAGE:
      return state?.map(category =>
        category.id === action.payload.id ? action.payload : category
      );

    case TOGGLE_PACKAGE:
      return state?.map(category =>
        category.id !== action.payload
          ? category
          : { ...category, enabled: !category.enabled }
      );

    case RESET_PACKAGE:
      return null;

    default:
      return state;
  }
};

export default packagesReducer;
