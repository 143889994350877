import ServiceProvidersPanel from "./panels/ServiceProvidersPanel";
import Profile from "./panels/Profile";
import SideModal from "./modals/SideModal";
import Dummy from "./panels/Dummy";
import Address from "./panels/Address";
import ServiceSettings from "./panels/ServiceSettings";
import Preferences from "./panels/Preferences";
import SPDocumentsPanel from "./panels/SPDocumentsPanel";
import MemberStatus from "./panels/MemberStatus";
import Orders from "./panels/Orders";
import SpCommisionPanel from "./panels/SPCommisionPanel";
import SpCommisionModal from "./panels/SpCommisionModal";
import ServiceProviderNewPanel from "../ServiceProviderNew/ServiceProviderNewPanel";
import ServiceProvidersPanelComplete from "./ServiceProvidersPanelComplete";

const tabRoutes = [
    {
        name: "Service Providers",
        path: "/service-providers",
        component: <ServiceProvidersPanel/>,
        modalRoutes: [
            {
                name: "",
                path: "/service-providers/service-providers/:id",
                component: <SideModal/>
            }
        ]
    },
    {
        name: "Service Providers Complete",
        path: "/service-providers-complete",
        component: <ServiceProvidersPanelComplete/>,
        modalRoutes: [
            {
                name: "",
                path: "/service-providers/service-providers/:id",
                component: <SideModal/>
            }
        ]
    },
];

export default tabRoutes;

export const spnTabRoutes = [
    {
        name: "Service Providers",
        path: "/service-providers",
        component: <ServiceProviderNewPanel/>
    },
];

export const SPCommisionsRoutes = [
    {
        name: "SP Commisions",
        path: "/sp-commisions",
        component: <SpCommisionPanel/>,
        modalRoutes: [
            {
                name: "",
                path: "/sp-commisions/sp-commisions/:id",
                component: <SpCommisionModal/>
            }
        ]
    }
]

export const modalSpCommisionRoutes = [
    {
        name: "Profile",
        path: "/profile",
        component: <Profile/>
    }
]

export const modalTabRoutes = [
    {
        name: "Profile",
        path: "/profile",
        component: <Profile/>
    },
    {
        name: "Service Settings",
        path: "/service-settings",
        component: <ServiceSettings/>
    },
    {
        name: "Address",
        path: "/address",
        component: <Address/>
    },
    {
        name: "Preferences",
        path: "/preferences",
        component: <Preferences/>
    },
    {
        name: "Member & Status",
        path: "/member-status",
        component: <MemberStatus/>
    },
    {
        name: "Documents",
        path: "/documents",
        component: <SPDocumentsPanel/>
    },
    {
        name: "Orders",
        path: "/orders",
        component: <Orders/>
    },
    // {
    //     name: "Commissions",
    //     path: "/commissions",
    //     component: <Dummy/>
    // },
];
