import React, { useState } from "react";
import { useLocation, Switch, Route } from "react-router-dom";
import tabRoutes from "./tab-routes";
import { Tabs } from "components";
import DocumentsPopup from "./popups/Documents/DocumentsPopup";

const popups = [
  {
    title: "+ New Document",
    path: "organization",
    component: closeModal => <DocumentsPopup closeModal={closeModal} />
  },
  {
    title: "+ New Document",
    path: "sub-organization",
    component: closeModal => <DocumentsPopup closeModal={closeModal} />
  },
  {
    title: "+ New Document",
    path: "service-provider",
    component: closeModal => <DocumentsPopup closeModal={closeModal} />
  }
];

export default function Documents() {
  const location = useLocation();
  const currentLocation = location.pathname.split("/", 3)[2];

  // States
  const [modal, setModal] = useState(); // Popup open or close state

  return (
    <>
      {/* Panels */}
      <Tabs
        routes={tabRoutes}
        title={popups.find(popup => currentLocation === popup.path)?.title}
        onClick={() => setModal(currentLocation)}
      />

      {
        // Popups
        modal &&
          popups?.length &&
          popups.map(
            popup =>
              currentLocation === popup.path &&
              popup.component(() => setModal(false))
          )
      }

      {/* Modals */}
      {tabRoutes[0]?.modalRoutes?.length && (
        <Switch>
          <Route path={`${tabRoutes[0]?.modalRoutes[0]?.path}`} key={1}>
            {tabRoutes[0]?.modalRoutes[0]?.component}
          </Route>
        </Switch>
      )}
    </>
  );
}
