import React, {useState, useEffect} from "react";
import styles from "./styles.module.scss";
import {BsArrowDown, BsArrowUp} from "react-icons/bs";
import {NoData, PageNavigator, Loader} from "../index";
import {useSelector} from "react-redux";
import * as XLSX from 'xlsx';

export default function Table({
                                  headers,
                                  data,
                                  onPageChange,
                                  current,
                                  totalPage,
                                  state,
                                  length,
                                  r_length,
                                  t_length,
                                  exportData,
                                  exportDataFunc
                              }) {
    const [iconAt, setIconAt] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [tableRows, setTableRows] = useState();
    const [sorting, setSorting] = useState({
        sortBy: "",
        sortIn: ""
    });

    const loading = useSelector(state => state.loading);
    

    useEffect(() => {
        setTableRows(data);
    }, [data]);

    // Sort rows by given column's heading
    const sortRows = sortBy => {
        if (!tableRows?.length) return;

        const rows = [...tableRows];

        // Sort in descending order
        if (sorting.sortBy !== sortBy || sorting.sortIn !== "ASC") {
            rows?.sort((a, b) => {
                if (!isNaN(a[sortBy]) && !isNaN(b[sortBy]))
                    return a[sortBy] - b[sortBy];

                if (a[sortBy]?.props?.text < b[sortBy]?.props?.text) return -1;
                if (a[sortBy]?.props?.text > b[sortBy]?.props?.text) return 1;
                if (a[sortBy] < b[sortBy]) return -1;
                if (a[sortBy] > b[sortBy]) return 1;
                return 0;
            });
            setSorting({sortIn: "ASC", sortBy});
        } else {
            // Sort in ascending order
            rows?.sort((a, b) => {
                if (!isNaN(a[sortBy]) && !isNaN(b[sortBy]))
                    return b[sortBy] - a[sortBy];

                if (a[sortBy]?.props?.text < b[sortBy]?.props?.text) return 1;
                if (a[sortBy]?.props?.text > b[sortBy]?.props?.text) return -1;
                if (a[sortBy] < b[sortBy]) return 1;
                if (a[sortBy] > b[sortBy]) return -1;
                return 0;
            });
            setSorting({sortIn: "DSC", sortBy});
        }
        setTableRows(rows);
    };

    const setCur = (cpage) =>{
        setCurrentPage(cpage);
    } 
    const get_rows = (tableRows) => {
        if (tableRows) {
            let users = 0
            tableRows.map((user, i) => {
                if (i >= (currentPage - 1) * 10 && i < currentPage * 10) {
                    users += 1
                }
            })
            if (t_length) {
                if (currentPage === 1) {
                    return (
                        <p>Showing data {currentPage} to {users} of
                            entries {state ? length : t_length}
                            {state && ` (filtered from ${t_length} total entries)`}
                        </p>
                    )
                } else {
                    return (
                        <p>Showing
                            data {currentPage - (currentPage - 1) + ((currentPage - 1) * 10)} to {users + ((currentPage - 1) * 10)} of
                            entries {t_length}</p>
                    )
                }
            } else {
                if (currentPage === 1) {
                    return (
                        <p>Showing data {currentPage} to {users} of
                            entries {tableRows.length} {(length && length !== tableRows.length) && `(filtered from ${length} total entries)`}</p>
                    )
                } else {
                    return (
                        <p>Showing
                            data {currentPage - (currentPage - 1) + ((currentPage - 1) * 10)} to {users + ((currentPage - 1) * 10)} of
                            entries {tableRows.length}</p>
                    )
                }
            }
        }
    }


    return (
        <>
            {exportData &&
                <div style={{marginTop: 20}}>
                    <button
                        onClick={exportDataFunc}
                    >Export Data
                    </button>
                </div>
            }
            <div className={styles.table__container}>
                <table>
                    <thead>
                    <tr>
                        {headers?.length &&
                            headers.map((header, index) => (
                                <th
                                    key={index}
                                    onClick={() => {
                                        if (!header?.sortBy || !tableRows?.length) return;
                                        setIconAt(header?.title);
                                        sortRows(header?.sortBy);
                                    }}
                                    className={header?.sortBy && styles.sortable}>
                                    {header?.title || header}
                                    {header?.title && header?.title === iconAt ? (
                                        sorting?.sortIn === "ASC" ? (
                                            <BsArrowDown size="12"/>
                                        ) : (
                                            <BsArrowUp size="12"/>
                                        )
                                    ) : null}
                                </th>
                            ))}

                        {typeof headers === "string" && <th>{headers}</th>}
                    </tr>
                    </thead>

                    <tbody>
                    {tableRows?.length
                        ? tableRows.map((user, i) => {
                            if (i >= (currentPage - 1) * 10 && i < currentPage * 10) {
                                return (
                                    <tr key={i}>
                                        {Object.keys(user).map((keyName, j) => {
                                            return <td key={j}>{user[keyName]}</td>;
                                        })}
                                    </tr>
                                );
                            } else return null;
                        })
                        : null}
                    </tbody>
                </table>

                {/* {!tableRows?.length && loading ? (
        <div className={styles.loading__container}>
          <Loader />
        </div>
      ) : null} */}

                {get_rows(tableRows)}

                {!tableRows?.length && !loading ? <NoData/> : null}

                {(tableRows?.length > 10 || totalPage > 1) && (
                    <PageNavigator
                        pageCount={
                            totalPage
                                ? totalPage
                                : tableRows?.length
                                    ? Math.ceil(tableRows?.length / 10)
                                    : ""
                        }
                        currentPage={currentPage}
                        onClick={e => {
                            onPageChange(e);
                            //setCurrentPage(e);
                            setCur(e);
                        }}
                    />
                )}
            </div>
        </>
    );
}
