import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {DocumentModal, ImageStamp, StatusButton, Table} from "../../components";
import Comment from "../ServiceProviders/panels/Comment";
import {serviceProviderAction} from "redux/actions";

function SpDocumentsTab({clickedSP}) {
    const history = useHistory();
    const path = history.location.pathname;
    const id = clickedSP.id

    // States
    const [tableRows, setTableRows] = useState();
    const [editableDetail, setEditableDetails] = useState();
    const [isPopupVisible, setPopupVisible] = useState();
    const [isCommentPopup, setCommentPopup] = useState();
    const [status, setStatus] = useState({
        id: null,
        status: null,
        comment: ""
    });

    const dispatch = useDispatch();
    const {serviceProvider, userRole} = useSelector(state => ({
        serviceProvider: state.serviceProvider?.users,
        userRole: state.userRole?.filter(role => role.module === "Service Provider")
    }));

    const handleChange = e => {
        setStatus({
            ...status,
            [e.target.name]: e.target.value
        });
    };

    useEffect(() => {
        let filtered = userRole?.find(item => item.privilege === "Read")
            ? serviceProvider?.find(item => item.userId == id)?.documents
            : null;

        if (!filtered?.length) {
            setTableRows();
            return;
        }

        // Map table rows
        let data = filtered?.map(obj => ({
            id: obj.id,
            documentName: obj.documentName,
            documentURL: (
                <ImageStamp
                    src={obj?.documentURL}
                    onClick={() => {
                        setEditableDetails(obj);
                        setPopupVisible(true);
                    }}
                />
            ),
            status: (
                <StatusButton
                    title={obj?.status}
                    options={
                        obj.documentURL?.length ? ["pending", "approved", "rejected"] : null
                    }
                    onChange={e => {
                        if (e === "rejected") {
                            dispatch(serviceProviderAction.rejectDoc(id, obj.id));
                            return;
                        }
                        setStatus({...status, status: e, id: obj.id});
                        setCommentPopup(true);
                    }}
                />
            ),
            comment: obj.comment,
            createdAt: `${new Date(obj.createdAt).toLocaleString()}`,
            updatedAt: `${new Date(obj.updatedAt).toLocaleString()}`
        }));

        setTableRows(data);
    }, [serviceProvider]);

    // Table Header
    const tableHeaders = [
        {
            title: "ID",
            sortBy: "id"
        },
        {
            title: "Document Name"
        },
        {
            title: "Document Image"
        },
        {
            title: "Status"
        },
        {
            title: "Comment"
        },
        {
            title: "Created",
            sortBy: "createdAt"
        },
        {
            title: "Updated",
            sortBy: "updatedAt"
        }
    ];

    const onReject = () => {
        dispatch(serviceProviderAction.rejectDoc(id, editableDetail?.id));
        setPopupVisible(false);
    };

    const onSave = () => {
        dispatch(serviceProviderAction.changeDocStatus(id, status));
        setCommentPopup(false);
    };

    return (
        <div>
            <Table headers={tableHeaders} data={tableRows}/>
            {isPopupVisible && (
                <DocumentModal
                    title={editableDetail?.documentName}
                    documentURL={editableDetail?.documentURL}
                    closeModal={() => setPopupVisible(false)}
                    onReject={onReject}
                />
            )}

            {isCommentPopup && (
                <Comment
                    closeModal={() => setCommentPopup(false)}
                    data={status}
                    handleChange={handleChange}
                    onSave={onSave}
                />
            )}
        </div>
    );
}


export default SpDocumentsTab;