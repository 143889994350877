import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from "react-router-dom";
import styles from "./styles.module.scss";
import {
    DocumentButton,
    FilterDropdown,
    ImageStamp,
    LightTextButton,
    Search,
    SearchButton, Status,
    StatusButton, SwitchButton, Table,
    TextInput,
    TextLink
} from "../../../components";
import {useDispatch, useSelector} from "react-redux";
import {adminToken, internalSearch} from "../../../helper";
import PackagesPopup from "../popups/Packages/PackagesPopup";
import {serviceProviderAction, spCommisionAction, categoryAction, subscriptionAction} from "../../../redux/actions";
import * as XLSX from "xlsx";
import SpNewModal from "../../ServiceProviderNew/SPNewModal";
import {Modal} from "@material-ui/core";
import axios from "axios";
import SubscriptionModal from "../../Customers/modals/SubscriptionModal";
import {IMAGE_URL} from "../../../configurations";
import clsx from "clsx";

const initialFilterState = {
    id: "",
    sp_id: "",
    subscription_id: ""
};

function SpCommisionPanel() {
    const history = useHistory();
    const currentLocation = history.location.pathname;

    // States
    const [tableRows, setTableRows] = useState();
    const [editableDetail, setEditableDetails] = useState();
    const [isPopupVisible, setPopupVisible] = useState();

    // Filter States
    const [status, setStatus] = useState();
    const [filterState, setFilterState] = useState(initialFilterState);

    // Redux actions
    const dispatch = useDispatch();
    const {user, spCommisionReducer, categories, userRole} = useSelector(state => ({
        user: state.user,
        spCommisionReducer: state.spCommisionReducer,
        categories: state.categories,
        userRole: state.userRole?.filter(role => role.module === "Service Provider")
    }));

    console.log("spCommisionReducer =>", spCommisionReducer)

    useEffect(() => {
        if (userRole?.find(item => item.privilege === "Read")) {
            dispatch(spCommisionAction.get());
            dispatch(serviceProviderAction.get());
            // dispatch(subscriptionAction.get(1));
            // dispatch(categoryAction.get());
        }

        return () => dispatch(serviceProviderAction.reset());
    }, []);

    useEffect(() => {
            let filtered = spCommisionReducer;

            if (!filtered?.length) {
                setTableRows();
                return;
            }

            // if (status !== 'All') {
            //     filtered = filtered?.filter(item => item.payment_status === status.toLowerCase());
            // }
            filtered = internalSearch(filterState, "id", filtered);
            filtered = internalSearch(filterState, "sp_id", filtered);
            filtered = internalSearch(filterState, "subscription_id", filtered);

            filtered = filtered?.map(obj => ({
                id: obj.id,
                sp_id: <TextLink
                    text={obj.sp_id}
                    // onClick={() => history.push(`/sp-commisions/sp-commisions/${obj.sp_id}`)}
                    onClick={() => {
                        let sub = null;
                        adminToken().then(token =>
                            axios.get(`https://cgapp.clykk.com/cart/api/v2/int/cart-management/cart/subscriptions/${obj.subscription_id}/detail`, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                    Authorization: `Bearer ${token}`
                                }
                            })
                                .then(res => sub = res.data)
                        )
                        setClickedSP({
                            id: obj.sp_id,
                            mobile: obj?.mobile,
                            name: obj?.firstName + " " + obj?.lastName,
                            categoryID: obj?.categoryID,
                            categoryName: categories?.find(item => item.id == obj.categoryID)?.name
                        })
                        handleOpenModal()
                    }}
                />,
                // subscription_id: obj.subscription_id,
                subscription_id: <TextLink
                    text={parseInt(obj.subscription_id)}
                    onClick={() => {
                        setClickedSubscription({id: obj.subscription_id, userId: null})
                        handleOpenSubscriptionModal()
                    }}
                />,
                serviceDate: obj.get_subscription_details[0].service_date,
                serviceTime: obj.get_subscription_details[0].service_time,
                amount: obj.amount,
                payment_status: <button
                    className={clsx('btn btn-primary w-75', obj?.payment_status === 'done' ? "btn-success" : "btn-warning")}
                    onClick={(status) => {
                        adminToken().then(token =>
                            axios.post(`https://clapp.clykk.com/sp_payments/public/index.php/api/updatepaymentstatus`, {
                                payment_by: user.first_name,
                                payment_id: obj.id,
                                status: 'done',
                                transaction_id: "123"
                            }, {headers: {Authorization: `Bearer ${token}`}})
                                .then(res => {
                                    dispatch(spCommisionAction.get())
                                })
                        );
                    }}
                >
                    {obj?.payment_status}
                </button>,
                create_by: obj.create_by,
                payment_date: obj.payment_date,
                createdAt: `${new Date(obj.created_at).toLocaleString()}`,
                updatedAt: `${new Date(obj.updated_at).toLocaleString()}`
            }));

            setTableRows(filtered);
        },
        [spCommisionReducer, status, filterState]
    );

    // Search based on category & sub category name
    const onSearch = () => {
    };

    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "ID",
                sortBy: "id"
            },
            {
                title: "SP ID",
                sortBy: "sp_id"
            },
            {
                title: "Subscription ID",
                sortBy: "subscription_id"
            },
            {
                title: "Service Date",
                sortBy: "serviceDate"
            },
            {
                title: "Service Time"
            },
            {
                title: "Amount",
            },
            {
                title: "Payment Status"
            },
            {
                title: "Paid By"
            },
            {
                title: "Payment Date"
            },
            {
                title: "Created At"
            },
            {
                title: "Updated At"
            }
        ],
        [tableRows]
    );

    const exportDataFunc = () => {

        console.log(tableRows)
        const rows = tableRows.map(item => {
            return {
                "ID": item.id,
                "SP ID": item.sp_id,
                "Subscription ID": item.subscription_id,
                "amount": item.amount,
                "Payment Status": item.payment_status,
                "Created By": item.create_by,
                "Created At": item.createdAt,
                "Updated At": item.updatedAt
            }
        })

        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "DataSheet.xlsx");
    }

    const [openModal, setOpenModal] = useState(false)
    const handleOpenModal = () => {
        setOpenModal(true)
    }
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const [clickedSP, setClickedSP] = useState(null)
    const [clickedSubscription, setClickedSubscription] = useState(null)

    const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false)

    const handleOpenSubscriptionModal = () => {
        setOpenSubscriptionModal(true)
    }

    const handleCloseSubscriptionModal = () => {
        setOpenSubscriptionModal(false)
    }

    const [value, setValue] = useState(0);

    return (
        <div>
            <div className={styles.filter}>
                <TextInput
                    search
                    label="ID"
                    name="id"
                    value={filterState?.id}
                    onChange={e =>
                        setFilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
                <TextInput
                    search
                    label="Service Provider ID"
                    name="sp_id"
                    value={filterState?.sp_id}
                    onChange={e =>
                        setFilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
                <TextInput
                    search
                    label="Subscription ID"
                    name="subscription_id"
                    value={filterState?.subscription_id}
                    onChange={e =>
                        setFilterState({...filterState, [e.target.name]: e.target.value})
                    }
                />
            </div>

            <Table headers={tableHeaders} data={tableRows} length={spCommisionReducer?.length} exportData={true}
                   exportDataFunc={exportDataFunc}/>

            {isPopupVisible && (
                <PackagesPopup
                    data={editableDetail}
                    closeModal={() => setPopupVisible(false)}
                />
            )}

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <SpNewModal clickedSP={clickedSP} handleCloseModal={handleCloseModal} value={value} setValue={setValue}
                            setClickedSubscription={setClickedSubscription}
                            handleOpenSubscriptionModal={handleOpenSubscriptionModal}/>
            </Modal>

            <Modal
                open={openSubscriptionModal}
                onClose={handleCloseSubscriptionModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <SubscriptionModal clickedSubscription={clickedSubscription} handleCloseModal={handleCloseSubscriptionModal}/>
            </Modal>

        </div>
    );
}

export default SpCommisionPanel;