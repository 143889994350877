import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./../styles.module.scss";
import { TextInput, Button, FilterDropdown } from "components";
import {
  cityAction,
  serviceRulesAction,
  subCategoriesAction
} from "redux/actions";

export default function Details({ onClick, details, setDetails, edit }) {
  const dispatch = useDispatch();

  // Local state
  const [sRule, setSRule] = useState();
  const [sRuleID, setSRuleID] = useState();

  const { categories, serviceRules, subCategories, city } = useSelector(
    state => ({
      categories: state.categories,
      serviceRules: state.serviceRules,
      subCategories: state.subCategories,
      city: state.city
    })
  );

  const filteredCategories = categories?.map(category => category.name); //make an arry of category names
  const categoryIDs = categories?.map(category => category.id);

  // Fetch service rules if not available in global state
  useEffect(() => {
    if (!serviceRules?.length) dispatch(serviceRulesAction.get());
    dispatch(cityAction.getAll());
  }, [dispatch]);

  const filteredSubCategories =
    subCategories?.map(subCategory => subCategory.name) || []; //make an arry of sub category names
  const subCategoryIDs = subCategories?.map(subCategory => subCategory.id); //make an arry of sub category id

  // Get sub categories based on category selection
  useEffect(() => {
    if (details?.categoryID === "Pick a category" || !details?.categoryID)
      return;
    dispatch(subCategoriesAction.get(details?.categoryID));
  }, [details?.categoryID, dispatch]);

  // Get service rules of selected sub category
  useEffect(() => {
    if (!details?.subcategoryID) {
      setSRule();
      setSRuleID();
      return;
    }
    const subCategory = subCategories?.find(
      subCategory => subCategory?.id == details?.subcategoryID
    );
    const serviceRuleType = subCategory?.serviceRule?.map(sr => sr.type);
    const serviceRuleID = subCategory?.serviceRule?.map(sr => sr.id);
    setSRule(serviceRuleType);
    setSRuleID(serviceRuleID);
  }, [details?.subcategoryID]);

  return (
    <div className={styles.main__container}>
      {!edit && (
        <FilterDropdown
          onChange={setDetails}
          name="category"
          key="category"
          label="Category"
          options={["Pick a category", ...filteredCategories]}
          value={details?.categoryID}
          values={[0, ...categoryIDs]}
        />
      )}
      <FilterDropdown
        onChange={setDetails}
        name="subcategoryID"
        key="subcategoryID"
        label="Sub Category"
        options={["Pick a sub category", ...filteredSubCategories]}
        value={details?.subcategoryID}
        values={subCategoryIDs?.length ? [0, ...subCategoryIDs] : []}
        disabled={edit}
      />
      {/* City */}
      <FilterDropdown
        onChange={e => {
          const cityName = {
            target: {
              name: "cityName",
              value: city?.find(item => item?.id == e.target.value)?.name
            }
          };

          setDetails(e);
          setDetails(cityName);
        }}
        name="cityID"
        key="city"
        label="City"
        options={["Pick a city", ...(city?.map(item => item?.name) || [])]}
        value={details?.cityID}
        values={[0, ...(city?.map(item => item?.id) || [])]}
        // disabled={edit}
      />
      <TextInput
        label="Package Name"
        placeholder="Package Name"
        name="packageName"
        key="packageName"
        onChange={setDetails}
        value={details?.packageName}
        textarea={true}
      />
      <TextInput
        label="Short Description"
        placeholder="Short Description"
        name="shortDescription"
        key="shortDescription"
        onChange={setDetails}
        value={details?.shortDescription}
        textarea={true}
      />
      <TextInput
        label="Base Price"
        placeholder="Base Price"
        name="basePrice"
        key="basePrice"
        onChange={setDetails}
        value={details?.basePrice}
      />
      <TextInput
        label="Selling Price"
        placeholder="Selling Price"
        name="sellingPrice"
        key="sellingPrice"
        onChange={setDetails}
        value={details?.sellingPrice}
      />
      <TextInput
        label="Display Order"
        placeholder="Display Order"
        name="displayOrder"
        key="displayOrder"
        onChange={setDetails}
        value={details?.displayOrder}
      />
      <FilterDropdown
        onChange={setDetails}
        name="offer"
        key="offer"
        label="Offer"
        options={["Pick an offer type", "Offers", "Deals", "Coupons"]}
        value={details?.offer}
        values={[0, "offers", "deals", "coupons"]}
      />
      <TextInput
        label="Offer URL"
        placeholder="Offer URL"
        name="offerURL"
        key="offerURL"
        onChange={setDetails}
        value={details?.offerURL}
      />
      <TextInput
        label="Tags"
        placeholder="Tags"
        name="tags"
        key="tags"
        onChange={setDetails}
        value={details?.tags}
      />
      <FilterDropdown
        onChange={setDetails}
        name="subCategoryServiceRuleID"
        key="subCategoryServiceRuleID"
        label="Delivery Type"
        options={["Pick a delivery type", ...(sRule || [])]}
        value={details?.subCategoryServiceRuleID}
        values={[0, ...(sRuleID || [])]}
        disabled={edit}
      />
      <div className={styles.btn__container}>
        <Button title="Next" onClick={onClick} />
      </div>
    </div>
  );
}
