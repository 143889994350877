import React, {useEffect, useState, useMemo} from "react";
import {Table, ImageStamp} from "components";
import {useHistory} from "react-router-dom";
import {userSharesAction} from "redux/actions";
import {useDispatch, useSelector} from "react-redux";

const Shares = () => {
    // Routers
    const id = useHistory().location.pathname.split("/")[3];

    // Redux
    const dispatch = useDispatch();

    const {customers, userShares, userRole} = useSelector(state => ({
        customers: state.customers?.users,
        userShares: state.userShares,
        userRole: state.userRole?.filter(role => role.module === "Users")
    }));

    // States
    const [tableRows, setTableRows] = useState();

    // Filter States
    const [status, setStatus] = useState();

    // Fetch Package Add On
    useEffect(() => {
        const {mobile, countrycode} =
        customers?.find(user => user?.userId == id) || {};

        dispatch(userSharesAction.get({countryCode: countrycode, mobile}));

        return () => dispatch(userSharesAction.reset());
    }, [dispatch]);

    useEffect(() => {
        let filtered = userShares;

        if (!filtered?.length) return;

        // filter based on Status
        filtered = filtered?.filter(item =>
            status === "Enabled"
                ? item.enabled
                : status === "Disabled"
                    ? !item.enabled
                    : item
        );

        // Map table rows
        const data = filtered?.map(obj => ({
            id: obj?.id,
            packageName: obj?.packageName,
            packageImage: <ImageStamp src={obj.packageImage}/>,
            shortDescription: obj?.shortDescription,
            basePrice: obj?.basePrice,
            sellingPrice: obj?.sellingPrice,
            sharedVia: obj?.sharedVia,
            receiverName: obj?.receiverName,
            contactInfo: obj?.contactInfo,
            createdAt: `${new Date(obj.createdAt).toLocaleString()}`
        }));

        setTableRows(data);
    }, [userShares, status]);

    // Table Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "ID"
            },
            {
                title: "Package Name",
                sortBy: "packageName"
            },
            {
                title: "Image"
            },
            {
                title: "Description"
            },
            {
                title: "Base Price"
            },
            {
                title: "Selling Price"
            },
            {
                title: "Shared Via"
            },
            {
                title: "Receiver Name"
            },
            {
                title: "Contact"
            },
            {
                title: "Created At"
            }
        ],
        [tableRows]
    );

    return <Table headers={tableHeaders} data={tableRows}/>;
};

export default Shares;
