import React, {useEffect, useState, useMemo} from "react";
import styles from "./styles.module.scss";
import {
    MultiTable,
    SearchButton,
    LightTextButton,
    FilterDropdown,
    Status
} from "components";
import {spPreferencesAction} from "redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

export default function () {
    const {pathname} = useHistory().location;
    const id = pathname.split("/")[3];

    // Redux
    const dispatch = useDispatch();
    const spPreferences = useSelector(state => state.spPreferences);

    // States
    const [tableRows, setTableRows] = useState();

    // Filter States
    const [status, setStatus] = useState();

    // Fetch Package Add On
    useEffect(() => {
        dispatch(spPreferencesAction.get(id));

        return () => dispatch(spPreferencesAction.reset());
    }, [dispatch]);

    useEffect(() => {
        if (!spPreferences?.length) return;
        const data = spPreferences.map((qna, index) => {
            let modifiedQA = {...qna};

            modifiedQA.options = qna.options.map(ans => ({
                id: ans.id,
                display_name: ans.display_name,
                display_text: ans.display_text,
                enabled: <Status active={ans.enabled}/>,
                selected: <Status active={ans.selected}/>
            }));

            return modifiedQA;
        });
        setTableRows(data);
    }, [spPreferences, status]);

    // Search
    const onSearch = () => {
    };

    // MultiTable Header
    const tableHeaders = useMemo(
        () => [
            {
                title: "Question Name"
            },
            {
                title: "Question Help Text"
            }
        ],
        []
    );

    const secTableHeaders = useMemo(
        () => [
            {
                title: "ID"
            },
            {
                title: "Option Name"
            },
            {
                title: "Option Text"
            },
            {
                title: "Enabled"
            },
            {
                title: "Selected"
            }
        ],
        []
    );

    return (
        <div>
            <div className={styles.filter}>
                <FilterDropdown
                    onChange={e => setStatus(e.target.value)}
                    label="Status"
                    options={["All", "Enabled", "Disabled"]}
                />
                <SearchButton onClick={onSearch}/>
                <LightTextButton/>
            </div>

            {tableRows?.length
                ? tableRows.map((qna, index) => (
                    <MultiTable
                        key={index}
                        headers={tableHeaders}
                        data={[
                            {
                                display_name: qna.display_name,
                                display_text: qna.display_text
                            }
                        ]}
                        secHeaders={secTableHeaders}
                        secData={qna.options}
                    />
                ))
                : null}
        </div>
    );
}
