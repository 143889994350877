import {
  ADD_MODULE,
  GET_MODULES,
  RESET_MODULES,
  UPDATE_MODULE,
  DELETE_MODULE,
  ACTIVE_MODULE,
  INACTIVE_MODULE
} from "../actionTypes";

const modulesReducer = (state = [], action) => {
  switch (action.type) {
    case GET_MODULES:
      return action.payload;

    case ADD_MODULE:
      return [action.payload, ...state];

    case UPDATE_MODULE:
      return state.map(item =>
        item?.module_id === action.payload?.module_id ? action.payload : item
      );

    case DELETE_MODULE:
      return action.payload;

    case ACTIVE_MODULE:
      return action.payload;

    case INACTIVE_MODULE:
      return action.payload;

    case RESET_MODULES:
      return [];

    default:
      return state;
  }
};

export default modulesReducer;
