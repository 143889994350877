import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Table, StatusButton, DocumentModal, ImageStamp} from "components";
import {useHistory} from "react-router-dom";
import {subOrgAction} from "redux/actions";
import Comment from "./Comment";

export default function SubOrgDocumentsPanel() {
    // Routers
    const history = useHistory();
    const path = history.location.pathname;
    const id = path.split("/")[3];

    // States
    const [tableRows, setTableRows] = useState();
    const [editableDetail, setEditableDetails] = useState();
    const [isPopupVisible, setPopupVisible] = useState();
    const [isCommentPopup, setCommentPopup] = useState();
    const [status, setStatus] = useState({
        id: null,
        status: null,
        comment: ""
    });

    const dispatch = useDispatch();
    const subOrg = useSelector(state => state.subOrg);

    const handleChange = e => {
        setStatus({
            ...status,
            [e.target.name]: e.target.value
        });
    };

    useEffect(() => {
        let filtered = subOrg?.find(item => item.id == id)?.documents;

        if (!filtered?.length) {
            setTableRows();
            return;
        }

        // Map table rows
        let data = filtered?.map(obj => ({
            id: obj.id,
            documentName: obj.documentName,
            documentURL: (
                <ImageStamp
                    src={obj?.documentURL}
                    onClick={() => {
                        setEditableDetails(obj);
                        setPopupVisible(true);
                    }}
                />
            ),
            status: (
                <StatusButton
                    title={obj?.status}
                    options={
                        obj.documentURL?.length ? ["pending", "approved", "rejected"] : null
                    }
                    onChange={e => {
                        if (e === "rejected") {
                            dispatch(subOrgAction.rejectDoc(id, obj.id));
                            return;
                        }

                        setStatus({...status, status: e, id: obj.id});
                        setCommentPopup(true);
                    }}
                />
            ),
            comment: obj.comment,
            createdAt: `${new Date(obj.createdAt).toLocaleString()}`,
            updatedAt: `${new Date(obj.updatedAt).toLocaleString()}`
        }));

        setTableRows(data);
    }, [subOrg]);

    // Table Header
    const tableHeaders = [
        {
            title: "ID",
            sortBy: "id"
        },
        {
            title: "Document Name"
        },
        {
            title: "Document Image"
        },
        {
            title: "Status"
        },
        {
            title: "Comment"
        },
        {
            title: "Created",
            sortBy: "createdAt"
        },
        {
            title: "Updated",
            sortBy: "updatedAt"
        }
    ];

    const onReject = () => {
        dispatch(subOrgAction.rejectDoc(id, editableDetail?.id));
        setPopupVisible(false);
    };

    const onSave = () => {
        dispatch(subOrgAction.changeDocStatus(id, status));
        setCommentPopup(false);
    };
    return (
        <div>
            <Table headers={tableHeaders} data={tableRows}/>
            {isPopupVisible && (
                <DocumentModal
                    title={editableDetail?.documentName}
                    documentURL={editableDetail?.documentURL}
                    closeModal={() => setPopupVisible(false)}
                    onReject={onReject}
                />
            )}

            {isCommentPopup && (
                <Comment
                    closeModal={() => setCommentPopup(false)}
                    data={status}
                    handleChange={handleChange}
                    onSave={onSave}
                />
            )}
        </div>
    );
}
