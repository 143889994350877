import React, {useEffect, useState} from "react";
import {SideModal, Tabs} from "components";
import {modalTabRoutes} from "../tab-routes";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

const Package = () => {
    // Routers
    const {id} = useParams();

    const packages = useSelector(state => state.packages);
    const [titles, setTitles] = useState();

    useEffect(() => {
        const pkg = packages?.find(item => item?.id == id);

        const titleArr = [{key: "Package Name", value: pkg?.packageName}];
        setTitles(titleArr);
    }, [id]);

    return (
        <SideModal title="Package ID" titles={titles?.length ? [...titles] : []}>
            <Tabs routes={modalTabRoutes}/>
        </SideModal>
    );
};

export default Package;
