import styles from './PhoneInput.module.scss';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './PhoneInput.css';

const PhoneTextInput = ({ onChange, value }) => (
    
        <PhoneInput 
            containerClass={styles.container}
            inputClass={styles.input}
            buttonClass={styles.btn}
            enableSearch
            inputProps={{
                name: 'phone',
                required: true,
                autoFocus: true
            }}
            country="in"
            onChange = {onChange}
            specialLabel="Enter your contact number below"
            value = { value }
        />
);

export default PhoneTextInput;