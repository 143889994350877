import React, { useState } from "react";
import { useLocation, Switch, Route } from "react-router-dom";
import tabRoutes from "./tab-routes";
import { Tabs } from "components";
import Popup from "./popups/Discounts/Popup";
import { useSelector } from "react-redux";

const popups = [
  {
    title: "+ New Discount",
    path: "coupons",
    component: closeModal => <Popup closeModal={closeModal} />
  },
  {
    title: "+ New Discount",
    path: "deals",
    component: closeModal => <Popup closeModal={closeModal} />
  },
  {
    title: "+ New Discount",
    path: "offers",
    component: closeModal => <Popup closeModal={closeModal} />
  }
];

export default function Documents() {
  const location = useLocation();
  const currentLocation = location.pathname.split("/", 3)[2];

  const userRole = useSelector(state =>
    state.userRole?.filter(role => role.module === "Discounts")
  );

  // States
  const [modal, setModal] = useState(); // Popup open or close state

  return (
    <>
      {/* Panels */}
      <Tabs
        routes={tabRoutes}
        title={popups.find(popup => currentLocation === popup.path)?.title}
        onClick={
          userRole?.find(item => item.privilege === "Create")
            ? () => setModal(currentLocation)
            : null
        }
      />

      {
        // Popups
        modal &&
          popups?.length &&
          popups.map(
            popup =>
              currentLocation === popup.path &&
              popup.component(() => setModal(false))
          )
      }

      {/* Modals */}
      {tabRoutes[0]?.modalRoutes?.length && (
        <Switch>
          <Route path={`${tabRoutes[0]?.modalRoutes[0]?.path}`}>
            {tabRoutes[0]?.modalRoutes[0]?.component}
          </Route>
        </Switch>
      )}
    </>
  );
}
