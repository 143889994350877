import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import { Table } from "components";
import { useDispatch, useSelector } from "react-redux";
import { serviceRulesAction } from "redux/actions";

const tableHeaders = ["Service ID", "Type", "Description"];

const ServiceRules = () => {
  // Disptach redux action to get modules
  const dispatch = useDispatch();

  const { serviceRules, userRole } = useSelector(state => ({
    serviceRules: state.serviceRules,
    userRole: state.userRole?.filter(role => role.module === "Category")
  }));

  // Effects
  useEffect(() => {
    if (userRole?.find(item => item.privilege === "Read"))
      dispatch(serviceRulesAction.get());
  }, [dispatch]);

  // Custom Functions
  const data = serviceRules?.map(serviceRule => ({
    id: `${serviceRule.id}`,
    type: `${serviceRule.type}`,
    description: `${serviceRule.description}`
  }));

  return (
    <div>
      <div className={styles.filter}></div>
      <Table headers={tableHeaders} data={data} />
    </div>
  );
};

export default ServiceRules;
