import React, {useEffect, useMemo, useState} from 'react';
import styles from "../ServiceProviders/panels/styles.module.scss";
import {
    DocumentButton,
    FilterDropdown,
    ImageStamp,
    LightTextButton,
    Search,
    SearchButton, Status,
    StatusButton, SwitchButton,
    Table,
    TextInput,
    TextLink
} from "../../components";
import PackagesPopup from "../ServiceProviders/popups/Packages/PackagesPopup";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {serviceProviderAction, categoryAction} from "redux/actions";
import {internalSearch} from "../../helper";
import * as XLSX from "xlsx";
import {Modal} from "@material-ui/core";
import ReportModal from "../Report/ReportModal";
import SpNewModal from "./SPNewModal";

const initialFilterState = {
    userId: "",
    firstName: "",
    lastName: "",
    mobile: "",
    category: ""
};

function ServiceProviderNewPanel(props) {
    return (
        <></>
    );
}

export default ServiceProviderNewPanel;