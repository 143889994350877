import { GET_MANUAL_ROUTING } from "../actionTypes";

export default function manualRoutingReducer(state = null, action) {
  switch (action.type) {
    case GET_MANUAL_ROUTING:
      return action.payload;

    default:
      return state;
  }
}
