import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import styles from "./../styles.module.scss";
import {TextInput, Button, FilterDropdown, CheckBox} from "components";
import {serviceRulesAction, subCategoriesAction} from "redux/actions";

export default function Details({onClick, details, setDetails, edit}) {
    return (
        <div className={styles.main__container}>
            <FilterDropdown
                onChange={setDetails}
                name="questionType"
                label="Question Type"
                options={["Pick a type", "Detail", "Requirements", "Checklist"]}
                value={details?.questionType}
                values={[0, "detail", "requirements", "checklist"]}
            />
            <TextInput
                label="Question Name"
                placeholder="Question Name"
                name="questionName"
                onChange={setDetails}
                value={details?.questionName}
                textarea={true}
            />
            <TextInput
                label="Display Order"
                placeholder="Display Order"
                name="questionSortOrder"
                onChange={setDetails}
                value={details?.questionSortOrder}
                textarea={true}
            />
            <div className={styles.btn__container}>
                <Button title="Next" onClick={onClick}/>
            </div>
        </div>
    );
}
