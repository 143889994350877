import React from "react";
import styles from "./DocumentButton.module.scss";

export default function DocumentButton({ uploaded, approved, total, onClick }) {
  return (
    <div className={styles.document__button}>
      <button
        className={
          !uploaded
            ? styles.status__pending
            : uploaded === approved
            ? styles.status__success
            : uploaded - approved
            ? styles.status__danger
            : null
        }
        onClick={onClick}
      >
        <p>
          {!uploaded
            ? "Not Uploaded!"
            : uploaded === approved
            ? "All Approved!"
            : "Attention"}
        </p>
        {(uploaded || 0) - (approved || 0) ? (
          <span>{(uploaded || 0) - (approved || 0)}</span>
        ) : null}
      </button>
      <p>Uploaded: {`${uploaded || 0}/${total || 0}`}</p>
      <p>Approved: {approved || 0}</p>
    </div>
  );
}
