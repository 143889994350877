import {
  GET_PRIVILEGES,
  ADD_PRIVILEGE,
  UPDATE_PRIVILEGE
} from "../actionTypes";

const privilegesReducer = (state = [], action) => {
  switch (action.type) {
    case GET_PRIVILEGES:
      return action.payload;

    case ADD_PRIVILEGE:
      return [action.payload, ...state];

    case UPDATE_PRIVILEGE:
      return state.map(item =>
        item?.privilege_id === action.payload?.privilege_id
          ? action.payload
          : item
      );

    default:
      return state;
  }
};

export default privilegesReducer;
