import React from "react";
import styles from "./styles.module.scss";
import {Link, useHistory} from "react-router-dom";

const MenuItem = ({active, name, children, path, menuBreak}) => {
    const history = useHistory()
    return (
        <div
            className={`${styles.menu__item} ${active ? styles.active : ""} ${
                menuBreak ? styles.break : ""
            }`}
            onClick={() => {
                history.push(path)
            }}>
            {children}
            <p>{name}</p>
        </div>
    );
};

export default MenuItem;
