import {
  GET_DEFAULT_PRIVILEGES,
  ADD_DEFAULT_PRIVILEGE,
  UPDATE_DEFAULT_PRIVILEGE,
  DELETE_DEFAULT_PRIVILEGE,
  ACTIVE_DEFAULT_PRIVILEGE,
  INACTIVE_DEFAULT_PRIVILEGE
} from "../actionTypes";

const defaultPrivilegeReducer = (state = [], action) => {
  switch (action.type) {
    case GET_DEFAULT_PRIVILEGES:
      return action.payload;

    case ADD_DEFAULT_PRIVILEGE:
      return [action.payload, ...state];

    case UPDATE_DEFAULT_PRIVILEGE:
      return state.map(item =>
        item?.default_role_p_id === action.payload?.default_role_p_id
          ? action.payload
          : item
      );

    case DELETE_DEFAULT_PRIVILEGE:
      return action.payload;

    case ACTIVE_DEFAULT_PRIVILEGE:
      return action.payload;

    case INACTIVE_DEFAULT_PRIVILEGE:
      return action.payload;

    default:
      return state;
  }
};

export default defaultPrivilegeReducer;
