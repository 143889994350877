import React from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux'

export default function Alert() {

    const error = useSelector(state => state.error)

    if(error){
        return (
            <div className={styles.container}>
                {error}
            </div>
        )
    } else {
        return null
    }
}
