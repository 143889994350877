import React, { useState, useEffect } from "react";
import { TextInput, Modal, FilterDropdown } from "components";
import { useDispatch, useSelector } from "react-redux";
import { stateAction, cityAction } from "redux/actions";

export default function ({ closeModal }) {
  const [name, setName] = useState();

  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();

  const dispatch = useDispatch();
  const country = useSelector(state => state.country);
  const state = useSelector(state => state.state);

  // Get states based on country selection
  useEffect(() => {
    setSelectedState(0);
    if (selectedCountry === "Pick a country" || !selectedCountry) return;
    dispatch(stateAction.get(selectedCountry));
  }, [selectedCountry]);

  const onSave = () => {
    if (!name || !selectedCountry || !selectedState) return;
    dispatch(cityAction.add(selectedCountry, selectedState, name));
    closeModal();
  };

  return (
    <Modal title="Create New State" closeModal={closeModal} onSave={onSave}>
      <FilterDropdown
        onChange={e => setSelectedCountry(e.target.value)}
        value={selectedCountry}
        label="Country"
        options={
          country?.length
            ? ["Pick a country", ...country?.map(item => item.name)]
            : ["Pick a country"]
        }
        values={country?.length ? [0, ...country?.map(item => item.id)] : [0]}
      />
      <FilterDropdown
        onChange={e => setSelectedState(e.target.value)}
        value={selectedState}
        label="State"
        options={
          state?.length
            ? ["Pick a state", ...state?.map(item => item.name)]
            : ["Pick a state"]
        }
        values={state?.length ? [0, ...state?.map(item => item.id)] : [0]}
      />
      <TextInput
        label="City"
        placeholder="City"
        name="name"
        onChange={e => setName(e.target.value)}
        value={name}
      />
    </Modal>
  );
}
